import BackgroundRings from "../components/BackgroundRings";
import bronze_header from "../assets/bronze-plan-header.png"
import silver_header from "../assets/silver-plan-header.png"
import gold_header from "../assets/gold-plan-header.png"
import bronze_medal from "../assets/bronze-medal.png"
import silver_medal from "../assets/silver-medal.png"
import gold_medal from "../assets/gold-medal.png"
import tick from "../assets/plan-feature-tick.png"
import popup_close from "../assets/popup-close.svg"
import next_arrow from "../assets/banner-next.svg"
import prev_arrow from "../assets/banner-prev.svg"
import star from "../assets/star.svg"
import {useEffect, useState} from "react";
import auth from "../api/auth";
import stripe_api from "../api/protected/memberships";


const PricingPlans = () => {

    const customerReviews = [
        {
            "text":
                "“I have been seeing how big the impact is of having  a diagnostic at the beginning of coaching " +
                "process, and how we actually avoid a lot of potential challenges if we do it deep enough  and" +
                " with all of the stakeholders.”",
            "name": "Evija Vasacenko",
            "rating": 5
        }
    ]

    const [showPopup, setShowPopup] = useState(false)
    const [activeReviewIDX, setActiveReviewIDX] = useState(0)
    const [faqMenu1Open, setFaqMenu1Open] = useState(false)
    const [faqMenu2Open, setFaqMenu2Open] = useState(false)
    const [faqMenu3Open, setFaqMenu3Open] = useState(false)
    const [faqMenu4Open, setFaqMenu4Open] = useState(false)
    const [faqMenu5Open, setFaqMenu5Open] = useState(false)

    const [isAuthenticated, setIsAuthenticated] = useState(false)

    useEffect(() => window.scrollTo({top: 0, left: 0, behavior: 'instant',}), []);

    const prevReview = () => {
        if(activeReviewIDX === 0) {
            setActiveReviewIDX(customerReviews.length - 1)
        } else {
            setActiveReviewIDX(activeReviewIDX - 1)
        }
    }
    const nextReview = () => {
        if(activeReviewIDX === customerReviews.length - 1) {
            setActiveReviewIDX(0)
        } else {
            setActiveReviewIDX(activeReviewIDX + 1)
        }
    }

    const toggleMenu = (ev) => {
        if (!ev) return;
        switch (ev.target.id) {
            case "faq-row-1":
                setFaqMenu1Open(!faqMenu1Open)
                break
            case "faq-row-2":
                setFaqMenu2Open(!faqMenu2Open)
                break
            case "faq-row-3":
                setFaqMenu3Open(!faqMenu3Open)
                break
            case "faq-row-4":
                setFaqMenu4Open(!faqMenu4Open)
                break
            case "faq-row-5":
                setFaqMenu5Open(!faqMenu5Open)
                break
            default:
                break
        }
    }

    const closePopup = () => {
        setShowPopup(false)
    }

    const openPopup = () => {
        setShowPopup(true)
    }

    return (
        <div className="learn-more-page-container unrestricted pricing-plans">
            <div className="background-rings-container">
                <BackgroundRings top={-370} right={-143}/>
            </div>
            <div className="learn-more-page-title">Choose the plan that’s right for you</div>
            <div className="learn-more-page-subtext">
                Whatever level of support you need with your Team
                Coaching, <b className="bold">PERILL Diagnostics for Teams</b> has a plan that’s suitable for you.
            </div>
            <div className="pricing-plans-page-title">Pricing plans for subscribers</div>
            <div className="pricing-plans-page-subtext">
                Simply select your preferred plan from the options below and sign up today.
            </div>
            <div className="pricing-plans-plan-container home-contents">
                <div className="home-page-background-circle" style={{top: 1000, left: 850}}/>
                <div className="pricing-plans-plan bronze-plan">
                    <div className="pricing-plans-plan-header-container">
                        <div className="pricing-plans-plan-header-image">
                            <img src={bronze_header} alt=""/>
                        </div>
                        <div className="pricing-plans-plan-header-text-container">
                            <div className="pricing-plans-plan-header">BRONZE</div>
                            <div className="pricing-plans-plan-price">Free</div>
                        </div>
                        <div className="pricing-plans-plan-header-medal-container">
                            <img src={bronze_medal} alt="Bronze Medal"/>
                        </div>
                    </div>
                    <div className="pricing-plans-plan-summary-container">
                        Ideal for Line Managers & HR Professionals who want to improve team effectiveness but are
                        not formally trained as Team Coaches.
                    </div>
                    <div className="pricing-plans-plan-features-container">
                        <div className="pricing-plans-plan-features-title">Free version with limited capability</div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Manage unlimited diagnostics for a <span className="feature-span">single company</span>
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Create unlimited numbers of teams & team member participants within that company
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Invite an unlimited number of team participants to complete a survey.
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Invite up to 5 stakeholders per team to complete a survey
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Issue an unlimited number of LITE surveys (anonymised data)
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Initial, Mid and End Point surveys available per team
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Run diagnostic report summaries for all surveys (anonymised data)
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Create comparison report (performance change over time) for every team
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick"/>
                            <div className="pricing-plans-plan-feature-text">
                                <span className="bold">INCLUDES:</span>
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                3 months free access to CCMI’s Coaching & Mentoring Resource Library
                            </div>
                        </div>
                    </div>
                    <div className="home-page-button"
                         onClick={() => auth.protectedNavigate("/dashboard", "/register")}>
                        GET THIS PLAN
                    </div>
                </div>
                <div className="pricing-plans-plan silver-plan">
                    <div className="pricing-plans-plan-header-container">
                        <div className="pricing-plans-plan-header-image">
                            <img src={silver_header} alt=""/>
                        </div>
                        <div className="pricing-plans-plan-header-text-container">
                            <div className="pricing-plans-plan-header">SILVER</div>
                            <div className="pricing-plans-plan-price">
                                £350 <span className="pricing-plans-price-span">/ PER ANNUM</span>
                            </div>
                        </div>
                        <div className="pricing-plans-plan-header-medal-container">
                            <img src={silver_medal} alt="Silver Medal"/>
                        </div>
                    </div>
                    <div className="pricing-plans-plan-summary-container">
                        Ideal for Team Coaches who want an effective team diagnostic tool and want to grow this side
                        of their practice. Suitable for newly qualified team coaches or those who only have a few
                        team coaching engagements a year.
                    </div>
                    <div className="pricing-plans-plan-features-container">
                        <div className="pricing-plans-plan-features-title">Everything in Bronze, plus:</div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Manage unlimited diagnostics for <span
                                className="feature-span">up to 3 client companies</span>
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Invite an unlimited number of stakeholders per team to complete a survey
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Issue an unlimited number of LITE surveys (attributable data)
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Issue an unlimited number of FULL surveys (attributable data)
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Run detailed diagnostic reports for all surveys (attributable data)
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Configure diagnostic reports / add context commentary
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                View & Compare Individual Participant Scores (with role overlay)
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Create comparison report (performance change over time) for every team
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Configure comparison reports / add context commentary
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Run Coach ROI & Feedback report (measuring change over time)
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick"/>
                            <div className="pricing-plans-plan-feature-text">
                                <span className="bold">INCLUDES:</span>
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                6 months free access to CCMI’s Coaching & Mentoring Resource Library
                            </div>
                        </div>
                    </div>
                    <div className="home-page-button" onClick={() => stripe_api.GetSilver("/pricing-plans")}>
                        GET THIS PLAN
                    </div>
                </div>
                <div className="pricing-plans-plan gold-plan">
                    <div className="pricing-plans-plan-header-container">
                        <div className="pricing-plans-plan-header-image">
                            <img src={gold_header} alt=""/>
                        </div>
                        <div className="pricing-plans-plan-header-text-container">
                            <div className="pricing-plans-plan-header">GOLD</div>
                            <div className="pricing-plans-plan-price">
                                £750 <span className="pricing-plans-price-span">/ PER ANNUM</span>
                            </div>
                        </div>
                        <div className="pricing-plans-plan-header-medal-container">
                            <img src={gold_medal} alt="Gold Medal"/>
                        </div>
                    </div>
                    <div className="pricing-plans-plan-summary-container">
                        Ideal for Team Coaches who want an effective team diagnostic tool and where team coaching is
                        a significant part of their practice. Suitable for experienced team coaches or those with
                        multiple team coaching engagements a year.
                    </div>
                    <div className="pricing-plans-plan-features-container">
                        <div className="pricing-plans-plan-features-title">Everything in Silver, plus:</div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Manage unlimited diagnostics for <span className="feature-span">unlimited client companies</span>
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Add your company logo to dual brand reports
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Add your Co-Coach name to reports
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Share Company / Team access with your co–coach
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Exclusive invitation to join a closed LinkedIn group of PERILL accredited coaches.
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick"/>
                            <div className="pricing-plans-plan-feature-text">
                                <span className="bold">INCLUDES:</span>
                            </div>
                        </div>
                        <div className="pricing-plans-plan-feature">
                            <div className="pricing-plans-plan-feature-tick">
                                <img src={tick} alt="Tick"/>
                            </div>
                            <div className="pricing-plans-plan-feature-text">
                                Ongoing free access to CCMI’s Coaching & Mentoring Resource Library
                            </div>
                        </div>
                    </div>
                    <div className="home-page-button" onClick={() => stripe_api.GetGold("/pricing-plans")}>
                        GET THIS PLAN
                    </div>
                </div>
            </div>
            <div className="pricing-plans-page-button-container home-contents">
                <div className="home-page-button filled-button" onClick={openPopup}>
                    Looking for an Enterprise solution?
                </div>
                {showPopup ?
                    <div className="pricing-plans-email-us-popup">
                        <div className="pricing-plans-email-us-popup-close" onClick={closePopup}>
                            <img className="link" src={popup_close} alt="Close"/>
                        </div>
                        <div className="pricing-plans-email-us-text">
                            If you are from a larger enterprise and looking for a more customised solution that is tailored
                            to meet the needs of your organisations coaching requirements, then please get in touch by
                            emailing us at
                        </div>
                        <div className="pricing-plans-email-us-email">
                            diagnostics@clutterbuck-cmi.com
                        </div>
                    </div>
                    :null}

            </div>
            <div className="pricing-plans-page-banner-container">
                <div className="home-page-perill-diagnostics-banner-mark pricing-plans"><b>“</b></div>
                <div className="pricing-plans-page-banner-title">What our clients say about PERILL</div>
                <div className="pricing-plans-page-banner-description-container">
                    <img src={prev_arrow} alt="Previous" onClick={prevReview}/>
                    <div className="pricing-plans-page-banner-description">
                        <i>{customerReviews[activeReviewIDX].text}</i>
                    </div>
                    <img src={next_arrow} alt="Next" onClick={nextReview}/>
                </div>
                <div className="pricing-plans-page-banner-name">{customerReviews[activeReviewIDX].name}</div>
                <div className="pricing-plans-page-banner-stars-container">
                    {Array.from({length: customerReviews[activeReviewIDX].rating},
                        (_,idx) => <img src={star} alt="Star"/>)
                    }
                </div>
            </div>
            <div className="pricing-plans-page-compare-title">Compare our plans</div>
            <div className="pricing-plans-page-compare-subtext">Find out what works best for you and your team.</div>
            <div className="pricing-plans-compare-container home-contents">
                <div className="home-page-background-circle" style={{top: -280, left: -280}}/>
                <div className="home-page-background-circle" style={{top: 1150, left: 800}}/>
                <div className="pricing-plans-compare-column feature">
                    <div className="pricing-plans-compare-column-header">Full Feature List</div>
                    <div className="pricing-plans-compare-column-row highlighted">Proof of qualification needed*</div>
                    <div className="pricing-plans-compare-column-row">Number of Companies you can service each year</div>
                    <div className="pricing-plans-compare-column-row">Issue unlimited LITE surveys (anonymised data)</div>
                    <div className="pricing-plans-compare-column-row">Issue unlimited LITE surveys (attributable data)</div>
                    <div className="pricing-plans-compare-column-row">Issue unlimited FULL surveys (anonymised data)</div>
                    <div className="pricing-plans-compare-column-row">Issue unlimited FULL surveys (attributable data)</div>
                    <div className="pricing-plans-compare-column-row">Create Teams and assign participants by role (Team Member, Leader, and Stakeholder)</div>
                    <div className="pricing-plans-compare-column-row">Invite Team Participants to complete survey via mail portal</div>
                    <div className="pricing-plans-compare-column-row">Invite Stakeholder Participants to complete survey via mail portal</div>
                    <div className="pricing-plans-compare-column-row">Create diagnostic reports (anonymised data)</div>
                    <div className="pricing-plans-compare-column-row">Create diagnostic reports (attributable data)</div>
                    <div className="pricing-plans-compare-column-row">Configure diagnotic report / add context commentary</div>
                    <div className="pricing-plans-compare-column-row">View & Compare Individual Participant Scores</div>
                    <div className="pricing-plans-compare-column-row">Create comparison report (performance change over time)</div>
                    <div className="pricing-plans-compare-column-row">Configure comparison report / add context commentary</div>
                    <div className="pricing-plans-compare-column-row">Create ROI & Feedback report (measuring change over time)</div>
                    <div className="pricing-plans-compare-column-row">Add your company logo to dual brand reports</div>
                    <div className="pricing-plans-compare-column-row">Add your Co-Coach name to reports</div>
                    <div className="pricing-plans-compare-column-row">Share Company / Team access with your co-coach</div>
                    <div className="pricing-plans-compare-column-row">Invite to closed Linked In group of PERILL coaches</div>
                    <div className="pricing-plans-compare-column-row final">Free access to CCMI's Content Management Resource Library</div>
                </div>
                <div className="pricing-plans-compare-column bronze">
                    <div className="pricing-plans-compare-column-header">
                        <div className="pricing-plans-compare-column-header-title">BRONZE</div>
                        <div className="pricing-plans-compare-column-header-description">
                            Business Leaders not formally trained as Team Coaches
                        </div>
                        <div className="pricing-plans-compare-column-header-price">FREE</div>
                        <div className="home-page-button"
                             onClick={() => auth.protectedNavigate("/dashboard", "/register")}>
                            GET THIS PLAN
                        </div>
                    </div>
                    <div className="pricing-plans-compare-column-row highlighted">
                        <div className="pricing-plans-tick-circle red">✗</div>
                        <div className="detail">N/A</div>
                    </div>
                    <div className="pricing-plans-compare-column-row">1</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row">Up to 5 per team</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row final">3 months</div>
                </div>
                <div className="pricing-plans-compare-column silver">
                    <div className="pricing-plans-compare-column-header">
                        <div className="pricing-plans-compare-column-header-title">SILVER</div>
                        <div className="pricing-plans-compare-column-header-description">
                            Trained Team Coaches with limited experience and / or client teams.
                        </div>
                        <div className="pricing-plans-compare-column-header-price">£350 per year</div>
                        <div className="home-page-button" onClick={() => stripe_api.GetSilver("/pricing-plans")}>
                            GET THIS PLAN
                        </div>
                    </div>
                    <div className="pricing-plans-compare-column-row highlighted">
                        <div className="pricing-plans-tick-circle green">✓</div>
                        <div className="detail">see details below</div>
                    </div>
                    <div className="pricing-plans-compare-column-row">Up to 3</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row">Unlimited</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row red">✗</div>
                    <div className="pricing-plans-compare-column-row final">6 months</div>
                </div>
                <div className="pricing-plans-compare-column gold">
                    <div className="pricing-plans-compare-column-header">
                        <div className="pricing-plans-compare-column-header-title">GOLD</div>
                        <div className="pricing-plans-compare-column-header-description">
                            Experienced Team Coaches with multiple team coaching engagements a year.
                        </div>
                        <div className="pricing-plans-compare-column-header-price">£750 per year</div>
                        <div className="home-page-button" onClick={() => stripe_api.GetGold("/pricing-plans")}>
                            GET THIS PLAN
                        </div>
                    </div>
                    <div className="pricing-plans-compare-column-row highlighted">
                        <div className="pricing-plans-tick-circle green">✓</div>
                        <div className="detail">see details below</div>
                    </div>
                    <div className="pricing-plans-compare-column-row">Unlimited</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row">Unlimited</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row green">✓</div>
                    <div className="pricing-plans-compare-column-row final">
                        <div>Ongoing</div>
                        <div className="detail">(Until subscription is cancelled)</div>
                    </div>
                </div>
            </div>
            <div className="pricing-plans-table-subtext-container home-contents">
                <span className="bold">*Suitable qualified Team Coaches from the following systemic programmes including:</span> CMI Team Coach
                Practitioner, GTCI Team Coach Practitioner (either PERILL or STC streams), AOEC / Renewal Associates
                Systemic Team Coaching Certificate or Diploma, Henley Business School, ORSC, Ruth Wageman’s 6
                Conditions, Team Coach International, Smurfit Business School (Dublin), High Performance Team Coaching
                System (Carr) and any other EMCC & ICF accredited programs at the same level.
            </div>
            <div className="pricing-plans-questions-title">Answering your common questions</div>
            <div className="pricing-plans-questions-subtext">
                Sed a eros sodales diam sagittis faucibus. Cras id erat nisl. Fusce faucibus nulla sed finibus egestas.
                Vestibulum purus magna, auctor consectetur sem nec, consectetur porta.
            </div>
            <div className="get-in-touch-dropdown-container home-contents">
                <div className="home-page-background-circle" style={{top: 180, left: -280}}/>
                <div className="get-in-touch-dropdown-row">
                    <div className="get-in-touch-dropdown-row-header no-select" id="faq-row-1" onClick={toggleMenu}>
                        <div className="get-in-touch-dropdown-row-title" id="faq-row-1">
                            What ante quis tincidunt porta, neque metus dapibus velit, porta aliquam nisi est quis
                            turpis?
                        </div>
                        <div className="get-in-touch-dropdown-row-button" id="faq-row-1">
                            {faqMenu1Open ? "-" : "+"}
                        </div>
                    </div>
                    <div className={faqMenu1Open ? "get-in-touch-dropdown-row-body" : "get-in-touch-dropdown-row-body hidden"}>
                        <div className="get-in-touch-dropdown-row-body-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna
                        </div>
                    </div>
                </div>
                <div className="get-in-touch-dropdown-row">
                    <div className="get-in-touch-dropdown-row-header no-select" id="faq-row-2" onClick={toggleMenu}>
                        <div className="get-in-touch-dropdown-row-title" id="faq-row-2">
                            What ante quis tincidunt porta, neque metus dapibus velit, porta aliquam nisi est quis
                            turpis?
                        </div>
                        <div className="get-in-touch-dropdown-row-button" id="faq-row-2">
                            {faqMenu2Open ? "-" : "+"}
                        </div>
                    </div>
                    <div className={faqMenu2Open ? "get-in-touch-dropdown-row-body" : "get-in-touch-dropdown-row-body hidden"}>
                        <div className="get-in-touch-dropdown-row-body-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna
                        </div>
                    </div>
                </div>
                <div className="get-in-touch-dropdown-row">
                    <div className="get-in-touch-dropdown-row-header no-select" id="faq-row-3" onClick={toggleMenu}>
                        <div className="get-in-touch-dropdown-row-title" id="faq-row-3">
                            What ante quis tincidunt porta, neque metus dapibus velit, porta aliquam nisi est quis
                            turpis?
                        </div>
                        <div className="get-in-touch-dropdown-row-button" id="faq-row-3">
                            {faqMenu3Open ? "-" : "+"}
                        </div>
                    </div>
                    <div className={faqMenu3Open ? "get-in-touch-dropdown-row-body" : "get-in-touch-dropdown-row-body hidden"}>
                        <div className="get-in-touch-dropdown-row-body-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna
                        </div>
                    </div>
                </div>
                <div className="get-in-touch-dropdown-row">
                    <div className="get-in-touch-dropdown-row-header no-select" id="faq-row-4" onClick={toggleMenu}>
                        <div className="get-in-touch-dropdown-row-title" id="faq-row-4">
                            What ante quis tincidunt porta, neque metus dapibus velit, porta aliquam nisi est quis
                            turpis?
                        </div>
                        <div className="get-in-touch-dropdown-row-button" id="faq-row-4">
                            {faqMenu4Open ? "-" : "+"}
                        </div>
                    </div>
                    <div className={faqMenu4Open ? "get-in-touch-dropdown-row-body" : "get-in-touch-dropdown-row-body hidden"}>
                        <div className="get-in-touch-dropdown-row-body-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna
                        </div>
                    </div>
                </div>
                <div className="get-in-touch-dropdown-row">
                    <div className="get-in-touch-dropdown-row-header no-select" id="faq-row-5" onClick={toggleMenu}>
                        <div className="get-in-touch-dropdown-row-title" id="faq-row-5">
                            What ante quis tincidunt porta, neque metus dapibus velit, porta aliquam nisi est quis
                            turpis?
                        </div>
                        <div className="get-in-touch-dropdown-row-button" id="faq-row-5">
                            {faqMenu5Open ? "-" : "+"}
                        </div>
                    </div>
                    <div className={faqMenu5Open ? "get-in-touch-dropdown-row-body" : "get-in-touch-dropdown-row-body hidden"}>
                        <div className="get-in-touch-dropdown-row-body-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingPlans