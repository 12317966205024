import types from "../assets/constants/types";
import summary from "../assets/overview.svg"
import {useNavigate} from "react-router-dom";

const SurveysGridRow = (props) => {
    const navigate = useNavigate()
    const survey = props.survey;

    const handleRowClick = (ev) => {
        if(ev.target.id === `survey-grid-navigate-${survey.id}`) {
            if(survey.demo) {
                navigate(`/surveys/${survey.id}?overview=true&demo=true`)
            } else {
                navigate(`/surveys/${survey.id}?overview=true`)
            }
        } else {
            props.handleClick(survey)
        }
    }

    return (
        <div className={survey.hidden ? "surveys-grid-row hidden link" : "surveys-grid-row link"} id={`survey-grid-${survey.id}`} onClick={handleRowClick}>
            <div className="surveys-grid-row-company-logo" style={{backgroundColor: '#' + survey.colour}} id={`survey-grid-${survey.id}`}/>
            <div className="surveys-grid-row-company-name" id={`survey-grid-${survey.id}`}>{survey.company_name}</div>
            <div className="surveys-grid-row-team-name" id={`survey-grid-${survey.id}`}>{survey.team_name}</div>
            <div className="surveys-grid-row-survey-name" id={`survey-grid-${survey.id}`}>{survey.survey_name}</div>
            <div className="surveys-grid-row-survey-type" id={`survey-grid-${survey.id}`}>{types.survey_types.find(x => x.id === survey.survey_type).name}</div>
            <div className="surveys-grid-row-survey-point" id={`survey-grid-${survey.id}`}>{types.point_types.find(x => x.id === survey.survey_point).name}</div>
            <div className="surveys-grid-row-survey-summary">
                <img src={summary} alt="summary icon" id={`survey-grid-navigate-${survey.id}`}/>
            </div>
        </div>
    )
}

export default SurveysGridRow;
