import {useEffect, useState} from "react";
import NoData from "./NoData";
import SelectionPopup from "./SelectionPopup";
import {Chart as ChartJS} from "chart.js/auto"; //THIS IS NOT UNUSED! REMOVING THIS LINE WILL CAUSE A CRASH!
// Fix discussed between JE and OP on 13/08/2024.
import {Bar} from "react-chartjs-2";
import dashboard_api from "../../api/protected/dashboard";
import cjs_config from "../../utils/ChartJSConfig";
import edit from "../../assets/edit-pencil.svg";
import del from "../../assets/delete-bin.svg";


const SurveyBarChart = (props) => {

    const [showEditMenu, setShowEditMenu] = useState(true)
    const [noData, setNoData] = useState(false);
    const [allCompanies, setAllCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState(0)
    const [isDemo, setIsDemo] = useState(false)

    const [chartData, setChartData] = useState({labels: [], datasets: []})

    useEffect(() => {
        setAllCompanies(props.companies)
    }, [props.companies]);

    useEffect(() => {
        if(!props.context) return;
        setSelectedCompany(props.context)
        setShowEditMenu(false);
    }, [props.context])

    useEffect(() => {
        if(!selectedCompany) return;
        getChartData(selectedCompany)
    }, [selectedCompany])

    useEffect(() => {
        setIsDemo(props.demo)
    }, [props.demo])

    const getChartData = (company_id) => {
        let response;
        if(isDemo) {
            response = dashboard_api.getDemoBarChartData(company_id)
        } else {
            response = dashboard_api.getBarChartData(company_id);
        }
        response.then(response => {
            let data = response.data
            if(!Object.keys(data).length) {
                console.log('setting no data')
                setShowEditMenu(false);
                setNoData(true);
                return;
            }
            let labels = []
            let datasets = []
            let created = []
            let sent = []
            let complete = []
            for(let idx in data) {
                labels.push(data[idx].team_name)
                created.push(data[idx].created)
                sent.push(data[idx].sent)
                complete.push(data[idx].complete)
            }
            datasets.push(cjs_config.constructDashboardBarDataset('Created', created, '#39A6E3'))
            datasets.push(cjs_config.constructDashboardBarDataset('Sent', sent, '#773DBD'))
            datasets.push(cjs_config.constructDashboardBarDataset('Complete', complete, '#F06C11'))
            setChartData({
                'labels': labels,
                'datasets': datasets
            })
            setShowEditMenu(false)
        }).catch()
    }

    const updateSelectedCompany = (company) => {
        if(!company) return;
        setIsDemo(company.demo)
        let response = dashboard_api.updateComponent(props.id, company.id, company.demo);
        response.then(() => {
            setSelectedCompany(company.id)
        })
    }

    const openEditMenu = (ev) => {
        if(!ev) return;
        setShowEditMenu(true)
    }
    const closeEditMenu = (ev) => {
        if(!ev) return;
        setShowEditMenu(false);
    }

    const titleFormat = (title="") => {
        if(!title) return "Survey Barchart";
        if(title.length > 20) return title.substring(0, 16) + '...'
        else return title
    }

    return (
        <>
            <div className="dashboard-component dashboard-chart">
                {!noData ?
                    <>
                        <div className="survey-line-chart-top-row">
                            <div className="survey-bar-chart-title">
                                {allCompanies.length && selectedCompany ?
                                    titleFormat(allCompanies.find((item) => item.id === selectedCompany).name)
                                    :
                                    "Survey Barchart"
                                }
                            </div>
                            <div className="survey-line-chart-legend-container">
                                <div className="survey-line-chart-legend">
                                    <div className="survey-line-chart-legend-dot" style={{backgroundColor: "#39A6E3"}}/>
                                    <div className="survey-line-chart-legend-title">Created</div>
                                </div>
                                <div className="survey-line-chart-legend">
                                    <div className="survey-line-chart-legend-dot" style={{backgroundColor: "#773DBD"}}/>
                                    <div className="survey-line-chart-legend-title">Sent</div>
                                </div>
                                <div className="survey-line-chart-legend">
                                    <div className="survey-line-chart-legend-dot" style={{backgroundColor: "#F06C11"}}/>
                                    <div className="survey-line-chart-legend-title">Complete</div>
                                </div>
                            </div>
                        </div>
                        {chartData ? <Bar data={chartData} options={cjs_config.dashboard_bar_options} height={160}/> : null}
                        <div className="dashboard-component-button-container survey-bar-chart">
                            <div className="dashboard-component-button" onClick={openEditMenu}>
                                <img src={edit} alt="Edit Component"/>
                            </div>
                            <div className="dashboard-component-button" onClick={() => props.manage(props.id)}>
                                <img src={del} alt="Delete Component"/>
                            </div>
                        </div>
                    </>
                    : <NoData id={props.id} edit={openEditMenu.bind(this)} delete={props.manage} context="company"/>
                }
            </div>
            {showEditMenu ?
                <SelectionPopup id={props.id}
                                title="Team Survey Completion"
                                selection_level={0}
                                companies={allCompanies}
                                selected={selectedCompany}
                                updateSelected={updateSelectedCompany.bind(this)}
                                closePopup={closeEditMenu.bind(this)}
                                deleteComponent={props.manage}
                />
                : null
            }
        </>

    )
}

export default SurveyBarChart