const getMessageForError = (msg) => {
    let message
    switch (msg.id) {
        case 4000006: //Incorrect login details
            message = "The provided credentials are invalid, check for spelling mistakes in your email address or password."
            break;
        case 1010004: //mfa authentication challenge - leave blank
            message = ""
            break;
        default:
            message = msg.text
    }
    return message
}

export default getMessageForError