const QuestionnaireInfo = () => {
    return (
        <div className="questionnaire-info-container">
            <div className="image">i</div>
            <div className="text">For each statement, please score them from 1 ( Strongly disagree) to 10
                (Strongly agree) and then rate your confidence level in the score that you have given from 1
                (Not confident) to 5 (Very confident).</div>
        </div>
    )
}

export default QuestionnaireInfo