import {useEffect, useState} from "react";
import Slider from "../Slider";
import profile_api from "../../api/protected/profile";
import stripe_api from "../../api/protected/memberships";
import {Link, useNavigate} from "react-router-dom";
import TextUtils from "../../utils/TextUtils";
import AccreditationPopup from "../AccreditationPopup";
import bronze_medal from "../../assets/bronze-medal.png"
import silver_medal from "../../assets/silver-medal.png"
import gold_medal from "../../assets/gold-medal.png"
import verified from "../../assets/accreditation-verified.svg"
import awaiting from "../../assets/accreditation-awaiting.svg"

const SettingsPreferences = (props) => {
    const navigate = useNavigate()
    const [subscription, setSubscription] = useState()
    const [accreditation, setAccreditation] = useState()

    const updateProfile = props.updateProfile;
    const [newsletter, setNewsletter] = useState();
    const [autofill, setAutofill] = useState(true)

    const [showAccreditationPopup, setShowAccreditationPopup] = useState(false)

    const updateNewsletter = (setting) => {
        setNewsletter(setting);
        let body = {"newsletter_consent": setting}
        let response = profile_api.updateNewsletter(body);
        response.then(() => {
            updateProfile();
        }).catch(() => {})
    }

    const updateAutofill = (setting) => {
        setAutofill(setting);
        let response = profile_api.updateAutofill({"autofill": setting});
        response.then(() => {
            updateProfile();
        }).catch(() => {})
    }

    useEffect(() => {
        getSubscription()
        GetAccreditation()
    }, [])

    const getSubscription = () => {
        let response = profile_api.getSubscription();
        response.then(response => {
            setSubscription(response.data.subscription)
            console.log(response.data.subscription)
        }).catch(() => {})
    }

    const GetAccreditation = () => {
        let response = profile_api.getAccreditation();
        response.then(response => {
            setAccreditation(response.data.accreditation);
        }).catch(err => {console.log(err)})
    }

    const createPortalSession = () => {
        stripe_api.CreatePortalSession();
    }

    useEffect(() => {
        if (!props.profile) return;
        setNewsletter(props.profile.marketing_consent);
        setAutofill(props.profile.autofill_enabled)
    }, [props.profile]);

    const handleManageClick = (ev) => {
        if(!ev) return;
        if(subscription && subscription.type) {
            createPortalSession()
        } else {
        navigate('/pricing-plans', {replace: true})
        }
    }

    return (
        <div className="settings-form-container">
            {showAccreditationPopup && showAccreditationPopup ? <AccreditationPopup /> : null}
            <div className="settings-form-title">Preferences</div>
            <div className={`settings-current-plan-container ${subscription && subscription.name ? subscription.name : ""}`}>
                <div className="settings-current-plan-header">
                    <div className="settings-current-plan-header-text">Your current plan</div>
                    <div className="settings-current-plan-header-plan-container">
                        <div>{subscription && subscription.name.toUpperCase()}</div>
                        <img src={subscription && subscription.type === 2 ?
                            gold_medal : subscription && subscription.type === 1 ?
                                silver_medal : bronze_medal
                        } alt="Your current plan"/>
                    </div>
                </div>
                <div className="settings-current-plan-body">
                    <div className="settings-current-plan-body-left">
                        <div className="settings-current-plan-body-left-header">Membership and billing details</div>
                        <div className="settings-current-plan-body-left-membership-container">
                            <div className="membership-text medium">Membership:</div>
                            <div className="membership-value bold">
                                {subscription && subscription.name.toUpperCase()}
                            </div>
                        </div>
                    </div>
                    <div className="manage-subscription-button bold" onClick={handleManageClick}>
                        MANAGE SUBSCRIPTION
                    </div>
                </div>
            </div>
            <div className="settings-page-subheading">Accreditation</div>
            <div className="settings-page-text">
                {accreditation && accreditation ?
                    <div className="settings-page-accreditation-pending">
                        <div className="accreditation-pending-heading">
                            Type: <span className="regular">{accreditation.type}</span>
                        </div>
                        <div className="accreditation-pending-heading-container">
                            <div className="accreditation-pending-heading">
                                Verification Status:
                            </div>
                            {accreditation.verified && accreditation.verification_date ?
                                <div className="accreditation-result verified bold">
                                    <img src={verified} alt="Verified"/>
                                    Verified
                                </div>
                                : !accreditation.verification_date ?
                                    <div className="accreditation-result awaiting bold">
                                        <img src={awaiting} alt="Awaiting Verification"/>
                                        Awaiting Verification
                                    </div>
                                    :
                                    <div className="accreditation-result denied bold">Denied</div>
                            }
                        </div>
                    </div>
                    :
                    <>
                        You can upload proof of accreditation at any time. Approval of accreditation will be within
                        7 working days. You will not be able to access the full features of the (Gold/Silver) plan
                        until you have uploaded these documents and they have been verified, even if your
                        payment has been taken.
                        <div className="begin-accreditation-button">
                            <div className="field-input-entry">
                                <div className="manage-subscription-button bold"
                                     onClick={() => setShowAccreditationPopup(true)}>
                                    BEGIN ACCREDITATION
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className="settings-page-subheading">Subscribe</div>
            <div className="settings-page-text">
                Get the latest news, blogs and thought leadership on Coaching, Mentoring and Leadership from
                Professor David Clutterbuck and the CCMI Network. Delivered directly to you via email every few
                months.
                We respect your data and privacy.
                <br/><br/>
                You can unsubscribe at any time. See our <Link to="/privacy" className="settings-link undecorated">Privacy
                Policy</Link> for details.
            </div>
            <div className="subscribe-slider">
                <Slider update={updateNewsletter.bind(this)} state={newsletter}
                        text="I consent to receiving CCMI Newsletters via email."
                />
            </div>
            <div className="settings-page-subheading">Autofilled Tasks</div>
            <div className="settings-page-text">
                Enable/Disable automatic tasks in your dashboard to-do list.
            </div>
            <div className="subscribe-slider">
                <Slider update={updateAutofill.bind(this)} state={autofill}
                        text={autofill ? "Disable" : "Enable"}
                />
            </div>
        </div>
    )
}

export default SettingsPreferences