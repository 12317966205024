import {useState} from "react";
import SummaryGraphicFlexedPoint from "./SummaryGraphicFlexedPoint";
import SummaryGraphicPoint from "./SummaryGraphicPoint";


const SummaryGraphical = (props) => {

    const cardData = props.cardData

    const [attributableData, setAttributableData] = useState(false)
    const [showTeamMembers, setShowTeamMembers] = useState(true)
    const [showTeamLeaders, setShowTeamLeaders] = useState(true)
    const [showStakeholders, setShowStakeholders] = useState(true)

    const normal_round = (value) => {
        if (!value) return;
        if (value - Math.floor(value) < 0.5) return Math.floor(value);
        return Math.ceil(value)
    }

    const getPillarColour = (pillar_id) => {
        switch (pillar_id) {
            case 1: return "pillar-blue"
            case 2: return "pillar-red"
            case 3: return "pillar-green"
            case 4: return "pillar-purple"
            case 5: return "pillar-pink"
            case 6: return "pillar-orange"
        }
    }

    const scorePoints = (theme, level) => {
        let points = []
        let tm_total = 0
        let tl_total = 0
        let st_total = 0
        let tm_avg;
        let tl_avg;
        let st_avg;
        let max = 0
        let min = 0

        switch (level) {
            case "high":
                max = 50
                min = 30
                break;
            case "mid":
                max = 30
                min = 15
                break;
            case "low":
                max = 15
                min = 0
        }
        let i = 0
        if (showTeamMembers) {
            for (const score of theme.team_member_scores) {
                i += 1
                tm_total = tm_total + score.score
                if (score.score > min && score.score <= max) {
                    let top = 100 - ((score.score - min) * 100 / (max - min))
                    let point = {
                        "class": "team-member",
                        "type": "individual",
                        "top": top,
                        "name": score.name,
                        "hidden_name": `Team Member ${i}`,
                        "score": score.score
                    }
                    points.push(point)
                }
            }
        }
        if (showTeamLeaders) {
            i = 0
            for (const score of theme.team_leader_scores) {
                i += 1
                tl_total = tl_total + score.score
                if (score.score > min && score.score <= max) {
                    let top = 100 - ((score.score - min) * 100 / (max - min))
                    let point = {
                        "class": "team-leader",
                        "type": "individual",
                        "top": top,
                        "name": score.name,
                        "hidden_name": `Team Leader ${i}`,
                        "score": score.score
                    }
                    points.push(point)
                }
            }
        }
        if (showStakeholders) {
            i = 0
            for (const score of theme.stakeholder_scores) {
                i += 1
                st_total = st_total + score.score
                if (score.score > min && score.score <= max) {
                    let top = 100 - ((score.score - min) * 100 / (max - min))
                    let point = {
                        "class": "stakeholder",
                        "type": "individual",
                        "top": top,
                        "name": score.name,
                        "hidden_name": `Stakeholder ${i}`,
                        "score": score.score
                    }
                    points.push(point)
                }
            }
        }

        let _idx = []
        let _copy = [...points]
        let _multi_points = []
        if (points.length > 1) {
            for (let i = 0; i < points.length; i++) {
                let _temp_points = []
                if (!IncludesIndex(i, _idx)) {
                    for (let n = i + 1; n < points.length; n++) {
                        if (points[i].score === points[n].score && points[i].type === "individual" && points[n].type === "individual") {
                            _idx.push(n)
                            _temp_points.push(points[n])
                        }
                    }
                }
                if (_temp_points.length > 0) {
                    _idx.push(i)
                    _temp_points.push(points[i])
                    _multi_points.push({
                        "type": "multi-points",
                        "top": _temp_points[0].top,
                        "data": _temp_points
                    })
                }
            }
        }
        _idx.sort()
        _idx.reverse()
        for (i=0;i<_idx.length;i++) {
            _copy.splice(_idx[i], 1)
        }
        for (i=0;i<_multi_points.length;i++) {
            _copy.push(_multi_points[i])
        }
        tm_avg = normal_round(tm_total / theme.team_member_scores.length)
        if (tm_avg > min && tm_avg <= max) {
            let top = 100 - ((tm_avg - min) * 100 / (max - min))
            let point = {
                "colour": "#27FF00",
                "class": "team-member",
                "type": "average",
                "top": top,
                "name": "Team Member Average",
                "score": tm_avg
            }
            _copy.push(point)
        }
        tl_avg = normal_round(tl_total / theme.team_leader_scores.length)
        if (tl_avg > min && tl_avg <= max) {
            let top = 100 - ((tl_avg - min) * 100 / (max - min))
            let point = {
                "colour": "#FF00D8",
                "class": "team-leader",
                "type": "average",
                "top": top,
                "name": "Team leader Average",
                "score": tl_avg
            }
            _copy.push(point)
        }
        st_avg = normal_round(st_total / theme.stakeholder_scores.length)
        if (st_avg > min && st_avg <= max) {
            let top = 100 - ((st_avg - min) * 100 / (max - min))
            let point = {
                "colour": "#000AFF",
                "class": "stakeholder",
                "type": "average",
                "top": top,
                "name": "Stakeholder Average",
                "score": st_avg
            }
            _copy.push(point)
        }
        return _copy
    }

    const IncludesIndex = (idx, array) => {
        if (array.length > 0) return false;
        for (let i=0;i<array.length;i++) {
            let isFound = array[i].includes(idx);
            if (isFound) return true;
        }
        return false;
    }

    return (
        <div className="summary-tabular-container">
            <div className="summary-tabular-header-container">
                <div className="summary-tabular-title-container">
                    <div className="summary-tabular-title">Graphical</div>
                    <div className="summary-tabular-attributable-switch">
                        <div className={`chart-controls-switch ${attributableData ? "active" : "inactive"}`}
                             onClick={() => setAttributableData(!attributableData)}>
                            <div className="chart-controls-switch-centre"/>
                        </div>
                        <div className="summary-tabular-attributable-switch-text">Attributable Data</div>
                    </div>
                </div>
                <div className="summary-tabular-controls-container">
                    <div className="summary-tabular-controls-text-container">
                        <div className="summary-tabular-colour-circle green"/>
                        <div className="summary-tabular-controls-text">Team Members</div>
                    </div>
                    <div className="summary-tabular-controls-switch">
                        <div className={`chart-controls-switch ${showTeamMembers ? "active" : "inactive"}`}
                             onClick={() => setShowTeamMembers(!showTeamMembers)}>
                            <div className="chart-controls-switch-centre"/>
                        </div>
                    </div>
                    <div className="summary-tabular-controls-text-container">
                        <div className="summary-tabular-colour-circle pink"/>
                        <div className="summary-tabular-controls-text">Team Leader(s)</div>
                    </div>
                    <div className="summary-tabular-controls-switch">
                        <div className={`chart-controls-switch ${showTeamLeaders ? "active" : "inactive"}`}
                             onClick={() => setShowTeamLeaders(!showTeamLeaders)}>
                            <div className="chart-controls-switch-centre"/>
                        </div>
                    </div>
                    <div className="summary-tabular-controls-text-container">
                        <div className="summary-tabular-colour-circle blue"/>
                        <div className="summary-tabular-controls-text">Stakeholder(s)</div>
                    </div>
                    <div className="summary-tabular-controls-switch">
                        <div className={`chart-controls-switch ${showStakeholders ? "active" : "inactive"}`}
                             onClick={() => setShowStakeholders(!showStakeholders)}>
                            <div className="chart-controls-switch-centre"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="summary-graphical-table-container">
                <div className="summary-graphical-table-left-spacer">
                    <div className="summary-graphical-spacer-segment">High</div>
                    <div className="summary-graphical-spacer-segment">Mid</div>
                    <div className="summary-graphical-spacer-segment no-border">Low</div>
                    <div className="summary-graphical-spacer-segment no-border corner"/>
                </div>
                {cardData && cardData.themes.map((theme, idx) => {
                    return (
                        <>
                            <div className="summary-graphical-table-column-container" key={idx} style={{zIndex: 7-idx}}>
                                <div className={`summary-graphical-table-column-top ${idx === 0 ? "left-corner" : idx === 4 ? "right-corner" : ""}`}>
                                    {scorePoints(theme, "high").map((points, idx) => {
                                        if ((points.class === "team-member" && !showTeamMembers) || (points.class === "team-leader" && !showTeamLeaders) || (points.class === "stakeholder" && !showStakeholders)) {
                                            return
                                        }
                                        if (points.type === "individual") {
                                            return (
                                                <SummaryGraphicPoint points={points}
                                                                     attributableData={attributableData}
                                                                     idx={idx} />
                                            )
                                        } else if (points.type === "average"){
                                            return (
                                                <div className={`summary-graphical-average ${points.class}`}
                                                     style={{top: `calc(${points.top}% - 3px)`}} key={idx}>
                                                <div className="summary-graphical-point-hover-container average">
                                                        <div className="summary-graphical-point-hover-triangle">
                                                            <div className="summary-graphical-point-hover-triangle-inner"/>
                                                        </div>
                                                        <div className="summary-graphical-point-hover-main">
                                                            <div className="summary-graphical-average-hover-details">
                                                                <div className="hover-details-key">Score</div>
                                                                <div className="hover-details-score">
                                                                    {points.score}
                                                                </div>
                                                            </div>
                                                            <div className="summary-graphical-point-hover-circle"/>
                                                            <div className="summary-graphical-point-hover-text">
                                                                {points.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (points.type === "multi-points") {
                                            return (
                                                <div className="summary-graphical-multi-points-container" style={{top: `${points.top}%`}}>
                                                    {points.data && points.data.map((point, index) => {
                                                        if ((point.class === "team-member" && !showTeamMembers) || (point.class === "team-leader" && !showTeamLeaders) || (point.class === "stakeholder" && !showStakeholders)) {
                                                            return
                                                        }
                                                        return(
                                                            <SummaryGraphicFlexedPoint point={point}
                                                                                       data={points.data}
                                                                                       attributableData={attributableData}
                                                                                       idx={index}
                                                                                       showTeamMembers={showTeamMembers}
                                                                                       showTeamLeaders={showTeamLeaders}
                                                                                       showStakeholders={showStakeholders}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="summary-graphical-table-column-middle">
                                    {scorePoints(theme, "mid").map((points, idx) => {
                                        if ((points.class === "team-member" && !showTeamMembers) || (points.class === "team-leader" && !showTeamLeaders) || (points.class === "stakeholder" && !showStakeholders)) {
                                            return
                                        }
                                        if (points.type === "individual") {
                                            return (
                                                <SummaryGraphicPoint points={points}
                                                                     attributableData={attributableData}
                                                                     idx={idx} />
                                            )
                                        } else if (points.type === "average"){
                                            return (
                                                <div className={`summary-graphical-average ${points.class}`}
                                                     style={{top: `calc(${points.top}% - 3px)`}} key={idx}>
                                                    <div className="summary-graphical-point-hover-container average">
                                                        <div className="summary-graphical-point-hover-triangle">
                                                            <div className="summary-graphical-point-hover-triangle-inner"/>
                                                        </div>
                                                        <div className="summary-graphical-point-hover-main">
                                                            <div className="summary-graphical-average-hover-details">
                                                                <div className="hover-details-key">Score</div>
                                                                <div className="hover-details-score">
                                                                    {points.score}
                                                                </div>
                                                            </div>
                                                            <div className="summary-graphical-point-hover-circle"/>
                                                            <div className="summary-graphical-point-hover-text">
                                                                {points.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (points.type === "multi-points") {
                                            return (
                                                <div className="summary-graphical-multi-points-container" style={{top: `${points.top}%`}}>
                                                    {points.data && points.data.map((point, index) => {
                                                        if ((point.class === "team-member" && !showTeamMembers) || (point.class === "team-leader" && !showTeamLeaders) || (point.class === "stakeholder" && !showStakeholders)) {
                                                            return
                                                        }
                                                        return(
                                                            <SummaryGraphicFlexedPoint point={point}
                                                                                       data={points.data}
                                                                                       attributableData={attributableData}
                                                                                       idx={index}
                                                                                       showTeamMembers={showTeamMembers}
                                                                                       showTeamLeaders={showTeamLeaders}
                                                                                       showStakeholders={showStakeholders}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="summary-graphical-table-column-bottom">
                                    {scorePoints(theme, "low").map((points, idx) => {
                                        if ((points.class === "team-member" && !showTeamMembers) || (points.class === "team-leader" && !showTeamLeaders) || (points.class === "stakeholder" && !showStakeholders)) {
                                            return
                                        }
                                        if (points.type === "individual") {
                                            return (
                                                <SummaryGraphicPoint points={points}
                                                                     attributableData={attributableData}
                                                                     idx={idx} />
                                            )
                                        } else if (points.type === "average"){
                                            return (
                                                <div className={`summary-graphical-average ${points.class}`}
                                                     style={{top: `calc(${points.top}% - 3px)`}} key={idx}>
                                                    <div className="summary-graphical-point-hover-container average">
                                                        <div className="summary-graphical-point-hover-triangle">
                                                            <div className="summary-graphical-point-hover-triangle-inner"/>
                                                        </div>
                                                        <div className="summary-graphical-point-hover-main">
                                                            <div className="summary-graphical-average-hover-details">
                                                                <div className="hover-details-key">Score</div>
                                                                <div className="hover-details-score">
                                                                    {points.score}
                                                                </div>
                                                            </div>
                                                            <div className="summary-graphical-point-hover-circle"/>
                                                            <div className="summary-graphical-point-hover-text">
                                                                {points.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (points.type === "multi-points") {
                                            return (
                                                <div className="summary-graphical-multi-points-container" style={{top: `${points.top}%`}}>
                                                    {points.data && points.data.map((point, index) => {
                                                        if ((point.class === "team-member" && !showTeamMembers) || (point.class === "team-leader" && !showTeamLeaders) || (point.class === "stakeholder" && !showStakeholders)) {
                                                            return
                                                        }
                                                        return(
                                                            <SummaryGraphicFlexedPoint point={point}
                                                                                       data={points.data}
                                                                                       attributableData={attributableData}
                                                                                       idx={index}
                                                                                       showTeamMembers={showTeamMembers}
                                                                                       showTeamLeaders={showTeamLeaders}
                                                                                       showStakeholders={showStakeholders}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="summary-graphical-table-column-text-container">
                                    <div className={`summary-graphical-table-column-pillar-colour ${getPillarColour(theme.pillar_id)}`}/>
                                    <div className="summary-graphical-table-column-pillar-text">
                                        {theme.name}
                                    </div>
                                </div>
                            </div>
                            <div className={`summary-graphical-table-centre-spacer ${idx === 4 ? "thin" : ""}`} key={`spacer-${idx}`}>
                                <div className="summary-graphical-spacer-segment"/>
                                <div className="summary-graphical-spacer-segment"/>
                                <div className="summary-graphical-spacer-segment no-border"/>
                                <div className="summary-graphical-spacer-segment no-border"/>
                            </div>
                        </>

                    )
                })}
            </div>
        </div>
    )
}


export default SummaryGraphical