import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import notifications_api from "../api/protected/notifications"


const NotificationCard = (props) => {

    const navigate = useNavigate()
    const handleDismiss = props.handle_dismiss

    const [id, setId] = useState(0)
    const [title, setTitle] = useState("")
    const [category, setCategory] = useState("")
    const [message, setMessage] = useState("")
    const [createdDate, setCreatedDate] = useState("")
    const [link, setLink] = useState("")

    useEffect(() => {
        setTitle(props.title)
        setCategory(props.category)
        setMessage(props.message)
        setLink(props.link)
        setId(props.id)

        let date = new Date(props.created_date * 1000).toLocaleString()
        setCreatedDate(date)
    }, [props]);

    const handleCardClick = (ev) => {
        if(!ev || !link) return;
        if(ev.target.id === `notification-dismiss-${id}`) return;
        navigate(`/${link}`, {replace: true})
    }

    return (
        <div className={`notification-card-container ${link ? "has-link" : ""}`} onClick={handleCardClick}>
            <div className="notification-card-title-container">
                <div className="notification-card-title">{title}</div>
                <div className="notification-card-date">{createdDate}</div>
            </div>
            <div className="notification-card-body-container">
                <div className="notification-card-message">{message}</div>
                <div className="notification-card-dismiss" id={`notification-dismiss-${id}`}
                     onClick={ev => handleDismiss(ev, id)}>
                    Dismiss
                </div>
            </div>
        </div>
    )
}

export default NotificationCard