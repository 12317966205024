import {useCallback, useEffect, useState} from "react";
import styles from "../models/Styles";
import kapi from "../api/kratos";
import auth from "../api/auth";
import eye from "../assets/eye.png"
import check from "../assets/green-tick-circle.svg";
import cross from "../assets/red_cross.png";
import text_utils from "../utils/TextUtils";

const ResetPassword = () => {
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [showPasswordGuide, setShowPasswordGuide] = useState(false);
    const [flowResponse, setFlowResponse] = useState();

    const InitSettings = () => {
        const response = kapi.init_settings();
        response.then(response => {
            setFlowResponse(response.data);
        }).catch(err => {
            console.log(err);
            setPassword("");
            setConfirmPassword("");
        })
    }

    const PageLoad = useCallback(() => {
        const params = new URLSearchParams(window.location.search);
        const flowId = params.get('flow');
        setPassword("");
        setConfirmPassword("");

        if (!flowId) {
            InitSettings();
        }
        else {
            const response = kapi.get_settings(flowId);
            response.then(response => {
                setFlowResponse(response.data);
            }).catch(err => {
                console.log("Unable to retrieve registration: " + err);
            });
        }
    }, [])

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleShowConfirmedPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const checkAllPasswordConditions = () => {
        if (!password) return false
        return (text_utils.containsSpecial(password) &&
            text_utils.containsDigit(password) &&
            text_utils.containsCapital(password) &&
            password.length >= 8 &&
            password === confirmPassword)
    }

    useEffect(() => {
        PageLoad()
    }, [PageLoad])

    useEffect(() => {
        localStorage.setItem("recovery", "true")
    }, []);

    return(
        <>
            <div className="reset-page-container">
                <div className="reset-container">
                    <div className="reset-form-container">
                        <form name="reset-form" action={flowResponse && flowResponse.ui.action}
                              method="POST">
                            <input name="csrf_token" type="hidden"
                                   value={flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'csrf_token')?.attributes.value}/>
                            <div>
                                {flowResponse && flowResponse?.ui?.messages?.map((msg, idx) => {
                                    return(
                                        <div className="messages">{msg.text}</div>
                                    )
                                })}
                            </div>
                            <div className="reset-heading-container">
                                <div className="reset-heading">Reset password</div>
                                <div className="reset-subheading">Please set a new password for your account</div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Password</div>
                                    <input name="password"
                                           type={showPassword ? "text" : "password"} value={password}
                                           onChange={(ev) => {
                                               setPassword(ev.target.value)
                                               setShowPasswordGuide(true)
                                           }}
                                           onFocus={() => {
                                               setShowPasswordGuide(true)
                                           }}
                                           required/>
                                    <img src={eye} onClick={toggleShowPassword} alt="eye logo"
                                         className="reset-eye link"/>
                                    <div>
                                        {flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'password')?.messages.map((msg, idx) => {
                                            return (
                                                <div className="messages" key={idx}>{msg.text}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Confirm password</div>
                                    <input name="confirm-password"
                                           type={showConfirmPassword ? "text" : "password"} value={confirmPassword}
                                           onChange={(ev) => setConfirmPassword(ev.target.value)}
                                           required/>
                                    <img src={eye} onClick={toggleShowConfirmedPassword} alt="eye logo"
                                         className="reset-eye link"/>
                                </div>
                            </div>
                            {showPasswordGuide && showPasswordGuide ?
                                <div className="password-guide-container">
                                    <div className="password-guide-section">
                                        <div className="password-card-image-container">
                                            <img src={password && password.length >= 8 ? check : cross} alt='password-check'/>
                                        </div>
                                        Minimum number of characters: 8
                                    </div>
                                    <div className="password-guide-section">
                                        <div className="password-card-image-container">
                                            <img src={text_utils.containsCapital(password) ? check : cross} alt='password-check'/>
                                        </div>
                                        Include at least 1 capital character
                                    </div>
                                    <div className="password-guide-section">
                                        <div className="password-card-image-container">
                                            <img src={text_utils.containsDigit(password) ? check : cross} alt='password-check'/>
                                        </div>
                                        Include at least 1 numeric character
                                    </div>
                                    <div className="password-guide-section">
                                        <div className="password-card-image-container">
                                            <img src={text_utils.containsSpecial(password) ? check : cross} alt='password-check'/>
                                        </div>
                                        Include at least 1 special character
                                    </div>
                                    <div className="password-guide-section">
                                        <div className="password-card-image-container">
                                            <img src={password === confirmPassword ? check : cross} alt='password-check'/>
                                        </div>
                                        Passwords must match
                                    </div>
                                </div>
                                : null
                            }
                            <div className="field-input">
                                <button className="reset-button link" name="method" type="submit" value="password"
                                        disabled={!checkAllPasswordConditions()}>
                                    Change password
                                </button>
                            </div>
                            <div className="field-input">
                                <div className="label">
                                    <a className="link" href="/login"> Go back to sign-in</a>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword