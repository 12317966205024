import {useEffect, useState} from "react";
import CoachRadioButton from "./CoachRadioButton";


const CoCoachTeamCard = (props) => {

    const team = props.team
    const update = props.update

    const handleSharedClick = (ev) => {
        if(!ev) return;
        update(team.id, !team.shared, team.editor)
    }

    const handleEditorClick = (ev) => {
        if(!ev) return;
        update(team.id, team.shared, !team.editor)
    }

    return (
        <div className="coach-edit-team-row">
            <div className="title-container medium">
                <div className="coach-edit-team-row-item company">{team.company_name}</div>
                <div className="coach-edit-team-row-item">{team.name}</div>
            </div>
            <div className="controls-container">
                <div className="coach-edit-team-row-item centred"
                     style={{visibility: props.disabled ? "hidden" : "unset", marginRight: "85px"}}>
                    <CoachRadioButton selected={team.shared} handle={handleSharedClick.bind(this)}
                                      disabled={props.disabled}/>
                </div>
                <div className="coach-edit-team-row-item centred" style={{marginRight: "50px"}}>
                    <CoachRadioButton selected={team.editor} handle={handleEditorClick.bind(this)}
                                      disabled={props.disabled}
                                      active={!!team.shared}
                    />
                </div>
                <div className="coach-edit-team-row-item centred" style={{marginRight: "46px"}}>
                    <CoachRadioButton selected={team.editor} handle={handleEditorClick.bind(this)}
                                      disabled={props.disabled}
                                      inverted={true} active={!!team.shared}/>
                </div>
            </div>
        </div>
    )
}

export default CoCoachTeamCard