import logo from "../assets/404.png";
const Unknown = () => {
    return (
        <div className="unknown-page-container">
            <div className="unknown-logo">
                <img src={logo} alt="404 logo"/>
            </div>
            <div className="unknown-title">
                PAGE NOT FOUND
            </div>
        </div>
    )
}

export default Unknown