
import {useEffect, useState} from "react";
import {Line} from "react-chartjs-2"
import dashboard_api from "../../api/protected/dashboard"
import cjs_config from "../../utils/ChartJSConfig"
import NoData from "./NoData";
import SelectionPopup from "./SelectionPopup";
import edit from "../../assets/edit-pencil.svg";
import del from "../../assets/delete-bin.svg";

const SurveyLineChart = (props) => {

    const [showEditMenu, setShowEditMenu] = useState(true)
    const [noData, setNoData] = useState(false);
    const [allTeams, setAllTeams] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [selectedTeam, setSelectedTeam] = useState(0)
    const [isDemo, setIsDemo] = useState(false)

    const [chartData, setChartData] = useState({labels: [], datasets: []})

    useEffect(() => {
        setAllTeams(props.teams)
    }, [props.teams]);

    useEffect(() => {
        setAllCompanies(props.companies)
    }, [props.companies])

    useEffect(() => {
        if(!props.context) return;
        setSelectedTeam(props.context)
        setShowEditMenu(false);
    }, [props.context])

    useEffect(() => {
        if(!selectedTeam) return;
        getChartData(selectedTeam)
    }, [selectedTeam]);

    useEffect(() => {
        setIsDemo(props.demo)
    }, [props.demo])

    const getChartData = (team_id) => {
        let response;
        if(isDemo) {
            response = dashboard_api.getDemoLineChartData(team_id);
        } else {
            response = dashboard_api.getLineChartData(team_id);
        }
        response.then(response => {
            if(!response.data.length) {
                setShowEditMenu(false);
                setNoData(true);
                return;
            }
            let labels = []
            let datasets = []
            let scores = []
            let variance = []
            for(let idx in response.data) {
                labels.push(response.data[idx].survey_name)
                scores.push(response.data[idx].average_score)
                variance.push(response.data[idx].variance)
            }
            datasets.push(cjs_config.constructDashboardLineDataset(
                'Variance', variance, '#A128ED', '#E1B2FF'
                ))
            datasets.push(cjs_config.constructDashboardLineDataset(
                'Score', scores, '#F478C4', '#FFE2F4',
            ))
            setChartData({
                'labels': labels,
                'datasets': datasets
            })
            setShowEditMenu(false)
        }).catch(err => console.log(err))
    }

    const updateSelectedTeam = (team) => {
        if(!team) return;
        setIsDemo(team.demo)
        let response = dashboard_api.updateComponent(props.id, team.id, team.demo);
        response.then(() => {
            setSelectedTeam(team.id)
        })
    }

    const openEditMenu = (ev) => {
        if(!ev) return;
        setShowEditMenu(true)
    }
    const closeEditMenu = (ev) => {
        if(!ev) return;
        setShowEditMenu(false);
    }

    return (
        <>
            <div className="dashboard-component dashboard-chart">
                {!noData ?
                    <>
                        <div className="survey-line-chart-top-row">
                            <div className="survey-line-chart-year">
                                2023
                            </div>
                            <div className="survey-line-chart-legend-container">
                                <div className="survey-line-chart-legend">
                                    <div className="survey-line-chart-legend-dot" style={{backgroundColor: "#A128ED"}}/>
                                    <div className="survey-line-chart-legend-title">Variance</div>
                                </div>
                                <div className="survey-line-chart-legend">
                                    <div className="survey-line-chart-legend-dot" style={{backgroundColor: "#F478C4"}}/>
                                    <div className="survey-line-chart-legend-title">Score</div>
                                </div>
                            </div>
                        </div>
                        {chartData ? <Line data={chartData} options={cjs_config.dashboard_line_options} height={160}/> : null}
                        <div className="dashboard-component-button-container survey-line-chart">
                            <div className="dashboard-component-button" onClick={openEditMenu}>
                                <img src={edit} alt="Edit Component"/>
                            </div>
                            <div className="dashboard-component-button" onClick={() => props.manage(props.id)}>
                                <img src={del} alt="Delete Component"/>
                            </div>
                        </div>
                    </>
                    : <NoData id={props.id} edit={openEditMenu.bind(this)} delete={props.manage} context="team"/>
                }
            </div>
            {showEditMenu ?
                <SelectionPopup id={props.id}
                                title="Survey Score Chart"
                                selection_level={1}
                                companies={allCompanies}
                                teams={allTeams}
                                selected={selectedTeam}
                                updateSelected={updateSelectedTeam.bind(this)}
                                closePopup={closeEditMenu.bind(this)}
                                deleteComponent={props.manage}
                />
                : null
            }
        </>
    )
}

export default SurveyLineChart