import BackgroundRings from "../components/BackgroundRings";
import enterprise_image from "../assets/enterprise-hero.svg"
import {useEffect} from "react";
import {Link} from "react-router-dom";

const LearnMoreEnterprise = () => {

    useEffect(() => window.scrollTo({top: 0, left: 0, behavior: 'instant'}), []);

    return (
        <div className="learn-more-page-container enterprise">
            <div className="home-page-background-circle" style={{top: 680, left: -250}}/>
            <div className="home-page-background-circle" style={{top: 1000, right: -350}}/>
            <BackgroundRings top={-370} right={-143}/>
            <div className="learn-more-page-title">Enterprise</div>
            <div className="learn-more-page-who-is-this-for-container">
                <div className="learn-more-page-who-is-this-for-image-container">
                    <img src={enterprise_image} alt="Enterprise"/>
                </div>
                <div className="learn-more-page-who-is-this-for-text-container">
                    <div className="learn-more-page-who-is-this-for-title">Who is this for?</div>
                    <div className="learn-more-page-who-is-this-for-description">
                        Our Enterprise package is for mid to large organisations who are seeking a low cost centralised
                        and standardised approach to manage the process of creating a company wide coaching culture.
                        <br/><br/>
                        <span className="bold">You’ll get all the benefits of:</span>
                        <br/><br/>
                        The Bronze package for your team leaders / Line managers to self organise their diagnostic and
                        create self coaching teams and team improvements.
                        <br/><br/>
                        The Gold package for centrally and securely administrating your teams and Team Coaching support
                        (both Internal & External team coaches).
                        <br/><br/>
                        Plus some additional organisational features to help you benchmark across teams (by function /
                        department / division / country etc) as well as holistic identification of company strengths &
                        weaknesses.
                    </div>
                </div>
            </div>
            <div className="learn-more-page-benefits-container">
                <div className="learn-more-page-benefits-title">What are the benefits?</div>
                <div className="learn-more-page-benefits-description">
                    The <span className="bold">PERILL diagnostics for teams</span> platform supports your business and all its constituent teams, in
                    an easy to use single low cost platform.
                </div>
                <div className="learn-more-page-subcontainer">
                    <div className="learn-more-page-bullet-container">
                        <div className="learn-more-page-bullet-container-title">
                            For the Organisation:
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Create a company wide coaching culture with self coaching teams.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Simple, secure, admin interface for managing all staff, teams, coaches and stakeholders
                                plus surveys and generating reports.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Control access / sharing of client teams with coaching pairs across the organisation.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Consistent approach & methodology across your organisation.
                            </div>
                        </div>
                    </div>
                    <div className="learn-more-page-bullet-container">
                        <div className="learn-more-page-bullet-container-title"/>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Proven, research led methodology from Professor David Clutterbuck.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Collated statistical measurement of team improvement across all client teams (ROI).
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Holistic benchmarking across teams (by function / department / division / country etc).
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Holistic identification of company strengths & weaknesses to support strategic planning and training requirements.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="learn-more-page-subcontainer">
                    <div className="learn-more-page-bullet-container">
                        <div className="learn-more-page-bullet-container-title">
                            For your Teams:
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Awareness of the teams collective strengths and weaknesses.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Understanding of the teams systems and context.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Communication & engagement within the team.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Psychology safety within the team.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Coaching culture within the team.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Engagement with the teams stakeholders.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Communication & engagement with other areas of the business.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Measurement of impact & performance improvements over time.
                            </div>
                        </div>
                    </div>
                    <div className="learn-more-page-bullet-container">
                        <div className="learn-more-page-bullet-container-title">
                            For your Coaching Staff <span className="medium inline-title">(where enhanced intervention is required)</span>:
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Both Lite & Full Diagnostic survey options.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Attritable, named data.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                More in-depth analysis.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Configurable reports with option to include commentary.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Feedback & recommendation ratings to identify areas for improvement (CPD).
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                ROI report (across all client teams) can be used to identify unconscious biases in
                                their interventions (eg always focusing on Purpose) - useful in taking to Supervision.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="learn-more-page-enquire-contaner">
                <div className="home-page-button filled-button">
                    <Link to="/get-in-touch" className="undecorated">ENQUIRE</Link>
                </div>
            </div>
        </div>
    )
}

export default LearnMoreEnterprise