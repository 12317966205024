import {useEffect, useState} from "react";
import search from "../assets/search.png";
import add from "../assets/add.png";
import companies_api from "../api/protected/companies"
import CompanyCardContainer from "../containers/CompanyCardContainer";
import NewCompany from "../components/companies/NewCompany";
import {useNavigate} from "react-router-dom";
import Slider from "../components/Slider";

const Companies = () => {
    const navigate = useNavigate()
    const [showHidden, setShowHidden] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [userCompanies, setUserCompanies] = useState([])
    const [demoCompanies, setDemoCompanies] = useState([])
    const [showNewCompany, setShowNewCompany] = useState(false);
    const [searchTerm, setSearchTerm] = useState('')


    useEffect(() => {
        getCompanies()
    }, [])

    useEffect(() => {
        if(!userCompanies || !demoCompanies) return;
        setCompanies([...demoCompanies, ...userCompanies])
    }, [demoCompanies, userCompanies])

    const handleEditCompany = (company) => {
        if (!company) return;
        if(company.demo) {
            navigate(`/companies/${company.id}?demo=true`)
        } else {
            navigate(`/companies/${company.id}`)
        }
    }

    const updateCompaniesGrid = () => {
        getCompanies();
        setShowNewCompany(false);
    }

    const getCompanies = () => {
        let demo_response = companies_api.getDemoCompanies();
        demo_response.then(response => {
            setDemoCompanies(response.data.demo_companies);
        })
        let response = companies_api.getCompanies();
        response.then(response => {
            setUserCompanies(response.data.companies);
        })
    }

    const updateToggleHidden = () => {
        setShowHidden(!showHidden);
    }

    return (
        <>
            <div className="companies-page-background-container">
                <div className="companies-page-container">
                    {showNewCompany ?
                        <NewCompany updateCompaniesGrid={updateCompaniesGrid.bind(this)}/>
                        :
                        <>
                            <div className="companies-page-header">
                                <div className="companies-page-title">
                                    Companies
                                </div>
                                <div className="companies-page-search-container">
                                    <input type="text" placeholder="Search" value={searchTerm}
                                           onChange={(ev) => setSearchTerm(ev.target.value)}
                                           onKeyUp={(ev) => {
                                                if (ev.key === 'Enter') navigate("/search?term=" + searchTerm)
                                           }}
                                    />
                                    <img src={search} alt="search icon" className="link"/>
                                </div>
                            </div>
                            <div className="page-actions-container">
                                <div className="page-toggle-hidden">
                                    <Slider update={updateToggleHidden.bind(this)} state={showHidden}/>
                                </div>
                                <div className="toggle-hidden-text">
                                    Show hidden
                                </div>
                                <div className="companies-page-add-container">
                                    <img src={add} alt="add icon" className="link"
                                         onClick={() => setShowNewCompany(true)}/>
                                </div>
                            </div>
                            <CompanyCardContainer companies={companies}
                                                  showHidden={showHidden}
                                                  handleClick={handleEditCompany.bind(this)} />
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default Companies
