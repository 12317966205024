import back_arrow from "../assets/backarrow.svg";
import {useEffect, useState} from "react";
import ThemeRowContainer from "../components/survey_overview/ThemeRowContainer";


const SummaryQuestions = (props) => {

    const cardType = props.cardType
    const surveyData = props.surveyData

    const [tmEnabled, setTmEnabled] = useState(true)
    const [tlEnabled, setTlEnabled] = useState(true)
    const [stEnabled, setStEnabled] = useState(true)
    const [attributableData, setAttributableData] = useState(false)

    const [teamMembers, setTeamMembers] = useState([])
    const [teamLeaders, setTeamLeaders] = useState([])
    const [stakeholders, setStakeholders] = useState([])

    useEffect(() => {
        if(!surveyData) return;
        let tm = surveyData.pillars[0].all_question_scores[0].scores.team_member_scores
        let tl = surveyData.pillars[0].all_question_scores[0].scores.team_leader_scores
        let st = surveyData.pillars[0].all_question_scores[0].scores.stakeholder_scores

        if(tm.length) {
            let filtered_tm = tm.map(x => x.participant_name);
            setTeamMembers(filtered_tm)
        }
        if(tl.length) {
            let filtered_tl = tl.map(x => x.participant_name);
            setTeamLeaders(filtered_tl)
        }
        if(st.length) {
            let filtered_st = st.map(x => x.participant_name);
            setStakeholders(filtered_st)
        }
    }, [surveyData]);

    const handleBackClick = (ev) => {
        if(!ev) return;
        props.back(cardType)
    }

    const getTitle = () => {
        switch (cardType) {
            case 0: return "Purpose & Motivation";
            case 1: return "External Systems & Processes";
            case 2: return "Relationships";
            case 3: return "Internal Systems & Processes";
            case 4: return "Learning";
            case 5: return "Leadership";
            default: return "Summary";
        }
    }

    const getContainerClassname = () => {
        switch (cardType) {
            case 0: return "purpose-and-motivation";
            case 1: return "external-systems-and-processes";
            case 2: return "relationships";
            case 3: return "internal-systems-and-Processes";
            case 4: return "learning";
            case 5: return "leadership";
            default: return "";
        }
    }

    const calculateMeanScore = (tm_scores, tl_scores, st_scores) => {
        let total_score = 0
        let score_count = 0
        let scores = []
        if(tmEnabled && tm_scores) scores = [...scores, ...tm_scores];
        if(tlEnabled && tl_scores) scores = [...scores, ...tl_scores];
        if(stEnabled && st_scores) scores = [...scores, ...st_scores];

        for(const score of scores) {
            score_count += 1
            total_score += score.score
        }

        if (!score_count) return 0;
        return Math.round(total_score / score_count)
    }

    const getFunctionText = (tm_scores, tl_scores, st_scores) => {
        let score = calculateMeanScore(tm_scores, tl_scores, st_scores)

        let _function_level
        if(score < 15) {
            _function_level = "Low"
        } else if(score < 30) {
            _function_level = "Medium"
        } else {
            _function_level = "High"
        }

        return _function_level
    }


    return (
        <>
            <div className="link" onClick={handleBackClick}>
                <img src={back_arrow} alt={"Back Arrow"}/>
            </div>
            <div className="summary-title">{getTitle()}</div>
            <div className={`summary-questions-container ${getContainerClassname()}`}>
                <div className="summary-questions-controls-container">
                    <div className="summary-tabular-title-container">
                        <div className="summary-tabular-attributable-switch">
                            <div className={`chart-controls-switch ${attributableData ? "active" : "inactive"}`}
                                 onClick={() => setAttributableData(!attributableData)} tabIndex="0">
                                <div className="chart-controls-switch-centre"/>
                            </div>
                            <div className="summary-tabular-attributable-switch-text">Attributable Data</div>
                        </div>
                    </div>
                    <div className="summary-tabular-controls-container">
                        <div className="summary-tabular-controls-text-container">
                            <div className="summary-tabular-colour-circle green"/>
                            <div className="summary-tabular-controls-text">Team Members</div>
                        </div>
                        <div className="summary-tabular-controls-switch">
                            <div className={`chart-controls-switch ${tmEnabled ? "active" : "inactive"}`}
                                 onClick={() => setTmEnabled(!tmEnabled)}>
                                <div className="chart-controls-switch-centre"/>
                            </div>
                        </div>
                        <div className="summary-tabular-controls-text-container">
                            <div className="summary-tabular-colour-circle pink"/>
                            <div className="summary-tabular-controls-text">Team Leader(s)</div>
                        </div>
                        <div className="summary-tabular-controls-switch">
                            <div className={`chart-controls-switch ${tlEnabled ? "active" : "inactive"}`}
                                 onClick={() => setTlEnabled(!tlEnabled)}>
                                <div className="chart-controls-switch-centre"/>
                            </div>
                        </div>
                        <div className="summary-tabular-controls-text-container">
                            <div className="summary-tabular-colour-circle blue"/>
                            <div className="summary-tabular-controls-text">Stakeholder(s)</div>
                        </div>
                        <div className="summary-tabular-controls-switch">
                            <div className={`chart-controls-switch ${stEnabled ? "active" : "inactive"}`}
                                 onClick={() => setStEnabled(!stEnabled)}>
                                <div className="chart-controls-switch-centre"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="summary-questions-table-container">
                    <div className="summary-questions-table-headings">
                        <div className="summary-questions-heading-spacer"/>
                        <div className="summary-questions-headings-container">
                            {tmEnabled && teamMembers.map((tm, idx) => {
                                return (
                                    <div className="summary-questions-participant-heading" key={idx}>
                                        <div className="summary-questions-participant-name">
                                            <div className="name-rotate">
                                                {attributableData ? tm : `Team Member ${idx + 1}`}
                                            </div>
                                        </div>
                                        <div className="summary-questions-participant-color tm-green"/>
                                    </div>
                                )
                            })}
                            {tlEnabled && teamLeaders.map((tl, idx) => {
                                return (
                                    <div className="summary-questions-participant-heading" key={idx}>
                                        <div className="summary-questions-participant-name">
                                            <div className="name-rotate">
                                                {attributableData ? tl : `Team Leader ${idx + 1}`}
                                            </div>
                                        </div>
                                        <div className="summary-questions-participant-color tl-pink"/>
                                    </div>
                                )
                            })}
                            {stEnabled && stakeholders.map((st, idx) => {
                                return (
                                    <div className="summary-questions-participant-heading" key={idx}>
                                        <div className="summary-questions-participant-name">
                                            <div className="name-rotate">
                                                {attributableData ? st : `Stakeholder ${idx + 1}`}
                                            </div>
                                        </div>
                                        <div className="summary-questions-participant-color st-blue"/>
                                    </div>
                                )
                            })}
                            <div className="summary-questions-participant-heading">
                                <div className="summary-questions-participant-name">
                                    <div className="name-rotate">Team Mean</div>
                                </div>
                                <div className="summary-questions-participant-color fill-grey"/>
                            </div>
                            <div className="summary-questions-participant-heading function">
                                <div className="summary-questions-participant-name">
                                    <div className="name-rotate">Function</div>
                                </div>
                                <div className="summary-questions-participant-color fill-grey"/>
                            </div>
                        </div>
                        <div className="summary-questions-heading-end-spacer"/>
                    </div>
                    <div className="summary-questions-themes-container">
                        {(cardType || cardType === 0) && surveyData && surveyData.pillars[cardType].themes.map((theme, t_idx) => {
                            return (
                                <>
                                    <ThemeRowContainer theme={theme} classname={getContainerClassname()}
                                                        calculateMeanScore={calculateMeanScore.bind(this)}
                                                        getFunctionText={getFunctionText.bind(this)}
                                                        tmEnabled={tmEnabled}
                                                        tlEnabled={tlEnabled}
                                                        stEnabled={stEnabled}
                                                        t_idx={t_idx}
                                                        surveyData={surveyData}
                                                        cardType={cardType}
                                                        getContainerClassname={getContainerClassname.bind(this)}
                                                        teamMembers={teamMembers}
                                                        teamLeaders={teamLeaders}
                                                        stakeholders={stakeholders}
                                    />
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SummaryQuestions