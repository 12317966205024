import back_arrow from "../assets/backarrow.svg";
import filter from "../assets/filter-icon.svg"
import filter_arrow from "../assets/filter-arrow.svg"
import {useNavigate, useParams} from "react-router-dom";
import ROITitleCard from "../components/ROITitleCard";
import ROIGraphical from "../components/ROIGraphical";
import {useEffect, useState} from "react";
import reports_api from "../api/protected/reports"
import comments_api from "../api/protected/comments"
import ROITabular from "../components/ROITabular";
import ROIBarChart from "../components/ROIBarChart";
import ROICoachPerformance from "../components/ROICoachPerformance";
import ROIFilterCompanyRow from "../components/ROIFilterCompanyRow";
import ROIReportSelector from "../components/ROIReportSelector";
import CoachCommentaryContainer from "../components/CoachCommentaryContainer";


const ROIReport = () => {

    const navigate = useNavigate()
    const params = useParams()

    const [selectedTeams, setSelectedTeams] = useState([])
    const [graphicalData, setGraphicalData] = useState([])
    const [tabularData, setTabularData] = useState([])
    const [barChartData, setBarChartData] = useState([])
    const [titleData, setTitleData] = useState(null)
    const [coachData, setCoachData] = useState(null)
    const [filterList, setFilterList] = useState([])
    const [filterSelected, setFilterSelected] = useState([])
    const [selectState, setSelectState] = useState(0)
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [benchmark, setBenchmark] = useState([0, 0, 0, 0, 0, 0])
    const [reportID, setReportId] = useState(0)

    useEffect(() => {
        window.addEventListener('scroll', handleFilterScroll)
        if(!params.reportId) {
            let response = comments_api.createRoiCommentId();
            response.then(response => {
                setReportId(response.data.id)
            }).catch(err => console.log(err))
        } else {
            setReportId(params.reportId)
        }
    }, []);

    const handleFilterScroll = (ev) => {
        if(!ev) return;
        let elem = document.getElementById('filter-open')
        if (elem) {
            elem.style.top = `${Math.max(323 - window.scrollY, 30)}px`
        }
    }

    const getValidTeams = () => {
        let response = reports_api.getValidROITeams();
        response.then(response => {
            setSelectedTeams(response.data.teams);
            formatFilterList(response.data.teams)
        }).catch(err => console.log(err))
    }

    const formatFilterList = (teams) => {
        let c = []
        let fl = []
        for(const team of teams) {
            if(!(c.map(x => x.name).includes(team.company_name))) {
                c.push({
                    "name": team.company_name,
                    "colour": team.company_colour
                })

            }
        }

        for(const company of c) {
            let _teams = []
            for(const team of teams) {
                if(company.name === team.company_name) {
                    _teams.push({
                        "team_id": team.team_id,
                        "demo": team.demo,
                        "team_name": team.team_name
                    })
                    fl.push({
                        "team_id": team.team_id,
                        "demo": team.demo,
                        "team_name": team.team_name,
                        "company_name": team.company_name,
                        "company_colour": team.company_colour
                    })
                }
            }
            company.teams = _teams
        }

        setFilterSelected(fl)
        setFilterList(c)
    }

    const getROIData = () => {
        if(!selectedTeams || !selectedTeams.length) return
        let st = []
        for(const team of selectedTeams) {
            st.push({
                "team_id": team.team_id,
                "demo": team.demo
            })
        }
        let response = reports_api.getROIData(st)
        response.then(response => {
            setGraphicalData(response.data.graphical)
            setTabularData(response.data.tabular)
            setBarChartData(response.data.bar_chart)
            setTitleData(response.data.title_data)
            setCoachData(response.data.coach_data)
            setBenchmark(response.data.benchmark)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getValidTeams()
    }, []);

    useEffect(() => {
        if(!selectedTeams || !selectedTeams.length) return;
        getROIData()
    }, [selectedTeams]);

    useEffect(() => {
        let all_teams = []
        for(const company of filterList) {
            for(const team of company.teams) {
                all_teams.push(team)
            }
        }
        if(all_teams.length === filterSelected.length && filterSelected.length > 0) {
            setSelectState(2)
        } else if(filterSelected.length) {
            setSelectState(1)
        } else {
            setSelectState(0)
        }
    }, [filterSelected])

    const handleBackClick = (ev) => {
        if(!ev) return;
        navigate('/dashboard')
    }

    const handleSelectAll = (ev) => {
        if(!ev) return;
        let temp_list = []
        if(selectState !== 2) {
            for(const company of filterList) {
                for(const team of company.teams) {
                    temp_list.push(team)
                }
            }
        }
        setFilterSelected(temp_list)
    }

    const updateFilterSelected = (teams) => {
        let s = [...filterSelected]
        for(const team of teams) {
            if(s.map(x => x.team_id).includes(team.team_id)) {
                s.splice(s.map(x => x.team_id).indexOf(team.team_id), 1)
            } else {
                s.push(team)
            }
        }
        setFilterSelected(s)
    }

    const handleFilterReset = (ev) => {
        if(!ev) return;
        window.location.reload()
    }

    const handleFilterSave = (ev) => {
        if(!ev) return;
        setSelectedTeams(filterSelected)
    }

    const closePopup = (ev) => {
        if(!ev || ev.target.id !== "mask") return;
        setSelectorOpen(false)
    }

    const handleOpenFilter = (ev) => {
        if(!ev) return;
        let elem = document.getElementById('filter-open')
        if (elem) {
            elem.style.display = "block"
        }
    }

    const handleCloseFilter = (ev) => {
        if(!ev) return;
        let elem = document.getElementById('filter-open')
        if(elem) {
            elem.style.display = "none"
        }
    }

    return (
        <div className="roi-report-page-background-container">
            <div className="roi-report-page-container">
                <div className="link" onClick={handleBackClick}>
                    <img src={back_arrow} alt={"Back Arrow"}/>
                </div>
                <div className="roi-report-page-title-container">
                    <div className="summary-title no-border">ROI Report</div>
                    <div className="generate-report-button" onClick={() => setSelectorOpen(true)}>Generate Report</div>
                </div>
                <div className="roi-report-page-body-container">
                    <div className="roi-report-page-filter-container">
                        <div className="roi-report-filter-button" onClick={handleOpenFilter}>
                            <div className="roi-report-filter-text bold">
                                Filter
                                <img src={filter_arrow} alt="Filter"/>
                            </div>
                            <div className="roi-report-filter-button-img">
                                <img src={filter} alt="Filter"/>
                            </div>
                        </div>
                        <div className="roi-report-filter-open-container" id="filter-open">
                            <div className="roi-report-filter-open-top-row bold">
                                <div className="roi-report-filter-open-top-row-text">Filter</div>
                                <div className="roi-report-filter-open-top-row-text large"
                                     onClick={handleCloseFilter}>
                                    <img src={filter_arrow} alt="Close"/>
                                </div>
                            </div>
                            <div className="roi-filter-all-select-row">
                                <div className={`roi-filter-selected-box ${selectState > 0 ? "filled": ""}`} onClick={handleSelectAll}>
                                    {selectState === 2 ? "✓" : selectState === 1 ? "-" : ""}
                                </div>
                                <div className="roi-filter-all-select-text bold">All</div>
                            </div>
                            {filterList.map((company, idx) => {
                                return <ROIFilterCompanyRow company={company} key={idx} selected={filterSelected}
                                                            update={updateFilterSelected.bind(this)}/>
                            })}
                            <div className="roi-filter-buttons-container">
                                <div className="roi-filter-button" onClick={handleFilterReset}>Reset</div>
                                <div className="roi-filter-button filled" onClick={handleFilterSave}>Save</div>
                            </div>
                        </div>
                    </div>
                    <div className="roi-report-page-body">
                        <ROITitleCard teams={selectedTeams} data={titleData}/>
                        <CoachCommentaryContainer context_id={reportID} section_id="roi-title-card"/>
                        <ROIBarChart data={barChartData} benchmark={benchmark}/>
                        <CoachCommentaryContainer context_id={reportID} section_id="roi-bar-chart"/>
                        <ROIGraphical data={graphicalData} teams={selectedTeams}/>
                        <CoachCommentaryContainer context_id={reportID} section_id="roi-graphical"/>
                        <ROITabular data={tabularData}/>
                        <CoachCommentaryContainer context_id={reportID} section_id="roi-tabular"/>
                        <ROICoachPerformance data={coachData}/>
                        <CoachCommentaryContainer context_id={reportID} section_id="roi-coach-performance"/>
                    </div>
                </div>
            </div>
            {selectorOpen ?
                <ROIReportSelector closePopup={closePopup.bind(this)} reportID={reportID}/> : null
            }
        </div>
    )
}

export default ROIReport