import add from "../../assets/add.svg"

const Placeholder = (props) => {
    const openMenu = props.openMenu
    const componentId = props.activeComponentID

    return (
        <div className="placeholder-component" onClick={() => openMenu(componentId)}>
            <img src={add} alt="New Component" />
        </div>
    )
}

export default Placeholder