import {useEffect, useState} from "react";
import acc_blue from "../../assets/account-icon-blue.svg"
import acc_grey from "../../assets/account-icon-grey.svg"
import pref_blue from "../../assets/preferences-icon-blue.svg"
import pref_grey from "../../assets/preferences-icon-grey.svg"
import coach_blue from "../../assets/co-coaches-icon-blue.svg"
import coach_grey from "../../assets/co-coaches-icon-grey.svg"
import sec_blue from "../../assets/security-icon-blue.svg"
import sec_grey from "../../assets/security-icon-grey.svg"

const SettingsMenu = (props) => {
    const [activeSection, setActiveSection] = useState();
    const updateActive = props.updateActive;

    useEffect(() => {
        setActiveSection(props.activeSection)
    }, [props.activeSection]);

    return (
        <>
            <div className={activeSection === 1 ? "settings-menu-item link active" : "settings-menu-item link"}
                 onClick={() => updateActive(1)}>
                <div className="settings-menu-icon-container adjust-left">
                    <img src={activeSection === 1 ? acc_blue : acc_grey} alt="Account Details"/>
                </div>
                Account Details
            </div>
            <div className={activeSection === 2 ? "settings-menu-item link active" : "settings-menu-item link"}
                 onClick={() => updateActive(2)}>
                <div className="settings-menu-icon-container">
                    <img src={activeSection === 2 ? pref_blue : pref_grey} alt="Preferences"/>
                </div>
                Preferences
            </div>
            <div className={activeSection === 3 ? "settings-menu-item link active" : "settings-menu-item link"}
                 onClick={() => updateActive(3)}>
                <div className="settings-menu-icon-container adjust-left">
                    <img src={activeSection === 3 ? coach_blue : coach_grey} alt="Co-Coaches"/>
                </div>
                Co-Coaches
            </div>
            <div className={activeSection === 4 ? "settings-menu-item link active" : "settings-menu-item link"}
                 onClick={() => updateActive(4)}>
                <div className="settings-menu-icon-container">
                    <img src={activeSection === 4 ? sec_blue : sec_grey} alt="Security"/>
                </div>
                Security
            </div>
        </>
    )
}

export default SettingsMenu