import BackgroundRings from "../components/BackgroundRings";
import auth from "../api/auth";
import pc_icon from "../assets/pc-icon.webp"
import perril_model from "../assets/perill_model.svg"
import green_tick from "../assets/green-tick-circle.svg"
import hdiw_image from "../assets/how-does-it-work-image.svg"
import perill_diagnostics from "../assets/perill-diagnostics-banner-image.svg"
import star from "../assets/star.svg"
import hr_professional from "../assets/hr-professional.svg"
import team_coaches from "../assets/team-coaches.svg"
import enterprise from "../assets/enterprise.svg"
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";


const Home = () => {
    const [isAuthed, setIsAuthed] = useState(false)

    useEffect(() => {
        let _user = auth.isAuthenticated()
        setIsAuthed(_user)
    }, []);

    return (
        <div className="home-page-container">
            <div className="home-page-intro-container home-contents">
                <BackgroundRings top={-507} right={-415}/>
                <div>
                    <div className="home-page-intro-title">
                        <span className="home-page-intro-title-span">PERILL</span> Diagnostics for Teams
                    </div>
                    <div className="home-page-intro-description">
                        The powerful team coaching tool that helps you elevate team performance through compelling
                        insight.
                    </div>
                    <div className="home-page-intro-button-container">
                        {!isAuthed ?
                            <Link to="/register" className="undecorated">
                                <div className="home-page-button filled-hover">TRY OUR FREE VERSION</div>
                            </Link>
                            :null}
                        <Link to="/pricing-plans" className="undecorated">
                            <div className="home-page-button filled-hover">SEE OUR PRICING PLANS</div>
                        </Link>

                    </div>
                </div>
                <div className="home-page-intro-image-container">
                    <img src={pc_icon} alt="Computer"/>
                </div>
            </div>
            <div className="home-page-info-container home-contents">
                <div className="home-page-info-box">
                    <div className="home-page-info-box-strike"/>
                    <div className="home-page-info-box-title">
                        DEVELOPED BY
                        <div>PROFESSOR. DAVID CLUTTERBUCK</div>
                    </div>
                    <div className="home-page-info-box-description">
                        Pioneer of developmental mentoring and one of the worlds most accomplished authors on
                        management thinking and team performance.
                    </div>
                </div>
                <div className="home-page-info-box">
                    <div className="home-page-info-box-strike"/>
                    <div className="home-page-info-box-title">
                        INSIGHT LED CONVERSATIONS
                    </div>
                    <div className="home-page-info-box-description">
                        Unbiased insight that helps create the right conditions for the conversations the team needs
                        to have with itself and its stakeholders.
                    </div>
                </div>
                <div className="home-page-info-box">
                    <div className="home-page-info-box-strike"/>
                    <div className="home-page-info-box-title">
                        A COMPLEX, ADAPTIVE SYSTEMS LENS
                    </div>
                    <div className="home-page-info-box-description">
                        Our platform looks beyond the simple team environment, into a complex systems of systems
                        perspective, by exploring the environmental context and its impact on team performance and
                        dynamics.
                    </div>
                </div>
            </div>
            <div className="home-page-perill-model-container home-contents">
                <div className="home-page-background-circle" style={{top: -50, right: -235}}/>
                <div className="home-page-background-circle" style={{top: 430, right: 890}}/>
                <div>
                    <img src={perril_model} alt="Perril Model"/>
                </div>
                <div>
                    <div className="home-page-perril-model-title">
                        A ground breaking approach to understanding team effectiveness
                    </div>
                    <div className="home-page-perril-model-description">
                        <span className="bold">PERILL Diagnostics for Teams</span> is a transformational diagnostic
                        that takes team and stakeholder feedback and applies complex, adaptive thinking to look at how the
                        team affects and is affected by the entire operational system. The findings are delivered via
                        accessible coaching themes, guiding you in coaching suggestions to enhance team performance,
                        reputation and collaboration.
                    </div>
                    <div className="home-page-button perill-button">
                        <Link to="/what-is-the-perill-model" className="undecorated">WHAT IS THE PERILL MODEL</Link>
                    </div>
                </div>
            </div>
            <div className="home-page-how-does-it-work-container home-contents">
                <div>
                    <div className="home-page-how-does-it-work-title">How does it work?</div>
                    <div className="home-page-how-does-it-work-section">
                        <div className="home-page-how-does-it-work-tick-container">
                            <img src={green_tick} alt="Tick"/>
                        </div>
                        <div>
                            <div className="home-page-how-does-it-work-section-title">
                                Select your plan
                            </div>
                            <div className="home-page-how-does-it-work-section-description">
                                If you are serious about affecting change within a team, <b> PERILL Diagnostics for
                                Teams </b> can help. Whatever your role - Team Leader, HR Professional, Team Coach or Global
                                Business - there is a plan that will support your requirements.
                            </div>
                        </div>
                    </div>
                    <div className="home-page-how-does-it-work-section">
                        <div className="home-page-how-does-it-work-tick-container">
                            <img src={green_tick} alt="Tick"/>
                        </div>
                        <div>
                            <div className="home-page-how-does-it-work-section-title">
                                Prepare your diagnostic
                            </div>
                            <div className="home-page-how-does-it-work-section-description">
                                Set up your company, team, participants and stakeholders, select your survey
                                diagnostic type and completion date, and hit send. The platform will email out
                                invitations to complete the survey and show you interactions and response rates.
                            </div>
                        </div>
                    </div>
                    <div className="home-page-how-does-it-work-section">
                        <div className="home-page-how-does-it-work-tick-container">
                            <img src={green_tick} alt="Tick"/>
                        </div>
                        <div>
                            <div className="home-page-how-does-it-work-section-title">
                                Unlock team insights and coaching recommendations
                            </div>
                            <div className="home-page-how-does-it-work-section-description">
                                Reveal your PERILL scores and insights, including areas of high team function,
                                dysfunction and possible action points to improve team performance.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-page-how-does-it-work-image-container">
                    <img src={hdiw_image} alt="How does it work?"/>
                </div>
            </div>
            <div className="home-page-perill-diagnostics-banner-container">
                <div className="home-page-perill-diagnostics-banner home-contents">

                    <div className="home-page-perill-diagnostics-banner-image-container">
                        <img src={perill_diagnostics} alt="Perill Diagnostics"/>
                    </div>
                    <div className="home-page-perill-diagnostics-summary">I love Perill diagnostics.</div>
                    <div className="home-page-perill-diagnostics-message">
                        <i>“I love Perill diagnostics. I have been seeing how big the impact is of having a diagnostic
                            at the beginning of the coaching process, and how we actually avoid a lot of potential
                            challenges if we do it deep enough and with all of the stakeholders.”</i>
                    </div>
                    <div className="home-page-perill-diagnostics-author">Evija Vasacenko</div>
                    <div className="home-page-perill-diagnostics-stars-container">
                        <img src={star} alt="Star"/>
                        <img src={star} alt="Star"/>
                        <img src={star} alt="Star"/>
                        <img src={star} alt="Star"/>
                        <img src={star} alt="Star"/>
                    </div>
                    <div className="home-page-perill-diagnostics-banner-mark"><b>“</b></div>
                </div>
            </div>
            <div className="home-page-platform-container home-contents">
                <div className="home-page-background-circle" style={{top: -340, right: -235}}/>
                <div className="home-page-background-circle" style={{top: 540, right: 855}}/>
                <div className="home-page-platform-title">A platform for everyone</div>
                <div className="home-page-platform-subtext">
                    Whether you are an accredited team coach with years of coaching experience or a business or team
                    leader dipping your toes into team coaching for the first time, there is a plan
                    on <span className="bold">PERILL Diagnostics For Teams</span> that will be suitable for your
                    requirements.
                </div>
                <div className="home-page-platform-box-container">
                    <div className="home-page-platform-box professional">
                        <div className="home-page-platform-box-circle professional">
                            <img src={hr_professional} alt=""/>
                        </div>
                        <div className="home-page-platform-box-main professional">
                            <div className="home-page-platform-box-title">For Line Managers <br/> & HR Professionals</div>
                            <div className="home-page-platform-box-banner professional">
                                Evaluate & Elevate Team Performance
                            </div>
                            <div className="home-page-platform-box-bullet-container fixed-bullet professional">
                                <div className="home-page-platform-box-bullet-point"/>
                                <div className="home-page-platform-box-bullet-script">
                                    Simple and effective team coaching framework that helps you to understand and
                                    improve team performance.
                                </div>
                            </div>
                            <div className="home-page-platform-box-bullet-container professional">
                                <div className="home-page-platform-box-bullet-point"/>
                                <div className="home-page-platform-box-bullet-script">
                                    Provides a diagnostic report for team leaders and their teams to increase awareness
                                    of their environment and enable the coaching conversations they need to have to
                                    affect performance change.
                                </div>
                            </div>
                            <Link to="/line-managers-and-hr-professionals" className="home-page-button">LEARN MORE</Link>
                        </div>
                    </div>
                    <div className="home-page-platform-box coaches">
                        <div className="home-page-platform-box-circle coaches">
                            <img src={team_coaches} alt=""/>
                        </div>
                        <div className="home-page-platform-box-main coaches">
                            <div className="home-page-platform-box-title">For Team Coaches</div>
                            <div className="home-page-platform-box-banner coaches">
                                Support Teams & Build Your Business
                            </div>
                            <div className="home-page-platform-box-bullet-container fixed-bullet coaches">
                                <div className="home-page-platform-box-bullet-point"/>
                                <div className="home-page-platform-box-bullet-script">
                                    A simple, streamlined and effective approach to managing team coaching engagements.
                                </div>
                            </div>
                            <div className="home-page-platform-box-bullet-container coaches">
                                <div className="home-page-platform-box-bullet-point"/>
                                <div className="home-page-platform-box-bullet-script">
                                    Support and grow your business through statistical measurement of the impact of
                                    your coaching engagement on team performance (to support ROI) as well as team
                                    feedback on your coaching approach.
                                </div>
                            </div>
                            <Link to="/team-coaches" className="home-page-button">LEARN MORE</Link>
                        </div>
                    </div>
                    <div className="home-page-platform-box enterprise">
                        <div className="home-page-platform-box-circle enterprise">
                            <img src={enterprise} alt=""/>
                        </div>
                        <div className="home-page-platform-box-main enterprise">
                            <div className="home-page-platform-box-title">For Enterprise</div>
                            <div className="home-page-platform-box-banner enterprise">
                                Embed & Embolden your Coaching Culture
                            </div>
                            <div className="home-page-platform-box-bullet-container fixed-bullet enterprise">
                                <div className="home-page-platform-box-bullet-point"/>
                                <div className="home-page-platform-box-bullet-script">
                                    Highly cost effective way to create a company wide coaching culture with self
                                    coaching teams and improved team performance.
                                </div>
                            </div>
                            <div className="home-page-platform-box-bullet-container enterprise">
                                <div className="home-page-platform-box-bullet-point"/>
                                <div className="home-page-platform-box-bullet-script">
                                    Centralised and consistent view of team performance, allowing holistic benchmarking
                                    across the business and highlighting of major themes to support strategic planning
                                    and training requirements.
                                </div>
                            </div>
                            <Link to="/enterprise" className="home-page-button">LEARN MORE</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Home