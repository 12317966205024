import {useEffect, useState} from "react";
import SummaryGraphicPoint from "./SummaryGraphicPoint";
import SummaryGraphicFlexedPoint from "./SummaryGraphicFlexedPoint";


const ROIGraphical = (props) => {

    const [data, setData] = useState([])
    const [companies, setCompanies] = useState([])

    useEffect(() => {
        setData(props.data)
    }, [props.data]);

    useEffect(() => {
        if(!props.teams || !props.teams.length) return;
        let c = []
        for(const team of props.teams) {
            if(!(c.map(x => x.name).includes(team.company_name))) {
                c.push({
                    "name": team.company_name,
                    "colour": team.company_colour
                })
            }
        }
        setCompanies(c)
    }, [props.teams]);

    const normal_round = (value) => {
        if (!value) return;
        if (value - Math.floor(value) < 0.5) return Math.floor(value);
        return Math.ceil(value)
    }

    const getPillarColour = (pillar_id) => {
        switch (pillar_id) {
            case 1: return "pillar-blue"
            case 2: return "pillar-red"
            case 3: return "pillar-green"
            case 4: return "pillar-purple"
            case 5: return "pillar-pink"
            case 6: return "pillar-orange"
        }
    }

    const IncludesIndex = (idx, array) => {
        if (array.length > 0) return false;
        for (let i=0;i<array.length;i++) {
            let isFound = array[i].includes(idx);
            if (isFound) return true;
        }
        return false;
    }

    const scorePoints = (theme, level) => {
        let points = []
        let _totals = []
        let max = 0
        let min = 0

        switch (level) {
            case "high":
                max = 50
                min = 30
                break;
            case "mid":
                max = 30
                min = 15
                break;
            case "low":
                max = 15
                min = 0
        }

        let i = 0
        for (const score of theme.data) {
            let _push_total = true
            if (!_totals.length) {
                _totals.push({
                    "company_id": score.company_id,
                    "company_name":score.company_name,
                    "colour":score.colour,
                    "demo":score.team_id.demo,
                    "total":score.team_average,
                    "length": 1
                })
                _push_total = false
            } else {
                for (let n=0;n<_totals.length;n++) {
                    if (_totals[n].company_id === score.company_id && _totals[n].demo === score.team_id.demo) {
                        _totals[n].total += score.team_average
                        _totals[n].length += 1
                        _push_total = false
                        break
                    }
                }
            }

            if (_push_total) {
                _totals.push({
                        "company_id": score.company_id,
                        "company_name":score.company_name,
                        "colour":score.colour,
                        "demo":score.team_id.demo,
                        "total":score.team_average,
                        "length": 1})
            }

            if (score.team_average > min && score.team_average <= max) {
                i += 1
                let top = 100 - ((score.team_average - min) * 100 / (max - min))
                let point = {
                    "colour": score.colour,
                    "type": "individual",
                    "top": top,
                    "name": score.team_name,
                    "hidden_name": `Team ${i}`,
                    "score": normal_round(score.team_average)
                }
                points.push(point)
            }
        }

        let _idx = []
        let _copy = [...points]
        let _multi_points = []
        if (points.length > 1) {
            for (let i = 0; i < points.length; i++) {
                let _temp_points = []
                if (!IncludesIndex(i, _idx)) {
                    for (let n = i + 1; n < points.length; n++) {
                        if (points[i].score === points[n].score && points[i].type === "individual" && points[n].type === "individual") {
                            _idx.push(n)
                            _temp_points.push(points[n])
                        }
                    }
                }
                if (_temp_points.length > 0) {
                    _idx.push(i)
                    _temp_points.push(points[i])
                    _multi_points.push({
                        "type": "multi-points",
                        "top": _temp_points[0].top,
                        "data": _temp_points
                    })
                }
            }
        }
        _idx.sort()
        _idx.reverse()
        for (i=0;i<_idx.length;i++) {
            _copy.splice(_idx[i], 1)
        }
        for (i=0;i<_multi_points.length;i++) {
            _copy.push(_multi_points[i])
        }
        for (let n=0;n<_totals.length;n++) {
            let avg = normal_round(_totals[n].total / _totals[n].length)
            if (avg > min && avg <= max) {
                let top = 100 - ((avg - min) * 100 / (max - min))
                let point = {
                    "colour": _totals[n].colour,
                    "type": "average",
                    "top": top,
                    "name": _totals[n].company_name + " Average",
                    "score": avg
                }
                _copy.push(point)
            }
        }
        return _copy
    }

    return (
        <div className="roi-graphical-container">
            <div className="roi-graphical-header-container">
                <div className="summary-tabular-title-container">
                    <div className="summary-tabular-title">Graphical</div>
                    <div className="roi-graphical-key-container">
                        <div className="key-point-outer">
                            <div className="key-point-inner"></div>
                        </div>
                        <div className="key-text medium">Teams</div>
                        <div className="key-mean"/>
                        <div className="key-text medium">Companies Mean Score</div>
                    </div>
                </div>
                <div className="roi-report-title-card-companies-container no-border">
                    {companies.map((company, idx) => {
                        return (
                            <div className="roi-report-title-card-company bold" key={idx}>
                                <div className="roi-report-title-card-company-colour"
                                     style={{backgroundColor: company.colour}}/>
                                <div className="roi-report-title-card-company-name">{company.name}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="roi-graphical-table-container">
                <div className="summary-graphical-table-left-spacer">
                    <div className="summary-graphical-spacer-segment">High</div>
                    <div className="summary-graphical-spacer-segment">Mid</div>
                    <div className="summary-graphical-spacer-segment no-border">Low</div>
                    <div className="summary-graphical-spacer-segment no-border corner"/>
                </div>
                {data && data.map((theme, idx) => {
                    return (
                        <>
                            <div className="summary-graphical-table-column-container" key={idx} style={{zIndex: 7-idx}}>
                                <div className={`summary-graphical-table-column-top ${idx === 0 ? "left-corner" : idx === 4 ? "right-corner" : ""}`}>
                                    {scorePoints(theme, "high").map((points, idx) => {
                                        if (points.type === "individual") {
                                            return (
                                                <SummaryGraphicPoint points={points}
                                                                     attributableData={true}
                                                                     idx={idx} />
                                            )
                                        } else if (points.type === "average"){
                                            return (
                                                <div className="summary-graphical-average" style={{top: `calc(${points.top}% - 3px)`, backgroundColor: points.colour}} key={idx}>
                                                    <div className="summary-graphical-point-hover-container average">
                                                        <div className="summary-graphical-point-hover-triangle">
                                                            <div className="summary-graphical-point-hover-triangle-inner"/>
                                                        </div>
                                                        <div className="summary-graphical-point-hover-main">
                                                            <div className="summary-graphical-average-hover-details">
                                                                <div className="hover-details-key">Score</div>
                                                                <div className="hover-details-score">
                                                                    {points.score}
                                                                </div>
                                                            </div>
                                                            <div className="summary-graphical-point-hover-circle" style={{backgroundColor: points.colour}}/>
                                                            <div className="summary-graphical-point-hover-text">
                                                                {points.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (points.type === "multi-points") {
                                            return (
                                                <div className="summary-graphical-multi-points-container" style={{top: `${points.top}%`}}>
                                                    {points.data && points.data.map((point, index) => {
                                                        return(
                                                            <SummaryGraphicFlexedPoint point={point}
                                                                                       data={points.data}
                                                                                       attributableData={true}
                                                                                       idx={index}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="summary-graphical-table-column-middle">
                                    {scorePoints(theme, "mid").map((points, idx) => {
                                        if (points.type === "individual") {
                                            return (
                                                <SummaryGraphicPoint points={points}
                                                                     attributableData={true}
                                                                     idx={idx} />
                                            )
                                        } else if (points.type === "average"){
                                            return (
                                                <div className="summary-graphical-average" style={{top: `calc(${points.top}% - 3px)`, backgroundColor: points.colour}} key={idx}>
                                                    <div className="summary-graphical-point-hover-container average">
                                                        <div className="summary-graphical-point-hover-triangle">
                                                            <div className="summary-graphical-point-hover-triangle-inner"/>
                                                        </div>
                                                        <div className="summary-graphical-point-hover-main">
                                                            <div className="summary-graphical-average-hover-details">
                                                                <div className="hover-details-key">Score</div>
                                                                <div className="hover-details-score">
                                                                    {points.score}
                                                                </div>
                                                            </div>
                                                            <div className="summary-graphical-point-hover-circle" style={{backgroundColor: points.colour}}/>
                                                            <div className="summary-graphical-point-hover-text">
                                                                {points.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (points.type === "multi-points") {
                                            return (
                                                <div className="summary-graphical-multi-points-container" style={{top: `${points.top}%`}}>
                                                    {points.data && points.data.map((point, index) => {
                                                        return(
                                                            <SummaryGraphicFlexedPoint point={point}
                                                                                       data={points.data}
                                                                                       attributableData={true}
                                                                                       idx={index}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="summary-graphical-table-column-bottom">
                                    {scorePoints(theme, "low").map((points, idx) => {
                                        if (points.type === "individual") {
                                            return (
                                                <SummaryGraphicPoint points={points}
                                                                     attributableData={true}
                                                                     idx={idx} />
                                            )
                                        } else if (points.type === "average"){
                                            return (
                                                <div className="summary-graphical-average" style={{top: `calc(${points.top}% - 3px)`, backgroundColor: points.colour}} key={idx}>
                                                    <div className="summary-graphical-point-hover-container average">
                                                        <div className="summary-graphical-point-hover-triangle">
                                                            <div className="summary-graphical-point-hover-triangle-inner"/>
                                                        </div>
                                                        <div className="summary-graphical-point-hover-main">
                                                            <div className="summary-graphical-average-hover-details">
                                                                <div className="hover-details-key">Score</div>
                                                                <div className="hover-details-score">
                                                                    {points.score}
                                                                </div>
                                                            </div>
                                                            <div className="summary-graphical-point-hover-circle" style={{backgroundColor: points.colour}}/>
                                                            <div className="summary-graphical-point-hover-text">
                                                                {points.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (points.type === "multi-points") {
                                            return (
                                                <div className="summary-graphical-multi-points-container" style={{top: `${points.top}%`}}>
                                                    {points.data && points.data.map((point, index) => {
                                                        return(
                                                            <SummaryGraphicFlexedPoint point={point}
                                                                                       data={points.data}
                                                                                       attributableData={true}
                                                                                       idx={index}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="summary-graphical-table-column-text-container">
                                    <div className={`summary-graphical-table-column-pillar-colour ${getPillarColour(theme.pillar_id)}`}/>
                                    <div className="summary-graphical-table-column-pillar-text">
                                        {theme.name}
                                    </div>
                                </div>
                            </div>
                            <div className={`summary-graphical-table-centre-spacer ${idx === 4 ? "thin" : ""}`} key={`spacer-${idx}`}>
                                <div className="summary-graphical-spacer-segment"/>
                                <div className="summary-graphical-spacer-segment"/>
                                <div className="summary-graphical-spacer-segment no-border"/>
                                <div className="summary-graphical-spacer-segment no-border"/>
                            </div>
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default ROIGraphical