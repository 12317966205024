import Rating from "./Rating";
import {useEffect, useState} from "react";
import q_api from "../api/public/questionnaire"
import logo from "../assets/ccmi-graphic-logo-color.svg";
import RoiRatingRow from "./questionnare/RoiRatingRow";
import RoiTextRow from "./questionnare/RoiTextRow";


const ROISurvey = (props) => {

    const [response, setResponse] = useState([])
    const [showUnansweredWarning, setShowUnansweredWarning] = useState(false);
    const [surveyComplete, setSurveyComplete] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(false)

    useEffect(() => {
        let r = [null, null, null, null, null, null, null, null, null, null, null, null]
        setResponse(r)
    }, []);

    useEffect(() => {
        let _enable = true
        for (let i=0;i<response.length;i++) {
            if (response[i] === null) {
                _enable = false
                break;
            }
        }
        setEnableSubmit(_enable)
    },[response])

    const handleScoreChange = (value, id) => {
        if (typeof value === "string") {
            if (value.length > 256) return;
        }
        setShowUnansweredWarning(false)
        let r = [...response]
        r[id] = value;
        setResponse(r)
    }

    const handleFormSubmit = (ev) => {
        if(!ev || !props.uuid || !props.accessKey || !enableSubmit) return;
        for(const r of response) {
            if(r === null) {
                setShowUnansweredWarning(true);
                return;
            }
        }
        let resp = q_api.postROI(props.uuid, props.accessKey, response)
        resp.then(() => setSurveyComplete(true)).catch(() => {})
    }


    return (
        !surveyComplete ?
            <div className="questionnaire-complete-roi-container">
                <div className="questionnaire-complete-card-container">
                    <div className="questionnaire-complete-logo finished">
                        <img src={logo} alt="ccmi logo"/>
                    </div>
                    <div className="questionnaire-complete-card-text-container">
                        <div className="questionnaire-complete-card-heading">You're Finished</div>
                        <div className="questionnaire-complete-card-sub-heading">
                            You’ve successfully completed your diagnostic questionnaire. Your responses have been
                            submitted.
                        </div>
                        <div className="questionnaire-complete-card-text">
                            Please help us further by completing a few extra questions on the overall  experience -
                            it should only take a couple of minutes. Please rate the following statements from 1 star
                            (lowest) to 10 stars (highest) based on how much you agree with them.
                        </div>
                    </div>
                </div>
                <div className="roi-card-container">
                    <div className="roi-card-header-container">
                        Feedback on your team Coach(es)
                    </div>
                    <RoiRatingRow number={"1."}
                                  idx={0}
                                  question={<div>The Team Coach(es) positively influenced the communication and
                                      effectiveness <b className="bold">of the team as a whole</b>?</div>}
                                  handleScoreChange={handleScoreChange.bind(this)}
                                  end={false}/>
                    <RoiRatingRow number={"2."}
                                  idx={1}
                                  question={<div>The Team Coach(es) positively influenced the communication and
                                      effectiveness of <b className="bold">you personally</b> within this team?</div>}
                                  handleScoreChange={handleScoreChange.bind(this)}
                                  end={false}/>
                    <RoiRatingRow number={"3."}
                                  idx={2}
                                  question={<div>I would recommend this Team Coach(es) to another team?</div>}
                                  handleScoreChange={handleScoreChange.bind(this)}
                                  end={false}/>
                    <RoiTextRow number={"4."}
                                idx={3}
                                question={<div>What would have made the team coaching experience more valuable for you?</div>}
                                handleScoreChange={handleScoreChange.bind(this)}
                                data={response}
                                end={false}/>
                    <RoiTextRow number={"5."}
                                idx={4}
                                question={<div>What was the most confusing or challenging part of the team coaching experience?</div>}
                                handleScoreChange={handleScoreChange.bind(this)}
                                data={response}
                                end={false}/>
                    <RoiTextRow number={"6."}
                                idx={5}
                                question={<div>Is there anything else you think we should know?</div>}
                                handleScoreChange={handleScoreChange.bind(this)}
                                data={response}
                                end={true}/>
                </div>
                <div className="roi-card-container">
                    <div className="roi-card-header-container">
                        Feedback on your team Coach(es)
                    </div>
                    <RoiRatingRow number={"1."}
                                  idx={6}
                                  question={<div>The surveys were easy to access.</div>}
                                  handleScoreChange={handleScoreChange.bind(this)} />
                    <RoiRatingRow number={"2."}
                                  idx={7}
                                  question={<div>The surveys were easy to complete.</div>}
                                  handleScoreChange={handleScoreChange.bind(this)}
                                  end={false}/>
                    <RoiRatingRow number={"3."}
                                  idx={8}
                                  question={<div>The survey summaries / reports were effective in uncovering our team
                                      challenges and opportunities.</div>}
                                  handleScoreChange={handleScoreChange.bind(this)}
                                  end={false}/>
                    <RoiRatingRow number={"4."}
                                  idx={9}
                                  question={<div>The survey summaries / reports were effective in improving our team
                                      communication and effectiveness.</div>}
                                  handleScoreChange={handleScoreChange.bind(this)}
                                  end={false}/>
                    <RoiRatingRow number={"5."}
                                  idx={10}
                                  question={<div>I would recommend PERILL Diagnostics for Teams to another team.</div>}
                                  handleScoreChange={handleScoreChange.bind(this)}
                                  end={false}/>
                    <RoiTextRow number={"6."}
                                idx={11}
                                question={<div>How could we improve the diagnostic survey or reports to make it easier
                                    to understand or use?</div>}
                                handleScoreChange={handleScoreChange.bind(this)}
                                data={response}
                                end={true}/>
                </div>
                <div className="roi-survey-warning-text">{showUnansweredWarning ? "Please answer all questions before submitting." : ""}</div>
                <div className={enableSubmit ? "submit-questionnaire-button roi link"
                    : "submit-questionnaire-button roi disabled"} onClick={handleFormSubmit}>Submit</div>
                {/*<div className="roi-survey-container">
                    <h3 className="roi-survey-intro-text">
                        Now that you have finished this Coaching engagement, please answer the following questions
                        on the Coaching experience and on the Diagnostic Surveys. Note: These responses will not be anonymous.
                    </h3>
                    <div className="roi-survey-form-container">
                        <div className="roi-survey-form-heading">FEEDBACK ON YOUR TEAM COACH / TEAM COACHES</div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">1.</div>
                                <div className="roi-survey-form-question">
                                    How would you rate the support that you have received overall through this team coaching
                                    engagement? (1 lowest, 5 highest)
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                                <Rating initialRating={0} maxRating={5} type={1} color="#0082CA" hideText={true}
                                        onRatingChange={handleScoreChange.bind(this)} id={0}/>
                            </div>
                        </div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">2.</div>
                                <div className="roi-survey-form-question">
                                    Has working with this team coach positively influenced the communication and effectiveness
                                    of your team?
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                                <select onChange={ev => handleScoreChange(ev.target.value, 1)}>
                                    <option value={null} disabled hidden selected>--</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="Not Sure">Not Sure</option>
                                </select>
                            </div>
                        </div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">3.</div>
                                <div className="roi-survey-form-question">
                                    Has working with this team coach positively influenced your individual communication and
                                    effectiveness within your team?
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                                <select onChange={ev => handleScoreChange(ev.target.value, 2)}>
                                    <option value={null} disabled hidden selected>--</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="Not Sure">Not Sure</option>
                                </select>
                            </div>
                        </div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">4.</div>
                                <div className="roi-survey-form-question">
                                    How likely would you be to recommend this Coach / Coach pair to another team or colleague?
                                    (1 - 10)
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                                <Rating initialRating={0} maxRating={10} type={1} color="#0082CA" hideText={true}
                                        onRatingChange={handleScoreChange.bind(this)} id={3}/>
                            </div>
                        </div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">5.</div>
                                <div className="roi-survey-form-question">
                                    What would have made the team coaching experience more valuable for you?
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                            <textarea className="roi-survey-text-input" placeholder="Suggestions"
                                      onChange={ev => handleScoreChange(ev.target.value, 4)}/>
                            </div>
                        </div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">6.</div>
                                <div className="roi-survey-form-question">
                                    What was the most confusing or challenging part of the team coaching experience?
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                            <textarea className="roi-survey-text-input" placeholder="Challenges"
                                      onChange={ev => handleScoreChange(ev.target.value, 5)}/>
                            </div>
                        </div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">7.</div>
                                <div className="roi-survey-form-question">
                                    Is there anything else you think we should know?
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                            <textarea className="roi-survey-text-input" placeholder="Anything else"
                                      onChange={ev => handleScoreChange(ev.target.value, 6)}/>
                            </div>
                        </div>
                        <div className="roi-survey-form-heading">FEEDBACK ON DIAGNOSTIC SURVEY & REPORTS</div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">1.</div>
                                <div className="roi-survey-form-question">
                                    How easy was it to access the diagnostic survey? (1 not easy, 10 very easy)
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                                <Rating initialRating={0} maxRating={10} type={1} color="#0082CA" hideText={true}
                                        onRatingChange={handleScoreChange.bind(this)} id={7}/>
                            </div>
                        </div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">2.</div>
                                <div className="roi-survey-form-question">
                                    How easy was it to complete the diagnostic survey? (1 not easy, 10 very easy)
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                                <Rating initialRating={0} maxRating={10} type={1} color="#0082CA" hideText={true}
                                        onRatingChange={handleScoreChange.bind(this)} id={8}/>
                            </div>
                        </div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">3.</div>
                                <div className="roi-survey-form-question">
                                    How effective were the PERILL diagnostic reports in helping you and your team to understand
                                    and improve its communication and effectiveness ? (1-10)
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                                <Rating initialRating={0} maxRating={10} type={1} color="#0082CA" hideText={true}
                                        onRatingChange={handleScoreChange.bind(this)} id={9}/>
                            </div>
                        </div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">4.</div>
                                <div className="roi-survey-form-question">
                                    How effective was your team coach in making a positive impact on your team performance?
                                    (1-10)
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                                <Rating initialRating={0} maxRating={10} type={1} color="#0082CA" hideText={true}
                                        onRatingChange={handleScoreChange.bind(this)} id={10}/>
                            </div>
                        </div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">5.</div>
                                <div className="roi-survey-form-question">
                                    How likely would you be to recommend this diagnostic to other teams or colleagues?
                                    (1 not at all, 10 absolutely!)
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                                <Rating initialRating={0} maxRating={10} type={1} color="#0082CA" hideText={true}
                                        onRatingChange={handleScoreChange.bind(this)} id={11}/>
                            </div>
                        </div>
                        <div className="roi-survey-question-container">
                            <div className="roi-survey-question-row">
                                <div className="roi-survey-question-number">6.</div>
                                <div className="roi-survey-form-question">
                                    How could we improve the diagnostic survey or reports to make it easier to understand or
                                    use?
                                </div>
                            </div>
                            <div className="roi-survey-rating-row">
                            <textarea className="roi-survey-text-input" placeholder="Improvements"
                                      onChange={ev => handleScoreChange(ev.target.value, 12)}/>
                            </div>
                        </div>
                        <div className="roi-survey-warning-text">{showUnansweredWarning ? "Please answer all questions before submitting." : ""}</div>
                        <div className="submit-questionnaire-button roi" onClick={handleFormSubmit}>Submit</div>
                    </div>
                </div>*/}
            </div>
            :
            <div className="questionnaire-complete-header">
                <div className="questionnaire-complete-logo">
                    <img src={logo} alt="ccmi logo"/>
                </div>
                <h2>You're Finished</h2>
                <h3>Thank you. You have now completed your diagnostics questionnaire.</h3>
            </div>
    )
}

export default ROISurvey