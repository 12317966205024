import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import TeamForm from "../components/teams/TeamForm";
import TeamSurveyCardContainer from "../containers/TeamSurveyCardContainer";
import Slider from "../components/Slider";
import DeleteDialog from "../components/DeleteDialog";
import teams_api from "../api/protected/teams";
import people_api from "../api/protected/people";
import deleteIcon from "../assets/delete-icon.svg"
import back_arrow from "../assets/back_arrow.png";
import TeamPeopleContainer from "../containers/TeamPeopleContainer";
import GenericDialog from "../components/GenericDialog";
import {te} from "date-fns/locale";


const EditTeam = () => {
    const navigate = useNavigate()
    const params = useParams();
    const [parameters, setParameters] = useSearchParams();

    const [teamId, setTeamId] = useState("")
    const [isDemo, setIsDemo] = useState(false)

    const [team, setTeam] = useState(null)
    const [teamName, setTeamName] = useState("");
    const [teamDescription, setTeamDescription] = useState("");
    const [companyId, setCompanyId] = useState();
    const [editor, setEditor] = useState(false)

    const [people, setPeople] = useState([]);
    const [fromUrl, setFromUrl] = useState("")

    const [showDelete, setShowDelete] = useState();
    const buttons = [{"text":"Delete", "id": "1"}, {"text":"Cancel", "id": "2"}];

    const [showDeleteHover, setShowDeleteHover] = useState(false)
    const [showHidden, setShowHidden] = useState()

    const [deletedPeople, setDeletedPeople] = useState([])
    const [showError, setShowError] = useState(false)
    const errorButtons = [{"text":"OK", "id": "1"}];
    const [showConfirmation, setShowConfirmation] = useState(false)

    useEffect(() => {
        if (parameters.get("from")) {
            let frm = parameters.get("from")
            setFromUrl(frm)
            parameters.delete("from")
        }
        if (parameters.get("demo")) {
            setIsDemo(true)
        }
        setParameters(parameters)
    }, [])

    useEffect(() => {
        if (!params.teamId) return;
        setTeamId(params.teamId)
    }, [params.teamId]);

    useEffect(() => {
        if (!teamId) return;
        getTeam()
        getPeople();
    }, [teamId]);

    const getTeam = () => {
        if (!teamId) return;
        if(isDemo) {
            let response = teams_api.getDemoTeamByID(teamId);
            response.then(response => {
                if (response.data.teams.length === 0) BackButton()
                let data = response.data.teams[0]
                setTeam(data);
                setTeamName(data.name);
                setTeamDescription(data.description);
                setCompanyId(data.company_id);
                setShowHidden(false);
                setEditor(false);
            })
        } else {
            let response = teams_api.getTeamByID(teamId);
            response.then(response => {
                if (response.data.teams.length === 0) BackButton()
                let data = response.data.teams[0]
                setTeam(data);
                setTeamName(data.name);
                setTeamDescription(data.description);
                setCompanyId(data.company_id);
                setShowHidden(data.hidden);
                setEditor(data.editor);
            })
        }
    }

    const getPeople = () => {
        if (!teamId) return;
        let response;
        if(isDemo) {
            response = people_api.getDemoPeople(teamId);
        } else {
            response = people_api.getPeople(teamId);
        }
        response.then(
            response => {
                setPeople(response.data.people);
            }
        )
    }

    const newPerson = (person) => {
        if(!person) return;
        let temp = [...people]
        temp.push(person)
        setPeople(temp)
    }

    const deletePerson = (ev, idx) => {
        let dp = [...deletedPeople]
        let temp = [...people]

        if (temp[idx].id !== "new") dp.push(temp[idx].id)
        temp.splice(idx, 1)

        setDeletedPeople(dp)
        setPeople(temp)
    }

    const updatePerson = (person, idx) => {
        let temp = [...people]
        temp[idx] = person
        setPeople(temp)
    }

    const savePeopleUpdates = () => {
        let response = people_api.updatePeople(teamId, companyId, people, deletedPeople)
        response.then(() => setShowConfirmation(true)).catch(() => triggerErrorMessage())
    }

    const triggerErrorMessage = () => {
        setShowConfirmation(false);
        setShowError(true);
    }

    const handleDeleteClick = () => {
        setShowDelete(!showDelete)
    }

    const handleDeleteResult = (result) => {
        if (!editor) return;
        if (result === "1") {
            deleteTeam()
        }
        setShowDelete(false)
    }

    const handleErrorResult = (result) => {
        if (result === "1") {
            setShowError(false);
            window.location.reload()
        }
    }

    const handleConfirmationResult = (result) => {
        if(result === "1") {
            setShowConfirmation(false);
            window.location.reload()
        }
    }

    const deleteTeam = () => {
        if(!teamId || !editor) return;
        let response = teams_api.deleteTeam(teamId)
        response.then(() => {
            navigate("/teams")
        }).catch(err => console.log(err))
    }

    const formSubmit = (body)=> {
        if (!body || !editor) return;
        if (body.hasOwnProperty("company_id")) delete body["company_id"]
        let response = teams_api.updateTeam(teamId, body);
        response.then(() => {
            navigate("/teams")
        }).catch(err => console.log(err))
    }

    const BackButton = () => {
        if (fromUrl) navigate("/" + fromUrl)
        else navigate("/teams")
    }

    const updateToggleHidden = () => {
        setShowHidden(!showHidden);
    }

    const handleDeleteHoverOn = () => {
        setShowDeleteHover(true)
    }

    const handleDeleteHoverOut = () => {
        setShowDeleteHover(false)
    }

    useEffect(() => {
        if (!editor) return;
        const body = {"hidden": showHidden}
        let response = teams_api.putTeamVisibility(teamId, body);
        response.catch(
            err => {
                console.log(err)
            }
        )
    }, [showHidden]);

    return (
        <div className="teams-page-background-container">
            <div className="teams-page-container">
                <div className="back-link link" onClick={() => BackButton()}>
                    <img src={back_arrow} alt={"Back Arrow"}/>
                </div>
                {team && team ?
                    <>
                        <div className="teams-page-header">
                            <div className="teams-page-title">
                                {teamName ? teamName : "Edit Team"}
                            </div>
                            {editor ?
                                <div className="col">
                                    <div className="header-icon-container">
                                        {editor ?
                                            <div className="header-slider-container">
                                                <div className="header-hide-text">Hide</div>
                                                <Slider update={updateToggleHidden.bind(this)} state={showHidden} type="Team"/>
                                            </div> : null
                                        }
                                        <div className="header-delete-container"
                                             onMouseOver={handleDeleteHoverOn}
                                             onClick={handleDeleteClick}
                                             onMouseLeave={handleDeleteHoverOut}>
                                            <img className="header-delete-icon link" src={deleteIcon} alt="Delete Team"/>
                                            {showDeleteHover ?
                                                <div className="delete-tooltip-container">
                                                    <div className="delete-tooltip-text">Delete Team</div>
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                :null}
                        </div>
                        <TeamForm submit={formSubmit.bind(this)}
                                  buttonText={"Update"}
                                  teamName={teamName && teamName}
                                  teamDescription={teamDescription && teamDescription}
                                  companyId={companyId}
                                  editor={editor}
                                  team={team}
                        />
                        <div className="team-form-container">
                            <TeamPeopleContainer people={people} company_id={companyId} isNew={false} editor={editor}
                                                 updatePeople={newPerson.bind(this)}
                                                 updatePerson={updatePerson.bind(this)}
                                                 deletePerson={deletePerson.bind(this)}
                            />
                            {editor ?
                                <div className="field-input">
                                    <button className="update-company-button link" onClick={savePeopleUpdates}>
                                        Update
                                    </button>
                                </div>
                                : null
                            }
                        </div>
                        <div className="team-form-container">
                            <div className="teams-form-title">
                                Surveys
                            </div>
                            <TeamSurveyCardContainer teamId={teamId} editor={editor} companyId={companyId}
                                                     isDemo={isDemo}/>
                        </div>

                    </> : null}
                {showDelete ?
                    <DeleteDialog title={"Delete Team"}
                                  type={"Team"}
                                  name={teamName && teamName}
                                  description={teamDescription && teamDescription}
                                  buttons={buttons}
                                  result={handleDeleteResult.bind(this)}/>
                    :null
                }
                {showError ?
                    <GenericDialog title="Update Failed"
                                   text="Failed to update team members."
                                   buttons={errorButtons}
                                   result={handleErrorResult.bind(this)}/>
                    :null
                }
                {showConfirmation ?
                    <GenericDialog title="Update Successful"
                                   text="Team people updated."
                                   buttons={errorButtons}
                                   result={handleConfirmationResult.bind(this)}/>
                    :null
                }
            </div>
        </div>
    )
}

export default EditTeam