import {useEffect, useState} from "react";


const ROICoachPerformance = (props) => {

    const [data, setData] = useState(null)

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    return (
        <div className="roi-coach-performance-container">
            <div className="roi-coach-performance-title-container">Coach Performance Summary</div>
            <div className="roi-coach-performance-body-container">
                <div className="roi-coach-performance-body-row">
                    <div className="roi-coach-performance-body-text semi-bold">
                        The following scores are the aggregated feedback scores you have received from team
                        participants, as rated at the end of the coaching engagement, for all teams selected for this
                        report.
                    </div>
                    <div className="roi-coach-performance-body-graphic">
                        <div className="roi-coach-performance-participants-asked bold">
                            <div className="roi-coach-performance-participants-asked-text">Participants Asked</div>
                            <div className="roi-coach-performance-participants-asked-spacer"/>
                            <div className="roi-coach-performance-participants-asked-text value">
                                {data && data.participants_asked}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="roi-coach-performance-body-row">
                    <div className="roi-coach-performance-body-text thin regular">
                        Level of perceived support received through the team coaching engagement.
                    </div>
                    <div className="roi-coach-performance-body-graphic wide">
                        <div className="roi-coach-performance-bar">
                            <div className="roi-coach-performance-bar-text-container bold">
                                <div className="roi-coach-performance-bar-text">None</div>
                                <div className="roi-coach-performance-bar-text">Low</div>
                                <div className="roi-coach-performance-bar-text">Moderate</div>
                                <div className="roi-coach-performance-bar-text">High</div>
                                <div className="roi-coach-performance-bar-text">Very High</div>
                            </div>
                            <div className="roi-coach-peformance-bar-graphic">
                                <div className="roi-coach-performance-bar-white-band">
                                    <div className="roi-coach-performance-bar-point" style={{left: `calc(${(data && data.support * 10)}% - 12.5px)`}}/>
                                    <div className="roi-coach-performance-bar-colour purple"/>
                                    <div className="roi-coach-performance-bar-spacer"/>
                                    <div className="roi-coach-performance-bar-colour red"/>
                                    <div className="roi-coach-performance-bar-spacer"/>
                                    <div className="roi-coach-performance-bar-colour yellow"/>
                                    <div className="roi-coach-performance-bar-spacer"/>
                                    <div className="roi-coach-performance-bar-colour green"/>
                                    <div className="roi-coach-performance-bar-spacer"/>
                                    <div className="roi-coach-performance-bar-colour blue"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="roi-coach-performance-body-row">
                    <div className="roi-coach-performance-body-text regular">
                        Percentage of participants who feel that this team coach has positively influenced
                        communication and effectiveness of the team as a whole.
                    </div>
                    <div className="roi-coach-performance-body-graphic">
                        <div className="roi-coach-performance-percentage bold">{data && data.team_improvement}%</div>
                    </div>
                </div>
                <div className="roi-coach-performance-body-row">
                    <div className="roi-coach-performance-body-text regular">
                        Percentage of participants who feel that this team coach has positively influenced
                        communication and effectiveness within the team.
                    </div>
                    <div className="roi-coach-performance-body-graphic">
                        <div className="roi-coach-performance-percentage bold">{data && data.personal_improvement}%</div>
                    </div>
                </div>
                <div className="roi-coach-performance-body-row">
                    <div className="roi-coach-performance-body-text thin regular">
                        Likelihood of participants to recommend this Coach to colleagues.
                    </div>
                    <div className="roi-coach-performance-body-graphic wide">
                        <div className="roi-coach-performance-bar">
                            <div className="roi-coach-performance-bar-text-container bold">
                                <div className="roi-coach-performance-bar-text">Definitely Not</div>
                                <div className="roi-coach-performance-bar-text">Not Likely</div>
                                <div className="roi-coach-performance-bar-text">Likely</div>
                                <div className="roi-coach-performance-bar-text">Most Likely</div>
                                <div className="roi-coach-performance-bar-text">Definitely</div>
                            </div>
                            <div className="roi-coach-peformance-bar-graphic">
                                <div className="roi-coach-performance-bar-white-band">
                                    <div className="roi-coach-performance-bar-point"
                                         style={{left: `calc(${data && data.recommend * 10}% - 12.5px)`}}/>
                                    <div className="roi-coach-performance-bar-colour purple"/>
                                    <div className="roi-coach-performance-bar-spacer"/>
                                    <div className="roi-coach-performance-bar-colour red"/>
                                    <div className="roi-coach-performance-bar-spacer"/>
                                    <div className="roi-coach-performance-bar-colour yellow"/>
                                    <div className="roi-coach-performance-bar-spacer"/>
                                    <div className="roi-coach-performance-bar-colour green"/>
                                    <div className="roi-coach-performance-bar-spacer"/>
                                    <div className="roi-coach-performance-bar-colour blue"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ROICoachPerformance