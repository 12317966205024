import CompanyCard from "../components/CompanyCard";
const CompanyCardContainer = (props) => {
    const companies = props.companies;
    const showHidden = props.showHidden;

    return (
        <div className="company-card-container">
            {companies && companies.map((company, idx) => {
                if(!company.hidden || company.demo || showHidden) {
                    return <CompanyCard company={company} handleClick={props.handleClick} key={idx}/>
                }
            })}
        </div>
    )
}
export default CompanyCardContainer
