import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")

const getCoachInvitations = () => http.get('/coach_invitations')
const acceptCoachInvitation = (id) => http.put(`/coach_invitations/${id}`)
const rejectCoachInvitation = (id) => http.delete(`/coach_invitations/${id}`)
const getUserInvitations = () => http.get('/user_coaches')
const createInvitation = (email) => http.post('/user_coaches', {
    "coach_email": email,
})
const revokeInvitation = (id) => http.delete(`/user_coaches/${id}`)
const getCoachDetails = (id, view=false) => http.get(`/coach_details/${id}/${view}`)
const getSharedResources = (id, view=false) => http.get(`/team_sharing/${id}/${view}`)

const updateSharedTeams = (id, new_teams, delete_teams) => http.put(`/team_sharing/${id}`, {
    "new_teams": new_teams,
    "delete_teams": delete_teams,
})


export default {
    getCoachInvitations,
    acceptCoachInvitation,
    rejectCoachInvitation,
    getUserInvitations,
    createInvitation,
    revokeInvitation,
    getCoachDetails,
    getSharedResources,
    updateSharedTeams
}