import {useEffect, useState} from "react";

const SurveyOverviewParticipantSelector = (props) => {

    const people = props.people;
    const result = props.result

    const buttons = [{"text":"Confirm", "id": "1"}, {"text":"Cancel", "id": "2"}];

    const [selectedPeople, setSelectedPeople] = useState([])

    const togglePerson = (ev) => {
        if (!ev.target.id) return;
        let selected = [...selectedPeople];
        if (isSelected(parseInt(ev.target.id))) {
            let index = selected.findIndex(x => x.id === parseInt(ev.target.id))
            if(index > -1) {
                // if it's in the array, remove it
                selected.splice(index,1);
            }
            setSelectedPeople(selected);
        } else {
            let index = selected.findIndex(x => x.id === parseInt(ev.target.id))
            if (index < 0) {
                let person = {}
                person.id = parseInt(ev.target.id);
                selected.push(person);
            }
            setSelectedPeople(selected);
        }

    }

    const isSelected = (id) => {
        if (!id || !selectedPeople) return;
        for(const person of selectedPeople) {
            if(person.id === id) {
                return true;
            }
        }
        return false;
    }

    const footerClick = (ev) => {
        if (!ev) return;
        let res = {"button": ev.target.id, "people": selectedPeople}
        result(res)
    }

    useEffect(() => {
        let selected = []
        for(const person of people) {
            if(person.percentage_complete === 100) {
                selected.push(person)
            }
        }
        setSelectedPeople(selected)
    }, [people]);

    return (
        <>
            <div className="page-mask"/>
            <div className="participant-selector-container">
                <div className="participant-selector-header-container">
                    <div className="participant-selector-header">
                        {props.title ? props.title : "Summary Participant Selection"}
                    </div>
                </div>
                <div className="participant-selector-body-description-container">
                    Select which participants you wish you'd like to see within your diagnostic summary...
                </div>
                <div className="participant-selector-body-container">
                    <div className="participant-selector-grid">
                        {people && people.map((person, idx) => {
                            return (
                                <div className={`participant-selector-row ${person.percentage_complete !== 100 ? "disabled" : ""}`} key={idx}>
                                    <div className="participant-selector-name">{person.name}</div>
                                    <div className="participant-selector-percentage">{person.percentage_complete}%</div>
                                    <div className="participant-selector-radio-container">
                                        <input type="checkbox" checked={isSelected(person.id)}
                                               onChange={togglePerson} id={person.id}
                                               disabled={person.percentage_complete !== 100}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="participant-selector-footer-container">
                    {buttons && buttons.map((button, idx) => {
                        return (
                            <div className="participant-selector-footer-button link" id={button.id}
                                 onClick={footerClick} key={idx}>
                                {button.text}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )

}

export default SurveyOverviewParticipantSelector