import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")


const getSurveys = () => http.get("/surveys");
const getSurvey = (survey) => http.get("/surveys/" + survey);
const newSurvey = (body) => http.post("/surveys", body);
const updateSurvey = (id, body) => http.put("/surveys/" + id, body);
const deleteSurvey = (id) => http.delete("/surveys/" + id);
const putSurveyVisibility = (survey_id, body) => http.put(`/survey_hidden/${survey_id}`, body)
const resendEmails = (survey_id, people) => http.post(`/resend_survey_emails/${survey_id}`, {
    "people": people
})
const getDemoSurveys = () => http.get('/demo_surveys')
const getDemoSurvey = (survey) => http.get(`/demo_surveys/${survey}`)
const checkSurveyPoint = (team_id) => http.get(`/check_survey_point/${team_id}`)

export default {
    getSurveys,
    getSurvey,
    newSurvey,
    deleteSurvey,
    updateSurvey,
    putSurveyVisibility,
    resendEmails,
    getDemoSurveys,
    getDemoSurvey,
    checkSurveyPoint
}