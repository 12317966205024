import {useEffect, useState} from "react";
import profile_api from "../api/protected/profile";
import {useNavigate} from "react-router-dom";
import styles from "../utils/ReactSelectStyles";
import Select from "react-select";
import Accreditations from "../models/Accreditations";
import ImageUtils from "../utils/ImageUtils";
import tick from "../assets/settings-tick.svg"
import cross from "../assets/settings-cross.svg"
import exit from "../assets/accreditation-cross.svg"
import p3_tick from "../assets/accreditation-tick.svg"
import uploader from "../assets/accreditation-uploader.svg"


const AccreditationPopup = () => {
    const navigate = useNavigate()

    // Pages
    // 0 - entry : do you have EMCC
    // 1 - YES EMCC Form entry
    // 2 - NO CHECK list and upload
    // 3 - Thank you for uploading
    // 4 - Upload later message
    const [popUpPage, setPopUpPage] = useState(0)

    // EMCC fields
    const [name, setName] = useState("")
    const [certNumber, setCertNumber] = useState("")
    const [awardLevel, setAwardLevel] = useState("")

    //Other Fields
    const [accreditations, setAccreditations] = useState([])
    const [typeSelected, setTypeSelected] = useState("")
    const [accreditationSelected, setAccreditationSelected] = useState("")

    const [fileType, setFileType] = useState("")
    const [fileName, setFileName] = useState("")
    const [base64File, setBase64File] = useState("")
    const [uploaded, setUploaded] = useState(false);

    useEffect(() => {
        setPopUpPage(0)
        GetProfile()
        setAccreditations(Accreditations.types)
    }, []);

    const GetProfile = () => {
        let response = profile_api.getProfile();
        response.then(response => {
            if (response.data.profile.first_name && response.data.profile.last_name)
                setName(response.data.profile.first_name + " " + response.data.profile.last_name)
        }).catch(err => {console.log(err)})
    }

    const handleGoToPage = (page) => {
        setPopUpPage(page)
    }

    const handleEMCCSubmit = () => {
        if (!EMCCFieldCheck()) return
        let body = {
            "type": "EMCC",
            "name": name,
            "certificate_number": certNumber,
            "award_level": awardLevel
        }
        let response = profile_api.submitAccreditation(body);
        response.then(() => {
            handleGoToPage(3)
        }).catch(err => console.log(err))
    }

    const handleOtherSubmit = () => {
        if (!UploadFieldCheck()) return
        let body = {
            "type": typeSelected,
            "file": {
                "name": fileName,
                "file_type": fileType,
                "base64": base64File
            }
        }
        let response = profile_api.submitAccreditation(body);
        response.then(() => {
            setUploaded(true)
        }).catch(err => {console.log(err)})
    }

    const EMCCFieldCheck = () => {
        return !(!certNumber || !awardLevel || !name)
    }

    const OtherFieldCheck = () => {
        return !(!base64File || !fileType || !fileName || !typeSelected || !uploaded)
    }

    const UploadFieldCheck = () => {
        return !(!base64File || !fileType || !fileName || !typeSelected)
    }

    const updateFileType = (string) => {
        if (string === "" || string === undefined) return;
        setFileType(string)
    }

    const handleFileUploaderClick = () => {
        let element = document.getElementById("accreditation-file-uploader")
        if (!element) return;
        element.click()
    }

    const first_page = () => {
        return (
            <div className="accreditation-popup first-page">
                <div className="accreditation-popup-header bold">Accreditation Required</div>
                <div className="accreditation-popup-text regular">
                    Do you have team coaching accreditation from the EMCC?
                </div>
                <div className="accreditation-popup-buttons-container">
                    <div className="accreditation-popup-button" onClick={() => handleGoToPage(1)}>
                        <img src={tick} alt="Yes"/>
                    </div>
                    <div className="accreditation-popup-button" onClick={() => handleGoToPage(2)}>
                        <img src={cross} alt="No"/>
                    </div>
                </div>
                <div className="accreditation-popup-text regular">
                    Unsure? You can use our Quick Look up button below to check.
                </div>
                <div className="manage-subscription-button bold">
                    <a className="undecorated" target="_blank" href="https://www.emccglobal.org/accreditation/itca/itca-holders/">
                        EMCC Quick Look up
                    </a>
                </div>
            </div>
        )
    }

    const second_page = () => {
        return (
            <div className="accreditation-popup second-page">
                <div className="accreditation-popup-x" onClick={() => {
                    navigate("/settings?preferences=1")
                    window.location.reload()
                }}>
                    <img src={exit} alt="Exit"/>
                </div>
                <div className="accreditation-popup-header bold">Accreditation</div>
                <div className="accreditation-popup-text regular">
                    Entering just your surname & clicking search is enough to find your details
                </div>
                <div className="accreditation-popup-label medium">Name</div>
                <div className="accreditation-input">
                    <input type="text" onChange={ev => setName(ev.target.value)} value={name}/>
                </div>
                <div className="accreditation-popup-label medium">ITCA Reference/Certificate Number</div>
                <div className="accreditation-input">
                    <input type="text" onChange={ev => setCertNumber(ev.target.value)} value={certNumber}/>
                </div>
                <div className="accreditation-popup-text regular">
                    Unsure? Use this link to look up and confirm your <span>ITCA/Certificate Number</span>
                </div>
                <div className="accreditation-popup-label medium">Award Level</div>
                <div className="accreditation-input">
                    <input type="text" onChange={ev => setAwardLevel(ev.target.value)} value={awardLevel}/>
                </div>
                <div className={`manage-subscription-button bold auto ${EMCCFieldCheck() ? "" : "disabled"}`}
                     onClick={() => handleEMCCSubmit()}>
                    SUBMIT
                </div>
            </div>
        )
    }

    const third_page = () => {
        return (
            <div className="accreditation-popup third-page">
                <div className="accreditation-popup-x" onClick={() => {
                    navigate("/settings?preferences=1")
                    window.location.reload()
                }}>
                    <img src={exit} alt="Exit"/>
                </div>
                <div className="accreditation-popup-header bold">Accreditation</div>
                <div className="accreditation-popup-text regular">
                    Please confirm which programme accreditation you have and upload certificate of training,
                    graduation or completion:
                </div>
                <Select options={accreditations}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.value}
                        value={accreditationSelected}
                        placeholder="Choose programme accreditation"
                        onChange={ev => {
                            setTypeSelected(ev.value);
                            setAccreditationSelected(ev)
                        }}
                        styles={styles.accreditationPopupSelect}
                />
                <div className="accreditation-file-input" onClick={() => handleFileUploaderClick()}>
                    <div className="accreditation-file-name medium">{fileName}</div>
                    <div className="accreditation-file-input-select-container">
                        <div className="accreditation-upload-file bold">Select file</div>
                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481
                            1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695
                            4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0
                            0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"/>
                        </svg>
                    </div>
                </div>
                <input type='file' id="accreditation-file-uploader"
                       onChange={async (e) => {
                           let base64 = await ImageUtils.convertBase64(e.target.files[0]);
                           base64 = base64.split(",")[1]
                           setBase64File(base64);
                           setFileName(e.target.files[0].name)
                           updateFileType(e.target.files[0].name.split(".")[1]);
                       }}
                       className="accreditation-file-uploader-hidden"
                />
                <div className="accreditation-upload-button-container">
                    <div className="file-uploaded-container">
                        {uploaded ?
                            <>
                                <div className="file-uploaded-text bold">File uploaded</div>
                                <div className="file-uploaded-img">
                                    <img src={p3_tick} alt="Uploaded"/>
                                </div>
                            </>
                            : null
                        }
                    </div>
                    <div className="upload-button-container" onClick={() => handleOtherSubmit()}>
                        <div className="upload-button-text bold">Upload</div>
                        <div className="upload-button-img">
                            <img src={uploader} alt="Upload"/>
                        </div>
                    </div>
                </div>
                <div className="accreditation-buttons-container">
                    <div className={`accreditation-button bold ${OtherFieldCheck() ? "" : "disabled"}`}
                         onClick={() => {
                             navigate("/settings?preferences=1")
                             window.location.reload()
                         }}>
                        DONE
                    </div>
                    <div className="accreditation-button bold cancel" onClick={() => {
                        navigate("/settings?preferences=1")
                        window.location.reload()
                    }}>
                        CANCEL
                    </div>
                </div>
            </div>
        )
    }

    const tp = () => {
        return (
            <div className="dialog-header-container">
                <div className="dialog-header">
                    Accreditation
                </div>
                <div className="dialog-text">
                    Please confirm which programme accreditation you have and upload certificate of training graduation
                    or completion:
                </div>
                <div className="dialog-text">
                    <Select options={accreditations}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.value}
                            value={accreditationSelected}
                            placeholder=""
                            onChange={ev => {
                                setTypeSelected(ev.value);
                                setAccreditationSelected(ev)
                            }}
                            styles={styles.registerStyles}
                    />
                    <div className="file-uploader-container">
                        <div className="file-uploader-button" onClick={() => handleFileUploaderClick()}>
                            {!base64File ? "Upload a file" : "Select a different file"}
                        </div>
                        <div className="file-label">
                            {fileName}
                        </div>
                    </div>
                    <input type='file' id="accreditation-file-uploader"
                           onChange={async (e) => {
                               let base64 = await ImageUtils.convertBase64(e.target.files[0]);
                               base64 = base64.split(",")[1]
                               setBase64File(base64);
                               setFileName(e.target.files[0].name)
                               updateFileType(e.target.files[0].name.split(".")[1]);
                           }}
                           className="accreditation-file-uploader-hidden"
                    />
                </div>

                <div className="dialog-footer-container">
                    <div className={OtherFieldCheck() ? "dialog-button link" : "dialog-button disabled"}
                         onClick={() => handleOtherSubmit()}>
                        <div className="dialog-button-text">Upload Now</div>
                    </div>
                    <div className="dialog-button link" onClick={() => handleGoToPage(4)}>
                        <div className="dialog-button-text">Upload Later</div>
                    </div>
                </div>
            </div>
        )
    }

    const fourth_page = () => {
        return (
            <div className="accreditation-popup fourth-page">
                <div className="accreditation-popup-header bold">Submitted</div>
                <div className="accreditation-popup-text regular">
                    Thank you, we have received your accreditation details. Approval of accreditation will be within 7
                    working days.
                </div>
                <div className="manage-subscription-button bold" onClick={() => {
                    navigate("/settings?preferences=1")
                    window.location.reload()
                }}>
                    CLOSE
                </div>
            </div>
        )
    }

    const fifth_page = () => {
        return (
            <div className="dialog-header-container">
                <div className="dialog-header">
                    Accreditation
                </div>
                <div className="dialog-text">
                    You can upload proof of accreditation in your profile area at any time.
                    Approval of accreditation will be within 7 working days.
                </div>
                <div className="dialog-text">
                    You will not be able to access the full features of the (Gold/Silver) plan until you have uploaded
                    these documents and they have been verified, even if your payment has been taken
                </div>
                <div className="dialog-footer-container">
                    <div className="dialog-button link" onClick={() => navigate("/dashboard")}>
                        <div className="dialog-button-text">Return to Dashboard</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="page-mask"/>
            <div className="accreditation-dialog-container">
                {popUpPage === 0
                    ? first_page()
                    : popUpPage === 1
                        ? second_page()
                        : popUpPage === 2
                            ? third_page()
                            : popUpPage === 3
                                ? fourth_page()
                                : popUpPage === 4
                                    ? fifth_page()
                                    : null}
            </div>
        </>
    )
}

export default AccreditationPopup

