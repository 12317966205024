import BackgroundRings from "../components/BackgroundRings";
import team_coaching from "../assets/team-coaching.png"
import star from "../assets/team-coach-star.svg"


const TeamCoaching = () => {
    return (
        <div className="learn-more-page-container team-coaching">
            <BackgroundRings top={-370} right={-143}/>
            <div className="learn-more-page-title">Team Coach Training</div>
            <div className="learn-more-page-subtext">
                CCMI offers a range of courses for those wishing to acquire Team Coaching skills, with Virtual, In
                person & In House training options wherever you or your team are in the World.
            </div>
            <div className="team-coaching-who-for-container">
                <div className="perill-model-unlock-text-container">
                    <div className="perill-model-unlock-text-title">Who are they for?</div>
                    <div className="perill-model-unlock-text">
                        These are aimed at both ‘External’ professional coaches and ‘Internal’ coaches for client
                        organisations such as business leaders, managers, staff & HR professionals. All our Team
                        Coaching courses are certified by both the EMCC (European Mentoring & Coaching Council) & the
                        ICF (International Coach Federation). They incorporate the PERILL model – the only complex,
                        adaptive systems approach to understanding the factors that influence a team’s performance and
                        includes FREE 12 months Gold Membership Package (worth £750).
                    </div>
                </div>
                <div className="perill-model-unlock-image-container">
                    <img src={team_coaching} alt="Team Coaching"/>
                </div>
            </div>
            <div className="team-coaching-platform-title">We offer the following courses</div>
            <div className="team-coaching-platforms-container">
                <div className="team-coaching-platform">
                    <div className="team-coaching-star-container">
                        <img src={star} alt="Star"/>
                    </div>
                    <div className="team-coaching-platform-box-title">Team Coaching Foundation</div>
                    <div className="team-coaching-platform-box-text-container">
                        <div className="team-coaching-platform-box-text">
                            This 3 day workshop provides an introduction to team dynamics and teaming. It’s ideal for
                            either businesses and individuals who manage teams; HR professionals who want to support
                            team coaching; or a professional coach with an evolving practice. It’s the first of three
                            courses within our Team Coaching Programme; leading onto Practitioner level and Senior
                            Practitioner level. It’s the first stage on the journey from coaching individuals to
                            coaching teams, providing a solid foundation and toolkit to help the team, through its
                            leader.
                        </div>
                    </div>
                    <div className="home-page-button button-fill">LAUNCH FOUNDATION</div>
                </div>
                <div className="team-coaching-platform">
                    <div className="team-coaching-star-container">
                        <img src={star} alt="Star"/>
                        <img src={star} alt="Star"/>
                    </div>
                    <div className="team-coaching-platform-box-title">Team Coaching Practitioner</div>
                    <div className="team-coaching-platform-box-text-container">
                        <div className="team-coaching-platform-box-text">
                            This 3 day intensive, highly practical course supports coaches in making the transition
                            from coaching individual clients in developing coaching cultures in their team to working
                            with the whole team.
                        </div>
                        <div className="team-coaching-platform-box-text">
                            It explores the messiness of working with whole teams providing practical tools and
                            techniques for enabling the team to understand and change its dynamics in ways that
                            increase collective performance and add value to all its stakeholders. The core of the
                            module is the PERILL framework; a complex, adaptive systems perspective that allows the
                            team to understand its drivers and barriers to performance. The module works step by step
                            through each phase of a team coaching assignment; from Scoping and Discovery, through
                            Contracting, Delivery, Evaluation and Withdrawal (when the team is better able to coach
                            itself).
                        </div>
                    </div>
                    <div className="home-page-button button-fill">LAUNCH PRACTIONER</div>
                </div>
                <div className="team-coaching-platform">
                    <div className="team-coaching-star-container small-star">
                        <img src={star} alt="Star"/>
                        <img src={star} alt="Star"/>
                        <img src={star} alt="Star"/>
                    </div>
                    <div className="team-coaching-platform-box-title">Team Coaching Senior Practitioner</div>
                    <div className="team-coaching-platform-box-text-container">
                        <div className="team-coaching-platform-box-text">
                            This 14 month immersive and experiential programme continues the team coaches’ journeys to
                            greatly enhance the coach’s toolkit; equips them to take the lead in team coaching pairs;
                            confidently working with increasing levels of complexity to help teams strengthen all
                            elements of the PERILL framework.
                        </div>
                        <div className="team-coaching-platform-box-text">
                            The emphasis of this programme is upon using participants’ current experience of teams they
                            are working with as the basis for developing a broader portfolio of approaches and
                            increasing their confidence in coping with challenging situations. A minimum of two client
                            teams will be required for you to be actively practising during the programme.
                        </div>
                    </div>
                    <div className="home-page-button button-fill">LAUNCH SNR PRACTIONER</div>
                </div>
            </div>
            <div className="home-page-background-circle" style={{top: 1500, left: -350}}/>
            <div className="team-coaching-more-details-text">
                For more details on our training courses, resources and books on Team Coaching, please visit our
                website.
            </div>
            <div className="home-page-button button-fill team-coaching-details">LAUNCH CCMI</div>
            <div className="team-coaching-more-details-text">
                You may also be interested in resources & courses covering these other subjects:
            </div>
            <div className="team-coaching-more-details-text bold">
                Coaching, Coaching Culture, Diversity & Inclusion, Mentoring, Talent Management, Supervision
            </div>
        </div>
    )
}

export default TeamCoaching