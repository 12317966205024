import BackgroundRings from "../components/BackgroundRings";
import professional_image from "../assets/professional-hero.png"
import bronze_medal from "../assets/bronze-medal.png"
import {useEffect} from "react";
import {Link} from "react-router-dom";
import auth from "../api/auth";

const LearnMoreProfessional = () => {

    useEffect(() => window.scrollTo({top: 0, left: 0, behavior: 'instant'}), []);

    return (
        <div className="learn-more-page-container professional">
            <BackgroundRings top={-370} right={-143}/>
            <div className="learn-more-page-title">Line Managers <br/>& HR Professionals</div>
            <div className="learn-more-page-who-is-this-for-container">
                <div className="learn-more-page-who-is-this-for-image-container">
                    <img src={professional_image} alt="Line Managers & HR Professionals"/>
                </div>
                <div className="learn-more-page-who-is-this-for-text-container">
                    <div className="learn-more-page-who-is-this-for-title">Who is this for? </div>
                    <div className="learn-more-page-who-is-this-for-medal-contaner">
                        <img src={bronze_medal} alt="Bronze"/>
                    </div>
                    <div className="learn-more-page-who-is-this-for-description">
                        Our FREE Bronze package is for Line Managers, Team leaders and HR Professionals who want a
                        simple and effective method of improving their teams performance. We designed this high impact,
                        zero cost package to be led by team leaders or HR professionals rather than coaches/ team coaches.
                    </div>
                    <div className="home-page-button filled-button">
                        <Link to="/pricing-plans" className="undecorated">SEE OUR PRICING PLANS</Link>
                    </div>
                </div>
            </div>
            <div className="learn-more-page-benefits-container">
                <div className="learn-more-page-benefits-title">What are the benefits?</div>
                <div className="learn-more-page-benefits-description">
                    <b className="bold">PERILL diagnostics for Teams</b> supports an understanding of the bigger picture of the team and the
                    context. The diagnostic report and team coaching framework provided allows for team leaders
                    and their teams to co-create a coaching culture and improve team performance through enhanced:
                </div>
                <div className="learn-more-page-subcontainer">
                    <div className="learn-more-page-bullet-container">
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Awareness of the teams collective strengths and weaknesses.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Understanding of the teams systems and context.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Communication & engagement within the team.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Psychology safety within the team.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Coaching culture within the team.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Engagement with the teams stakeholders.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Communication & engagement with other areas of the business.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Measurement of impact & performance improvements over time.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="learn-more-page-button-container">
                    <div className="home-page-button filled-button">
                        <div className="undecorated"
                             onClick={() => auth.protectedNavigate("/dashboard", "/register")}>
                            Sign up for free
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LearnMoreProfessional