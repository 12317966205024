import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")

const getComments = (context_id, section_id) => http.get(`/summary_comments/${context_id}/${section_id}`)
const newComment = (context_id, section_id, comment) => http.post(`/summary_comments/${context_id}/${section_id}`, {
    "comment": comment,
})
const editComment = (comment_id, comment) => http.put(`/summary_comments/null/null/${comment_id}`, {
    "comment": comment
})
const deleteComment = (comment_id) => http.delete(`/summary_comments/null/null/${comment_id}`)
const createRoiCommentId = () => http.post('/roi_comment_id')

export default {
    getComments,
    newComment,
    editComment,
    deleteComment,
    createRoiCommentId
}