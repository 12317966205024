import BackgroundRings from "../components/BackgroundRings";
import coaches_image from "../assets/coaches-hero.svg"
import silver_medal from "../assets/silver-medal.png"
import gold_medal from "../assets/gold-medal.png"
import {useEffect} from "react";
import {Link} from "react-router-dom";

const LearnMoreCoaches = () => {

    useEffect(() => window.scrollTo({top: 0, left: 0, behavior: 'instant'}), []);

    return (
        <div className="learn-more-page-container coaches">
            <BackgroundRings top={-370} right={-143}/>
            <div className="learn-more-page-title">Team Coaches</div>
            <div className="learn-more-page-who-is-this-for-container">
                <div className="learn-more-page-who-is-this-for-image-container">
                    <img src={coaches_image} alt="Team Coaches"/>
                </div>
                <div className="learn-more-page-who-is-this-for-text-container">
                    <div className="learn-more-page-who-is-this-for-title">Who is this for? </div>
                    <div className="learn-more-page-who-is-this-for-medal-contaner">
                        <img src={silver_medal} alt="Silver"/>
                        <img src={gold_medal} alt="Gold"/>
                    </div>
                    <div className="learn-more-page-who-is-this-for-description">
                        Our Silver & Gold packages are only for accredited/certified Team Coaches or Team Leaders
                        trained as Team Coaches. As trained professionals, you are better
                        placed to handle additional detail & complexity, as well as manage the psychological safety
                        risks associated with attributable named survey data.
                        <br/><br/>
                        You’ll get all the benefits of the Bronze package, plus plenty additional features. These packages
                        are also designed to help build your practice (such as ROI reporting) and your self development
                        - identifying areas of focus for your CPD & Supervision.
                    </div>
                    <div className="home-page-button filled-button">
                        <Link to="/pricing-plans" className="undecorated">SEE OUR PRICING PLANS</Link>
                    </div>
                </div>
            </div>
            <div className="learn-more-page-benefits-container">
                <div className="learn-more-page-benefits-title">What are the benefits?</div>
                <div className="learn-more-page-benefits-description">
                    The <b className="bold">PERILL diagnostics for teams</b> platform supports your client team,
                    your business and your personal development in an easy to use single low cost platform.
                </div>
                <div className="learn-more-page-subcontainer">
                    <div className="learn-more-page-bullet-container">
                        <div className="learn-more-page-bullet-container-title">
                            For your Team / Client (as per Bronze):
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Awareness of the teams collective strengths and weaknesses.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Understanding of the teams systems and context.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Communication & engagement within the team.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Psychology safety within the team.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Coaching culture within the team.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Engagement with the teams stakeholders.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Communication & engagement with other areas of the business.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Measurement of impact & performance improvements over time.
                            </div>
                        </div>
                    </div>
                    <div className="learn-more-page-bullet-container">
                        <div className="learn-more-page-bullet-container-title">
                            Plus:
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Both Lite & Full Diagnostic survey options.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Attritable, named data.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                More in-depth analysis.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Configurable reports with  option to include commentary.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Unlimited teams and surveys.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Unlimited stakeholder invites.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="learn-more-page-subcontainer">
                    <div className="learn-more-page-bullet-container">
                        <div className="learn-more-page-bullet-container-title">
                            For your Coaching Practice:
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Highly cost effective.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Simple admin interface for managing teams, surveys and generating reports.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Control access / sharing of client teams with your co-coach.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Consistent approach across your engagements.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Proven, research led methodology from Professor David Clutterbuck.
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Collated statistical measurement of team improvement across all client teams (ROI).
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Feedback & recommendation ratings across all client teams.
                            </div>
                        </div>
                    </div>
                    <div className="learn-more-page-bullet-container">
                        <div className="learn-more-page-bullet-container-title">
                            For your Personal Development:
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                Feedback & recommendation ratings to identify areas for improvement (CPD).
                            </div>
                        </div>
                        <div className="learn-more-page-bullet">
                            <div className="learn-more-page-bullet-point"/>
                            <div className="learn-more-page-bullet-text">
                                ROI report (across all client teams) can be used to identify unconscious biases in your
                                interventions (eg always focusing on Purpose) - perfect for taking to Supervision.
                            </div>
                        </div>
                        <div className="home-page-button filled-button">
                            <Link to="/pricing-plans" className="undecorated">SEE OUR PRICING PLANS</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LearnMoreCoaches