import {useEffect, useState} from "react";
import server_error from "../assets/server_error.png";

const Error = () => {
    const [errorId, setErrorId] = useState();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setErrorId(params.get('id'));
    }, []);

    return (
        <>
            <div className="error-page-container">
                <div className="error-image">
                    <img src={server_error} alt="server error logo" />
                </div>
                <div className="error-title">
                    Oops - something has gone wrong.
                </div>
                <div className="error-text">
                    <div className="error-heading">
                        If this error is causing difficulties using the system,
                        please contact support and provide the following ID:
                    </div>
                    <div className="error-id-container">
                        <div className="error-id">{errorId}</div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Error