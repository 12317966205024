import avatar from "../assets/ccmi-graphic-logo-color.svg";
import {Link} from "react-router-dom";

const PasswordChanged = () => {

    return (
        <div className="logout-page-container">
            <div className="logout-container">
                <div className="logout-welcome-container password-reset">
                    <img src={avatar} alt="Logo"/>
                    <div className="logout-welcome-title">Password Reset Successful</div>
                    <div className="logout-welcome-subtitle">You may now log in with your new password</div>
                    <div className="login-privacy-container">
                        Log in again
                        <Link to="/login">Login</Link>
                    </div>
                    <div className="login-register-container">
                        Return to the home page
                        <Link to="/">Home</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordChanged