import {useEffect, useState} from "react";

const CoachRadioButton = (props) => {

    const [selected, setSelected] = useState(false)
    const [text, setText] = useState("")

    useEffect(() => {
        if (props.inverted) {
            setSelected(!props.selected)
        } else {
            setSelected(props.selected)
        }
        setText(props.text)
    }, [props]);

    const handleClick = (ev) => {
        if (!ev || (!props.active && props.active !== undefined)) return;
        props.handle(ev)
    }


    return (
        <div className="coach-radio-container">
            <div  className="coach-radio-text">{text}</div>
            <div className={`perill-resources-filter-radio-button-container ${selected ? "selected" : ""} ${!props.active && props.active !== undefined ? "hide" : ""}`} onClick={handleClick}
                 style={{visibility: props.view ? "hidden" : "unset", cursor: props.disabled ? "not-allowed" : ""}} tabIndex="0">
                <div className={`perill-resources-filter-radio ${selected ? "selected" : ""} ${!props.active && props.active !== undefined ? "hide" : ""}`}/>
            </div>
        </div>
    )
}

export default CoachRadioButton