import BackgroundRings from "../components/BackgroundRings";
import dave from "../assets/david-clutterbuck.png"
import what_we_do from "../assets/what-we-do.png"
import why_us from "../assets/why-us.png"
import ColourBar from "../components/ColourBar";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";


const AboutCCMI = () => {

    const navigate = useNavigate();

    const pricingPlans = () => {
        navigate("/pricing-plans")
    }

    useEffect(() => window.scrollTo({top: 0, left: 0, behavior: 'instant'}), []);

    return (
        <div className="learn-more-page-container unrestricted">
            <div className="background-rings-container">
                <BackgroundRings top={-370} right={-143} globe={true}/>
            </div>
            <div className="learn-more-page-title">About CCMI</div>
            <div className="learn-more-page-subtext">
                CCMI is a training organisation specialising in coaching and mentoring which operates through an
                international consortium of boutique training consultancies.
            </div>
            <div className="about-ccmi-intro-container home-contents">
                <div className="about-ccmi-intro-text-container">
                    <div className="about-ccmi-intro-text-title">
                        World Leaders in Coaching & Mentoring for 45 years.
                    </div>
                    <div className="about-ccmi-intro-text">
                        Clutterbuck Coaching and Mentoring International (CCMI) was founded by Professor David
                        Clutterbuck, pioneer of developmental mentoring, a leading authority in global coaching &
                        mentoring and author of over 75 related books. Among his many methodologies, frameworks and
                        tools is the PERILL diagnostic - for assessing Teams from a systemic and holistic perspective.
                    </div>
                    <div className="about-ccmi-intro-text">
                        For more than 45 years, David has been coaching teams, training coaches to be Team Coaches,
                        researching and writing on the subject. To support his work he has developed methodologies,
                        frameworks and tools which have evolved into the PERILL model - a diagnostic used for assessing
                        Teams from a systemic & holistic perspective.
                    </div>
                </div>
                <div className="about-ccmi-intro-image-container">
                    <img src={dave} alt="Prof. David Clutterbuck"/>
                </div>
            </div>
            <div className="about-ccmi-what-we-do-container home-contents">
                <div className="about-ccmi-what-we-do-image-container">
                    <img src={what_we_do} alt="What we do"/>
                </div>
                <div className="about-ccmi-what-we-do-text-container">
                    <div className="about-ccmi-intro-text-title">
                        What we do
                    </div>
                    <div className="about-ccmi-intro-text">
                        We help organisations across the globe achieve a strategic and practical approach to local and
                        multi-country coaching & mentoring programmes, that are both high impact and sustainable.
                    </div>
                    <div className="about-ccmi-intro-text">
                        We offer diagnostic platforms, programmes, courses, content resources, training & support for
                        business leaders, HR professionals, line managers, individuals, professional coaches, mentors
                        and mentees.
                    </div>
                    <div className="about-ccmi-intro-text">
                        Whether you are a Coach, Mentor or Trainer; Business leader, HR Professional or Line Manager;
                        CCMI can offer standard or bespoke consultancy or training packages & programmes to suit
                        specific requirements.
                    </div>
                    <div className="home-page-button filled-button" onClick={pricingPlans}>SEE OUR PRICING PLANS</div>
                </div>
            </div>
            <div className="about-ccmi-intro-container home-contents">
                <div className="home-page-background-circle" style={{top: 60, left: -400}}/>
                <div className="about-ccmi-intro-text-container">
                    <div className="about-ccmi-intro-text-title">
                        Why us?
                    </div>
                    <div className="about-ccmi-intro-text">
                        CCMI remains at the leading edge of coaching & mentoring with a network of more than 25 partner
                        organisations covering over 120 countries, providing a consistent, high quality, reliable
                        service in the specialist areas of coaching & mentoring.
                    </div>
                    <div className="about-ccmi-intro-text">
                        CCMI’s products & services incorporate the latest research, balancing that with a pragmatic
                        coaching approach that understands the requirements of business culture.
                    </div>
                    <div className="about-ccmi-intro-text">
                        All coaching & mentoring by CCMI and its network partners offers the best practice approach to
                        coaching and mentoring from around the world and are aligned to the major governing bodies
                        (EMCC & ICF) and training standards (such as the International Standards for Mentoring
                        Programmes in Employment).
                    </div>
                </div>
                <div className="about-ccmi-intro-image-container">
                    <img src={why_us} alt="Why us?"/>
                </div>
            </div>
            <div className="about-ccmi-banner-container">
                <ColourBar height={3}/>
                <div className="about-ccmi-banner">
                    <div className="about-ccmi-banner-section">
                        <div className="about-ccmi-banner-section-title">Spent</div>
                        <div className="about-ccmi-banner-section-value-container">
                            <div className="about-ccmi-banner-section-value">45</div>
                            <div className="about-ccmi-banner-section-value-subtext">YEARS</div>
                        </div>
                        <div className="about-ccmi-banner-section-subtext">at the leading edge of Coaching</div>
                    </div>
                    <div className="about-ccmi-banner-separator left-centre"/>
                    <div className="about-ccmi-banner-section">
                        <div className="about-ccmi-banner-section-title">Over</div>
                        <div className="about-ccmi-banner-section-value">500</div>
                        <div className="about-ccmi-banner-section-subtext">Coaches tutored each year</div>
                    </div>
                    <div className="about-ccmi-banner-separator right-centre"/>
                    <div className="about-ccmi-banner-section">
                        <div className="about-ccmi-banner-section-title">Over</div>
                        <div className="about-ccmi-banner-section-value-container">
                            <div className="about-ccmi-banner-section-value">500k</div>
                            <div className="about-ccmi-banner-section-value-subtext">PEOPLE</div>
                        </div>
                        <div className="about-ccmi-banner-section-subtext">trained in David’s methodologies</div>
                    </div>
                </div>
                <ColourBar height={3}/>
            </div>
            <div className="about-ccmi-have-a-question-container home-contents">
                <div className="home-page-background-circle" style={{top: 60, left: -400}}/>
                <div className="home-page-background-circle" style={{top: -350, left: 950}}/>
                <div className="about-ccmi-have-a-question-title">Have a question?</div>
                <div className="about-ccmi-have-a-question-text">See our frequently asked questions for help.</div>
                <Link to="/get-in-touch" className="link">
                    <div className="home-page-button filled-button">FAQ</div>
                </Link>

            </div>
        </div>
    )
}

export default AboutCCMI