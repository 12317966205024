import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import SurveysGridContainer from "../containers/SurveysGridContainer";
import NewSurvey from "../components/NewSurvey";
import Slider from "../components/Slider";
import surveys_api from "../api/protected/surveys";
import search from "../assets/search.png";
import add from "../assets/add.png";


const Surveys = () => {
    const navigate = useNavigate()
    const [surveys, setSurveys] = useState([]);
    const [userSurveys, setUserSurveys] = useState([]);
    const [demoSurveys, setDemoSurveys] = useState([]);
    const [showNewSurvey, setShowNewSurvey] = useState(false);
    const [showSurveysGrid, setShowSurveysGrid] = useState(true);

    const [showHidden, setShowHidden] = useState(false);
    const [searchTerm, setSearchTerm] = useState('')

    const [point, setPoint] = useState(1)
    const [correctPoint, setCorrectPoint] = useState(false);

    useEffect(() => {
        setSurveys([...demoSurveys, ...userSurveys])
    }, [userSurveys, demoSurveys])

    useEffect(() => {

    }, [])

    const handleAddSurvey = () => {
        setShowNewSurvey(true);
        setShowSurveysGrid(false);
    }

    const handleEditSurvey = (survey) => {
        if(survey.demo) {
            navigate(`/surveys/${survey.id}?demo=true`);
        } else {
            navigate(`/surveys/${survey.id}`);
        }
    }

    const getSurveys = () => {
        let response = surveys_api.getSurveys();
        response.then(response => {
            setUserSurveys(response.data.surveys);
        })
        response = surveys_api.getDemoSurveys();
        response.then(response => {
            setDemoSurveys(response.data.surveys)
        })
    }

    const updateSurveysGrid = () => {
        getSurveys();
        setShowNewSurvey(false);
        setShowSurveysGrid(true);
    }

    useEffect(() => {
        if (localStorage.getItem("new_survey") !== null) {
            setPoint(localStorage.getItem("new_survey"))
            localStorage.removeItem("new_survey");
            handleAddSurvey();
        } else {
            setCorrectPoint(true)
            getSurveys()
        }
    }, [])

    const updateToggleHidden = () => {
        setShowHidden(!showHidden);
    }

    return (
        <div className="surveys-page-background-container">
            <div className="surveys-page-container">
                {showSurveysGrid ?
                    <>
                        <div className="surveys-page-header">
                            <div className="surveys-page-title">Surveys</div>
                            <div className="surveys-page-search-container">
                                <input type="text" placeholder="Survey Search" value={searchTerm}
                                       onChange={ev => setSearchTerm(ev.target.value)}
                                       onKeyUp={ev => {
                                           if (ev.key === 'Enter') navigate("/search?term=" + searchTerm)
                                       }}
                                />
                                <img src={search} alt="search icon" className="link"/>
                            </div>
                        </div>
                        <div className="page-actions-container">
                            <div className="page-toggle-hidden">
                                <Slider update={updateToggleHidden.bind(this)} state={showHidden}/>
                            </div>
                            <div className="toggle-hidden-text">Show hidden</div>
                            <div className="surveys-page-add-container">
                                <img src={add} alt="add icon" className="link" onClick={handleAddSurvey}/>
                            </div>
                        </div>
                        <SurveysGridContainer surveys={surveys} showHidden={showHidden}
                                              handleClick={handleEditSurvey.bind(this)}
                        />
                    </> : null
                }
                {showNewSurvey ?
                    <NewSurvey updateSurveysGrid={updateSurveysGrid} passedPoint={point}
                               setPassedPoint={setPoint.bind(this)} correctPoint={correctPoint}/>
                    : null
                }
            </div>
        </div>
    )
}

export default Surveys
