import React, {useEffect, useState} from "react";
import profile_logo from "../assets/profile-user-icon.svg";
import {Link, useNavigate} from "react-router-dom";
import auth from "../api/auth";
import ColourBar from "../components/ColourBar";
import ccmi_logo from "../assets/ccmi_logo.webp";
import profile_api from "../api/protected/profile";
import notifications_api from "../api/protected/notifications"

const MainHeader = () => {
    const navigate = useNavigate();
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [profilePicture, setProfilePicture] = useState(profile_logo);
    const [session, setSession] = useState(false)

    const [companiesNotificationCount, setCompaniesNotificationCount] = useState(0)
    const [teamsNotificationCount, setTeamsNotificationCount] = useState(0)
    const [surveysNotificationCount, setSurveysNotificationCount] = useState(0)
    const [reportsNotificationCount, setReportsNotificationCount] = useState(0)
    const [generalNotificationCount, setGeneralNotificationCount] = useState(0)

    function handleLogoClick() {
        navigate("/");
    }

    function handleLogout() {
        window.location.assign("/logout")
    }

    function handleSettingsClick() {
        setShowProfileMenu(false);
        navigate("/settings")
    }

    const handleNotificationsClick = (ev) => {
        if(!ev) return;
        setShowProfileMenu(false)
        navigate("/notifications")
    }

    const handleLinkClick = (url) => {
        auth.protectedNavigate(url, "/logout")
    }

    const GetProfilePicture = () => {
        let response = profile_api.getProfilePicture();
        response.then(
            response => {
                if (response.data.image !== "") {
                    setProfilePicture(response.data.image);
                }
            }
        ).catch(err => {
            console.log(err);
        })
    }

    const GetNotificationIndicators = () => {
        let response = notifications_api.getNotificationCount();
        response.then(response => {
            setCompaniesNotificationCount(response.data.companies)
            setTeamsNotificationCount(response.data.teams)
            setSurveysNotificationCount(response.data.surveys)
            setReportsNotificationCount(response.data.reports)
            setGeneralNotificationCount(response.data.general)
        }).catch()
    }

    useEffect(() => {
        GetProfilePicture();
        GetNotificationIndicators();
    }, [])

    return (
        <div className="home-header-container bordered">
            <ColourBar/>
            <div className="home-header-contents">
                <Link to="/" className="home-header-logo-container">
                    <img src={ccmi_logo} alt="CCMI"/>
                </Link>
                <div className="home-header-navbar no-select">
                    <div className="home-header-navbar-item">
                        <div className="home-header-navbar-item-title" onClick={() => handleLinkClick("/dashboard")}>
                            Dashboard
                        </div>
                    </div>
                    <div className="home-header-navbar-item">
                        {companiesNotificationCount ? <div className="header-profile-notification-dot companies">{companiesNotificationCount}</div> : null}
                        <div className="home-header-navbar-item-title" onClick={() => handleLinkClick("/companies")}>
                            Companies
                        </div>
                    </div>
                    <div className="home-header-navbar-item">
                        {teamsNotificationCount ? <div className="header-profile-notification-dot teams">{teamsNotificationCount}</div> : null}
                        <div className="home-header-navbar-item-title" onClick={() => handleLinkClick("/teams")}>
                            Teams
                        </div>
                    </div>
                    <div className="home-header-navbar-item">
                        {surveysNotificationCount ? <div className="header-profile-notification-dot surveys">{surveysNotificationCount}</div> : null}
                        <div className="home-header-navbar-item-title" onClick={() => handleLinkClick("/surveys")}>
                            Surveys
                        </div>
                    </div>
                    <div className="home-header-navbar-item">
                        {reportsNotificationCount ? <div className="header-profile-notification-dot reports">{reportsNotificationCount}</div> : null}
                        <div className="home-header-navbar-item-title" onClick={() => handleLinkClick("/reports")}>
                            Reports
                        </div>
                    </div>
                    <div className="home-header-navbar-item header-profile-container">
                        {generalNotificationCount ? <div className="header-profile-notification-dot general">{generalNotificationCount}</div> : null}
                        <div className={profilePicture !== profile_logo ? "header-profile-image-circle" :
                            "header-profile-image-circle default" } onClick={() => setShowProfileMenu(!showProfileMenu)}>
                            <img src={profilePicture} alt="Profile" className="link"/>
                        </div>
                        {showProfileMenu ?
                            <>
                                <div className="transparent-page-mask" onClick={() => setShowProfileMenu(false)}/>
                                <div className="home-header-navbar-dropwdown-menu main-header">
                                    <div className="home-header-navbar-dropwdown-menu-triangle-outer">
                                        <div className="home-header-navbar-dropwdown-menu-triangle-inner"/>
                                    </div>
                                    <div className="home-header-navbar-dropdown-item" onClick={handleNotificationsClick}>Notifications</div>
                                    <div className="home-header-navbar-dropdown-item" onClick={handleSettingsClick}>Settings</div>
                                    <div className="home-header-navbar-dropdown-item" onClick={handleLogout}>Logout</div>
                                </div>
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MainHeader