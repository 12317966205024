import avatar from "../assets/ccmi-graphic-logo-color.svg";
import {Link} from "react-router-dom";
import {useEffect} from "react";

const LoggedOut = () => {

    useEffect(() => {
        let recovery = localStorage.getItem('recovery')
        if(recovery) {
            localStorage.removeItem('recovery')
            window.location.assign('/password-changed');
        }
    }, []);

    return (
        <div className="logout-page-container">
            <div className="logout-container">
                <div className="logout-welcome-container">
                    <img src={avatar} alt="Logo"/>
                    <div className="logout-welcome-title">Logged Out</div>
                    <div className="logout-welcome-subtitle">You have been logged out</div>
                    <div className="login-privacy-container">
                        Log in again
                        <Link to="/login">Login</Link>
                    </div>
                    <div className="login-register-container">
                        Return to the home page
                        <Link to="/">Home</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoggedOut