import {useEffect, useState} from "react";
import team_api from "../api/protected/teams";
import TeamSurveyCard from "../components/teams/TeamSurveyCard";

const TeamSurveyCardContainer = (props) => {
    const teamId = props.teamId;
    const companyId = props.companyId
    const [surveys, setSurveys] = useState([]);

    const GetSurveys = () => {
        let response;
        if(props.isDemo) {
            response = team_api.getDemoTeamSurveys(teamId)
        } else {
            response = team_api.getTeamSurveys(teamId);
        }
        response.then(
            response => {
                setSurveys(response.data.surveys);
            }
        )
    }

    useEffect(() => {
        if (!props.teamId) return;
        GetSurveys()
    }, [props.teamId]);

    return (
        <div className="survey-cards-container">
            {surveys && surveys.map((survey, idx) => {
                return (
                    <div className="col" key={idx}>
                        <TeamSurveyCard survey={survey} editor={props.editor} teamId={teamId} companyId={companyId}/>
                    </div>
                )
            })}
        </div>
    )
}

export default TeamSurveyCardContainer