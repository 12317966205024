import {useEffect, useState} from "react";


const VerifySharedPopup = (props) => {
    const created = props.created;
    const deleted = props.deleted;
    const result = props.result;

    const buttons = [{"text":"Confirm", "id": "1"}, {"text":"Cancel", "id": "2"}];

    const footerClick = (ev) => {
        if (!ev) return;
        let res = {"button": ev.target.id}
        result(res)
    }

    return(
        <>
            <div className="page-mask"/>
            <div className="verify-shared-container">
                <div className="verify-shared-header-container">
                    <div className="verify-shared-header">
                        Verify Shared Assets
                    </div>
                </div>
                {created && created.length ?
                    <>
                        {created && created.map(x => x.change).includes('shared') ?
                            <>
                                <div className="verify-shared-body-description-container">
                                    The following teams, and their associated companies and surveys, will be shared
                                    with your co-coach.
                                </div>
                                <div className="verify-shared-body-container">
                                    <div className="verify-shared-grid">
                                        {created && created.map((link, idx) => {
                                            if (link.change === "shared") {
                                                return (
                                                    <div className="verify-shared-body-row created" key={idx}>
                                                        <div className="verify-shared-body-name">{link.name}</div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </> : null
                        }
                        {created && created.map(x => x.change).includes('editor') ?
                            <>
                                <div className="verify-shared-body-description-container">
                                    The following teams, and their associated surveys, will have their editor
                                    permissions
                                    changed.
                                </div>
                                <div className="verify-shared-body-container">
                                    <div className="verify-shared-grid">
                                        {created && created.map((link, idx) => {
                                            if (link.change === "editor") {
                                                return (
                                                    <div className="verify-shared-body-row created" key={idx}>
                                                        <div className="verify-shared-body-name">{link.name}</div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </> : null
                        }
                    </>
                    : null
                }
                {deleted && deleted.length ?
                    <>
                        <div className="verify-shared-body-description-container">
                            The following teams will no longer be shared with your co-coach.
                        </div>
                        <div className="verify-shared-body-container">
                            <div className="verify-shared-grid">
                                {deleted && deleted.map((link, idx) => {
                                    return (
                                        <div className="verify-shared-body-row deleted" key={idx}>
                                            <div className="verify-shared-body-name">{link.name}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                    : null
                }
                <div className="verify-shared-footer-container">
                    {buttons && buttons.map((button, idx) => {
                        return (
                            <div className="verify-shared-footer-button link" id={button.id}
                                 onClick={footerClick} key={idx}>
                                {button.text}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default VerifySharedPopup