const TeamsGridRow = (props) => {
    const team = props.team;

    return (
        <div className={team.hidden ? "teams-grid-row hidden link" : "teams-grid-row link"} onClick={() => props.handleClick(team)}>
            <div className="teams-grid-row-company-logo"
                 style={{backgroundColor: '#' + team.colour}}></div>
            <div className="teams-grid-row-company-name">{team.company_name}</div>
            <div className="teams-grid-row-team-name">{team.name}</div>
            {team.shared ?
                <div className="teams-grid-row-team-shared">Co-Coaching</div>
                :null}
        </div>
    )
}

export default TeamsGridRow;
