import {useEffect, useState} from "react";
import Slider from "./Slider";
import del from "../assets/delete-bin.svg";
import edit from "../assets/co-coaches-pen-icon.svg"


const CoCoachCoachesGrid = (props) => {

    const [coaches, setCoaches] = useState([])

    useEffect(() => {
        setCoaches(props.coaches)
    }, [props]);

    return (
        <div className="settings-form-container co-coaches">
            <div className="settings-form-title">Your Co-Coaches</div>
            {coaches && coaches.length ?
                <div className="settings-co-coaches-grid">
                    <div className="settings-co-coaches-row header">
                        <div className="settings-co-coaches-column email">Email</div>
                        <div className="settings-co-coaches-column status">Status</div>
                        <div className="settings-co-coaches-column controls"/>
                    </div>
                    {coaches && coaches.map((coach, idx) => {
                        return (
                            <div className="settings-co-coaches-row" key={idx}>
                                <div className="settings-co-coaches-column email">{coach.email}</div>
                                <div className="settings-co-coaches-column status recolour medium">
                                    <div className={`co-coaches-indicator ${coach.status === "Active" ? "active" : "pending"}`}/>
                                    {coach.status}
                                </div>
                                <div className="settings-co-coaches-column controls">
                                    <div className="settings-co-coaches-column-button"
                                         onClick={() => props.handle(coach.id, coach.status)}>
                                        <img src={edit} alt="Edit"/>
                                    </div>
                                    <div className="settings-co-coaches-column-button"
                                         onClick={() => props.revoke(coach.id)}>
                                        <img src={del} alt="Delete"/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                :
                <div className="settings-co-coaches-no-coaches-message">You don't have any active co-coaches.</div>
            }
        </div>
    )
}

export default CoCoachCoachesGrid