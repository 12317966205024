import {useState} from "react";
import questions from "../assets/questions.svg"
import retracted from "../assets/retracted.svg"
import expanded from "../assets/expanded.svg"


const SummaryTabular = (props) => {

    const cardData = props.cardData
    const surveyData = props.surveyData
    const showQuestions = props.showQuestions
    const cardType = props.cardType

    const [attributableData, setAttributableData] = useState(false)
    const [showTeamMembers, setShowTeamMembers] = useState(true)
    const [showTeamLeaders, setShowTeamLeaders] = useState(true)
    const [showStakeholders, setShowStakeholders] = useState(true)

    const [teamMemberExpanded, setTeamMemberExpanded] = useState(false)
    const [teamLeaderExpanded, setTeamLeaderExpanded] = useState(false)
    const [stakeholderExpanded, setStakeholderExpanded] = useState(false)

    const getPillarColour = (pillar_id) => {
        switch (pillar_id) {
            case 1: return "blue"
            case 2: return "red"
            case 3: return "green"
            case 4: return "purple"
            case 5: return "pink"
            case 6: return "orange"
        }
    }

    const avgScore = (scores) => {
        if (!scores) return;

        let count = scores.length;
        let total = 0;
        for(const score of scores){
            total += score.score && score.score ? score.score : score;
        }

        if (!count || !total) return "n/a"
        return Math.round(total / count);
    }

    const getFunctionScore = (score) => {
        let _function_level
        if(score < 15) {
            _function_level = "Low"
        } else if(score < 30) {
            _function_level = "Medium"
        } else {
            _function_level = "High"
        }

        return _function_level
    }

    const getTeamMean = (theme) => {
        let scores = []
        if(showTeamMembers) {
            scores = [...scores, ...theme.team_member_scores]
        }
        if(showTeamLeaders) {
            scores = [...scores, ...theme.team_leader_scores]
        }
        if(showStakeholders) {
            scores = [...scores, ...theme.stakeholder_scores]
        }
        return avgScore(scores)
    }

    return (
        <div className="summary-tabular-container">
            <div className="summary-tabular-header-container">
                <div className="summary-tabular-title-container">
                    <div className="summary-tabular-title">Tabular</div>
                    <div className="summary-tabular-attributable-switch">
                        <div className={`chart-controls-switch ${attributableData ? "active" : "inactive"}`}
                             onClick={() => setAttributableData(!attributableData)}>
                            <div className="chart-controls-switch-centre"/>
                        </div>
                        <div className="summary-tabular-attributable-switch-text">Attributable Data</div>
                    </div>
                </div>
                <div className="summary-tabular-controls-container">
                    <div className="summary-tabular-controls-text-container">
                        <div className="summary-tabular-colour-circle green"/>
                        <div className="summary-tabular-controls-text">Team Members</div>
                    </div>
                    <div className="summary-tabular-controls-switch">
                        <div className={`chart-controls-switch ${showTeamMembers ? "active" : "inactive"}`}
                             onClick={() => setShowTeamMembers(!showTeamMembers)}>
                            <div className="chart-controls-switch-centre"/>
                        </div>
                    </div>
                    <div className="summary-tabular-controls-text-container">
                        <div className="summary-tabular-colour-circle pink"/>
                        <div className="summary-tabular-controls-text">Team Leader(s)</div>
                    </div>
                    <div className="summary-tabular-controls-switch">
                        <div className={`chart-controls-switch ${showTeamLeaders ? "active" : "inactive"}`}
                             onClick={() => setShowTeamLeaders(!showTeamLeaders)}>
                            <div className="chart-controls-switch-centre"/>
                        </div>
                    </div>
                    <div className="summary-tabular-controls-text-container">
                        <div className="summary-tabular-colour-circle blue"/>
                        <div className="summary-tabular-controls-text">Stakeholder(s)</div>
                    </div>
                    <div className="summary-tabular-controls-switch">
                        <div className={`chart-controls-switch ${showStakeholders ? "active" : "inactive"}`}
                             onClick={() => setShowStakeholders(!showStakeholders)}>
                            <div className="chart-controls-switch-centre"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="summary-tabular-top-row">
                <div className="summary-tabular-see-questions-container link"
                     onClick={() => props.seeQuestions(cardType)}>
                    {showQuestions ?
                        <>
                            <div className="summary-tabular-questions-icon-background">
                                <img src={questions} alt="View Questions"/>
                            </div>
                            <div className="summary-tabular-icon-text">See Questions</div>
                        </> : null
                    }
                </div>
                {cardData && cardData.themes.map((theme, idx) => {
                    return <div className="summary-tabular-section-column" key={idx}>{theme.name}</div>
                })}
            </div>
            <div className="summary-tabular-colour-row">
                <div className="summary-tabular-filler-row large"/>
                {cardData && cardData.themes.map((theme, idx) => {
                    return (
                        <div className="summary-tabular-section-column" key={idx}>
                            <div className={`summary-tabular-pillar-colour ${getPillarColour(theme.pillar_id)}`}/>
                        </div>
                    )
                })}
            </div>
            {showTeamMembers ?
                <>
                    <div className="summary-tabular-colour-border-container">
                        <div className="summary-tabular-short-border green"/>
                        <div className={`summary-tabular-long-border green ${teamMemberExpanded ? "active" : ""}`}/>
                    </div>
                    <div className="summary-tabular-team-member-row mean">
                        <div className="team-member-row-click-container">
                            <div className="summary-tabular-expand-circle green link"
                                 onClick={() => setTeamMemberExpanded(!teamMemberExpanded)}>
                                <img src={teamMemberExpanded ? expanded : retracted}
                                     alt={teamMemberExpanded ? "Retract" : "Expand"}
                                     className={teamMemberExpanded ? "expanded-icon" : "retracted-icon"}/>
                            </div>
                            <div className="team-member-row-name-container">Team Member Mean</div>
                        </div>
                        {cardData && cardData.themes.map((theme, idx) => {
                            return (
                                <div className="summary-tabular-section-column" key={idx}>
                                    {avgScore(theme.team_member_scores)}
                                </div>
                            )
                        })}
                    </div>
                    {teamMemberExpanded ?
                        <>
                            {cardData && cardData.themes[0].team_member_scores.map((tm, tm_idx) => {
                                return (
                                    <div className="summary-tabular-team-member-row" key={tm_idx}>
                                        <div className="summary-tabular-row-name">
                                            {attributableData ? tm.name : `Team Member ${tm_idx + 1}`}
                                        </div>
                                        {cardData && cardData.themes.map((theme, idx) => {
                                            return (
                                                <div className="summary-tabular-section-column" key={idx}>
                                                    {theme.team_member_scores[tm_idx].score === 0 ? "n/a"
                                                        : theme.team_member_scores[tm_idx].score}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </> : null
                    }
                </> : null
            }
            {showTeamLeaders ?
                <>
                    <div className="summary-tabular-colour-border-container dark">
                        <div className="summary-tabular-short-border pink"/>
                        <div className={`summary-tabular-long-border pink ${teamLeaderExpanded ? "active" : ""}`}/>
                    </div>
                    <div className="summary-tabular-team-leader-row mean">
                        <div className="team-member-row-click-container">
                            <div className="summary-tabular-expand-circle green link"
                                 onClick={() => setTeamLeaderExpanded(!teamLeaderExpanded)}>
                                <img src={teamLeaderExpanded ? expanded : retracted}
                                     alt={teamLeaderExpanded ? "Retract" : "Expand"}
                                     className={teamLeaderExpanded ? "expanded-icon" : "retracted-icon"}/>
                            </div>
                            <div className="team-member-row-name-container">Team Leader Mean</div>
                        </div>
                        {cardData && cardData.themes.map((theme, idx) => {
                            return (
                                <div className="summary-tabular-section-column" key={idx}>
                                    {avgScore(theme.team_leader_scores)}
                                </div>
                            )
                        })}
                    </div>
                    {teamLeaderExpanded ?
                        <>
                            {cardData && cardData.themes[0].team_leader_scores.map((tl, tl_idx) => {
                                return (
                                    <div className="summary-tabular-team-member-row" key={tl_idx}>
                                        <div className="summary-tabular-row-name">
                                            {attributableData ? tl.name : `Team Leader ${tl_idx + 1}`}
                                        </div>
                                        {cardData && cardData.themes.map((theme, idx) => {
                                            return (
                                                <div className="summary-tabular-section-column" key={idx}>
                                                    {theme.team_leader_scores[tl_idx].score}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </> : null
                    }
                </> : null
            }
            {showStakeholders ?
                <>
                    <div className="summary-tabular-colour-border-container">
                        <div className="summary-tabular-short-border blue"/>
                        <div className={`summary-tabular-long-border blue ${stakeholderExpanded ? "active" : ""}`}/>
                    </div>
                    <div className="summary-tabular-team-member-row mean">
                        <div className="team-member-row-click-container">
                            <div className="summary-tabular-expand-circle blue link"
                                 onClick={() => setStakeholderExpanded(!stakeholderExpanded)}>
                                <img src={stakeholderExpanded ? expanded : retracted}
                                     alt={stakeholderExpanded ? "Retract" : "Expand"}
                                     className={stakeholderExpanded ? "expanded-icon" : "retracted-icon"}/>
                            </div>
                            <div className="team-member-row-name-container">Stakeholder Mean</div>
                        </div>
                        {cardData && cardData.themes.map((theme, idx) => {
                            return (
                                <div className="summary-tabular-section-column" key={idx}>
                                    {avgScore(theme.stakeholder_scores)}
                                </div>
                            )
                        })}
                    </div>
                    {stakeholderExpanded ?
                        <>
                            {cardData && cardData.themes[0].stakeholder_scores.map((st, st_idx) => {
                                return (
                                    <div className="summary-tabular-team-member-row" key={st_idx}>
                                        <div className="summary-tabular-row-name">
                                            {attributableData ? st.name : `Stakeholder ${st_idx + 1}`}
                                        </div>
                                        {cardData && cardData.themes.map((theme, idx) => {
                                            return (
                                                <div className="summary-tabular-section-column" key={idx}>
                                                    {theme.stakeholder_scores[st_idx].score}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </> : null
                    }
                </> : null
            }
            <div className="summary-tabular-spacer-border"/>
            <div className="summary-tabular-team-row mean">
                <div className="summary-tabular-row-name">Team Mean</div>
                {cardData && cardData.themes.map((theme, idx) => {
                    return (
                        <div className="summary-tabular-section-column" key={idx}>
                            {getTeamMean(theme)}
                        </div>
                    )
                })}
            </div>
            <div className="summary-tabular-function-row mean">
                <div className="summary-tabular-row-name">Function</div>
                {cardData && cardData.themes.map((theme, idx) => {
                    return (
                        <div className="summary-tabular-section-column" key={idx}>
                            {getFunctionScore(getTeamMean(theme))}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SummaryTabular