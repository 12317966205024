import auth from "../auth";
import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")

const CreateCheckoutSession = (tier, _from="dashboard") => {
    let response = http.post(`/create_checkout/${tier}`, {"from": _from})
    response.then(
        response => {
            let url = response.data.checkout_session_url
            window.location.replace(url)
        }
    ).catch(() => {})
}

const CreatePortalSession = () => {
    let response = http.post(`/create_portal`, {"from": "/settings"})
    response.then(
        response => {
            let url = response.data.portal_session_url
            window.location.replace(url)
        }
    ).catch(() => {})
}

const CheckCheckoutOutcome = (checkout_id) => http.get(`/checkout_outcome/${checkout_id}`);

const ExecuteEnquiry = () => {
    let tier = localStorage.getItem('membershipEnquiry')
    if (!tier) return;
    localStorage.removeItem('membershipEnquiry')

    return tier
}

const GetSilver = (_from) => {
    if (auth.isAuthenticated()) {
        localStorage.setItem('membershipEnquiry', 'silver')
        window.location.assign("/dashboard");
        // CreateCheckoutSession('silver', _from)
    }
    else  {
        localStorage.setItem('membershipEnquiry', 'silver')
        window.location.assign("/register");
    }
}

const GetGold = (_from) => {
    if (auth.isAuthenticated()) {
        localStorage.setItem('membershipEnquiry', 'gold')
        window.location.assign("/dashboard");
    }
    else {
        localStorage.setItem('membershipEnquiry', 'gold')
        window.location.assign("/register");
    }
}


export default {
    GetGold,
    GetSilver,
    CreatePortalSession,
    CreateCheckoutSession,
    CheckCheckoutOutcome,
    ExecuteEnquiry
}