const CompaniesTeamsGridRow = (props) => {
    const team = props.team;

    return (
        <div className="teams-grid-row link" onClick={() => props.handleClick(team)}>
            <div className="teams-grid-row-company-logo" style={{backgroundColor: '#' + team.colour}}/>
            <div className="teams-grid-row-team-name">{team.name}</div>
            <div className="teams-grid-row-team-description">{team.description}</div>
        </div>
    )
}

export default CompaniesTeamsGridRow;
