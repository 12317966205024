import {useEffect, useState} from "react";
import cross from "../assets/co-coaches-green-cross.svg"


const CoCoachInviteForm = (props) => {

    const [submitEnabled, setSubmitEnabled] = useState(false)
    const [sentInvite, setSentInvite] = useState(false)

    useEffect(() => {
        let re = /.+@.+/gm //matches email addresses
        setSubmitEnabled(re.test(props.coachEmail))
        if (props.coachEmail === "") return;
        setSentInvite(false)
    }, [props.coachEmail])

    const handleSendInvitation = (ev) => {
        if(!ev || !submitEnabled) return;
        setSentInvite(true)
        props.setCoachEmail("")
        props.postCoCoach(ev)
    }


    return (
        <div className="settings-form-container co-coaches">
            <div className="coach-form-title-container">
                <div className="coach-form-title">Add Co-Coach</div>
                <div className="coach-form-subtitle">
                    <span className="bold">Please Note:</span> In order to use the Co-Coach functionality, your
                    Co-Coach must also be a registered user with GOLD account access. Please ensure that the email
                    address that you use to invite them to Co-Coach is the same email address that they used to
                    register their account.
                </div>
            </div>
            <div className="coach-form-headings medium">
                <div className="coach-form-heading name">Coach Name</div>
                <div className="coach-form-heading email">Coach Email</div>
            </div>
            <div className="coach-form-row">
                <div className="coach-form-email-container name">
                    <input type="text" value={props.coachName} className="coach-form-text-input"
                           onChange={ev => props.setCoachName(ev.target.value)}/>
                </div>
                <div className="coach-form-email-container email">
                    <input type="text" value={props.coachEmail} className="coach-form-text-input"
                           onChange={ev => props.setCoachEmail(ev.target.value)}/>
                </div>
                <div className="co-coach-form-submit-button" onClick={handleSendInvitation}>
                    <img src={cross} alt="Send Invitation"/>
                </div>
            </div>
        </div>
    )
}


export default CoCoachInviteForm