import pricing_plans_image from "../assets/pricing-plans-banner-image.png";
import ColourBar from "../components/ColourBar";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import logo from "../assets/ccmi_logo.webp";
import email_api from "../api/public/email";


const HomeFooter = (props) => {
    const removeButton = props.removeButton;

    const [showEmailInput, setShowEmailInput] = useState(false)
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")

    const handleSubscribe = () => {
        setShowEmailInput(true)
        setEmail("")
        setError("")
    }

    const handleSubmitEmail = () => {
        let body = {
            "email": email
        }
        let response = email_api.subscribe(body);
        response.then(
            response => {
                setEmail("")
                setError("")
                setShowEmailInput(false)
            }
        ).catch(err => {
            setError("Please ensure you have entered a valid email")
        })
    }

    return (
        <div className="home-footer-container">
            <div className="home-page-pricing-plans-banner-container" style={props.hide_banner ? {display: "none"} : null}>
                <div className="home-page-pricing-plans-banner">
                    <div className="home-page-pricing-plans-banner-quote">”Teams are the engine of</div>
                    <div className="home-page-pricing-plans-banner-quote tabbed">performance and productivity.”</div>
                    <div className="home-page-pricing-plans-banner-author">Professor David Clutterbuck</div>
                    {removeButton ?
                        null
                    :
                        <div className="home-page-button pricing-plans">
                            <Link to="/pricing-plans" className="undecorated">SEE OUR PRICING PLANS</Link>
                        </div>
                    }
                    <div className="home-page-pricing-plans-image">
                        <img src={pricing_plans_image} alt="Teams"/>
                    </div>
                </div>
            </div>
            <div className="home-footer-links-container">
                <div className="footer-text-container">
                    <div className="footer-subscribe-title">Subscribe to our newsletter</div>
                    <div className="footer-subscribe-text">
                        Subscribe today to receive the latest news, blogs and thought leadership on Coaching,
                        Mentoring and Leadership from Professor David Clutterbuck and the CCMI Network. Delivered
                        directly to you via email every few months.
                    </div>
                    <div className="footer-subscribe-button" onClick={() => handleSubscribe()}>SUBSCRIBE</div>
                    <div className="footer-subscribe-text-disclaimer">
                        We respect your data and privacy.
                        You can unsubscribe at any time.
                        See our <span><Link className="link" to="/privacy">Privacy Policy</Link></span> for details
                    </div>
                </div>
                <div className="footer-nav-container home">
                    <div className="footer-logo">
                        <img src={logo} alt="Logo"/>
                    </div>
                    <div className="footer-links">
                        <Link to="/privacy" >Privacy Policy</Link>
                        <Link to="/faq">FAQ's</Link>
                    </div>
                </div>
            </div>
            <ColourBar height={16}/>
            {showEmailInput ?
                <div className="email-subscribe-input-container">
                    <div className="email-subscribe-description">
                        Please enter your email to subscribe to our newsletter
                    </div>
                    <input type="email" className="email-subscribe-email-entry" placeholder={"Enter email"}
                           onChange={(ev) => setEmail(ev.target.value)}/>
                    <div className="email-subscribe-description">
                        {error && error}
                    </div>
                    <div className="footer-subscribe-button no-border" onClick={() => handleSubmitEmail()}>
                        SUBSCRIBE
                    </div>
                </div>
                : null
            }

        </div>
    )
}

export default HomeFooter