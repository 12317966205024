import red_cross from "../assets/red_cross.png"
import {useState} from "react";


const TeamPersonRow = (props) => {

    const handleEnterContext = props.handleEnterContext
    const updatePerson = props.updatePerson
    const deletePerson = props.deletePerson
    const roles = props.roles
    const idx = props.idx
    const person = props.person
    const editor = props.editor
    const people = props.people

    const [showEmailWarning, setShowEmailWarning] = useState(false)

    const checkEmailDuplicate = (ev, idx) => {
        if (!ev || !idx || !(people && people.length)) return;
        let email = ev.target.value;
        if (people.map(x => x.email).includes(email) && people.map(x => x.email).indexOf(email) !== idx) {
            setShowEmailWarning(true);
        } else {
            setShowEmailWarning(false);
        }
    }

    return (
        <>
            <div className="person-form-column-container" onKeyDown={ev => handleEnterContext(ev, idx)}>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Name</div>
                        <input className="person-name-input" type="text" id={`person-name-input${idx}`}
                               value={person.name} disabled={!editor}
                               onChange={ev => updatePerson(ev, idx, "name")}
                        />
                    </div>
                </div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Role</div>
                        <select className="person-role-input" id={`person-role-input${idx}`}
                                value={person.role} disabled={!editor}
                                onChange={ev => updatePerson(ev, idx, "role")}
                        >
                            {roles && roles.map((role, idx) => {
                                return <option value={role.id} key={idx}>{role.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Email</div>
                        <input className="person-email-input" type="text" id={`person-email-input${idx}`}
                               value={person.email} disabled={!editor}
                               onChange={ev => {
                                   updatePerson(ev, idx, "email");
                                   checkEmailDuplicate(ev, idx);
                               }}
                        />
                    </div>
                </div>
                {editor ?
                    <div className="people-grid-row-person-delete person-row" onClick={ev => deletePerson(ev, idx)}>
                        <img src={red_cross} alt="Delete"/>
                    </div>
                    :
                    null
                }
            </div>
            {showEmailWarning ? <div className="messages">This email is already in use.</div> : null}
        </>
    )
}

export default TeamPersonRow