import ccmi_logo from "../assets/ccmi_logo.webp"
import open from "../assets/open-dropdown.svg"
import close from "../assets/close-dropdown.svg"
import ColourBar from "../components/ColourBar";
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import auth from "../api/auth";


const HomeHeader = () => {

    const navigate = useNavigate();
    const [perillMenuOpen, setPerillMenuOpen] = useState(false)
    const [ccmiMenuOpen, setCcmiMenuOpen] = useState(false)

    const togglePerillMenu = (ev) => {
        if (!ev) return;
        setCcmiMenuOpen(false)
        setPerillMenuOpen(!perillMenuOpen)
    }
    const toggleCCMIMenu = (ev) => {
        if(!ev) return;
        setPerillMenuOpen(false)
        setCcmiMenuOpen(!ccmiMenuOpen)
    }

    const handleItemClick =(ev) => {
        if (!ev.target.id) return;
        switch (ev.target.id) {
            case "PERILL":
                navigate("/what-is-the-perill-model")
                break;
            case "HIW":
                navigate("/perill-how-it-works")
                break;
            case "Resources":
                navigate("/perill-resources")
                break;
            case "About":
                navigate("/about-ccmi")
                break;
            case "Training":
                navigate("/team-coaching")
                break;
            case "Books":
                break;
        }
        setPerillMenuOpen(false);
    }

    return (
        <div className="home-header-container">
            <ColourBar/>
            <div className="home-header-contents">
                <Link to="/" className="home-header-logo-container">
                    <img src={ccmi_logo} alt="CCMI"/>
                </Link>
                <div className="home-header-navbar no-select">
                    <div className="home-header-navbar-item">
                        <div className="home-header-navbar-item-title" onClick={togglePerillMenu}>About PERILL</div>
                        <div className="home-header-navbar-dropdown-image-container" onClick={togglePerillMenu}>
                            <img src={perillMenuOpen ? close : open} alt={perillMenuOpen ? "Close" : "Open"}/>
                        </div>
                        {perillMenuOpen ?
                            <div className="home-header-navbar-dropwdown-menu" onClick={togglePerillMenu}>
                                <div className="home-header-navbar-dropwdown-menu-triangle-outer">
                                    <div className="home-header-navbar-dropwdown-menu-triangle-inner"/>
                                </div>
                                <div className="home-header-navbar-dropdown-item" id={"PERILL"} onClick={handleItemClick}>
                                    What is PERILL
                                </div>
                                <div className="home-header-navbar-dropdown-item" id={"HIW"} onClick={handleItemClick}>
                                    How it works
                                </div>
                                <div className="home-header-navbar-dropdown-item" id={"Resources"} onClick={handleItemClick}>
                                    PERILL Resources
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    <div className="home-header-navbar-item">
                        <div className="home-header-navbar-item-title" onClick={toggleCCMIMenu}>About CCMI</div>
                        <div className="home-header-navbar-dropdown-image-container" onClick={toggleCCMIMenu}>
                            <img src={ccmiMenuOpen ? close : open} alt={ccmiMenuOpen ? "Close" : "Open"}/>
                        </div>
                        {ccmiMenuOpen ?
                            <div className="home-header-navbar-dropwdown-menu" onClick={toggleCCMIMenu}>
                                <div className="home-header-navbar-dropwdown-menu-triangle-outer">
                                    <div className="home-header-navbar-dropwdown-menu-triangle-inner"/>
                                </div>
                                <div className="home-header-navbar-dropdown-item" id={"About"} onClick={handleItemClick}>
                                    About Us
                                </div>
                                <div className="home-header-navbar-dropdown-item" id={"Training"} onClick={handleItemClick}>
                                    Team Coach Training
                                </div>
                                <div className="home-header-navbar-dropdown-item" id={"Books"} onClick={handleItemClick}>
                                    <div className="undecorated">Team Coaching Books</div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    <div className="home-header-navbar-item">
                        <Link to="/pricing-plans" className="home-header-navbar-item-title undecorated">Pricing</Link>
                    </div>
                    <div className="home-header-navbar-item undecorated" onClick={() => auth.protectedNavigate("/dashboard", "/register")}>
                        <div className="home-header-navbar-item-title">Sign Up</div>
                    </div>
                    <div className="home-header-navbar-item undecorated" onClick={() => auth.protectedNavigate("/dashboard", "/login")}>
                        <div className="home-header-navbar-item-title">My Account</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeHeader