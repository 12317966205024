import LegalClause from "../components/LegalClause";
import LegalClauseHeader from "../components/LegalClauseHeader";
import {useEffect} from "react";


const PrivacyPolicy = () => {

    useEffect(() => window.scrollTo({top: 0, left: 0, behavior: 'instant'}), []);

    return (
        <div className="privacy-policy-container home-contents">
            <div className="privacy-policy-title">Privacy Policy</div>
            <div className="privacy-policy-heading">PERILL Diagnostics for Teams APP – Privacy Supplement</div>
            <div className="privacy-policy-description">
                This privacy supplement gives some general information around the processing conducted for our PERILL
                Diagnostics for Teams App by Clutterbuck Coaching and Mentoring Ltd (CCCMI) and to provide further
                context to our main privacy policy. If you’re using our app together with another service such as the
                CCMI website, you should read the privacy information for the other service too.
            </div>
            <div className="privacy-policy-description">
                CCMI / the PERILL Diagnostics for Teams App treats personal data provided to us with respect and
                integrity. This Privacy Supplement Policy explains how we handle personal information that we collect
                in the course of performing our functions and activities within the PERILL Diagnostics for Teams App.
            </div>
            <LegalClauseHeader text="1. Data Collection & Processing:">
                <LegalClause text="We collect and handle different types of information from or through our PERILL
                Diagnostics for Teams App (Services):"/>
                <LegalClause text="Information Collected from our Account Holders: Where an individual user
                registers their details for an Account (usually a Team Coach, Team Leader, HR professional or similar),
                we collect Personal Data. Examples of Personal Data include name, email address, and job description as
                well as other data that when linked with other data may be personally identifiable information, such as
                industry, geographic area or language preferences."/>
                <LegalClause text="In this context, CCMI is considered the data controller as defined by the terms of
                the European Union’s Directive 95/46/EC on data privacy or the General Data Protection Regulation
                (GDPR) (EU) 2016/679."/>
                <LegalClause text="Information Provided by our Account Holders: Where an Account Holder (usually a Team
                Coach, Team Leader, HR professional or similar) registers the details of their team coach clients, team
                members, stakeholders or company employees (Survey Participants), in order to issue out and manage
                results of the diagnostic survey, the Account Holder is collecting Personal Data. Examples of Personal
                Data include name, email address, and job description as well as other data that when linked with other
                data may be personally identifiable information, such as industry, geographic area
                or language preferences."/>
                <LegalClause text="In this context, the Account Holder is considered the data controller under the
                Directive for any data containing personal information provided by the Account Holder, whilst CCMI is
                the data processor. The Account Holder has full responsibility for collecting, and managing the Personal
                 Data of all Survey Participants within the PERILL Diagnostics for Teams App. Each Account Holder is
                 responsible for confirming they have permission to add Survey Participants details and for providing
                 notice to them concerning the purpose of doing so prior to adding any Survey Participants Personal
                 Data. CCMI has no direct relationship with the individuals (Survey Participants) whose Personal Data
                 it hosts and processes on behalf of the Account Holder."/>
                <LegalClause text="User-provided Information: When you use the Service, register your details (such as
                subscribing for our newsletter or enquiries about training) or send us enquiries, either as an Account
                Holder or a User you may provide, and we may collect Personal Data. Examples of Personal Data include
                name, email address."/>
                <LegalClause text='"Automatically Collected" Information: When an Account Holder or User uses the
                Service, we may automatically record certain information from their device by using various types of
                technology, including cookies. This "automatically collected" information may include IP address or
                other device address or ID, web browser and/or device type, the web pages or sites visited just before
                or just after using the Service, the pages or other content the User views or interacts with on the
                Service, and the dates and times of the visit, access, or use of the Service. We also may use these
                technologies to collect information regarding an interaction with email messages, such as whether the
                User opens, clicks on, or forwards a message. This information is gathered from all Survey Participants.'/>
            </LegalClauseHeader>
            <LegalClauseHeader text="2. Use and Disclosure:">
                <LegalClause text="We will match certain personal information about you with your diagnostic survey
                responses in order for us to provide Account Holders and Survey Participants diagnostic reports to
                improve the effectiveness of teams – one for the team members and a more detailed version for the
                Account Holder (usually a trained Team Coach). To create a psychology safe environment for open and
                honest discussion, individual names will be withheld on any team member reports and are only made
                available to Account Holders who are trained Team Coaches in the PERILL Diagnostic. We may also analyse
                Survey Participants diagnostic survey responses for research and/or commercial purposes and reserve the
                right to publish aggregated findings."/>
                <LegalClause text="Where Account Holders consent, we will occasionally send you marketing messages.
                As CCMI is not the data controller for Survey Participants personal data, we cannot and will not access
                or use this data for marketing our Services."/>
                <LegalClause text="We do not under any circumstances provide, rent, sell or exchange any personal
                information to any third parties without your consent other than where you would reasonably expect us
                to disclose it or if we are legally required to do so. Personal information in connection with our
                Services will only be made available to contractually bound third party subcontractors to facilitate
                the provision of Services by storing or processing such data on behalf of CCMI."/>
            </LegalClauseHeader>
            <LegalClauseHeader text="3. Cookies and Tracking Technologies">
                <LegalClause text="We use automatically collected information and other information collected during
                the Services through cookies and similar technologies to: (i) personalise our Service, such as
                remembering a User’s information or login details so that the User will not have to re-enter it during
                a visit or on subsequent visits; (ii) provide customised content and information; (iii) monitor and
                analyse the effectiveness of Service and third-party activities; and (iv) monitor site usage metrics
                such as the number of visits and pages viewed."/>
            </LegalClauseHeader>
            <LegalClauseHeader text="4. Access, Correction and Deletion">
                <LegalClause text="We are committed to safeguarding the privacy of our Account Holders and Survey
                Participants of the PERILL Diagnostics for Teams App:"/>
                <LegalClause text="Account Holders - If you wish to access or amend any personal information we hold
                about you, please sign into your Online Account to do so. To request that we delete any information
                about you that we have obtained through the use of our Services, you may contact us at
                diagnostics@clutterbuck-cmi.com. Unless personal information is required to be retained by us for
                administrative or legal reasons, we will meet such requests at the earliest possible opportunity."/>
                <LegalClause text="Survey Participants - If you wish to access or amend any personal information we
                hold about you, please contact the Account Holder (as named in your initial survey invite email) as
                CCMI is only the data processor of your data and therefore not legally able to edit or delete it. Once
                the Account Holder has deleted your details, CCMI will complete the deletion at the earliest possible
                opportunity unless the personal information is required to be retained by us for administrative or
                legal reasons."/>
            </LegalClauseHeader>
            <LegalClauseHeader text="5. International transfers of your personal data">
                <LegalClause text="The hosting facilities for our Services are situated in Dublin, Ireland. The European
                 Commission has made an “adequacy decision” with respect to the data protection laws of each of these
                 countries. Transfers to each of these countries will be protected by appropriate safeguards, namely the
                  use of standard data protection clauses adopted or approved by the European Commission, a copy of
                  which you can obtain from Guide to the UK General Data Protection Regulation (UK GDPR)."/>
            </LegalClauseHeader>
            <LegalClauseHeader text="6. Data Retention">
                <LegalClause text="We only retain the personal information for as long as necessary to fulfil the
                purposes for which we have initially collected it, unless otherwise required by law."/>
                <LegalClause text="For Account Holders, we will retain your account information for one year after your
                account becomes inactive (no active subscription, login or survey issuance activity),– this includes
                your personal information as well as any other data in your account including any clients, teams and
                Survey Participants personal information you have uploaded and any subsequent diagnostic surveys or
                reports. Once we have deleted your account, these details cannot be retrieved."/>
                <LegalClause text="For Survey Participants, we will retain your account information using the criteria
                below:"/>
                <LegalClause text="If you as an individual is deleted from the team or company (immediate deletion and
                no retention of your Personal Information)."/>
                <LegalClause text="If the team or the company you are associated with is deleted (immediate deletion
                and no retention of your Personal Information)."/>
                <LegalClause text="If the Account Holders account becomes inactive for 12 months (no active
                subscription, login or survey issuance activity), we will delete the account including all Personal
                Information of the Account Holder, Survey Participants, companies and teams held within it (immediate
                deletion and no retention of your Personal Information)."/>
                <LegalClause text="Please Note: Once we have deleted your Account or Personal Information, these details
                 cannot be retrieved."/>
                <LegalClause text="CCMI will continue to retain and use the now fully anonymised diagnostic survey
                responses in order to improve our survey diagnostics."/>
            </LegalClauseHeader>
        </div>
    )
}

export default PrivacyPolicy