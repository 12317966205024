import {useEffect, useState} from "react";

const ROITitleCard = (props) => {

    const [teams, setTeams] = useState([])
    const [companies, setCompanies] = useState([])
    const [data, setData] = useState(null)

    useEffect(() => {
        setTeams(props.teams)
    }, [props.teams]);

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        if(!teams || !teams.length) return;
        let c = []
        for(const team of teams) {
            if(!(c.map(x => x.name).includes(team.company_name))) {
                c.push({
                    "name": team.company_name,
                    "colour": team.company_colour
                })
            }
        }
        setCompanies(c)
    }, [teams])

    const getImprovement = () => {
        if(!data) return 0
        if(data.init_avg === 0) return 0
        return ((data.endpoint_avg - data.init_avg) / data.init_avg) * 100
    }

    const getMarkerPosition = (x) => {
        if(!data) return 0
        switch (x) {
            case "init": return Math.round(2 * data.init_avg)
            case "end": return Math.round(2 * data.endpoint_avg)
            default: return 0
        }
    }

    return (
        <div className="roi-report-title-card-container">
            <div className="roi-report-title-card-details-container">
                <div className="roi-report-title-card-heading">Overview</div>
                <div className="roi-report-title-card-subheading">Companies:</div>
                <div className="roi-report-title-card-companies-container">
                    {companies.map((company, idx) => {
                        return (
                            <div className="roi-report-title-card-company bold" key={idx}>
                                <div className="roi-report-title-card-company-colour" style={{backgroundColor: company.colour}}/>
                                <div className="roi-report-title-card-company-name">{company.name}</div>
                            </div>
                        )
                    })}
                </div>
                <div className="roi-report-title-card-subheading">
                    Total Teams: <span className="roi-report-title-card-detail-value">{teams.length}</span>
                </div>
            </div>
            <div className="roi-report-title-card-improvement-container">
                <div className="roi-report-title-card-heading no-decoration">Teams Overall Performance</div>
                <div className="roi-report-title-card-improvement-body-container">
                    <div className="roi-report-title-card-improvement-left-half">
                        <div className="roi-report-title-card-improvement-bar-container title-card">
                            <div className="roi-title-card-graphic-text">START OF ENGAGEMENT</div>
                            <div className="roi-coach-performance-body-graphic title-card">
                                <div className="roi-coach-performance-bar">
                                    <div className="roi-coach-peformance-bar-graphic">
                                        <div className="roi-coach-performance-bar-white-band">
                                            <div className={`roi-coach-performance-bar-point title-card ${data && data.init_avg < 25 ? "low" : ""}`}
                                                 style={{left: `calc(${getMarkerPosition('init')}% - 17.5px)`}}>
                                                {data && Math.round(data.init_avg)}
                                            </div>
                                            <div className="roi-coach-performance-bar-colour zero"/>
                                            <div className="roi-coach-performance-bar-spacer"/>
                                            <div className="roi-coach-performance-bar-colour ten"/>
                                            <div className="roi-coach-performance-bar-spacer"/>
                                            <div className="roi-coach-performance-bar-colour twenty"/>
                                            <div className="roi-coach-performance-bar-spacer"/>
                                            <div className="roi-coach-performance-bar-colour thirty"/>
                                            <div className="roi-coach-performance-bar-spacer"/>
                                            <div className="roi-coach-performance-bar-colour forty"/>
                                        </div>
                                    </div>
                                    <div className="roi-coach-performance-bar-text-container bold title-card">
                                        <div className="roi-coach-performance-bar-text">0</div>
                                        <div className="roi-coach-performance-bar-text">10</div>
                                        <div className="roi-coach-performance-bar-text">20</div>
                                        <div className="roi-coach-performance-bar-text">30</div>
                                        <div className="roi-coach-performance-bar-text">40</div>
                                        <div className="roi-coach-performance-bar-text">50</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="roi-title-card-vs-container">VS</div>
                        <div className="roi-report-title-card-improvement-bar-container title-card">
                            <div className="roi-title-card-graphic-text">END OF ENGAGEMENT</div>
                            <div className="roi-coach-performance-body-graphic title-card">
                                <div className="roi-coach-performance-bar">
                                    <div className="roi-coach-peformance-bar-graphic">
                                        <div className="roi-coach-performance-bar-white-band">
                                            <div className={`roi-coach-performance-bar-point title-card ${data && data.endpoint_avg < 25 ? "low" : ""}`}
                                                 style={{left: `calc(${getMarkerPosition('end')}% - 17.5px)`}}>
                                                {data && Math.round(data.endpoint_avg)}
                                            </div>
                                            <div className="roi-coach-performance-bar-colour zero"/>
                                            <div className="roi-coach-performance-bar-spacer"/>
                                            <div className="roi-coach-performance-bar-colour ten"/>
                                            <div className="roi-coach-performance-bar-spacer"/>
                                            <div className="roi-coach-performance-bar-colour twenty"/>
                                            <div className="roi-coach-performance-bar-spacer"/>
                                            <div className="roi-coach-performance-bar-colour thirty"/>
                                            <div className="roi-coach-performance-bar-spacer"/>
                                            <div className="roi-coach-performance-bar-colour forty"/>
                                        </div>
                                    </div>
                                    <div className="roi-coach-performance-bar-text-container bold title-card">
                                        <div className="roi-coach-performance-bar-text">0</div>
                                        <div className="roi-coach-performance-bar-text">10</div>
                                        <div className="roi-coach-performance-bar-text">20</div>
                                        <div className="roi-coach-performance-bar-text">30</div>
                                        <div className="roi-coach-performance-bar-text">40</div>
                                        <div className="roi-coach-performance-bar-text">50</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="roi-report-title-card-improvement-right-half">
                        <div className="roi-title-card-graphic-text">PERFORMANCE % CHANGE</div>
                        {getImprovement() > 0 ? <div className="roi-report-title-card-tick-box">✓</div> : null}
                        <div className="roi-report-title-card-improvement-text">
                            {Math.round(getImprovement())}<span>%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ROITitleCard