import {useSearchParams} from "react-router-dom";
import {useState} from "react";
import q_api from "../api/public/questionnaire";

import QuestionnaireAccess from "../components/questionnare/QuestionnaireAccess";
import QuestionnaireEntry from "../components/questionnare/QuestionnaireEntry";
import QuestionnaireResponses from "../components/questionnare/QuestionnaireResponses";
import QuestionnaireComplete from "../components/questionnare/QuestionnaireComplete";

const Questionnaire = () => {
    const [params] = useSearchParams()
    const uuid = params.get("v");
    const [validAccessKey, setValidAccessKey] = useState(false);
    const [accessKey, setAccessKey] = useState();
    const [questions, setQuestions] = useState();
    const [pillarName, setPillarName] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [showEntryPage, setShowEntryPage] = useState(false);
    const [showResponsesPage, setShowResponsesPage] = useState(false);
    const [showCompletedPage, setShowCompletedPage] = useState(false);
    const [surveyType, setSurveyType] = useState(1);
    const [surveyPoint, setSurveyPoint] = useState(0);
    const [coachSubscription, setCoachSubscription] = useState(0)

    const updateKey = (key) => {
        setAccessKey(key);
        setValidAccessKey(true);
        setShowEntryPage(true);
    }

    const updateQuestions = (questions) => {
        setQuestions(questions)
    }

    const updatePillarName = (name) => {
        setPillarName(name);
    }

    const updateEntry = () => {
        setShowEntryPage(false);
        setShowResponsesPage(true);
    }

    const handleNextPage = (responses) => {
        if (currentPage > 6) return;
        let body = {
            "responses": responses
        }
        let response = q_api.updateResponses(uuid, accessKey, currentPage, body);
        response.then(() => {
            if (currentPage === 6) {
                setShowResponsesPage(false);
                setShowCompletedPage(true);
            } else {
                const next = currentPage + 1;
                setCurrentPage(next);
                let r = q_api.getQuestions(uuid, accessKey, next);
                r.then(r => {
                    updateQuestions(r.data.responses);
                    setPillarName(r.data.pillar.name);
                    window.scrollTo(0, 0);
                }).catch(err => {
                    console.log(err);
                })
            }
        }).catch(err => console.log(err))
    }

    const handleSaveProgress = (responses) => {
        if (!responses) return;
        let body = {
            "responses": responses
        }
        let response = q_api.updateResponses(uuid, accessKey, currentPage, body);
        response.then(() => {

        }).catch(err => console.log(err))
    }

    const handlePrevPage = (responses) => {
        if (currentPage === 1) return;

        let body = {
            "responses": responses
        }
        let response = q_api.updateResponses(uuid, accessKey, currentPage, body);
        response.then(() => {
            const next = currentPage - 1;
            setCurrentPage(next);
            let r = q_api.getQuestions(uuid, accessKey, next);
            r.then(r => {
                updateQuestions(r.data.responses);
                setPillarName(r.data.pillar.name);
                window.scrollTo(0, 0)
            }).catch(err => console.log(err))
        }).catch(err => console.log(err))
    }

    return (
        !validAccessKey ?
            <QuestionnaireAccess uuid={uuid} updateKey={updateKey.bind(this)}
                                 updateQuestions={updateQuestions.bind(this)}
                                 updatePillarName={updatePillarName.bind(this)}
                                 updateSurveyType={setSurveyType.bind(this)}
                                 updateSurveyPoint={setSurveyPoint.bind(this)}
                                 updateCoachSubscription={setCoachSubscription.bind(this)}
            />
            :
            <>
                {showEntryPage ?
                    <QuestionnaireEntry updateEntry={updateEntry.bind(this)} surveyType={surveyType}/>
                    : null
                }
                {showResponsesPage ?
                    <QuestionnaireResponses questions={questions} handleNext={handleNextPage.bind(this)}
                                            handlePrev={handlePrevPage.bind(this)}
                                            handleSave={handleSaveProgress.bind(this)}
                                            page={currentPage} pillarName={pillarName}
                    />
                    : null
                }
                {showCompletedPage ?
                    <QuestionnaireComplete surveyPoint={surveyPoint} uuid={uuid}
                                           accessKey={accessKey} coachSubscription={coachSubscription}/>
                    : null
                }
            </>
    )
}

export default Questionnaire