import axios from "axios";

const kratosAxiosInstance = (url_extension) => {

    // API LOCATION
    const baseUrl = process.env.REACT_APP_API_URL + url_extension;

    let api = axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    api.interceptors.response.use(function (response)  {
        return response;
    }, function (error) {
        return Promise.reject(error);
    })

    return api
}

export default kratosAxiosInstance