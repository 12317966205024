import {useEffect, useState} from "react";
import edit from "../../assets/edit-pencil.svg";
import del from "../../assets/delete-bin.svg";
import rating from "../../assets/rating-icon.svg";
import recommended from "../../assets/recommended-icon.svg";
import survey from "../../assets/survey.png"
import PercentageWheel from "../PercentageWheel";
import dashboard_api from "../../api/protected/dashboard"
import SelectionPopup from "./SelectionPopup";
import ROIReportSelector from "../ROIReportSelector";
import {useNavigate} from "react-router-dom";


const CoachROI = (props) => {

    const navigate = useNavigate()
    const [showEditMenu, setShowEditMenu] = useState(true);
    const [allCompanies, setAllCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState(0)
    const [isDemo, setIsDemo] = useState(false)

    const [companyName, setCompanyName] = useState("")
    const [colour, setColour] = useState("")
    const [improvement, setImprovement] = useState(0)
    const [ratingScore, setRatingScore] = useState(0)
    const [recommendedScore, setRecommendedScore] = useState(0)

    const [showReportConfig, setShowReportConfig] = useState(false)

    useEffect(() => {
        setAllCompanies(props.companies)
    }, [props.companies]);

    useEffect(() => {
        if(!props.context) return;
        setSelectedCompany(props.context)
        setShowEditMenu(false);
    }, [props.context])

    useEffect(() => {
        if(!selectedCompany) return;
        getCoachROI(selectedCompany)
        setShowEditMenu(false)
    }, [selectedCompany]);

    useEffect(() => {
        setIsDemo(props.demo)
    }, [props.demo])

    const getCoachROI = (id) => {
        let response;
        console.log(isDemo)
        if(isDemo) {
            response = dashboard_api.getDemoCoachROI(id)
        } else {
            response = dashboard_api.getCoachROI(id)
        }
        response.then(response => {
            setCompanyName(response.data.company_name)
            setColour(response.data.company_colour)
            setImprovement(response.data.improvement_percentage)
            setRatingScore(response.data.coach_rating)
            setRecommendedScore(response.data.coach_recommendation)
        }).catch(() => {})
    }

    const updateSelectedCompany = (company) => {
        if(!company) return;
        setIsDemo(company.demo)
        let response = dashboard_api.updateComponent(props.id, company.id, company.demo);
        response.then(() => {
            setSelectedCompany(company.id)
        })
    }

    const handleInfoClick = () => {
        //Do something
        return;
    }

    const openEditMenu = (ev) => {
        if(!ev) return;
        setShowEditMenu(true)
    }
    const closeEditMenu = (ev) => {
        if(!ev) return;
        setShowEditMenu(false);
    }

    const handleGenerateReportClick = (ev) => {
        if(!ev) return;
        navigate('/roireport')
    }

    const closeReportConfig = (ev) => {
        if(!ev || ev.target.id !== "mask") return;
        setShowReportConfig(false)
    }

    return (
        <>
            <div className="dashboard-component">
                <div className="company-feedback-component-container">
                    <div className="company-feedback-component-column">
                        <div className="company-feedback-component-title">{companyName}</div>
                        <div className="company-feedback-component-subtext">COACH FEEDBACK</div>
                        <div className="company-feedback-component-rating-container">
                            <div className="company-feedback-component-rating-container-image">
                                <img src={rating} alt="Personal Rating"/>
                            </div>
                            <div className="company-feedback-component-rating-container-content">
                                <div className="company-feedback-component-rating-container-title">Personal Rating</div>
                                <div className="company-feedback-component-rating-container-table">
                                    <div className="company-feedback-component-rating-container-table-row">
                                        <div className={`component-rating ${ratingScore >= 1 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${ratingScore >= 2 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${ratingScore >= 3 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${ratingScore >= 4 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${ratingScore >= 5 ? "filled" : "empty"}`}/>
                                    </div>
                                    <div className="company-feedback-component-rating-container-table-row">
                                        <div className={`component-rating ${ratingScore >= 6 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${ratingScore >= 7 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${ratingScore >= 8 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${ratingScore >= 9 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${ratingScore >= 10 ? "filled" : "empty"}`}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="company-feedback-component-rating-container">
                            <div className="company-feedback-component-rating-container-image">
                                <img src={recommended} alt="Recommended"/>
                            </div>
                            <div className="company-feedback-component-rating-container-content">
                                <div className="company-feedback-component-rating-container-title">Recommended</div>
                                <div className="company-feedback-component-rating-container-table">
                                    <div className="company-feedback-component-rating-container-table-row">
                                        <div className={`component-rating ${recommendedScore >= 1 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${recommendedScore >= 2 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${recommendedScore >= 3 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${recommendedScore >= 4 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${recommendedScore >= 5 ? "filled" : "empty"}`}/>
                                    </div>
                                    <div className="company-feedback-component-rating-container-table-row">
                                        <div className={`component-rating ${recommendedScore >= 6 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${recommendedScore >= 7 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${recommendedScore >= 8 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${recommendedScore >= 9 ? "filled" : "empty"}`}/>
                                        <div className={`component-rating ${recommendedScore >= 10 ? "filled" : "empty"}`}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="company-feedback-component-column aligned">
                        <div className="company-feedback-component-title">Coach ROI Stats</div>
                        <div className="company-feedback-component-subtext">TEAM PERFORMANCE</div>
                        <PercentageWheel handleInfoClick={handleInfoClick.bind(this)}
                                         colour={colour}
                                         completion={improvement}
                                         text="IMPROVEMENT"
                                         customClass=""
                        />
                    </div>
                </div>
                <div className="dashboard-component-button-container company-feedback">
                    <div className="dashboard-component-button" onClick={openEditMenu}>
                        <img src={edit} alt="Edit Component"/>
                    </div>
                    <div className="dashboard-component-button" onClick={() => props.manage(props.id)}>
                        <img src={del} alt="Delete Component"/>
                    </div>
                </div>
                <div className="roi-report-generate-button">
                    <div className="dashboard-component-button" onClick={handleGenerateReportClick}>
                        <img src={survey} alt="Get ROI Report"/>
                    </div>
                </div>
            </div>
            {showEditMenu ?
                <SelectionPopup id={props.id}
                                title="Coach ROI"
                                selection_level={0}
                                companies={allCompanies}
                                selected={selectedCompany}
                                updateSelected={updateSelectedCompany.bind(this)}
                                closePopup={closeEditMenu.bind(this)}
                                deleteComponent={props.manage}
                />
                : null
            }
        </>

    )
}

export default CoachROI