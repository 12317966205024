import {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import SurveySummary from "../components/survey_overview/SurveySummary";
import SummaryTop5s from "./SummaryTop5s";
import survey_diag_api from "../api/protected/diagnostics";
import SummaryPillars from "./SummaryPillars";
import SummaryQuestions from "./SummaryQuestions";


const SurveyOverview = () => {

    const params = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [showSummary, setShowSummary] = useState(true);
    const [showTop5s, setShowTop5s] = useState(false);
    const [showPillars, setShowPillars] = useState(false);
    const [showQuestions, setShowQuestions] = useState(false);

    const [surveyData, setSurveyData] = useState();
    const [cardId, setCardId] = useState(0);


    const getSurvey = () => {
        let participants = []
        if (searchParams.get("selected")) {
            let params = searchParams.get("selected").trim()
            participants = params.substring(1, params.length - 1).split(",")
        } else {
            navigate("/surveys/" + params.surveyId + "?overview=true")
        }

        let body = {}
        if (participants.length) {
            body.participants = participants
        } else return;

        let response;
        if(searchParams.get('demo')) {
            response = survey_diag_api.getDemoSurveyDiagnostics(params.surveyId, body);
        } else {
            response = survey_diag_api.getSurveyDiagnostics(params.surveyId, body);
        }
        response.then(response => {
            let data = {...response.data};
            if(data.Error) {
                return;
            }
            setSurveyData(data);
        }).catch((err) => {
            console.log(err)
        })
    }

    const scrollTop = () => {
        window.scrollTo(0, 0)
    }

    const updateShowSummary = () => {
        setShowTop5s(false);
        setShowPillars(false);
        setShowQuestions(false);
        setShowSummary(true);
        scrollTop()
    }

    const updateShowTop5s = (cardId) => {
        setShowSummary(false);
        setShowQuestions(false);
        setShowPillars(false);
        setShowTop5s(true);
        setCardId(cardId)
        scrollTop()
    }

    const updateShowPillars = (pillar_id) => {
        setShowSummary(false);
        setShowQuestions(false);
        setShowTop5s(false);
        setShowPillars(true);
        setCardId(pillar_id)
        scrollTop()
    }

    const updateShowQuestions = (pillar_id) => {
        setShowSummary(false);
        setShowTop5s(false)
        setShowPillars(false)
        setShowQuestions(true)
        setCardId(pillar_id)
        scrollTop()
    }

    const switchCard = (id) => {
        setCardId(id)
    }

    useEffect(() => {
        if (!params.surveyId) return;
        getSurvey();
    }, [params.surveyId]);

    return (
        <div className="survey-overview-page-background-container">
            <div className="survey-overview-page-container">
                {showSummary ?
                    <SurveySummary surveyData={surveyData} showTop5s={updateShowTop5s.bind(this)}
                                   showPillars={updateShowPillars.bind(this)}/>
                    : null
                }
                {showTop5s ?
                    <SummaryTop5s surveyData={surveyData} cardType={cardId} back={updateShowSummary.bind(this)}
                                  switchMenu={switchCard.bind(this)} surveyID={params.surveyId}/>
                    : null
                }
                {showPillars ?
                    <SummaryPillars surveyData={surveyData} cardType={cardId} back={updateShowSummary.bind(this)}
                                    switchMenu={switchCard.bind(this)} seeQuestions={updateShowQuestions.bind(this)}
                                    surveyID={params.surveyId}/>
                    : null
                }
                {showQuestions ?
                    <SummaryQuestions surveyData={surveyData} cardType={cardId} back={updateShowPillars.bind(this)}/>
                    : null
                }
            </div>
        </div>
    )
}

export default SurveyOverview