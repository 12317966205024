import globe from "../assets/background-globe.png"


const BackgroundRings = (props) => {
    return (
        <div className="home-page-background-ring-container" style={{top: props.top, right: props.right}}>
            <div className="home-page-background-ring" style={{width: 1384, height: 1384}}>
                <div className="home-page-background-ring" style={{width: 972, height: 972}}>
                    <div className="home-page-background-ring" style={{width: 688, height: 688}}>
                        <div className="home-page-background-ring" style={{width: 556, height: 556}}>
                            {props.globe ?
                                <img src={globe} alt=""/>
                                :
                                <div className="home-page-background-ring-centre"/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default BackgroundRings