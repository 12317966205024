import React, {useEffect, useState} from 'react';

const Rating = (props) => {
    const initialRating = props.initialRating
    const maxRating = props.maxRating
    const onRatingChange = props.onRatingChange
    const id = props.id
    const color = props.color

    const [rating, setRating] = useState(initialRating);
    const [type, setType] = useState("score")

    useEffect(() => {
        if (!props.type) return;
        if (props.type === 1) {
            setType("score")
        } else {
            setType("confidence")
        }
    },[props.type])

    useEffect(() => {
        setRating(initialRating)
    }, [initialRating]);

    const handleRatingChange = (newRating) => {
        setRating(newRating);
        if (onRatingChange) {
            onRatingChange(newRating, id);
        }
    };

    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= maxRating; i++) {
            stars.push(
                <span
                    key={i}
                    onClick={() => handleRatingChange(i)}
                    style={{ cursor: 'pointer', color: i <= rating ? color : 'gray', margin: '0px 5px', fontSize: "18px" }}
                >
          ★
        </span>
            );
        }
        return stars;
    };

    return (
        <div className="rating-container">
            {props.hideText ? <></> : <div className="rating-legend" style={{color: color}}>{type}</div>}
            {renderStars()}
        </div>
    );
};

export default Rating;