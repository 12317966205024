import {useEffect, useState} from "react";
import GenericDialog from "../components/GenericDialog";
import pdf_api from "../api/protected/pdf"
import {useNavigate, useParams} from "react-router-dom";
import RadioButton from "../components/RadioButton";
const ReportConfigurator = () => {

    const params = useParams();
    const navigate = useNavigate()

    const surveyId = params.surveyId

    const [overview, setOverview] = useState(true);
    const [teamStrengths, setTeamStrengths] = useState(true);
    const [graphicalCharts, setGraphicalCharts] = useState(true);
    const [summaryDataTables, setSummaryDataTables] = useState(true);
    const [detailDataTables, setDetailDataTables] = useState(true);
    const [coachingCommentary, setCoachingCommentary] = useState(true);
    const [coachingExercises, setCoachingExercises] = useState(true);
    const [howToRead, setHowToRead] = useState(true);

    const [companyLogo, setCompanyLogo] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [coachingLogo, setCoachingLogo] = useState("");
    const [coachingName, setCoachingName] = useState("");

    const [reportName, setReportName] = useState("");

    const buttons = [{"id": 1, "text": "Cancel"}];
    const [showInfoDialog, setShowInfoDialog] = useState(false);

    const [filter, setFilter] = useState(1)
    const [welcome, setWelcome] = useState(true)
    const [presentation, setPresentation] = useState(false)
    const [stakeholders, setStakeholders] = useState(false)
    /*const [ROI, setROI] = useState(false)*/

    const radioButtons = [
        {"id":1, "text": "WELCOME"},
        {"id":2, "text": "PRESENTATION"},
        {"id":3, "text": "STAKEHOLDERS"},
        /*{"id":4, "text": "ROI??"},*/
    ]

    const handleSlider = (ev) => {
        if (!ev) return;
        let id = ev.currentTarget.id
        switch (id) {
            case '1': setOverview(!overview); break;
            case '2': setTeamStrengths(!teamStrengths); break;
            case '3': setGraphicalCharts(!graphicalCharts); break;
            case '4': setSummaryDataTables(!summaryDataTables); break;
            case '5': setDetailDataTables(!detailDataTables); break;
            case '6': setCoachingCommentary(!coachingCommentary); break;
            case '7': setCoachingExercises(!coachingExercises); break;
            case '8': setHowToRead(!howToRead); break;
            default: break;
        }
    }

    const GetReportDetail = (id) => {
        if (!id) return;
        // call end-point for company logo & name, also coaching org logo & name
    }

    const handleSubmit = () => {
        // Added post with survey_id
        if (reportName === "") return;
        let body = {
            "name": reportName,
            "survey_id": surveyId,
            "report_type": filter,
            "overview": overview,
            "team_strengths": teamStrengths,
            "graphical_charts": graphicalCharts,
            "summary_data_Tables": summaryDataTables,
            "detail_data_tables": detailDataTables,
            "coaching_commentary": coachingCommentary,
            "coaching_exercises": coachingExercises,
            "how_to_read": howToRead
        }
        setShowInfoDialog(!showInfoDialog)
        let response  = pdf_api.postReport(body)
        response.then(() => {
            navigate("/reports")
        }).catch(() => {
            console.log("Failed to produce report")
        })
    }

    const handleInfoDialogResult = (res) => {
        if(!res) return;
        setShowInfoDialog(!showInfoDialog)
    }

    const handleFilter = (id) => {
        if (!id) return;
        setFilter(id)
    }

    useEffect(() => {
        if (!filter) return;
        if (filter === "1") {
            setWelcome(true)
            setPresentation(false)
            setStakeholders(false)
            // setROI(false)
        } else if (filter === "2") {
            setWelcome(false)
            setPresentation(true)
            setStakeholders(false)
            // setROI(false)
        } else if (filter === "3") {
            setWelcome(false)
            setPresentation(false)
            setStakeholders(true)
            // setROI(false)
        } /*else if (filter === "4") {
            setWelcome(false)
            setPresentation(false)
            setStakeholders(false)
            setROI(true)
        }*/
    },[filter])

    useEffect(() => {
        GetReportDetail()
    },[])

    return (
        <>
            <div className="report-configure-page">
                <div className="report-configure-header">
                    <div className="report-configure-title col">Report Configurator</div>
                </div>
                <div className="report-configure-description-container">
                    <div className="report-configure-description">
                        This configuration tool enables you to include or exclude components rendered into the PDF for
                        you to share with your clients and their teams.
                    </div>
                    <div className="report-configure-description">
                        You report will be generated server side and available in the Reports page for you to open
                        or download.
                    </div>
                </div>
                <div className="field-input">
                    <div className="label">Report Name</div>
                    <div className="field-input-entry">
                        <input className="report-input" name="report-name" type="text" value={reportName}
                               onChange={(ev) => setReportName(ev.target.value)}
                               required/>
                    </div>
                </div>
                <div className="report-configure-filter-container">
                    <div className="report-configure-filter-title">
                        Report Type
                    </div>
                    <div className="report-configure-filter-menu-container">
                        {filter ?
                            <>
                                <RadioButton button={radioButtons[0]}
                                             selected={welcome}
                                             result={handleFilter.bind(this)}/>
                                <RadioButton button={radioButtons[1]}
                                             selected={presentation}
                                             result={handleFilter.bind(this)}/>
                                <RadioButton button={radioButtons[2]}
                                             selected={stakeholders}
                                             result={handleFilter.bind(this)}/>
                                {/*<RadioButton button={radioButtons[3]}
                                             selected={ROI}
                                             result={handleFilter.bind(this)}/>*/}
                            </>
                            :null}
                    </div>
                </div>
                <div className="report-configure-options-container">
                    <div className="report-configure-options-row">
                        <div className="report-configure-option-title">PERILL Overview chart</div>
                        <div className="report-configure-option-slider-container">
                            <div className="slider">
                                <div className={overview ? "slider-container on" : "slider-container off"}
                                     id="1" onClick={handleSlider}>
                                    <div className={overview ? "slider-button on" : "slider-button off"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report-configure-options-row">
                        <div className="report-configure-option-title">PERILL Team Strengths and Weaknesses overview
                            cards</div>
                        <div className="report-configure-option-slider-container">
                            <div className="slider">
                                <div className={teamStrengths ? "slider-container on" : "slider-container off"}
                                     id="2" onClick={handleSlider}>
                                    <div className={teamStrengths ? "slider-button on" : "slider-button off"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report-configure-options-row">
                        <div className="report-configure-option-title">PERILL Diagnostic Summary Graphical charts</div>
                        <div className="report-configure-option-slider-container">
                            <div className="slider">
                                <div className={graphicalCharts ? "slider-container on" : "slider-container off"}
                                     id="3" onClick={handleSlider}>
                                    <div className={graphicalCharts ? "slider-button on" : "slider-button off"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report-configure-options-row">
                        <div className="report-configure-option-title">Diagnostic Summary data tables (expanded)</div>
                        <div className="report-configure-option-slider-container">
                            <div className="slider">
                                <div className={summaryDataTables ? "slider-container on" : "slider-container off"}
                                     id="4" onClick={handleSlider}>
                                    <div className={summaryDataTables ? "slider-button on" : "slider-button off"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report-configure-options-row">
                        <div className="report-configure-option-title">Survey Detail data tables</div>
                        <div className="report-configure-option-slider-container">
                            <div className="slider">
                                <div className={detailDataTables ? "slider-container on" : "slider-container off"}
                                     id="5" onClick={handleSlider}>
                                    <div className={detailDataTables ? "slider-button on" : "slider-button off"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report-configure-options-row">
                        <div className="report-configure-option-title">Coaching programme commentary</div>
                        <div className="report-configure-option-slider-container">
                            <div className="slider">
                                <div className={coachingCommentary ? "slider-container on" : "slider-container off"}
                                     id="6" onClick={handleSlider}>
                                    <div className={coachingCommentary ? "slider-button on" : "slider-button off"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report-configure-options-row">
                        <div className="report-configure-option-title">Coaching programme exercises</div>
                        <div className="report-configure-option-slider-container">
                            <div className="slider">
                                <div className={coachingExercises ? "slider-container on" : "slider-container off"}
                                     id="7" onClick={handleSlider}>
                                    <div className={coachingExercises ? "slider-button on" : "slider-button off"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report-configure-options-row">
                        <div className="report-configure-option-title">PERILL How to read this report</div>
                        <div className="report-configure-option-slider-container">
                            <div className="slider">
                                <div className={howToRead ? "slider-container on" : "slider-container off"}
                                     id="8" onClick={handleSlider}>
                                    <div className={howToRead ? "slider-button on" : "slider-button off"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-configure-logos-container">
                    <div className="report-configure-logo-container company">
                        <img src={companyLogo} alt={companyName}/>
                    </div>
                    <div className="report-configure-logo-container coaching">
                        <img src={coachingLogo} alt={coachingName}/>
                    </div>
                </div>
                <div className="report-configure-submit-container">
                    <div className="report-configure-submit link" onClick={handleSubmit}>Generate Report</div>
                </div>
            </div>
            {showInfoDialog ?
                <GenericDialog title="Generating Report..."
                               text="Once completed, you will be redirected to the reports page."
                               buttons={buttons} result={handleInfoDialogResult.bind(this)}/>
                : null
            }
        </>
    )
}

export default ReportConfigurator
