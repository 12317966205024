import {useState} from "react";

const ReportsGridRow = (props) => {

    const report = props.report
    const idx = props.idx
    const DownloadReport = props.DownloadReport
    const [showDeleteHover, setShowDeleteHover] = useState(false)

    const ReportType = (type, point) => {
        let _type = ""
        let _point = ""
        switch (type) {
            case 1: _type = "Full"; break;
            case 2: _type = "Lite"; break;
            default: break;
        }
        switch (point) {
            case 1: _point = "Initial"; break;
            case 2: _point = "Mid Point"; break;
            case 3: _point = "End Point"; break;
            case 4: _point = "Post Engagement"; break;
            default: break;
        }
        return `${_point} ${_type}`
    }

    const handleRowClick = (report) => {
        DownloadReport(report)
    }

    const handleDeleteHoverOn = () => {
        setShowDeleteHover(true)
    }

    const handleDeleteHoverOut = () => {
        setShowDeleteHover(false)
    }

    return(
        <div className="reports-grid-row link" key ={idx} id = {report.id} onClick={() => handleRowClick(report)}
             onMouseOver={handleDeleteHoverOn} onMouseLeave={handleDeleteHoverOut}>
            <div className="reports-grid-row-company-logo" style={{backgroundColor: '#' + report.colour}}>
                <img src={report && report.logo} alt={report && report.name + " Logo"}
                     className="company-card-logo"/>
            </div>
            <div className="reports-grid-row-name">{report.name}</div>
            <div className="reports-grid-row-survey-name">{report.survey_name}</div>
            <div className="reports-grid-row-report-type">{ReportType(report.type, report.point)}</div>
            <div className="reports-grid-row-created-date">{report.timestamp}</div>
            {showDeleteHover ?
                <div className="report-creator-container">
                    <div className="delete-tooltip-text">
                        Created by: {report.firstname + " " + report.lastname}
                    </div>
                </div>
                : null
            }
        </div>
    )
}

export default ReportsGridRow