import notifications_api from "../api/protected/notifications"
import {useEffect, useState} from "react";
import NotificationCard from "../components/NotificationCard";



const Notifications = () => {

    const [notifications, setNotifications] = useState([])

    const getNotifications = () => {
        let response = notifications_api.getNotifications()
        response.then(response => {
            setNotifications(response.data)
        }).catch(err => console.log(err))
    }

    const handleDismiss = (ev, id) => {
        if(!ev || !id) return;
        let response = notifications_api.deleteNotification(id);
        response.then(() => {
            window.location.reload()
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getNotifications()
    }, []);

    return (
        <div className="notifications-page-background-container">
            <div className="notifications-page-container">
                <div className="notifications-page-header">
                    <div className="notifications-page-title">Notifications</div>
                </div>
                <div className="notifications-page-body">
                    {notifications.map((notification, idx) => {
                        return <NotificationCard title={notification.title}
                                                 message={notification.message}
                                                 category={notification.category}
                                                 created_date={notification.created_date}
                                                 link={notification.link}
                                                 id={notification.id}
                                                 handle_dismiss={handleDismiss.bind(this)}
                                                 key={idx}/>
                    })}
                    <div style={notifications.length ? {display: "none"} : null}>
                        You don't have any notifications.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notifications