import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")


const getSurveyDiagnostics = (surveyId, body) => http.post(`/survey_diagnostics/${surveyId}`, body);
const getDemoSurveyDiagnostics = (surveyId, body) => http.post(`/demo_survey_diagnostics/${surveyId}`, body);

export default {
    getSurveyDiagnostics,
    getDemoSurveyDiagnostics
}