import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import unsub_api from "../api/public/email"
import GenericDialog from "../components/GenericDialog";


const Unsubscribe = () => {
    const [reason, setReason] = useState("")
    const navigate = useNavigate();
    const [params] = useSearchParams();

    const buttons = [{"id": 1, "text": "Cancel"}];
    const [showInfoDialog, setShowInfoDialog] = useState(false);

    const SubmitResponse = () => {
        let body = {
            'token': params.get('token'),
            'reason': reason
        }
        let response = unsub_api.putUnsubscribe(body)
        response.then(
            response => {
                let _data = response.data.response
                if (_data === "Successfully updated") {
                    navigate('/')
                } else {
                    setShowInfoDialog(!showInfoDialog)
                }
            }
        )
    }

    const handleCancel = () => {
        navigate('/')
    }

    const handleInfoDialogResult = (res) => {
        if(!res) return;
        setShowInfoDialog(!showInfoDialog)
    }

    useEffect(() => window.scrollTo({top: 0, left: 0, behavior: 'instant'}), []);

    return (
        <div className="unsubscribe-page-container">
            <div className="unsubscribe-page-title">Unsubscribe to our newsletter?</div>
            <div className="unsubscribe-page-subtext">
                We're sorry to see you go, please describe why you want to stop receiving our weekly newsletters.
            </div>
            <div className="unsubscribe-page-input-container">
                <textarea className="unsubscribe-page-input"
                       maxLength="1024"
                       value={reason}
                       onChange={(ev) =>
                           setReason(ev.target.value)}
                       required
                />
                <div className="unsubscribe-char-counter-container">
                    <div className="unsubscribe-char-counter">{reason.length} \ 1024 characters</div>
                </div>
            </div>
            <div className="unsubscirbe-buttons-container">
                <div className="unsubscirbe-button submit" onClick={SubmitResponse}>Submit</div>
                <div className="unsubscirbe-button cancel" onClick={handleCancel}>Cancel</div>
            </div>
            {showInfoDialog ?
                <GenericDialog title="Failed to unsubscribe..."
                               text="Please contact our support team with your subscribed email address to resolve this issue."
                               buttons={buttons} result={handleInfoDialogResult.bind(this)}/>
                : null
            }
        </div>
    )
}

export default Unsubscribe