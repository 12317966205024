const dashboard_bar_options = {
    responsive: true,
    plugins: {legend: {display: false}},
    scales: {
        x: {
            stacked: true, offset: true, border: {display: false},
            ticks: {
                padding: 0,
                stepSize: 1,
                callback: function(v) {
                    let current_label = this.getLabelForValue(v) || "";
                    let new_label;
                    if(current_label.length >= 10) {
                        new_label = current_label.substring(0, 6) + '...'
                    } else {
                        new_label = current_label
                    }
                    return new_label
                },
            },
            grid: {display: false, drawBorder: false},
            title: {text: "Team Name", display: true, font: {family: "ccmiInterRegular, serif", size: 14}, color: "#0082CA"},

        },
        y: {
            stacked: true, border: {display: false, dash: [2, 2]}, ticks: {padding: 10, stepSize: 1}, grid: {drawBorder: false},
            title: {text: "Number of Surveys", display: true, font: {family: "ccmiInterRegular, serif", size: 14}}
        }
    }
}

const dashboard_line_options = {
    responsive: true,
    plugins: {legend: {display: false}},
    scales: {
        x: {
            title: {text: "Survey Date", display: true, font: {family: "ccmiInterRegular, serif", size: 14}, color: "#0082CA"},
            border: {dash: [2, 4]},
            grid: {display: true, drawBorder: true, drawTicks: false},
            ticks: {
                callback: function(v) {
                    let current_label = this.getLabelForValue(v) || "";
                    let new_label;
                    if(current_label.length >= 10) {
                        new_label = current_label.substring(0, 6) + '...'
                    } else {
                        new_label = current_label
                    }
                    return new_label
                },
                padding: 10
            },
            offset: true,
        },
        y: {
            title: {text: "Score & Variance", display: true, font: {family: "ccmiInterRegular, serif", size: 14}},
            border: {display: false},
            grid: {display: true, drawBorder: false, tickLength: 0},
            ticks: {display: false, padding: 0, stepSize: 1},
        },
    },
}

const constructDashboardBarDataset = (label, data, color) => {
    return {
        label: label,
        data: data,
        backgroundColor: color,
        barPercentage: 0.35,
        borderRadius: {topLeft: 8, topRight: 8, bottomLeft: 8, bottomRight: 8},
        borderSkipped: false,
        offset: true,
        pointStyle: "rectRounded"
    }
}

const constructDashboardLineDataset = (label, data, colour, point_colour) => {
    return {
        label: label,
        data: data,
        borderWidth: 1,
        borderColor: colour,
        pointRadius: 5,
        pointBorderWidth: 3,
        pointBorderColor: point_colour,
        pointBackgroundColor: colour,
    }
}

export default {
    dashboard_bar_options,
    dashboard_line_options,
    constructDashboardBarDataset,
    constructDashboardLineDataset
}