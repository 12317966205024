import SurveysGridRow from "../components/SurveysGridRow";

const SurveysGridContainer = (props) => {
    const surveys = props.surveys;
    const showHidden = props.showHidden;

    return (
        <div className="surveys-grid-container">
            <div className="surveys-grid-headers">
                <div className="surveys-grid-company-logo"/>
                <div className="surveys-grid-company-name">Company Name</div>
                <div className="surveys-grid-team-name">Team Name</div>
                <div className="surveys-grid-survey-name">Survey Name</div>
                <div className="surveys-grid-survey-type">Survey Type</div>
                <div className="surveys-grid-survey-point">Survey Point</div>
                <div className="surveys-grid-survey-summary">Summary</div>
            </div>
            {surveys && surveys.map((survey, idx) => {
                if(!survey.hidden || survey.demo || showHidden) {
                    return (
                        <SurveysGridRow survey={survey} handleClick={() => props.handleClick(survey)}
                                        key={idx}
                        />
                    )
                }
            })}
        </div>
    )
}
export default SurveysGridContainer;