import CompaniesTeamsGridRow from "../components/CompaniesTeamsGridRow";

const CompaniesTeamsGridContainer = (props) => {
    const teams = props.teams;

    return (
        <div className="companies-teams-grid-container">
            {teams && teams.map((team) => {
                return <CompaniesTeamsGridRow team={team} handleClick={props.handleClick} key={team && team.id}/>
            })}
        </div>
    )
}
export default CompaniesTeamsGridContainer;