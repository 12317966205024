import {useEffect, useState} from "react";
import DatePicker, {registerLocale} from 'react-datepicker'
import surveys_api from "../api/protected/surveys";
import companies_api from "../api/protected/companies";
import teams_api from "../api/protected/teams";
import people_api from "../api/protected/people";
import en from 'date-fns/locale/en-GB';
import types from "../assets/constants/types";
import 'react-datepicker/dist/react-datepicker.css'
import back_arrow from "../assets/back_arrow.png";
import SurveyParticipantsGridContainer from "../containers/SurveyParticipantsGridContainer";
import SurveyUpdateParticipants from "./SurveyUpdateParticipants";
import {useSearchParams} from "react-router-dom";
import {clear} from "@testing-library/user-event/dist/clear";
import SurveyPointPopup from "./SurveyPointPopup";
registerLocale('en-gb', en);


const NewSurvey = (props) => {

    const disabled_date = (surveyDate) => new Date() < surveyDate;
    const [params, setParams] = useSearchParams()

    const [companies, setCompanies] = useState([]);
    const [companyID, setCompanyID] = useState(0);
    const [teams, setTeams] = useState([]);
    const [teamID, setTeamID] = useState(0);
    const [people, setPeople] = useState([]);
    const [selectedPeople, setSelectedPeople] = useState([]);
    const [surveyName, setSurveyName] = useState("");
    const [surveyPoint, setSurveyPoint] = useState(1);
    const [surveyType, setSurveyType] = useState(1);
    const [surveyExpiry, setSurveyExpiry] = useState(new Date());
    const [newlySelected, setNewlySelected] = useState([])
    const [showPopup, setShowPopup] = useState(false)

    const [companyLoaded, setCompanyLoaded] = useState(false)
    const [teamLoaded, setTeamLoaded] = useState(false)

    const [showPointWarning, setShowPointWarning] = useState(false)

    useEffect(() => {
        if (params.get('type')) {
            setSurveyPoint(parseInt(params.get("type")))
        } else {
            setSurveyPoint(props.passedPoint)
            props.setPassedPoint(1)
        }

        getCompanies();
    }, [])

    useEffect(() => {
        if(!teamID) return;
        if (props.correctPoint) {
            let response = surveys_api.checkSurveyPoint(teamID)
            response.then(response => {
                for(const point_type of response.data) {
                    if(point_type.count === 0) {
                        console.log(point_type)
                        setSurveyPoint(point_type.point)
                        break;
                    }
                }
            })
        }
    }, [teamID]);

    useEffect(() => {
        if(!surveyPoint || !teamID || surveyPoint === 1) return;
        let response = surveys_api.checkSurveyPoint(teamID)
        response.then(response => {
            if(surveyPoint > 1 && surveyPoint < 5) {
                let prev_point_count = response.data[surveyPoint - 2].count
                if(!prev_point_count) setShowPointWarning(true)
            }
        }).catch(err => console.log(err))
    }, [surveyPoint, teamID]);

    useEffect(() => {
        if(!companyLoaded || !companies.length) return;
        if (params.get('company')) {
            setCompanyID(parseInt(params.get('company')))
        } else {
            setCompanyID(companies[0].id)
        }
    }, [companyLoaded, companies])

    useEffect(() => {
        if (!companyID) return;
        getTeams();
    }, [companyID]);

    useEffect(() => {
        if(!teamLoaded || !teams.length) return;
        if (params.get('team')) {
            setTeamID(parseInt(params.get('team')))
        } else {
            setTeamID(teams[0].id)
        }
    }, [teamLoaded, teams]);

    useEffect(() => {
        if (!teamID) return;
        getPeople();
    }, [teamID])

    const clearParams = () => {
        setParams({})
    }

    const getCompanies = () => {
        let response = companies_api.getCompanies();
        response.then(response => {
            setCompanies(response.data.companies);
        }).finally(() => {
            setCompanyLoaded(true);
        })
    }

    const getTeams = () => {
        let response = teams_api.getTeamsByCompanyID(companyID);
        response.then(response => {
            setTeams(response.data.teams)
        }).finally(() => {
            setTeamLoaded(true)
        })
    }

    const getPeople = () => {
        let response = people_api.getPeople(teamID);
        response.then(response => {
            setPeople(response.data.people)
        }).finally(() => {
            clearParams()
        })
    }

    const createSurvey = (is_draft=false) => {
        let body = {
            "survey_type": surveyType,
            "survey_point": surveyPoint,
            "company_id": companyID,
            "team_id": teamID,
            "name": surveyName,
            "expiry_date": Math.trunc(surveyExpiry.getTime()/1000),
            "participants": selectedPeople,
            "is_draft": is_draft
        }

        let response = surveys_api.newSurvey(body);
        response.then(() => props.updateSurveysGrid()).catch(() => console.log(body))
    }

    const handleNewlySelected = (result) => {
        if (!result) return;
        if (result === "1") {
            createSurvey();
        } else if (result === "2") {
            createSurvey(true)
        }
        setShowPopup(false)
    }

    const submitController = (ev) => {
        ev.preventDefault();
        if (newlySelected.length === 0) {
            createSurvey();
        } else {
            setShowPopup(true)
        }
    }

    const validateSubmit = () => {
        return (!surveyName || !surveyType || !surveyPoint || !companyID || !teamID);
    }

    const closePointPopup = (ev) => {
        if(!ev) return;
        setShowPointWarning(false)
    }

    const handlePointCancel = (ev) => {
        if(!ev) return;
        setSurveyPoint(1);
        setShowPointWarning(false);
    }

    return (
        <>
            <div className="back-link link" onClick={() => props.updateSurveysGrid()}>
                <img src={back_arrow} alt="Back Arrow"/>
            </div>
            <div className="surveys-page-header">
                <div className="surveys-page-title">{surveyName ? surveyName : "New Survey"}</div>
            </div>
            <div className="survey-form-container">
                <div className="survey-form-title">Survey Details</div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Survey Type</div>
                        <select onChange={ev => setSurveyType(parseInt(ev.target.value))} value={surveyType}>
                            {types.survey_types && types.survey_types.map((surveyType, idx) => {
                                return <option value={surveyType.id} key={idx}>{surveyType.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="field-input-entry">
                        <div className="label">Survey Point</div>
                        <select onChange={ev => setSurveyPoint(parseInt(ev.target.value))} value={surveyPoint}>
                            {types.point_types && types.point_types.map((surveyPoint, idx) => {
                                return <option value={surveyPoint.id} key={idx}>{surveyPoint.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Company</div>
                        <select onChange={ev => setCompanyID(parseInt(ev.target.value))} value={companyID}>
                            {companies && companies.map((company, idx) => {
                                return <option value={company.id} key={idx}>{company.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Team</div>
                        <select onChange={ev => setTeamID(parseInt(ev.target.value))} value={teamID}>
                            {teams && teams.map((team, idx) => {
                                return <option value={team.id} key={idx}>{team.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="field-input">
                    <div className="label">Survey Name</div>
                    <div className="field-input-entry">
                        <input className="survey-name-input" type="text" required
                               onChange={(ev) => setSurveyName(ev.target.value)}
                        />
                    </div>
                </div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Expiry Date</div>
                        <DatePicker dateFormat="dd/MM/yyyy" locale="en-gb" filterDate={disabled_date}
                                    selected={surveyExpiry} onChange={(date) => setSurveyExpiry(date)}
                        />
                    </div>
                </div>
            </div>
            <div className="survey-form-container">
                <div className="survey-form-title">People</div>
                <SurveyParticipantsGridContainer people={people} selectedPeople={selectedPeople}
                                                 updateSelected={setSelectedPeople.bind(this)} isNew={true}
                                                 newlySelected={newlySelected}
                                                 updateNewlySelected={setNewlySelected.bind(this)}/>
                <div className="field-input">
                    <button className={`update-company-button link ${validateSubmit() ? "disabled" : ""}`}
                            onClick={submitController}
                            disabled={validateSubmit()}>
                        Save
                    </button>
                </div>
                {showPopup ?
                    <SurveyUpdateParticipants people={newlySelected} result={handleNewlySelected.bind(this)}
                                              cancel_text="Save Draft"
                    /> : null
                }
            </div>
            {showPointWarning ?
                <SurveyPointPopup point={surveyPoint} acceptNewSurvey={closePointPopup.bind(this)}
                                  cancelNewSurvey={handlePointCancel.bind(this)}
                                  closePopup={closePointPopup.bind(this)}
                />
                : null
            }
        </>
    )
}

export default NewSurvey;
