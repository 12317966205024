import logo from "../../assets/ccmi-graphic-logo-color.svg";

const QuestionnaireEntry = (props) => {
    const updateEntry = props.updateEntry;
    const surveyType = props.surveyType

    const formSubmit = (ev)=> {
        ev.preventDefault();
        updateEntry();
    }

    return (
        <div className="questionnaire-entry-container">
            <div className="questionnaire-entry-page">
                <div className="questionnaire-entry-header">
                    <div className="questionnaire-entry-logo">
                        <img src={logo} alt="ccmi logo"/>
                    </div>
                    <h2 style={{margin: "50px 0px"}}>Welcome.</h2>
                    <h3>You have been invited to take part in a PERILL diagnostics questionnaire.</h3>
                    <br/>
                    <h3>
                        {surveyType === 1 ?
                            "There are 6 sections to complete, with 20 statements per section. It should not take " +
                            "you more than 45 - 60 minutes to complete the survey in its entirety, but you can save " +
                            "your progress and revisit the questions at any time."
                            :
                            "There are 6 sections to complete, with 5 statements per section. It should not take " +
                            "you more than 15 minutes to complete the survey in its entirety, but you can save " +
                            "your progress and revisit the questions at any time."
                        }
                    </h3>
                    <h3>
                        For each statement, please score them from 1 ( Strongly disagree) to 10 (Strongly agree)
                        and then rate your confidence level in the score that you have given from 1 (Not confident)
                        to 5 (Very confident).
                    </h3>
                    <h3>
                        {surveyType === 3 ?
                            "Your scores (along with the scores of all other participants) will be used to produce a " +
                            "PERILL report on team function and will be reported back to the issuer of the survey. " +
                            "Your scores will be anonymous."
                            :
                            "Your scores will be used to produce a report on stakeholder perception of team function " +
                            "vs. the team perception, and will be used to help coach the team."
                        }
                    </h3>
                </div>
                <form name="questionnaire-form" method="POST">
                    <div className="field-input entry-button">
                        <button className="submit-questionnaire-button link"
                                name="method"
                                type="submit"
                                onClick={formSubmit}>
                            BEGIN SURVEY
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default QuestionnaireEntry