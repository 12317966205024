import {useEffect, useState} from "react";
import del from "../assets/delete-bin.svg";
import eye from "../assets/eye.png"
import {useNavigate} from "react-router-dom";


const CoCoachLinkGrid = (props) => {

    const [activeLinks, setActiveLinks] = useState([])
    const handle = props.handle
    const navigate = useNavigate()

    useEffect(() => {
        setActiveLinks(props.activeLinks)
    }, [props]);

    return (
        activeLinks && activeLinks.length ?
            <div className="settings-form-container co-coaches">
                <div className="settings-form-title">Shared With Me</div>
                <div className="settings-invites-grid">
                    <div className="settings-co-coaches-row header medium">
                        <div className="settings-co-coaches-column email">Origin</div>
                        <div className="settings-co-coaches-column expiry-date">Status</div>
                        <div className="settings-co-coaches-column controls"/>
                    </div>
                    {activeLinks && activeLinks.map((active_link, idx) => {
                        return (
                            <div className="settings-co-coaches-row shared" key={idx}>
                                <div className="settings-co-coaches-column email">{active_link.email}</div>
                                <div className="settings-co-coaches-column expiry-date recolour medium">
                                    <div className={`co-coaches-indicator ${active_link.status === "Active" ? "active" : "pending"}`}/>
                                    {active_link.status}
                                </div>
                                <div className="settings-co-coaches-column controls">
                                    <div className="settings-co-coaches-column-button" onClick={() => navigate(`/co-coach/${active_link.id}?view=true`)}>
                                        <img src={eye} alt="View"/>
                                    </div>
                                    <div className="settings-co-coaches-column-button" onClick={() => handle(active_link.id, false)}>
                                        <img src={del} alt="Delete"/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div> : null
    )
}


export default CoCoachLinkGrid