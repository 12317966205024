import React, {useEffect, useState} from 'react';
import {useDraggable} from '@dnd-kit/core';

const Draggable = (props) => {
    const [opacity, setOpacity] = useState()
    const {attributes, listeners, setNodeRef, transform} = useDraggable({id: props.id});

    useEffect(() => {
        setOpacity(props.opacity)
    }, [props.opacity]);

    return (
        <div ref={setNodeRef} {...listeners} {...attributes} className={opacity && opacity ? "draggable faded" : "draggable"}>
            {props.children}
        </div>
    );
}

export default Draggable