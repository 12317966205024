import {useEffect, useState} from "react";
import dashboard_api from "../../api/protected/dashboard"
import NoData from "./NoData";
import SelectionPopup from "./SelectionPopup";
import ComponentScrollbar from "../ComponentScrollbar";
import info from "../../assets/info-icon.png"
import edit from "../../assets/edit-pencil.svg";
import del from "../../assets/delete-bin.svg";


const SurveyCompletion = (props) => {

    const [showEditMenu, setShowEditMenu] = useState(true)
    const [allSurveys, setAllSurveys] = useState([])
    const [allTeams, setAllTeams] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [selectedSurvey, setSelectedSurvey] = useState(0)
    const [isDemo, setIsDemo] = useState(false)

    const [participants, setParticipants] = useState([])
    const [scrollSync, setScrollSync] = useState(0)

    useEffect(() => {
        setAllSurveys(props.surveys)
    }, [props.surveys]);

    useEffect(() => {
        setAllTeams(props.teams)
    }, [props.teams]);

    useEffect(() => {
        setAllCompanies(props.companies)
    }, [props.companies]);

    useEffect(() => {
        if(!props.context) return;
        setSelectedSurvey(props.context)
        setShowEditMenu(false);
    }, [props.context])

    useEffect(() => {
        setIsDemo(props.demo)
    }, [props.demo]);

    useEffect(() => {
        if(!selectedSurvey) return;
        getSurveyCompletion(selectedSurvey)
    }, [selectedSurvey])

    const getSurveyCompletion = (survey_id) => {
        let response;
        if(isDemo) {
            response = dashboard_api.getDemoSurveyCompletion(survey_id);
        } else {
            response = dashboard_api.getSurveyCompletion(survey_id);
        }
        response.then(response => {
            setParticipants(response.data)
            setShowEditMenu(false)
        })
    }

    const synchroniseScroll = (ev) => {
        if(!ev) return;
        setScrollSync(ev.target.scrollTop)
    }
    const updateSelectedSurvey = (survey) => {
        if(!survey) return;
        setIsDemo(survey.demo)
        let response = dashboard_api.updateComponent(props.id, survey.id, survey.demo);
        response.then(() => {
            setSelectedSurvey(survey.id)
        })
    }
    const openEditMenu = (ev) => {
        if(!ev) return;
        setShowEditMenu(true)
    }
    const closeEditMenu = (ev) => {
        if(!ev) return;
        setShowEditMenu(false);
    }

    return (
        <>
            <div className="dashboard-component">
                {participants.length ?
                    <>
                        <div className="survey-component-top-row">
                            <div className="survey-component-title-container">
                                <div className="survey-component-title">Survey Completion by Participants</div>
                                <img src={info} alt="Info"/>
                            </div>
                            <div className="survey-component-button-container">
                                <div className="dashboard-component-button" onClick={openEditMenu}>
                                    <img src={edit} alt="Edit Component"/>
                                </div>
                                <div className="dashboard-component-button" onClick={() => props.manage(props.id)}>
                                    <img src={del} alt="Delete Component"/>
                                </div>
                            </div>
                        </div>
                        <div className="survey-component-table-container">
                            <div className="survey-component-scroll-area" id={`survey-scroll-area${props.id}`}
                                 onScroll={synchroniseScroll}>
                                {participants.map((item, idx) => {
                                    return (
                                        <div className="survey-component-participant-row" key={idx}>
                                            <div className="survey-component-participant-row-colour-circle" style={{backgroundColor: item.colour ? item.colour : '#000000'}}/>
                                            <div className="survey-component-participant-row-main">
                                                <div className="survey-component-participant-row-info">
                                                    <div className="survey-component-participant-row-name">{item.name}</div>
                                                    <div className="survey-component-participant-row-email">{item.email}</div>
                                                    <div className="survey-component-participant-row-percentage"
                                                         style={item.completion_percentage !== 100 ? {color: "#CBCBCB"} : null}>
                                                        100%
                                                    </div>
                                                </div>
                                                <div className="survey-component-participant-row-bar">
                                                    <div className="survey-component-participant-row-bar-inner" style={{width: item.completion_percentage + '%'}}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <ComponentScrollbar scrollContainer={`survey-scroll-area${props.id}`} id={props.id} sync={scrollSync}/>
                        </div>
                    </>
                    : <NoData id={props.id} edit={openEditMenu.bind(this)} delete={props.manage} context="survey"/>
                }
            </div>
            {showEditMenu ?
                <SelectionPopup id={props.id}
                                title="Participant Completion"
                                selection_level={2}
                                companies={allCompanies}
                                teams={allTeams}
                                surveys={allSurveys}
                                selected={selectedSurvey}
                                updateSelected={updateSelectedSurvey.bind(this)}
                                closePopup={closeEditMenu.bind(this)}
                                deleteComponent={props.manage}
                />
                : null}
        </>
    )
}

export default SurveyCompletion