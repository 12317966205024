

const SurveyUpdateParticipants = (props) => {


    const people = props.people;
    const result = props.result;
    const cancel_text = props.cancel_text;

    const buttons = [{"text":"Confirm", "id": "1"}, {"text":cancel_text, "id": "2"}];

    const footerClick = (ev) => {
        if (!ev) return;
        let res = ev.target.id
        result(res)
    }

    return (
        <>
            <div className="page-mask"/>
            <div className="survey-update-participant-container">
                <div className="survey-update-participant-selector-header-container">
                    <div className="participant-selector-header">
                        Survey Participant Selection
                    </div>
                </div>
                <div className="survey-update-participant-selector-body-description-container">
                    Are you sure you want to invite these new participants?
                </div>
                <div className="survey-update-participant-selector-body-container">
                    <div className="survey-update-participant-selector-grid">
                        {people && people.map((person, idx) => {
                            return (
                                <div className="participant-selector-row" key={idx}>
                                    <div className="participant-selector-name">{person.name}</div>
                                    <div className="participant-selector-email">{person.email}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="participant-selector-footer-container">
                    {buttons && buttons.map((button, idx) => {
                        return (
                            <div className="participant-selector-footer-button link" id={button.id}
                                 onClick={footerClick} key={idx}>
                                {button.text}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default SurveyUpdateParticipants