import {useEffect, useState} from "react";
import kapi from "../api/kratos";
import auth from "../api/auth";
import {useSearchParams} from "react-router-dom";

const Logout = () => {

    const [params] = useSearchParams()
    const [returnUrl, setReturnUrl] = useState('')

    useEffect(() => {
        let return_url = params.get('return_to')
        if (return_url) {
            setReturnUrl(return_url)
        } else {
            setReturnUrl('logged_out')
        }

    }, []);

    useEffect(() => {
        if (!returnUrl || returnUrl === '') return;
        if (auth.isAuthenticated() || returnUrl === "password-changed") {
            Logout()
        } else {
            window.location.assign(`/${returnUrl}`)
        }
    }, [returnUrl]);

    const  Logout = () => {
        const response = kapi.logout_urls();
        response.then(response => {
            let url = response.data.logout_url;
            localStorage.removeItem('user')
            window.location.assign(url);
        }).catch((error) => {
            console.log("FAILED")
            localStorage.removeItem('user')
            let statusCode = error?.response?.status;
            if (!statusCode) return
            if (statusCode === 401) {
                window.location.assign(`/${returnUrl}`);
            }
            console.log(error)
        })
    }

    return <></>
}

export default Logout