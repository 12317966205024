import {useEffect, useState} from "react";
import companies_api from "../../api/protected/companies";
import {useSearchParams} from "react-router-dom";
import TeamPeopleContainer from "../../containers/TeamPeopleContainer";

const TeamForm = (props) => {
    const [companies, setCompanies] = useState([]);
    const [companyID, setCompanyID] = useState(null);
    const [companyName, setCompanyName] = useState();
    const [teamName, setTeamName] = useState(props.teamName);
    const [teamDescription, setTeamDescription] = useState("");

    const [teamPeople, setTeamPeople] = useState([])
    const [buttonText, setButtonText] = useState("")
    const submit = props.submit
    const isNew = props.isNew
    const editor = props.editor

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!props.buttonText) return;
        setButtonText(props.buttonText)
    }, [props.buttonText])

    useEffect(() => {
        if (!props.teamName) return;
        setTeamName(props.teamName);
    }, [props.teamName])

    useEffect(() => {
        if (!props.teamDescription) return;
        setTeamDescription(props.teamDescription);
    }, [props.teamDescription])

    useEffect(() => {
        if (!props.companyId) return;
        if (props.companyId === 0) return;
        setCompanyID(props.companyId);
    }, [props.companyId])

    useEffect(() => {
        getCompanies();
    }, [])

    useEffect(() => {
        if (props.searchName !== undefined) {
            setTeamName(props.searchName)
            searchParams.delete("name")
            setSearchParams(searchParams)
        }
    },[props.searchName])

    useEffect(() => {
        if (props.companyId !== null) {
            setCompanyID(props.companyId)
            searchParams.delete("company")
            setSearchParams(searchParams)
        }
    },[props.companyId])

    const getCompanies = () => {
        let response = companies_api.getCompanies();
        response.then(response => {
            setCompanies(response.data.companies);
            if (props.companyId) {
                for(let i=0;i<response.data.companies.length;i++) {
                    if (response.data.companies[i].id === props.companyId){
                        setCompanyName(response.data.companies[i].name);
                        break;
                    }
                }
            } else {
                if (response.data.companies.length > 0) {
                    setCompanyID(response.data.companies[0].id)
                    setCompanyName(response.data.companies[0].name)
                }
            }
        })
    }

    const updatePeople = (person) => {
        if(!person) return;
        let temp = [...teamPeople]
        temp.push(person)
        setTeamPeople(temp)
    }

    const deletePerson = (ev, idx) => {
        let temp = [...teamPeople]
        temp.splice(idx, 1)
        setTeamPeople(temp)
    }

    const updatePerson = (person, idx) => {
        let temp = [...teamPeople]
        temp[idx] = person
        setTeamPeople(temp)
    }

    const formSubmit = (ev)=> {
        ev.preventDefault();
        let body = {
            "name": teamName,
            "description": teamDescription,
            "company_id": companyID
        }
        submit(body, teamPeople)
    }

    return (
        <>
            <div className="team-form-container">
                <div className="teams-form-title">
                    Company Details
                </div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Company</div>
                        {!isNew ?
                            <input className="company-input" type="text" value={props.team ? props.team.company_name : companyName}
                                   disabled={true} required/>
                            :
                            <select onChange={(ev) => setCompanyID(ev.target.value)} value={companyID}>
                                {companies && companies.map((company, idx) => {
                                    return <option value={company.id} key={"companies-" + idx}>{company.name}</option>
                                })}
                            </select>
                        }
                    </div>
                </div>
                <div className="field-input">
                    <div className="label">Team Name</div>
                    <div className="field-input-entry">
                        <input className="team-name-input" type="text" value={teamName} required disabled={!editor}
                               onChange={(ev) => setTeamName(ev.target.value)}
                        />
                    </div>
                </div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Team Description</div>
                        <input type="text" maxLength="50" value={teamDescription} disabled={!editor}
                               onChange={(ev) => setTeamDescription(ev.target.value)}
                        />
                    </div>
                </div>
                {!isNew && editor?
                    <div className="field-input">
                        <button className="update-company-button link" disabled={!teamName} onClick={formSubmit}>
                                {buttonText && buttonText}
                        </button>
                    </div>
                    : null
                }
            </div>
            {isNew ?
                <>
                    <div className="team-form-container">
                        <TeamPeopleContainer people={teamPeople} company_id={companyID} isNew={isNew} editor={true}
                                             updatePeople={updatePeople.bind(this)}
                                             updatePerson={updatePerson.bind(this)}
                                             deletePerson={deletePerson.bind(this)}
                        />
                    </div>
                    <div className="field-input">
                        <button className="update-company-button link" disabled={!teamName} onClick={formSubmit}>
                            {buttonText && buttonText}
                        </button>
                    </div>
                </>
                : null
            }
        </>
    )
}

export default TeamForm