import {useEffect, useState, useContext} from "react";
import QuestionnaireInfo from "./QuestionnaireInfo";
import Rating from '../Rating';
import warning_triangle from "../../assets/warningtriangle.webp"

const QuestionnaireResponses = (props) => {
    const questions = props.questions;
    const page = props.page;
    const pillarName = props.pillarName;
    const nextPage = props.handleNext;
    const prevPage = props.handlePrev;
    const savePage = props.handleSave

    const [responses, setResponses] = useState();
    const [color, setColor] = useState("var(--ccmi-lightgreen)")
    const [notCompleteError, setNotCompleteError] = useState(false)

    useEffect(() => {
        if (!questions) return;
        let r = [...questions];
        for (let i=0;i<r.length;i++){
            if (r[i].score === null)
                r[i].score = 0;
            if (r[i].confidence === null)
                r[i].confidence = 0;
        }
        setResponses(r);
    }, [questions]);

    const handleScoreChange = (value, id) => {
        if (!value) return;
        if (!id) return;
        let r = [...responses];
        for (let i=0;i<r.length;i++){
            if (r[i].question_id === parseInt(id)){
                r[i].score = parseInt(value);
                break;
            }
        }
        setResponses(r);
    }

    const handleConfidenceChange = (value, id) => {
        if (!value) return;
        if (!id) return;
        let r = [...responses];
        for (let i=0;i<r.length;i++){
            if (r[i].question_id === parseInt(id)){
                r[i].confidence = parseInt(value);
                break;
            }
        }
        setResponses(r);
    }

    const responsesComplete = () => {
        if (!responses) return false;
        let result = true;
        for (let i=0;i<responses.length;i++){
            if (!responses[i].score || responses[i].score === 0 ||
                !responses[i].confidence || responses[i].confidence === 0){
                result = false;
                break;
            }
        }
        return result;
    }

    useEffect(() => {
        if (!page) return;
        switch (page) {
            case 1:
                setColor("var(--ccmi-lightblue)")
                break;
            case 2:
                setColor("var(--ccmi-red)")
                break;
            case 3:
                setColor("var(--ccmi-lightgreen)")
                break;
            case 4:
                setColor("var(--ccmi-purple)")
                break;
            case 5:
                setColor("var(--ccmi-pink)")
                break;
            case 6:
                setColor("var(--ccmi-orange)")
        }
    },[page])

    const attemptSubmit = (responses) => {
        if (responsesComplete()) {
            setNotCompleteError(false)
            nextPage(responses)
        }
        else {
            setNotCompleteError(true)
        }
    }

    return (
        <>
            <QuestionnaireInfo />
            <div className="questionnaire-responses-container">
                <div className="pillar-sections-header-container">
                    <div className={page >= 1 ?
                        "pillar-sections-number-container blue": "pillar-sections-number-container"}>1</div>
                    <div className="pillar-sectios-separator"/>
                    <div className={page >= 2 ?
                        "pillar-sections-number-container red": "pillar-sections-number-container"}>2</div>
                    <div className="pillar-sectios-separator"/>
                    <div className={page >= 3 ?
                        "pillar-sections-number-container green": "pillar-sections-number-container"}>3</div>
                    <div className="pillar-sectios-separator"/>
                    <div className={page >= 4 ?
                        "pillar-sections-number-container purple": "pillar-sections-number-container"}>4</div>
                    <div className="pillar-sectios-separator"/>
                    <div className={page >= 5 ?
                        "pillar-sections-number-container pink": "pillar-sections-number-container"}>5</div>
                    <div className="pillar-sectios-separator"/>
                    <div className={page >= 6 ?
                        "pillar-sections-number-container orange": "pillar-sections-number-container"}>6</div>
                </div>
                <div className="pillar-progress-container">
                    <div className="pillar-progress">
                        {responses && page &&
                            "Questions " +
                            responses[0].display_number +
                            " to " +
                            responses[responses.length-1].display_number +
                            " of " +
                            responses.length * 6}
                    </div>
                </div>
                <div className="responses-title">{pillarName}</div>
                <div className="responses-rows">
                    {responses && responses.map((response, idx) => {
                        return (
                            <div className="question-row" key={idx}>
                                <div className="number col">{response.display_number}</div>
                                <div className="question">{response.question}</div>
                                <div className="score col">
                                    <Rating initialRating={response.score} maxRating={10} type={1} page={page} color={color}
                                            onRatingChange={handleScoreChange.bind(this)} id={response.question_id}/>
                                </div>
                                <div className="confidence col">
                                    <Rating initialRating={response.confidence} maxRating={5} type={2} page={page} color={color}
                                            onRatingChange={handleConfidenceChange.bind(this)} id={response.question_id}/>
                                </div>
                            </div>
                        )

                    })}
                </div>
                {notCompleteError
                    ?
                    <div className="response-not-complete-error-container">
                        <div className="not-complete-error-image-container">
                            <img src={warning_triangle} alt={"Warning Triangle"} className="not-complete-error-image" />
                        </div>
                        <div className="not-complete-error-text">
                            It looks like you've missed something! Please check all questions have been answered.
                        </div>
                    </div>
                    :
                    null
                }
                <div className="responses-buttons">
                    <button className={page > 1 ? "left" : "left hidden"} disabled={page <= 1}
                            onClick={() => prevPage(responses)}>PREVIOUS PILLAR</button>
                    <button className="middle" onClick={() => savePage(responses)}>SAVE YOUR PROGRESS</button>
                    <button className={"right"} onClick={() => attemptSubmit(responses)}>
                        {page < 6 ? "NEXT PILLAR" : "COMPLETE SURVEY"}
                    </button>
                </div>
            </div>

        </>
    )
}
export default QuestionnaireResponses