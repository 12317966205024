import {useEffect, useState} from "react";
import comments_api from "../api/protected/comments"
import edit from "../assets/edit-pencil.svg"
import del from "../assets/delete-bin.svg"
import {get} from "axios";

const CoachCommentaryContainer = (props) => {

    const [newComment, setNewComment] = useState("")
    const [editedComment, setEditedComment] = useState("")
    const [editCommentID, setEditCommentID] = useState(0)
    const [comments, setComments] = useState([])
    const [showEditDialogue, setShowEditDialogue] = useState(false)
    const context_id = props.context_id
    const section_id = props.section_id

    useEffect(() => {
        if(!context_id || !section_id) return;
        getComments()
    }, [context_id, section_id]);

    const getComments = () => {
        let response = comments_api.getComments(context_id, section_id);
        response.then(response => {
            setComments(response.data)
        }).catch(err => console.log(err))
    }

    const postComment = (ev) => {
        if(!ev || !newComment) return;
        let response = comments_api.newComment(context_id, section_id, newComment);
        response.then(() => getComments()).catch(err => console.log(err))
    }

    const deleteComment = (ev, id) => {
        if(!ev) return;
        let response = comments_api.deleteComment(id);
        response.then(() => getComments()).catch(err => console.log(err))
    }

    const editComment = (ev) => {
        if(!ev) return;
        let response = comments_api.editComment(editCommentID, editedComment);
        response.then(() => getComments()).catch(err => console.log(err))
        setShowEditDialogue(false);
        setEditedComment("")
        setEditCommentID(0)
    }

    const handleEditClick = (ev, comment, id) => {
        if(!ev) return;
        setShowEditDialogue(true);
        setEditedComment(comment)
        setEditCommentID(id)
    }

    const closeEditDialogue = (ev) => {
        if(!ev) return;
        setShowEditDialogue(false);
    }

    return (
        <>
            <div className="coach-commentary-container">
                {comments.map((comment, idx) => {
                    return (
                        <div className="coach-commentary-comment-container">
                            <div className="comment-comment regular">{comment.comment}</div>
                            <div className="comment-details-container">
                                {comment.editable ?
                                    <div className="comment-controls">
                                        <div className="comment-button"
                                             onClick={ev => handleEditClick(ev, comment.comment, comment.id)}>
                                            <img src={edit} alt="Edit Comment"/>
                                        </div>
                                        <div className="comment-button" onClick={ev => deleteComment(ev, comment.id)}>
                                            <img src={del} alt="Delete Comment"/>
                                        </div>
                                    </div>
                                    : <div className="spacer"/>
                                }
                                <div className="comment-name bold">{comment.author}</div>
                            </div>
                        </div>
                    )
                })}
                <div className="coach-commentary-add-comment-container">
                    <div className="coach-commentary-comment-input regular">
                        <input type="text" value={newComment} placeholder="Add a comment"
                               onChange={ev => setNewComment(ev.target.value)}/>
                    </div>
                    <div className="coach-commentary-post-comment-button bold" onClick={postComment}>ADD COMMENT</div>
                </div>
            </div>
            {showEditDialogue ?
                <>
                    <div className="page-mask" onClick={closeEditDialogue}/>
                    <div className="coach-commentary-edit-comment-container">
                        <div className="coach-commentary-comment-input regular">
                            <input type="text" value={editedComment} placeholder="Edit comment"
                                   onChange={ev => setEditedComment(ev.target.value)}/>
                        </div>
                        <div className="coach-commentary-post-comment-button grey bold" onClick={closeEditDialogue}>
                            CANCEL
                        </div>
                        <div className="coach-commentary-post-comment-button bold" onClick={editComment}>
                            EDIT COMMENT
                        </div>
                    </div>
                </>
                : null
            }
        </>
    )
}

export default CoachCommentaryContainer