import arrow from "../../assets/settings-dropdown-arrow.svg"
import {useState} from "react";


const SettingsDropdown = (props) => {

    const [showOptions, setShowOptions] = useState(false)
    const options = props.options
    const update = props.update
    const selected = props.selected

    const toggleMenu = (ev) => {
        if(!ev) return;
        if(ev.target.id !== "menu-toggle") return;
        setShowOptions(!showOptions)
    }

    const triggerUpdate = (ev, value) => {
        if(!ev) return;
        update(value);
        setShowOptions(false);
    }

    return (
        <div className={`settings-dropdown-container ${props.classnames}`} tabIndex="0" onClick={toggleMenu} id="menu-toggle">
            <div className="settings-dropdown-selected" id="menu-toggle">{selected ? selected : ""}</div>
            <img src={arrow} alt="" id="menu-toggle"/>
            <div className="settings-dropdown-option-container" style={{display: showOptions ? "block" : "none"}}>
                <div className="settings-dropdown-triangle-outer">
                    <div className="settings-dropdown-triangle-inner"/>
                </div>
                {options && options.map((value, idx) => {
                    return <div className="settings-dropdown-option" key={idx}
                                onClick={ev => triggerUpdate(ev, value)}>{value}</div>
                })}
            </div>
        </div>
    )
}

export default SettingsDropdown