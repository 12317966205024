import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")


const getReports = () => http.get("/reports");
const getValidROITeams = () => http.get("/roi_teams");
const generateROIReport = (teams, reportID) => http.post("/roi_report", {
    "teams": teams,
    "report_id": reportID
})
const getROIData = (teams) => http.post("/roi_data", {
    "teams": teams
})
const getROIReports = () => http.get("/roi_report")
const downloadROIReport = (path) => http.post("/download_roi", {
    "path": path
})

export default {
    getReports,
    getValidROITeams,
    generateROIReport,
    getROIData,
    getROIReports,
    downloadROIReport
}