import TeamsGridRow from "../components/TeamsGridRow";

const TeamsGridContainer = (props) => {
    const teams = props.teams;
    const showHidden = props.showHidden;

    return (
        <div className="teams-grid-container">
            {teams && teams.map((team, idx) => {
                if(!team.hidden || team.demo || showHidden) {
                    return <TeamsGridRow team={team} handleClick={props.handleClick} key={idx}/>
                }
            })}
        </div>
    )
}
export default TeamsGridContainer;