import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import search_api from "../api/protected/search"

import search from "../assets/search.png";
import team from "../assets/team.png"
import company from "../assets/company.png"
import survey from "../assets/survey.png"
import person from "../assets/person.png"

const SearchPage = () => {
    const [parameters, setParameters] = useSearchParams();

    const [limit, setLimit] = useState(10)
    const [searchTerm, setSearchTerm] = useState('');

    const [companies, setCompanies] = useState([]);
    const [teams, setTeams] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [teamPeople, setTeamPeople] = useState([]);


    useEffect(() => {
        let term = parameters.get("term")
        if (!term) return;
        setSearchTerm(term)
    }, [parameters.get("term")]);

    useEffect(() => {
        setParameters({"term": searchTerm})
        Search()
    }, [searchTerm])

    const Search = () => {
        if (!searchTerm) return;
        let response = search_api.globalSearch(searchTerm, limit);
        response.then(
            response => {
                setCompanies(response.data.companies);
                setTeams(response.data.teams);
                setSurveys(response.data.surveys);
                setTeamPeople(response.data.team_people);
            }
        )
    }

    return (
        <div className="companies-page-background-container">
            <div className="companies-page-container">
                <div className="companies-page-header">
                    <div className="companies-page-title">Search</div>
                    <div className="companies-page-search-container">
                        <input type="text" placeholder="Company Search" value={searchTerm}
                               onChange={(ev) => setSearchTerm(ev.target.value)}/>
                        <img src={search} alt="search icon" className="link"/>
                    </div>
                </div>
                <div className="search-page-results-container">
                    {companies && companies.map((element, idx) => {
                        return (
                            <div className="search-page-result" key={idx}>
                                <div className="result-image-identifier company">
                                    <img src={company} alt="Company"/>
                                </div>
                                <Link to={"/companies/" + element.id} className="link company">
                                    {element.name}
                                </Link>
                            </div>
                        )
                    })}
                    {teams && teams.map((element, idx) => {
                        return (
                            <div className="search-page-result" key={idx}>
                                <div className="result-image-identifier team">
                                    <img src={team} alt="Team" />
                                </div>
                                <Link to={"/companies/" + element.company.id} className="link company">
                                    {element.company.name}
                                </Link>
                                <div className="search-page-result-separator">
                                    >
                                </div>
                                <Link to={"/teams/" + element.id} className="link company">
                                    {element.name}
                                </Link>
                            </div>
                        )
                    })}
                    {surveys && surveys.map((element, idx) => {
                        return (
                            <div className="search-page-result" key={idx}>
                                <div className="result-image-identifier survey">
                                    <img src={survey} alt="Survey"/>
                                </div>
                                <Link to={"/companies/" + element.company.id} className="link company">
                                    {element.company.name}
                                </Link>
                                <div className="search-page-result-separator">
                                    >
                                </div>
                                <Link to={"/teams/" + element.team.id} className="link company">
                                    {element.team.name}
                                </Link>
                                <div className="search-page-result-separator">
                                    >
                                </div>
                                <Link to={"/teams/" + element.team.id} className="link company">
                                    {element.name}
                                </Link>
                            </div>
                        )
                    })}
                    {teamPeople && teamPeople.map((element, idx) => {
                        return (
                            <div className="search-page-result" key={idx}>
                                <div className="result-image-identifier person">
                                    <img src={person} alt="Person"/>
                                </div>
                                <Link to={"/companies/" + element.company.id} className="link company">
                                    {element.company.name}
                                </Link>
                                <div className="search-page-result-separator">
                                    >
                                </div>
                                <Link to={"/teams/" + element.team.id} className="link company">
                                    {element.team.name}
                                </Link>
                                <div className="search-page-result-separator">
                                    >
                                </div>
                                <Link to={"/teams/" + element.team.id} className="link company">
                                    {element.name}
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default SearchPage
