import expanded from "../assets/expanded.svg";
import retracted from "../assets/retracted.svg";
import {useEffect, useState} from "react";
import {te} from "date-fns/locale";


const ROITabularRow = (props) => {

    const [data, setData] = useState(null)
    const [rowExpanded, setRowExpanded] = useState(false)
    const [colour, setColour] = useState("#0082CA")

    useEffect(() => {
        setData(props.data)
        if (props.data.colour) {setColour("#" + props.data.colour)} else {setColour("#0082CA")}
    }, [props.data]);

    const getAverages = (team_data) => {
        let p1 = 0; let p2 = 0; let p3 = 0; let p4 = 0; let p5 = 0; let p6 = 0;
        for (const team of team_data) {
            p1 += team.scores[0];
            p2 += team.scores[1];
            p3 += team.scores[2];
            p4 += team.scores[3];
            p5 += team.scores[4];
            p6 += team.scores[5];
        }

        if (team_data.length) {
            p1 = p1 / team_data.length
            p2 = p2 / team_data.length
            p3 = p3 / team_data.length
            p4 = p4 / team_data.length
            p5 = p5 / team_data.length
            p6 = p6 / team_data.length
        }

        return [p1, p2, p3, p4, p5, p6]
    }

    const formatScore = (score) => {
        let s = Math.round(score)
        if (s > 0) {
            return `+${s}%`
        } else {
            return `${s}%`
        }
    }

    return (
        <>
            <div className="summary-tabular-colour-border-container">
                <div className="summary-tabular-short-border" style={{backgroundColor: colour}}/>
                <div className={`summary-tabular-long-border ${rowExpanded ? "active" : ""}`} style={{backgroundColor: colour}}/>
            </div>
            <div className="summary-tabular-team-member-row mean">
                <div className="roi-company-row-click-container">
                    <div className="summary-tabular-expand-circle link" style={{backgroundColor: colour}}
                         onClick={() => setRowExpanded(!rowExpanded)}>
                        <img src={rowExpanded ? expanded : retracted}
                             alt={rowExpanded ? "Retract" : "Expand"}
                             className={rowExpanded ? "expanded-icon" : "retracted-icon"}/>
                    </div>
                    <div className="team-member-row-name-container">{data && data.name}</div>
                </div>
                {data && getAverages(data.team_data).map((score, idx) => {
                    let s = formatScore(score);
                    return (
                        <div className={`summary-tabular-section-column ${score > 0 ? "green" : "red"}`} key={idx}>{s}</div>
                    )
                })}
            </div>
            {rowExpanded ?
                data && data.team_data.map((team, idx) => {
                    return (
                        <div className="summary-tabular-team-member-row" key={idx}>
                            <div className="roi-tabular-row-name">{team.name}</div>
                            {team && team.scores.map((score, idx) => {
                                let s = formatScore(score);
                                return (
                                    <div className={`summary-tabular-section-column ${score > 0 ? "green" : "red"}`}
                                         key={idx}>
                                        {s}
                                    </div>
                                )
                            })}
                        </div>
                    )
                    })
                : null
            }
        </>
    )
}


export default ROITabularRow