import TeamPersonRow from "../components/TeamPersonRow";
import add from "../assets/add.png";
import {useState} from "react";
import DeleteDialog from "../components/DeleteDialog";
import GenericDialog from "../components/GenericDialog";


const TeamPeopleContainer = (props) => {

    const people = props.people;
    const updatePeople = props.updatePeople;
    const deleteFn = props.deletePerson;
    const editor = props.editor;
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    const [storedEv, setStoredEv] = useState(null)
    const [storedIdx, setStoredIdx] = useState(0)
    const buttons = [{"text":"Delete", "id": "1"}, {"text":"Cancel", "id": "2"}];

    const roles = [
        {"id": 1, "name": "Team Member"},
        {"id": 2, "name": "Team Leader"},
        {"id": 3, "name": "Stakeholder"},
    ]

    const createBlankTeamPerson = (ev) => {
        if(!ev) return;
        let person = {
            "id": "new",
            "name": "",
            "role": 1,
            "color": "237842",
            "email": "",
            "company_id": props.company_id
        }
        updatePeople(person)
    }

    const deletePerson = (ev, idx) => {
        setStoredEv(ev)
        setStoredIdx(idx)
        setShowDeletePopup(true)
    }

    const handleDeleteDialogue = (result) => {
        if (!editor) return;
        if (result === "1") {
            deleteFn(storedEv, storedIdx)
        }
        setStoredEv(null)
        setStoredIdx(0)
        setShowDeletePopup(false)
    }

    const updatePerson = (ev, idx, key) => {
        let person = people[idx]
        switch (key) {
            case "name":
                person.name = ev.target.value;
                break;
            case "role":
                person.role = parseInt(ev.target.value);
                break;
            case "email":
                person.email = ev.target.value;
                break;
            default:
                break;
        }
        props.updatePerson(person, idx)
    }

    const handleEnterContext = (ev, idx) => {
        if(!ev) return;
        if(ev.key !== "Enter") return;

        switch (ev.target.id) {
            case `person-name-input${idx}`:
                document.getElementById(`person-role-input${idx}`).focus();
                break;
            case `person-role-input${idx}`:
                document.getElementById(`person-email-input${idx}`).focus();
                break;
            case `person-email-input${idx}`:
                document.getElementById(`person-name-input${idx}`).focus();
                break;
            default:
                break;
        }
    }

    return (
        <div className="add-person-container">
            <div className="add-person-form-title-container">
                <div className="add-person-form-title">{editor ? "Add a team member" : "Team Members"}</div>
                {editor ?
                    <div className="team-people-add-container" onClick={createBlankTeamPerson}>
                        <img src={add} alt="Add Team Person"/>
                    </div>
                    : null
                }
            </div>
            {people && people.map((person, idx) => {
                return (
                    <>
                        <TeamPersonRow person={person}
                                       people={people}
                                       handleEnterContext={handleEnterContext.bind(this)}
                                       updatePerson={updatePerson.bind(this)}
                                       deletePerson={deletePerson.bind(this)}
                                       roles={roles}
                                       idx={idx}
                                       key={idx}
                                       editor={editor}
                        />
                        {showDeletePopup ?
                            <GenericDialog title="Delete Team Person"
                                           text="Are you sure you want to delete this person?"
                                           buttons={buttons}
                                           result={handleDeleteDialogue.bind(this)}
                            /> : null
                        }
                    </>
                )
            })}
        </div>
    )
}

export default TeamPeopleContainer;