

const DashboardComponentSelector = (props) => {

    const show = props.show
    const closeMenu = props.close

    return (
        <div className="page-mask" id="page-mask" style={show ? null : {display: "none"}}
             onClick={() => closeMenu('')}>
            <div className="dashboard-page-component-menu">
                <div className="dashboard-page-component-menu-column">
                    <div className="dashboard-page-component-menu-item" id="component-item"
                         onClick={(ev) => closeMenu("overview")}>
                        <div className="dashboard-page-component-menu-item-title" id="component-item">
                            Diagnostic Summary
                        </div>
                        <div className="dashboard-page-component-menu-item-description" id="component-item">
                            An overview of Survey completion
                        </div>
                    </div>
                    <div className="dashboard-page-component-menu-item" id="component-item"
                         onClick={(ev) => closeMenu("feedback")}>
                        <div className="dashboard-page-component-menu-item-title" id="component-item">
                            Company Feedback
                        </div>
                        <div className="dashboard-page-component-menu-item-description" id="component-item">
                            An overview of improvement and ratings across your company
                        </div>
                    </div>
                    <div className="dashboard-page-component-menu-item" id="component-item"
                         onClick={(ev) => closeMenu("participant_completion")}>
                        <div className="dashboard-page-component-menu-item-title" id="component-item">
                            Participant Survey Completion
                        </div>
                        <div className="dashboard-page-component-menu-item-description" id="component-item">
                            An overview of survey completion across all participants
                        </div>
                    </div>
                </div>
                <div className="dashboard-page-component-menu-column">
                    <div className="dashboard-page-component-menu-item" id="component-item"
                         onClick={(ev) => closeMenu("team_completion")}>
                        <div className="dashboard-page-component-menu-item-title" id="component-item">
                            Team Survey Completion
                        </div>
                        <div className="dashboard-page-component-menu-item-description" id="component-item">
                            A chart showing survey completions across all your teams
                        </div>
                    </div>
                    <div className="dashboard-page-component-menu-item" id="component-item"
                         onClick={(ev) => closeMenu("survey_score")}>
                        <div className="dashboard-page-component-menu-item-title" id="component-item">
                            Survey Score Chart
                        </div>
                        <div className="dashboard-page-component-menu-item-description" id="component-item">
                            A chart showing survey scores across all your teams
                        </div>
                    </div>
                    <div className="dashboard-page-component-menu-item" id="component-item"
                         onClick={(ev) => closeMenu("todo")}>
                        <div className="dashboard-page-component-menu-item-title" id="component-item">
                            To Do List
                        </div>
                        <div className="dashboard-page-component-menu-item-description" id="component-item">
                            A helpful to do list
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardComponentSelector