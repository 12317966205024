const ColourBar = (props) => {
    return (
        <div className="home-header-colour-bar-container" style={{height: props.height}}>
            <div className="home-header-colour-bar"/>
            <div className="home-header-colour-bar"/>
            <div className="home-header-colour-bar"/>
            <div className="home-header-colour-bar"/>
            <div className="home-header-colour-bar"/>
            <div className="home-header-colour-bar"/>
            <div className="home-header-colour-bar"/>
            <div className="home-header-colour-bar"/>
            <div className="home-header-colour-bar"/>
            <div className="home-header-colour-bar"/>
            <div className="home-header-colour-bar"/>
            <div className="home-header-colour-bar"/>
        </div>
    )
}

export default ColourBar