import eye from "../assets/eye.png";
import {useCallback, useEffect, useState} from "react";
import kapi from "../api/kratos";
import avatar from "../assets/ccmi-graphic-logo-color.svg";
import {Link} from "react-router-dom";
import getMessageForError from "../utils/KratosErrorMessages";

const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [totp, setTotp] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [flowResponse, setFlowResponse] = useState("");

    const InitLogin = () => {
        let response = kapi.init_login();
        response.then(response => {
            setFlowResponse(response.data);
        }, function(error){
            console.log(error);
        })
    }

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('forgotten-password')) !== true) return;
        window.location.assign("/forgot-password");
    },[])

    const PageLoad = useCallback(() => {
        const params = new URLSearchParams(window.location.search);
        const flowId = params.get('flow');

        if (!flowId) {
            InitLogin();
        }
        else {
            const response = kapi.get_login(flowId);
            response.then(response => {
                setFlowResponse(response.data);
            }).catch(err => {
                console.log("Unable to retrieve login: " + err);
            });
        }

    }, [])

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
        PageLoad();
    }, [PageLoad])

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <>
            <div className="login-page-container">
                <div className="login-container">
                    <div className="login-welcome-container col">
                        <img src={avatar} alt="Logo"/>
                        <div className="login-welcome-title">
                            Welcome back
                        </div>
                        <div className="login-welcome-subtitle">
                            Log in to access your account
                        </div>
                        <div className="login-register-container">
                            Don't have an account?
                            <Link to="/register" className="login-register">Register here</Link>
                        </div>
                        <div className="register-privacy-container flex">
                            <a href="https://clutterbuck-cmi.com/">CCMI </a>
                            <a href="privacy">Privacy policy</a>
                        </div>
                        <div className="login-faq">
                            <Link to="/faq" >FAQ/Self Help Guide</Link>
                        </div>
                    </div>
                    <div className="login-form-container col">
                        <form name="login-form" action={flowResponse && flowResponse.ui.action} method="POST">
                            <div>
                                {flowResponse && flowResponse?.ui?.messages?.map((msg, idx) => {
                                    return <div className="messages" key={idx}>{getMessageForError(msg)}</div>
                                })}
                            </div>
                            <input name="csrf_token" type="hidden"
                                   value={flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'csrf_token')?.attributes.value}/>
                            {flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'totp_code') ?
                                <>
                                    <div className="field-input">
                                        <div className="label">Google Authenticator OTP</div>
                                        <div className="field-input-entry">
                                            <input className="login-input" name="totp_code" type="text" value={totp}
                                                   onChange={ev => setTotp(ev.target.value.trim())}
                                                   required maxLength={6}/>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <button className="login-button link" name="method" type="submit" value="totp"
                                                disabled={!totp}>
                                            Login
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="field-input">
                                        <div className="label">Email</div>
                                        <div className="field-input-entry">
                                            <input className="login-input" name="password_identifier" type="email"
                                                   value={email}
                                                   onChange={(ev) => setEmail(ev.target.value)}
                                                   required/>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <div className="label">Password</div>
                                        <div className="field-input-entry">
                                            <input name="password"
                                                   type={showPassword ? "text" : "password"} value={password}
                                                   onChange={(ev) => setPassword(ev.target.value)}
                                                   required/>
                                            <img src={eye} onClick={toggleShowPassword} alt="eye logo"
                                                 className="eye link"/>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <div className="label right">
                                            <Link to="/forgot-password">Forgot password ?</Link>
                                        </div>
                                    </div>
                                    <div className="field-input">
                                        <button className="login-button link" name="method" type="submit"
                                                value="password"
                                                disabled={!email || !password}>
                                            Login
                                        </button>
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login