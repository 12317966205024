import edit from "../../assets/edit-pencil.svg";
import del from "../../assets/delete-bin.svg";


const NoData = (props) => {
    return (
        <div className="dashboard-no-data-component">
            <div className="dashboard-no-data-title">No data</div>
            <div className="dashboard-no-data-description">
                There is no available data for your selected {props.context}. Please select another.
            </div>
            <div className="dashboard-component-button-container company-overview">
                <div className="dashboard-component-button" onClick={props.edit}>
                    <img src={edit} alt="Edit Component"/>
                </div>
                <div className="dashboard-component-button" onClick={() => props.delete(props.id)}>
                    <img src={del} alt="Delete Component"/>
                </div>
            </div>
        </div>
    )
}

export default NoData