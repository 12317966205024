import BackgroundRings from "../components/BackgroundRings";
import {useEffect, useState} from "react";
import RadioButton from "../components/RadioButton";
import arrow from "../assets/article-arrow.svg"


const PerillResources = () => {

    const radioButtons = [
        {"id":1, "text": "ALL RESOURCES"},
        {"id":2, "text": "GENERIC"},
        {"id":3, "text": "TEAM LEADERS"},
        {"id":4, "text": "TEAM COACHES"},
    ]

    const teamCoachArticles = [
        {"id":1, "type":3, "title":"Beyond Systemic Thinking in Coaching and Team Coaching -", "description":"An Introduction " +
                "to systemic thinking and the evolution of complex, adaptive systems thinking and then ultimately the " +
                "PERILL model & a diagnostic."},
        {"id":2, "type":3, "title":"Working with the CASP Questionnaire -", "description":"A short overview to the 3 " +
                "ways PERILL can be used - and interview template, working model & a diagnostic."},
        {"id":3, "type":3, "title":"PERILL in Action - A Brief Guide -", "description":"An overview of the different " +
                "ways PERILL can be used in various phases of a team coaching engagement using PERILL."},
        {"id":4, "type":3, "title":"A Typical Team Coaching Project Timeline -", "description":"You may find this " +
                "typical timeline useful as a guide to team coach engagement."},
        {"id":5, "type":3, "title":"Complex Adaptive Systems PERILL (CASP) in Action: The Dream Team -",
            "description":"This completely fictional case study aims to show a good example of a team coaching " +
                "engagement whilst using the PERILL model."},
        {"id":6, "type":3, "title":"A guide to using the PERILL APP -", "description":"Containing a wealth of useful " +
                "information and frequently asked questions."}
    ]

    const [filter, setFilter] = useState(1)
    const [showAll, setShowAll] = useState(true)
    const [showGeneric, setShowGeneric] = useState(false)
    const [showLeaders, setShowLeaders] = useState(false)
    const [showCoaches, setShowCoaches] = useState(false)

    const handleArticle = () => {

    }

    const handleFilter = (id) => {
        if (!id) return;
        setFilter(id)
    }

    useEffect(() => {
        if (!filter) return;
        if (filter === "1") {
            setShowAll(true)
            setShowGeneric(false)
            setShowLeaders(false)
            setShowCoaches(false)
        } else if (filter === "2") {
            setShowAll(false)
            setShowGeneric(true)
            setShowLeaders(false)
            setShowCoaches(false)
        } else if (filter === "3") {
            setShowAll(false)
            setShowGeneric(false)
            setShowLeaders(true)
            setShowCoaches(false)
        } else if (filter === "4") {
            setShowAll(false)
            setShowGeneric(false)
            setShowLeaders(false)
            setShowCoaches(true)
        }
    },[filter])

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant',
        })
    }, []);

    return (
        <>
            <div className="perill-resources-page-container">
                <div className="background-rings-container">
                    <BackgroundRings top={-370} right={-143} globe={true}/>
                </div>
                <div className="perill-resources-page-title">PERILL Resources</div>
                <div className="perill-resources-page-subtext">
                    These resources have been created to support you as a Team Leader or as a trained Team Coach in
                    using <b className="bold">PERILL Diagnostics for Teams</b>.
                </div>
                <div className="perill-resources-filter-container">
                    <div className="perill-resources-filter-title">
                        Filter
                    </div>
                    <div className="perill-resources-filter-menu-container">
                        {filter ?
                            <>
                                <RadioButton button={radioButtons[0]}
                                             selected={showAll}
                                             result={handleFilter.bind(this)}/>
                                <RadioButton button={radioButtons[1]}
                                             selected={showGeneric}
                                             result={handleFilter.bind(this)}/>
                                <RadioButton button={radioButtons[2]}
                                             selected={showLeaders}
                                             result={handleFilter.bind(this)}/>
                                <RadioButton button={radioButtons[3]}
                                             selected={showCoaches}
                                             result={handleFilter.bind(this)}/>
                            </>
                            :null}
                    </div>
                </div>
                {showAll || showGeneric ?
                    <div className="perill-resources-section-container">
                        <div className="perill-resources-sub-heading">Generic Resources:</div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row large">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        The PERILL LITE Team Diagnostic Questions -
                                    </b>
                                    A copy of the 30 PERILL LITE questions (the shorter version)
                                </div>
                                <div className="perill-resources-article-link-container link">
                                    <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                    <div className="perill-resources-article-link-img-container">
                                        <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row large">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        The PERILL Team Diagnostic Questions -
                                    </b>
                                    A copy of the 120 PERILL questions (the standard version).
                                </div>
                                <div className="perill-resources-article-link-container link">
                                    <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                    <div className="perill-resources-article-link-img-container">
                                        <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row sub-title">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title blue">
                                        Sample Reports -
                                    </b>
                                    These are generated within the system and are of the fictional Sweet Dreams Chocolate Factory by way of example:
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row small">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        Lite Report - Detailed Analysis -
                                    </b>
                                    this report is your detailed report & not to be shared with the team.
                                </div>
                                <div className="perill-resources-article-link-container link" onClick={handleArticle}>
                                    <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                    <div className="perill-resources-article-link-img-container">
                                         <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row small">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        Lite Report - Team Meeting Presentation -
                                    </b>
                                    this report is for you to work through with the team.
                                </div>
                                <div className="perill-resources-article-link-container link" onClick={handleArticle}>
                                    <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                    <div className="perill-resources-article-link-img-container">
                                        <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row small">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        Lite Report - Team & Stakeholder Overview  -
                                    </b>
                                    this report compares the team position with that of its team leader(s) &
                                    stakeholder(s) for a wider 360 degree view.
                                </div>
                                <div className="perill-resources-article-link-container link" onClick={handleArticle}>
                                    <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                    <div className="perill-resources-article-link-img-container">
                                        <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row small">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        Lite Report - Engagement Report -
                                    </b>
                                    this report compares the two different reports over time to measure effectiveness of
                                    your engagement.
                                </div>
                                <div className="perill-resources-article-link-container link" onClick={handleArticle}>
                                    <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                    <div className="perill-resources-article-link-img-container">
                                        <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row large top">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        PERILL Interview with David & Dumi Magadlela -
                                    </b>
                                    Dumi interviews David about the PERILL model.
                                </div>
                                <div className="perill-resources-article-link-container link" onClick={handleArticle}>
                                    <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                    <div className="perill-resources-article-link-img-container">
                                        <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row large">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        The PERILL Model -
                                    </b>
                                    An overview of the PERILL model and its 6 interacting elements or pillars.
                                </div>
                                <div className="perill-resources-article-link-container link" onClick={handleArticle}>
                                    <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                    <div className="perill-resources-article-link-img-container">
                                        <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row large block">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        Coaching The Team At Work (2nd Edition) by David Clutterbuck engagement -
                                    </b>
                                    This book outlines the PERILL theory and the various processes a team and team coach go
                                    through during an engagememtn. It's avaliable as
                                    a <b className="bold-blue">paperback version</b> or as
                                    a <b className="bold-blue">Kindle Edition</b> via Amazon and all good book sellers.
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row large">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        A guide to using the PERILL APP -
                                    </b>
                                    Containing a wealth of useful information and FAQ’s.
                                </div>
                                <div className="perill-resources-article-link-container link" onClick={handleArticle}>
                                    <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                    <div className="perill-resources-article-link-img-container">
                                        <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :null}
                {showAll || showLeaders ?
                    <div className="perill-resources-section-container">
                        <div className="perill-resources-sub-heading">Specific Resources for the Team Leader:</div>
                        <div className="perill-resources-sub-heading-description">
                            These resources are designed for Team Leaders & others who are not trained as team coaches yet
                            are originating the diagnostic surveys and essentially facilitating / coaching their team
                            through the process.
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row large">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        Using the PERILL Lite report with teams: A guide for team leaders -
                                    </b>
                                    Whilst predominantly written for Team Leaders to use and debrief the diagnostic reports,
                                    it provides some helpful pointers and messages for coaches.
                                </div>
                                <div className="perill-resources-article-link-container link" onClick={handleArticle}>
                                    <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                    <div className="perill-resources-article-link-img-container">
                                        <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row large">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        Video for the Team / Stakeholders -
                                    </b>
                                    Short video on introducing PERILL to your team or stakeholders.
                                </div>
                                <div className="perill-resources-article-link-container link" onClick={handleArticle}>
                                    <div className="perill-resources-article-link-text">WATCH VIDEO</div>
                                    <div className="perill-resources-article-link-img-container">
                                        <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="perill-resouces-articles-container">
                            <div className="perill-resources-article-row large">
                                <div className="perill-resources-article-description">
                                    <b className="perill-resources-article-title">
                                        Timeline & Resources explanation -
                                    </b>
                                    An overview of the timeline and processes that a Team Leader led PERILL diagnostic
                                    process might look like.
                                </div>
                                <div className="perill-resources-article-link-container link" onClick={handleArticle}>
                                    <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                    <div className="perill-resources-article-link-img-container">
                                        <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :null}
                {showAll || showCoaches ?
                    <div className="perill-resources-section-container">
                        <div className="perill-resources-sub-heading">Specific Resources for the Team Coaches:</div>
                        <div className="perill-resources-sub-heading-description">
                            These resources are designed for Team Coaches - they are not designed to teach ypu how to be a
                            Team Coach (although we offer training courses for that) but instead focus on the process of
                            using PERILL diagnostic surveys with teams.
                        </div>
                        {teamCoachArticles && teamCoachArticles.map((article, idx) => {
                           return (
                               <div className="perill-resouces-articles-container" key={idx}>
                                   <div className="perill-resources-article-row large">
                                       <div className="perill-resources-article-description">
                                           <b className="perill-resources-article-title">{article.title}</b>{article.description}
                                       </div>
                                       <div className="perill-resources-article-link-container link" onClick={handleArticle}>
                                           <div className="perill-resources-article-link-text">READ ARTICLE</div>
                                           <div className="perill-resources-article-link-img-container">
                                               <img className="perill-resources-article-link-img-container" src={arrow} alt="arrow"/>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           )
                        })}
                    </div>
                    :null}
            </div>
            {showAll ?
                <div className="home-page-background-circle" style={{bottom: -1800, right: -150}}/>
                :null}
            {showGeneric ?
                <div className="home-page-background-circle" style={{bottom: -600, right: -150}}/>
                :null}
            {showLeaders ?
                <div className="home-page-background-circle" style={{bottom: -500, right: -150}}/>
                :null}
            {showCoaches ?
                <div className="home-page-background-circle" style={{bottom: -500, right: -150}}/>
                :null}

    </>
    )
}

export default PerillResources