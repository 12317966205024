import Rating from "../Rating";


const RoiRatingRow = (props) => {
    const number = props.number;
    const idx = props.idx;
    const question = props.question;
    const handleScoreChange = props.handleScoreChange;
    const end = props.end;

    return(
        <div className="roi-row-container">
            <div className="roi-row-data-container">
                <div className="roi-row-int">
                    {number}
                </div>
                <div className="roi-row-question">
                    {question}
                </div>
                <div className="roi-survey-rating-row">
                    <Rating initialRating={0} maxRating={10} type={1} color="#F6CD45" hideText={true}
                            onRatingChange={handleScoreChange.bind(this)} id={idx}/>
                </div>
            </div>
            <div className="roi-row-empty-container">
                <div className="roi-row-int"/>
                {!end ?
                    <div className="roi-underline-container"/>
                    :null}
            </div>
        </div>
    )
}

export default RoiRatingRow