import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import kapi from "../api/kratos";


const SettingsFlowHandler = () => {

    const navigate = useNavigate()
    useEffect(() => {
        handleFlow()
    }, []);

    const handleFlow = () => {
        const params = new URLSearchParams(window.location.search);
        const flowId = params.get('flow');
        if(flowId) {
            let response = kapi.get_settings(flowId);
            response.then(response => {
                if (response.data.ui?.messages[0]?.id === 1060001) {
                    navigate('/reset-password?flow=' + flowId)
                } else if (response.data.ui?.nodes?.find(node => node.attributes.name === 'totp_code')?.messages?.length > 0) {
                    navigate('/settings?security=true')
                } else {
                    navigate('/settings')
                }
            }).catch(err => {
                //handles redirect with 2fa password reset
                if (err?.response?.data?.error?.id === "session_aal2_required") {
                    window.location.assign(err.response.data.redirect_browser_to)
                } else {
                    navigate('/settings')
                }
            })
        } else {
            navigate('/settings')
        }
    }

    return <></>
}


export default SettingsFlowHandler