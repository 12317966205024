import teams_api from "../../api/protected/teams";
import TeamForm from "./TeamForm";
import person_api from "../../api/protected/people";
import back_arrow from "../../assets/back_arrow.png";

const NewTeam = (props) => {

    const updateTeamsGrid = props.updateTeamsGrid
    const searchName = props.searchName

    const peopleSubmit = (teamPeople, team_id) => {
        if (teamPeople.length > 0 && team_id !== 0) {
            for (let i=0; i < teamPeople.length; i++) {
                let person = {
                    "name": teamPeople[i].name,
                    "role": teamPeople[i].role,
                    "email": teamPeople[i].email,
                    "team_id": team_id,
                    "company_id": teamPeople[i].company_id
                }
                let response = person_api.newPerson(person);
                response.then().catch(() => console.log(person))
            }
        }
    }

    const formSubmit = (body, teamPeople)=> {
        let response = teams_api.newTeam(body);
        response.then(response => {
            let team_id = response.data.team_id;
            peopleSubmit(teamPeople, team_id)
            updateTeamsGrid()
        }).catch((err) => console.log(err))
    }

    return (
        <>
            <div className="back-link link" onClick={() => updateTeamsGrid()}>
                <img src={back_arrow} alt={"Back Arrow"}/>
            </div>
            <div className="teams-page-header">
                <div className="teams-page-title">
                    New Team
                </div>
            </div>
            <TeamForm submit={formSubmit.bind(this)}
                      buttonText={"Save"}
                      isNew={true}
                      searchName={searchName}
                      companyId={props.companyId}
                      editor={true}
            />
        </>
    )
}

export default NewTeam;
