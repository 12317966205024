import {useEffect, useState} from "react";


const SummaryProgramme = (props) => {
    const data = props.programmes;
    const cardType = props.cardType
    const [programmes, setProgrammes] = useState([]);

    useEffect(() => {
        if (!data) return;
        if (cardType === undefined) return;
        setProgrammes(data)
    }, [data, cardType]);


    const getPillarColour = (t) => {
        let pillar_themes = [
            [
                "Alignment", "Clarity", "Enthusiasm & Contribution", "Enjoyment", "Support & Collaboration"
            ],
            [
                "Environmental Awareness", "Stakeholder & Customer Alignment", "Talent & Resource Acquisition",
                "Stakeholder & Customer Communication", "Trust & Integrity in Relationships"
            ],
            [
                "Skills & Expertise", "Respect & Trust", "Camaraderie & Cohesion", "Safe Communication",
                "Values Diversity & Inclusion"
            ],
            [
                "Appropriate Resourcing", "Team Agility", "Accountability", "System Efficacy", "Team Protection"
            ],
            [
                "Development Planning", "Learning Relevance", "Embedded Learning", "Developmental Orientation",
                "Reflective Learning"
            ],
            [
                "Vision & Context", "Collaborative & Empowering Leadership", "Interpersonal Leadership",
                "Leadership Courage", "Inspirational Leadership"
            ]
        ]
        let pillar_idx;

        for(let pillar in pillar_themes) {
            if(pillar_themes[pillar].includes(t)) {
                pillar_idx = pillar
                break;
            }
        }

        switch (pillar_idx) {
            case "0": return "pillar-blue";
            case "1": return "pillar-red";
            case "2": return "pillar-green";
            case "3": return "pillar-purple";
            case "4": return "pillar-pink";
            case "5": return "pillar-orange";
            default: return ""
        }
    }

    const parseExercises = (text) => {
        let exercises = [];
        let lines = text.trim().split('\n\n');
        for(let i=0;i<lines.length;i++){
            let line = lines[i]
            line = line.slice(line.indexOf(' ') + 1)
            exercises.push(line);
        }
        return exercises;
    }


    return (
        <>
            {programmes.map((programme, idx) => {
                return (
                    <div className="summary-tabular-container" key={idx}>
                        {idx === 0 ? <div className="summary-programme-title">Coaching Programme</div> : null}
                        <div className="summary-programme-details-container">
                            <div className="summary-programme-detail-container">
                                <div className="summary-programme-detail-text">Score</div>
                                <div className="summary-programme-detail-value">{Math.round(programme.score)}</div>
                            </div>
                            <div className="summary-programme-detail-divider"/>
                            <div className="summary-programme-detail-container">
                                <div className="summary-programme-detail-text">Variance</div>
                                <div className="summary-programme-detail-value">{Math.round(programme.variance)}</div>
                            </div>
                            <div className="summary-programme-detail-divider"/>
                            <div className="summary-programme-detail-container left-align">
                                <div className="summary-programme-detail-text">Theme</div>
                                <div className="summary-programme-detail-colour-container">
                                    <div className={`summary-programme-detail-colour-dot ${getPillarColour(programme.theme_name)}`}/>
                                    <div className="summary-programme-detail-colour-text">{programme.theme_name}</div>
                                </div>
                            </div>
                        </div>
                        <div className="summary-programme-subtitle">System Commentary</div>
                        <div className="summary-programme-text">{programme.commentary}</div>
                        <div className="summary-programme-subtitle">Suggested Coaching Exercises</div>
                        {programme.exercises && parseExercises(programme.exercises).map((exercise, idx) => {
                            return (
                                <div className="summary-programme-subtext" key={idx}>
                                    <span className="bold">{`${idx + 1}.`}</span> {exercise}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </>

    )
}

export default SummaryProgramme