import SettingsMenu from "../components/settings/SettingsMenu";
import {useEffect, useState} from "react";
import SettingsAccountDetails from "../components/settings/SettingsAccountDetails";
import SettingsPreferences from "../components/settings/SettingsPreferences";
import SettingsSecurity from "../components/settings/SettingsSecurity";
import SettingsCoCoaches from "../components/settings/SettingsCoCoaches"
import profile_api from "../api/protected/profile";
import {useSearchParams} from "react-router-dom";
import org_roles_api from "../api/organisationRoles";
const Settings = () => {
    const [activeSection, setActiveSection] = useState(1);
    const [profile, setProfile] = useState();
    const [profilePicture, setProfilePicture] = useState();
    const [coachingOrganisationLogo, setCoachingOrganisationLogo] = useState();
    const [organisationRoles, setOrganisationRoles] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const updateActive = (section) => {
        setActiveSection(section);
    }
    const GetProfile = () => {
        let response = profile_api.getProfile();
        response.then(response => {
            setProfile(response.data.profile);
        }).catch(err => {console.log(err)})
    }
    const GetProfilePicture = () => {
        let response = profile_api.getProfilePicture();
        response.then(response => {
            setProfilePicture(response.data.image);
        }).catch(err => {console.log(err)})
    }
    const GetCoachingLogo = () => {
        let response = profile_api.getCoachingOrganisationLogo();
        response.then(
            response => {
                setCoachingOrganisationLogo(response.data.image);
            }
        ).catch(err => {
            console.log(err);
        })
    }

    const getOrganisationRoles = () => {
        let response = org_roles_api.getOrganisationRoles();
        response.then(response => {
            setOrganisationRoles(response.data.roles)
        }).catch(err => {console.log(err)})
    }

    const loadData = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'instant'})
        GetProfile()
        GetProfilePicture()
        GetCoachingLogo()
        getOrganisationRoles()
    }

    useEffect(() => {
        if (searchParams.get('preferences') !== null) {
            setActiveSection(2)
            searchParams.delete('preferences')
            setSearchParams(searchParams)
        } else if (searchParams.get('coaching') !== null) {
            setActiveSection(3)
            searchParams.delete('coaching')
            setSearchParams(searchParams)
        } else if (searchParams.get('security') !== null) {
            setActiveSection(4)
            searchParams.delete('security')
            setSearchParams(searchParams)
        }

    }, []);

    useEffect(() => {
        loadData()
    }, []);

    return (
        <div className="settings-page-background-container">
            <div className="settings-page-container">
                <div className="settings-menu-container">
                    <SettingsMenu activeSection={activeSection} updateActive={updateActive.bind(this)}/>
                </div>
                <div className="section-content-container">
                    {activeSection === 1 ?
                        <SettingsAccountDetails profile={profile}
                                                profilePicture={profilePicture && profilePicture}
                                                coachingOrganisationLogo={coachingOrganisationLogo && coachingOrganisationLogo}
                                                reloadData={loadData.bind(this)}
                                                orgRoles={organisationRoles && organisationRoles} /> : null
                    }
                    {activeSection === 2 ?
                        <SettingsPreferences profile={profile} updateProfile={GetProfile.bind(this)}/> : null
                    }
                    {activeSection === 3 ?
                        <SettingsCoCoaches profile={profile} updateProfile={GetProfile.bind(this)}/> : null
                    }
                    {activeSection === 4 ?
                        <SettingsSecurity profile={profile} updateProfile={GetProfile.bind(this)}/> : null
                    }
                </div>

            </div>
        </div>
    )
}

export default Settings