import React from 'react';
import {useDroppable} from '@dnd-kit/core';

const Droppable = (props) => {
    const _id = props.id
    const _disabled = props.disabled
    const {isOver, setNodeRef} = useDroppable({
        id: _id,
        disabled: _disabled
    });

    const style = {
        opacity: isOver ? '50%' : undefined
    };

    return (
        <div ref={setNodeRef} style={style} className="droppable">
            {props.children}
        </div>
    );
}

export default Droppable