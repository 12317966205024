import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")


const getComponents = () => http.get('/dashboard');
const createComponent = (position_id, component_type, context_id=null, isDemo) => http.post('/dashboard', {
    "position_id": position_id,
    "component_type": component_type,
    "context_id": context_id,
    "demo": isDemo
});
const updateComponent = (position_id, context_id, demo) => http.put(`/dashboard/${position_id}`, {
    "context_id": context_id,
    "demo": demo
})
const deleteComponent = (position_id) => http.delete(`/dashboard/${position_id}`)
const getTodoList = () => http.get('/todo_list');
const newTodo = (body) => http.post('/todo_list', body);
const editTodo = (id, body) => http.put(`/todo_list/${id}`, body);
const deleteTodo = (id) => http.delete(`/todo_list/${id}`);
const getSurveyOverview = (id) => http.get(`/survey_overview/${id}`);
const getSurveyCompletion = (id) => http.get(`/survey_completion/${id}`);
const getLineChartData = (id) => http.get(`/survey_linechart/${id}`);
const getBarChartData = (id) => http.get(`/survey_barchart/${id}`);
const getCoachROI = (id) => http.get(`/coach_roi/${id}`);
const getCompanySelections = () => http.get('/company_selections');
const getTeamSelections = () => http.get('/team_selections');
const getSurveySelections = () => http.get('/survey_selections');
const moveCardPosition = (old_pos, new_pos) => http.put(`/dashboard_card_position/${old_pos}`, {"new_position": new_pos})
const getDemoSurveyOverview = (id) => http.get(`/demo_survey_overview/${id}`);
const getDemoCoachROI = (id) => http.get(`/demo_coach_roi/${id}`);
const getDemoSurveyCompletion = (id) => http.get(`/demo_survey_completion/${id}`);
const getDemoBarChartData = (id) => http.get(`/demo_survey_barchart/${id}`)
const getDemoLineChartData = (id) => http.get(`/demo_survey_linechart/${id}`)


export default {
    getComponents,
    createComponent,
    updateComponent,
    deleteComponent,
    getTodoList,
    newTodo,
    editTodo,
    deleteTodo,
    getSurveyOverview,
    getSurveyCompletion,
    getLineChartData,
    getBarChartData,
    getCoachROI,
    getCompanySelections,
    getTeamSelections,
    getSurveySelections,
    moveCardPosition,
    getDemoSurveyOverview,
    getDemoCoachROI,
    getDemoSurveyCompletion,
    getDemoBarChartData,
    getDemoLineChartData
}