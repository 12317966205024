import {useEffect, useState} from "react";
import ROIFilterTeamRow from "./ROIFilterTeamRow";


const ROIFilterCompanyRow = (props) => {

    const [company, setCompany] = useState(null)
    const [rowExpanded, setRowExpanded] = useState(false)
    const [selectState, setSelectState] = useState(0)

    useEffect(() => {
        setCompany(props.company)
    }, [props.company]);

    useEffect(() => {
        if(!company) return;
        let all_included = true
        let found_included = false
        for(const team of company.teams) {
            if(props.selected.map(x => x.team_id).includes(team.team_id)) {
                found_included = true
            } else {
                all_included = false
            }
        }

        if(all_included) {
            setSelectState(2)
        } else if(found_included) {
            setSelectState(1)
        } else {
            setSelectState(0)
        }

    }, [props.selected, company])

    const toggleRow = (ev) => {
        if(!ev) return;
        if(ev.target.id === "toggle") {
            setRowExpanded(!rowExpanded)
        }
    }

    const handleBoxClick = (ev) => {
        if(!ev) return;
        if(selectState !== 1) {
            props.update(company.teams)
        } else {
            let temp_list = []
            for(const team of company.teams) {
                if(!(props.selected.map(x => x.team_id).includes(team.team_id))) {
                    temp_list.push(team)
                }
            }
            props.update(temp_list)
        }
    }

    return (
        <div className="roi-filter-company-row-container">
            <div className="roi-filter-company-row" onClick={toggleRow}>
                {rowExpanded ?
                    <div className="filter-open-arrow" id="toggle"/> :
                    <div className="filter-closed-arrow" id="toggle"/>
                }
                <div className={`roi-filter-selected-box ${selectState > 0 ? "filled": ""}`} onClick={handleBoxClick}>
                    {selectState === 2 ? "✓" : selectState === 1 ? "-" : ""}
                </div>
                <div className="filter-colour-circle" id="toggle" style={{backgroundColor: company && company.colour}}/>
                <div className="filter-company-name regular" id="toggle">{company && company.name}</div>
            </div>
            <div className="roi-filter-company-teams-container">
                {rowExpanded ?
                    company && company.teams.map((team, idx) => {
                        return <ROIFilterTeamRow team={team} key={idx} colour={company && company.colour}
                                                 selected={props.selected} update={props.update.bind(this)}/>
                    }) : null
                }
            </div>
        </div>
    )
}


export default ROIFilterCompanyRow