const RadioButton = (props) => {
    const button = props.button;
    const result = props.result;

    const handleButtonClick = (ev) => {
        if (!ev) return;
        result(ev.currentTarget.id);
    }

    return (
        <div className="perill-resources-filter-radio-container">
            <div className="perill-resources-filter-radio-text">
                {button && button.text}
            </div>
            <div className="perill-resources-filter-radio-button-container link" id={button && button.id}
                 onClick={handleButtonClick}>
                <div className={props.selected ? "perill-resources-filter-radio selected" : "perill-resources-filter-radio"}/>
            </div>
        </div>
    )
}

export default RadioButton