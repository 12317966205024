import back_arrow from "../assets/backarrow.svg";
import strengths from "../assets/strengths.svg";
import weaknesses from "../assets/weaknesses.svg"
import team_variance from "../assets/variance.svg"
import stakeholder_variance from "../assets/stakeholder_variance.svg"
import strengths_light from "../assets/strengths_light.svg"
import weaknesses_light from "../assets/weaknesses_light.svg"
import team_variance_light from "../assets/variance_light.svg"
import stakeholder_variance_light from "../assets/stakeholder_variance_light.svg"
import SummaryGraphical from "../components/SummaryGraphical";
import SummaryTabular from "../components/SummaryTabular";
import SummaryProgramme from "../components/SummaryProgramme";
import {useEffect, useState} from "react";
import CoachCommentaryContainer from "../components/CoachCommentaryContainer";


const SummaryTop5s = (props) => {

    const surveyData = props.surveyData;

    const cardType = props.cardType;
    const [cardData, setCardData] = useState();

    useEffect(() => {
        if (!surveyData || cardType === undefined) return;
        setCardData(surveyData.summary_aggregates[cardType]);
        console.log(surveyData)
    }, [surveyData, cardType]);


    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    const handleBackClick = () => {
        props.back(cardType);
    }

    const getTitle = () => {
        switch (cardType) {
            case 0: return "Strengths";
            case 1: return "Weaknesses";
            case 2: return "Team Variance";
            case 3: return "Stakeholder Variance";
            default: return "Summary";
        }
    }

    return (
        <>
            <div className="link" onClick={handleBackClick}>
                <img src={back_arrow} alt={"Back Arrow"}/>
            </div>
            <div className="summary-title">{getTitle()}</div>
            <div className="summary-top-5-container">
                <div className="summary-top-5-controls-container">
                    <div className={`summary-top-5-control-button link ${cardType === 0 ? "active" : ""}`}
                         onClick={() => props.switchMenu(0)}>
                        <img src={cardType === 0 ? strengths_light : strengths} alt="Strengths"/>
                    </div>
                    <div className={`summary-top-5-control-button link ${cardType === 1 ? "active" : ""}`}
                         onClick={() => props.switchMenu(1)}>
                        <img src={cardType === 1 ? weaknesses_light : weaknesses} alt="Weaknesses"/>
                    </div>
                    <div className={`summary-top-5-control-button link ${cardType === 2 ? "active" : ""}`}
                         onClick={() => props.switchMenu(2)}>
                        <img src={cardType === 2 ? team_variance_light : team_variance} alt="Team Varaince"/>
                    </div>
                    <div className={`summary-top-5-control-button link ${cardType === 3 ? "active" : ""}`}
                         onClick={() => props.switchMenu(3)}>
                        <img src={cardType === 3 ? stakeholder_variance_light : stakeholder_variance} alt="Stakeholder Variance"/>
                    </div>
                </div>
                <div className="summary-top-5-body-container">
                    <SummaryGraphical cardData={cardData}/>
                    <CoachCommentaryContainer context_id={props.surveyID} section_id="top-5-graphical"/>
                    <SummaryTabular cardData={cardData} surveyData={surveyData} showQuestions={false}/>
                    <CoachCommentaryContainer context_id={props.surveyID} section_id="top-5-tabular"/>
                    <SummaryProgramme programmes={surveyData.summary_aggregates[cardType].coaching_programme}
                                      cardType={cardType}/>
                    <CoachCommentaryContainer context_id={props.surveyID} section_id="top-5-programme"/>
                </div>
            </div>
        </>
    )
}

export default SummaryTop5s