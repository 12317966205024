



const SurveyPointPopup = (props) => {

    const point = props.point
    const points = ["Initial", "Midpoint", "Endpoint", "Post Review"]


    const handleProceed = (ev) => {
        if(!ev) return;
        props.acceptNewSurvey(ev)
    }

    const handleCancel = (ev) => {
        if(!ev) return;
        props.cancelNewSurvey(ev)
    }

    const getPrefix = (prev=false) => {
        if(prev) {
            if(point === 1 || point === 3) {
                return "a"
            } else {
                return "an"
            }
        }
        if(point === 1 || point === 3) {
            return "an"
        } else {
            return "a"
        }
    }

    const getSurveyName = (prev=false) => {
        if(!point) return;
        if(prev) {
            if(point < 2) return;
            return points[point-2]
        }
        return points[point-1]
    }


    return (
        <>
            <div className="page-mask" onClick={() => props.closePopup}/>
            <div className="survey-point-warning-popup-container">
                <div className="survey-point-warning-popup-message regular">
                    You are selecting {getPrefix()} {getSurveyName()} survey without
                    completing {getPrefix(true)} {getSurveyName(true)} survey first. Are you sure?
                </div>
                <div className="survey-point-warning-popup-controls-container">
                    <div className="survey-point-warning-popup-button bold" onClick={handleCancel}>CANCEL</div>
                    <div className="survey-point-warning-popup-button bold fill" onClick={handleProceed}>CONFIRM</div>
                </div>
            </div>
        </>
    )
}

export default SurveyPointPopup