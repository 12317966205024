import kapi from "./kratos";

const getCurrentUserLocal = () => {
    let user = localStorage.getItem('user')
    return user && JSON.parse(user);
}

const hasSession = async () => {
    try {
        const response = await kapi.check_session();
        if (response.data.active) {
            let user = {
                "fullName": response.data.identity.traits.name.first + " " + response.data.identity.traits.name.last,
                "email": response.data.identity.traits.email
            }
            localStorage.setItem("user", JSON.stringify(user));
            return true;
        }
        else {
            localStorage.removeItem("user");
        }
    }
    catch {
        localStorage.removeItem("user");
    }
    return false;
}


const isAuthenticated = () => {
    // check for token
    const user = getCurrentUserLocal();

    if (user && user.email) {
        return true;
    } else {
        return false;
    }
}

const protectedNavigate = (url, fallbackUrl) => {
    const session = hasSession();
    session.then((session) => {
        if (session) {
            window.location.assign(url);
        }
        else {
            window.location.assign(fallbackUrl);
        }
    })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getCurrentUserLocal,
    hasSession,
    isAuthenticated,
    protectedNavigate
};
