const GenericDialog = (props) => {
    const title = props.title;
    const text = props.text;
    const buttons = props.buttons;
    const result = props.result;
    const wide = props.wide && props.wide

    const handleClick = (ev) => {
        if (!ev) return;
        result(ev.currentTarget.id);
    }

    return (
        <>
            <div className="page-mask"/>
            <div className={wide && wide ? "dialog-container wide" : "dialog-container"}>
                <div className="dialog-header-container">
                    <div className="dialog-header">{title}</div>
                    <div className="dialog-text">{text}</div>
                    {props.buttons ?
                        <div className="dialog-footer-container">
                            {buttons && buttons.map((button, idx) => {
                                return (
                                    <div className="dialog-button link" onClick={handleClick} id={button.id} key={idx}>
                                        <div className="dialog-button-text">{button.text}</div>
                                    </div>
                                )
                            })}
                        </div> : null
                    }
                </div>
            </div>
        </>
    )
}

export default GenericDialog