import MainHeader from "../containers/MainHeader";

const Forbidden = () => {
    return (
        <>
            <MainHeader />
            <div className="forbidden-page-container">
                <div className="forbidden-page-title">
                    Forbidden
                </div>
                <div className="forbidden-page-content">
                    You have attempted to access a page that you do not have authority to use.
                </div>
            </div>
        </>
    )
}

export default Forbidden