import {useEffect, useState} from "react";


const ComponentScrollbar = (props) => {

    const [scrollPosition, setScrollPosition] = useState(0)
    const [isScrolling, setIsScrolling] = useState(false)
    const [totalHeight, setTotalHeight] = useState(0)

    //Prevent chain stops useEffect chaining when correcting scrollbar position via props.sync
    const [preventChain, setPreventChain] = useState(false)

    useEffect(() => {
        let track_height = document.getElementById(`scroll-track${props.id}`).offsetHeight
        let thumb_height = document.getElementById(`scroll-thumb${props.id}`).offsetHeight
        setTotalHeight(track_height - thumb_height)
    }, []);

    useEffect(() => {
        if(!totalHeight) return;
        if(preventChain) {
            setPreventChain(false)
            return;
        }
        let percentage =  scrollPosition / totalHeight
        let element = document.getElementById(props.scrollContainer)
        let children = element.children
        let total = 0
        for (const child of children) {
            total = total + child.offsetHeight
        }
        element.scrollTop = Math.round((total - element.offsetHeight) * percentage)
    }, [scrollPosition]);

    useEffect(() => {
        if(!props.sync) return;
        setPreventChain(true)
        let element = document.getElementById(props.scrollContainer)
        let children = element.children
        let total = 0
        for (const child of children) {
            total = total + child.offsetHeight
        }
        if(props.sync === 1) {
            setScrollPosition(0)
        } else {
            setScrollPosition((props.sync / (total - element.offsetHeight)) * totalHeight)
        }
    }, [props.sync])

    const handleTrackClick = (ev) => {
        if(!ev || ev.target.id !== `scroll-track${props.id}`) return;
        let rect = ev.target.getBoundingClientRect();
        let click_y = ev.clientY - rect.top;
        let thumb_height = ev.target.children[0].offsetHeight

        // Prevents thumb going above track
        if(click_y - (thumb_height / 2) < 0) {
            setScrollPosition(0)
        }
        // Prevents thumb going below track
        else if(rect.height - click_y < (thumb_height / 2)) {
            setScrollPosition(rect.height - thumb_height)
        }
        // Centres thumb around cursor
        else {
            setScrollPosition(click_y - (thumb_height / 2))
        }
    }

    const handleThumbMove = (ev) => {
        if(!ev || ev.target.id !== `scroll-thumb${props.id}` || !isScrolling) return;
        let parent = ev.target.parentElement
        let thumb_height = ev.target.offsetHeight
        let rect = parent.getBoundingClientRect()
        let new_pos = ev.clientY - rect.top

        // Prevents thumb going above track
        if(new_pos - (thumb_height / 2) < 0) {
            setScrollPosition(0)
        }
        // Prevents thumb going below track
        else if(rect.height - new_pos < (thumb_height / 2)) {
            setScrollPosition(rect.height - thumb_height)
        }
        // Centres thumb around cursor
        else {
            setScrollPosition(new_pos - (thumb_height / 2))
        }
    }
    const handleThumbUp = (ev) => {
        if(!ev || ev.target.id !== `scroll-thumb${props.id}`) return;
        setIsScrolling(false)
    }
    const handleThumbDown = (ev) => {
        if(!ev || ev.target.id !== `scroll-thumb${props.id}`) return;
        setIsScrolling(true)
    }


    return (
        <div className="todo-component-scrollbar" id={`scroll-track${props.id}`} onClick={handleTrackClick}>
            <div className="todo-component-scrollbar-thumb" id={`scroll-thumb${props.id}`}
                 onMouseDown={handleThumbDown}
                 onMouseUp={handleThumbUp}
                 onMouseLeave={handleThumbUp}
                 onMouseMove={handleThumbMove}
                 style={{top: scrollPosition}}
            />
        </div>
    )
}

export default ComponentScrollbar