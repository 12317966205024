import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")


const getPeople = (id) => http.get("/people/" + id);
const newPerson = (body) => http.post("/people", body);
const updatePeople = (team_id, company_id, people, deletedPeople) => http.put("/people", {
    "team_id": team_id,
    "company_id": company_id,
    "people": people,
    "deleted_people": deletedPeople
});
const deletePerson = (id) => http.delete("/people/" + id)
const getDemoPeople = (id) => http.get(`/demo_people/${id}`);

export default {
    getPeople,
    newPerson,
    updatePeople,
    deletePerson,
    getDemoPeople
}