import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import kapi from "../api/kratos";
import org_roles_api from "../api/organisationRoles"
import text_utils from "../utils/TextUtils";
import Slider from "../components/Slider";
import countries from "i18n-iso-countries";
import avatar from "../assets/ccmi-graphic-logo-color.svg";
import eye from "../assets/eye.png";
import check from "../assets/green-tick-circle.svg";
import cross from "../assets/red_cross.png";
import Select from "react-select"
import styles from "../utils/ReactSelectStyles"


const Register = () => {
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [token, setToken] = useState("");
    const [organisation, setOrganisation] = useState("");
    const [orgRole, setOrgRole] = useState();
    const [orgRoleSelected, setOrgRoleSelected] = useState(); //needed for react-select, not used in kratos form
    const [country, setCountry] = useState("");
    const [countrySelected, setCountrySelected] = useState(); //needed for react-select, not used in kratos form
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [flowResponse, setFlowResponse] = useState();
    const [countryCodes, setCountryCodes] = useState([]);
    const [organisationRoles, setOrganisationRoles] = useState();
    const [processingConsent, setProcessingConsent] = useState(false);
    const [marketingConsent, setMarketingConsent] = useState(false);
    const [showPasswordGuide, setShowPasswordGuide] = useState(false);
    const navigate = useNavigate();

    const [showEmailReminder, setShowEmailReminder] = useState(false);
    const [showFirstnameReminder, setShowFirstnameReminder] = useState(false);
    const [showLastnameReminder, setShowLastnameReminder] = useState(false);
    const [showTokenReminder, setShowTokenReminder] = useState(false);
    const [showOrganisationReminder, setShowOrganisationReminder] = useState(false);
    const [showOrgRoleReminder, setShowOrgRoleReminder] = useState(false);
    const [showCountryReminder, setShowCountryReminder] = useState(false);
    const [showConsentReminder, setShowConsentReminder] = useState(false);


    const InitRegistration = () => {
        // clear any existing user in storage
        localStorage.removeItem("user");

        let response = kapi.init_registration();
        response.then(response => {
            setFlowResponse(response.data);
        }, function() {
            navigate("/logout");
        })
    }

    const PageLoad = () => {
        const params = new URLSearchParams(window.location.search);
        const flowId = params.get('flow');

        if (!flowId) {
            InitRegistration();
        }
        else {
            let response = kapi.get_registration(flowId);
            response.then(response => {
                setFlowResponse(response.data);
                setFirstName(response.data.ui.nodes.find(n => n.attributes.name === 'traits.name.first').attributes.value)
                setLastName(response.data.ui.nodes.find(n => n.attributes.name === 'traits.name.last').attributes.value)
                setEmail(response.data.ui.nodes.find(n => n.attributes.name === 'traits.email').attributes.value)
                setOrganisation(response.data.ui.nodes.find(n => n.attributes.name === 'traits.organisation').attributes.value)
                setCountry(response.data.ui.nodes.find(n => n.attributes.name === 'traits.country').attributes.value)
                setOrgRole(response.data.ui.nodes.find(n => n.attributes.name === 'traits.organisation_role').attributes.value)
                setToken(response.data.ui.nodes.find(n => n.attributes.name === 'traits.token').attributes.value)
            }).catch(err => {
                console.log("Unable to retrieve registration: " + err);
            });
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleShowConfirmedPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const getCountries = () => {
        let items = [];
        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        for (const [key, value] of Object.entries(countries.getNames("en", {select: "official"}))){
            let item = {};
            item.code = key;
            item.name = value;
            items.push(item);
        }
        setCountryCodes(items);
        setCountry("GB")
    }

    const getOrganisationRoles = () => {
        let response = org_roles_api.getOrganisationRoles();
        response.then(response => {
            setOrganisationRoles(response.data.roles)
        }).catch(err => {console.log(err)})
    }

    const updateProcessingConsent = (status) => {
        setProcessingConsent(status);
    }

    const updateMarketingConsent = (status) => {
        setMarketingConsent(status);
    }

    const checkAllPasswordConditions = () => {
        if (!password) return false
        return (text_utils.containsSpecial(password) &&
            text_utils.containsDigit(password) &&
            text_utils.containsCapital(password) &&
            password.length >= 8 &&
            password === confirmPassword)
    }

    const handleFalseButtonClick = (ev) => {
        if(!ev) return;
        ev.preventDefault()
        setShowEmailReminder(!email);
        setShowFirstnameReminder(!firstName);
        setShowLastnameReminder(!lastName);
        setShowTokenReminder(!token);
        setShowOrganisationReminder(!organisation);
        setShowOrgRoleReminder(!orgRole)
        setShowCountryReminder(!country);
        setShowConsentReminder(!processingConsent);
    }

    useEffect(() => {
        getCountries();
        getOrganisationRoles();
        PageLoad()
    }, []);

    useEffect(() => {
        if (country !== undefined) return;
        setCountry("GB")
    },[country])

return (
        <>
            <div className="register-page-container">
                <div className="sign-up-container">
                    <div className="register-welcome-container col">
                        <img src={avatar} alt="Logo"/>
                        <div className="register-welcome-title">
                            Create account
                        </div>
                        <div className="register-welcome-subtitle">
                            Sign up to get started
                        </div>
                        <div className="register-login-container">
                            Already have an account?
                            <Link to="/login">Sign in here</Link>
                        </div>
                        <div className="register-privacy-container flex">
                            <a href="https://clutterbuck-cmi.com/">CCMI </a>
                            <a href="privacy">Privacy policy</a>
                        </div>
                        <div className="register-faq">
                            <Link to="/faq" >FAQ/Self Help Guide</Link>
                        </div>
                    </div>
                    <div className="register-form-container col">
                        <form name="register-form" action={flowResponse && flowResponse.ui.action} method="POST">
                            <div>
                                {flowResponse && flowResponse?.ui?.messages?.map((msg, idx) => {
                                    return (
                                        <div className="messages" key={idx}>{msg.text}</div>
                                    )
                                })}
                            </div>
                            <input name="csrf_token" type="hidden"
                                   value={flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'csrf_token')?.attributes.value}/>
                            <div className="field-input">
                                <div className="label">Email</div>
                                <div className="field-input-entry">
                                    <input className="login-input" name="traits.email" type="email" value={email}
                                           onChange={(ev) => setEmail(ev.target.value)}
                                           required/>
                                    {showEmailReminder ? <div className="messages">This field is required.</div> : null}
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">First name</div>
                                    <input name="traits.name.first"
                                           type="text" value={firstName}
                                           onChange={(ev) => setFirstName(ev.target.value)}
                                           required/>
                                    {showFirstnameReminder ? <div className="messages">This field is required.</div> : null}
                                    <div>
                                        {flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'traits.name.first')?.messages.map((msg, idx) => {
                                            return (
                                                <div className="messages" key={idx}>{msg.text}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Last name</div>
                                    <input name="traits.name.last"
                                           type="text" value={lastName}
                                           onChange={(ev) => setLastName(ev.target.value)}
                                           required/>
                                    {showLastnameReminder ? <div className="messages">This field is required.</div> : null}
                                    <div>
                                        {flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'traits.name.last')?.messages.map((msg, idx) => {
                                            return (
                                                <div className="messages" key={idx}>{msg.text}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Password</div>
                                    <input name="password"
                                           type={showPassword ? "text" : "password"} value={password}
                                           onChange={(ev) => {
                                               setPassword(ev.target.value)
                                               setShowPasswordGuide(true)
                                           }}
                                           onFocus={() => {
                                               setShowPasswordGuide(true)
                                           }}
                                           required/>
                                    <img src={eye} onClick={toggleShowPassword} alt="eye logo"
                                         className="eye link"/>
                                    <div>
                                        {flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'password')?.messages.map((msg, idx) => {
                                            return (
                                                <div className="messages" key={idx}>{msg.text}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Confirm password</div>
                                    <input name="confirm-password"
                                           type={showConfirmPassword ? "text" : "password"} value={confirmPassword}
                                           onChange={(ev) => setConfirmPassword(ev.target.value)}
                                           required/>
                                    <img src={eye} onClick={toggleShowConfirmedPassword} alt="eye logo"
                                         className="eye link"/>
                                </div>
                            </div>
                            {showPasswordGuide && showPasswordGuide ?
                                <div className="password-guide-container">
                                    <div className="password-guide-section">
                                        <div className="password-card-image-container">
                                            <img src={password && password.length >= 8 ? check : cross} alt='password-check'/>
                                        </div>
                                        Minimum number of characters: 8
                                    </div>
                                    <div className="password-guide-section">
                                        <div className="password-card-image-container">
                                            <img src={text_utils.containsCapital(password) ? check : cross} alt='password-check'/>
                                        </div>
                                        Include at least 1 capital character
                                    </div>
                                    <div className="password-guide-section">
                                        <div className="password-card-image-container">
                                            <img src={text_utils.containsDigit(password) ? check : cross} alt='password-check'/>
                                        </div>
                                        Include at least 1 numeric character
                                    </div>
                                    <div className="password-guide-section">
                                        <div className="password-card-image-container">
                                            <img src={text_utils.containsSpecial(password) ? check : cross} alt='password-check'/>
                                        </div>
                                        Include at least 1 special character
                                    </div>
                                    <div className="password-guide-section">
                                        <div className="password-card-image-container">
                                            <img src={password === confirmPassword ? check : cross} alt='password-check'/>
                                        </div>
                                        Passwords must match
                                    </div>
                                </div>
                                : null
                            }
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Access token</div>
                                    <input name="traits.token"
                                           type="text" value={token}
                                           onChange={(ev) => setToken(ev.target.value)}
                                           required/>
                                    {showTokenReminder ? <div className="messages">This field is required.</div> : null}
                                    <div>
                                        {flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'traits.token')?.messages.map((msg, idx) => {
                                            return (
                                                <div className="messages" key={idx}>{msg.text}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="register-form-divider"></div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Name of Organisation</div>
                                    <input name="traits.organisation"
                                           type="text" value={organisation}
                                           onChange={(ev) => setOrganisation(ev.target.value)}
                                           required/>
                                    {showOrganisationReminder ? <div className="messages">This field is required.</div> : null}
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Organisation Role</div>
                                    <Select name="traits.organisation_role" options={organisationRoles}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            value={orgRoleSelected}
                                            placeholder=""
                                            onChange={ev => {
                                                setOrgRole(ev.id)
                                                setOrgRoleSelected(ev)
                                            }}
                                            styles={styles.registerStyles}
                                            required={true}
                                    />
                                    {showOrgRoleReminder ? <div className="messages">This field is required.</div> : null}
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <div className="label">Country you are based in</div>
                                    <Select name="traits.country" options={countryCodes}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.code}
                                            value={countrySelected}
                                            placeholder=""
                                            onChange={ev => {
                                                setCountry(ev.code);
                                                setCountrySelected(ev)
                                            }}
                                            styles={styles.registerStyles}
                                            required={true}
                                    />
                                    {showCountryReminder ? <div className="messages">This field is required.</div> : null}
                                </div>
                            </div>
                            <div className="field-input-consent">
                                <div className="field-input-entry">
                                    <input name="traits.processing" type="hidden" value={processingConsent ? 1 : 0}/>
                                    <Slider state={processingConsent} update={updateProcessingConsent.bind(this)}
                                            text="I have read and accepted the CCMI Terms of Use and Privacy Policy"
                                            elem={<>I have read and accepted the CCMI <Link to='/terms-of-use' className="register-span">Terms of Use</Link> and <Link to='/privacy' className="register-span">Privacy Policy</Link></>}
                                    />
                                    {showConsentReminder ? <div className="messages">This field is required.</div> : null}
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <input name="traits.marketing" type="hidden" value={marketingConsent ? 1 : 0}/>
                                    <Slider state={marketingConsent} update={updateMarketingConsent.bind(this)}
                                            text="Get the latest news, blogs and thought leadership on Coaching,
                                            Mentoring and Leadership from Professor David Clutterbuck and the
                                            CCMI Network delivered directly to you via email every few months."/>
                                </div>
                            </div>
                            <div className="field-input">
                                {!email || !firstName || !lastName || !token || !organisation || !country ||
                                !orgRole || !checkAllPasswordConditions() || !processingConsent ?
                                    <button className="register-button invalid link" name="method" type="button"
                                            value="password" onClick={handleFalseButtonClick}
                                    >
                                        Register
                                    </button>
                                    :
                                    <button className="register-button link" name="method" type="submit"
                                            value="password"
                                            disabled={!email || !firstName || !lastName || !token || !organisation ||
                                                !country || !orgRole || !checkAllPasswordConditions() ||
                                                !processingConsent}>
                                        Register
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
)
}

export default Register