const survey_types = [
    { "id": 1, "name": "Full" },
    { "id": 2, "name": "Lite" }
]

const point_types = [
    { "id": 1, "name": "Initial" },
    { "id": 2, "name": "Mid" },
    { "id": 3, "name": "End" },
    { "id": 4, "name": "Post review" }
]

export default {
    survey_types,
    point_types
}