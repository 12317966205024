import {useEffect, useState} from "react";
import dashboard_api from "../../api/protected/dashboard"
import add from "../../assets/add.svg"
import del from "../../assets/delete-bin.svg"
import edit from "../../assets/edit-pencil.svg"
import checkmark from "../../assets/plan-feature-tick.png"
import info from "../../assets/info-icon.png"
import {useNavigate} from "react-router-dom";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const TodoComponent = (props) => {

    const navigate = useNavigate()

    const [todoList, setTodoList] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [activeEditId, setActiveEditId] = useState(0)
    const [activeEditTitle, setActiveEditTitle] = useState("")
    const [activeEditDescription, setActiveEditDescription] = useState("")
    const [activeEditDate, setActiveEditDate] = useState(new Date())
    const [activeEditChecked, setActiveEditChecked] = useState(false)


    useEffect(() => {
        getTodoList()
    }, []);

    const getTodoList = () => {
        let response = dashboard_api.getTodoList();
        response.then(response => {
            setTodoList(response.data)
        }).catch()
    }

    const newTodo = (body) => {
        let response = dashboard_api.newTodo(body);
        response.then(() => {
            getTodoList()
        }).catch(err => console.log(err))
    }

    const editTodo = (id, body) => {
        let response = dashboard_api.editTodo(id, body);
        response.then(() => {
            getTodoList()
        }).catch()
    }

    const deleteTodo = (id) => {
        let response = dashboard_api.deleteTodo(id);
        response.then(() => {
            getTodoList()
        })
    }

    const formatDate = (ts) => {
        let date = new Date(ts * 1000)
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }

    const getCurrentDate = () => {
        return new Date().getTime() / 1000
    }

    const handleNewTaskClick = (ev) => {
        if(!ev) return;
        setEditMode(true)
    }

    const handleLinkNavigate = (url, ev) => {
        if(!url || ev.target.id === "controls") return;
        navigate(`/${url}`, {replace: true})
    }

    const editTodoRow = (ev, item) => {
        if(!ev || !item) return;
        let date_string = new Date(item.date * 1000)
        setActiveEditTitle(item.title)
        setActiveEditDescription(item.description)
        setActiveEditDate(date_string)
        setActiveEditId(item.id)
        setActiveEditChecked(item.checked)
        setEditMode(true);
    }

    const resetEditState = () => {
        setActiveEditTitle("")
        setActiveEditDescription("")
        setActiveEditDate("")
        setActiveEditId(0)
        setActiveEditChecked(false)
    }

    const editTitle = (ev) => {
        if(!ev) return;
        setActiveEditTitle(ev.target.value)
    }

    const editDescription = (ev) => {
        if(!ev) return;
        setActiveEditDescription(ev.target.value)
    }

    const editDate = (ev) => {
        if(!ev) return;
        setActiveEditDate(ev)
    }

    const cancelEdit = (ev) => {
        if(!ev) return;
        resetEditState()
        setEditMode(false);
    }

    const saveEdit = (ev) => {
        if(!ev) return;
        let date;
        if(activeEditDate) {
            date = new Date(activeEditDate).getTime() / 1000
        } else {
            date = null
        }

        let body = {
            "title": activeEditTitle,
            "description": activeEditDescription,
            "date": date,
            "checked": activeEditChecked
        }

        if(activeEditId) {
            editTodo(activeEditId, body);
        } else {
            newTodo(body);
        }
        resetEditState()
        setEditMode(false)
    }

    const toggleCheckmark = (item) => {
        editTodo(item.id, {
            "title": item.title,
            "description": item.description,
            "date": item.date,
            "checked": !item.checked
        })
    }


    return (
        <div className="dashboard-component">
            {!editMode ?
                <>
                    <div className="todo-component-title-row">
                        To do list
                        <div className="todo-component-info-button">
                            <img src={info} alt="info"/>
                            <div className="todo-component-info-hover-container">
                                Use this to to do list to create reminders for following up incomplete surveys,
                                creating reports, and creating later  surveys (such as the mid or end point surveys)
                                <div className="todo-component-info-hover-triangle-outer">
                                    <div className="todo-component-info-hover-triangle-inner"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="todo-component-table-container">
                        {todoList.map((item, idx) => {
                            let overdue = item.date <= getCurrentDate()
                            return (
                                <div className="todo-component-table-row-container" key={idx}>
                                    <div className={`todo-component-checkbox ${overdue && !item.checked ? "overdue" : ""}`}
                                         onClick={() => toggleCheckmark(item)}>
                                        {item.checked ? <img src={checkmark} alt="Done"/> : null}
                                    </div>
                                    <div className={`todo-component-table-row ${item.link ? "clickable" : ""}`}
                                         onClick={ev => handleLinkNavigate(item.link, ev)}>
                                        <div className="todo-component-table-row-title">{item.title}</div>
                                        <div className="todo-component-date-controls-container" id="controls">
                                            <div className={`todo-component-table-row-date ${overdue ? "overdue" : ""}`}
                                                 id="controls">
                                                {item.date ? formatDate(item.date) : ""}
                                            </div>
                                            <div className="todo-controls-button" onClick={ev => editTodoRow(ev, item)}
                                                 id="controls">
                                                <img src={edit} alt="Edit Task" id="controls"/>
                                            </div>
                                            <div className="todo-controls-button" onClick={ev => editTodoRow(ev, item)}
                                                 id="controls">
                                                <img src={edit} alt="Edit Date" id="controls"/>
                                            </div>
                                            <div className="todo-controls-button" onClick={() => deleteTodo(item.id)}
                                                 id="controls">
                                                <img src={del} alt="Delete Task" id="controls"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="dashboard-component-button-container">
                        <div className="dashboard-component-button" onClick={handleNewTaskClick}>
                            <img src={add} alt="New Task"/>
                        </div>
                        <div className="dashboard-component-button" onClick={() => props.manage(props.id)}>
                            <img src={del} alt="Delete Component"/>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="todo-component-title-row">{activeEditId ? "Edit Task" : "Add Task"}</div>
                    <div className="todo-component-edit-container">
                        <div className="todo-component-edit-upper-container">
                            <div className="todo-component-title-container">
                                <div className="todo-component-textbox-label">Task Title</div>
                                <div className="todo-component-textbox title">
                                    <input type="text" value={activeEditTitle} onChange={editTitle}/>
                                </div>
                            </div>
                            <div className="todo-component-title-textbox-container">
                                <div className="todo-component-textbox-label">Detailed Description</div>
                                <div className="todo-component-textbox textarea">
                                    <textarea value={activeEditDescription} onChange={editDescription}/>
                                </div>
                            </div>
                        </div>
                        <div className="todo-component-edit-lower-container">
                            <div className="todo-component-title-date-container">
                                <div className="todo-component-textbox-label">Add Due Date: (optional)</div>
                                <div className="todo-component-textbox datepicker">
                                    <DayPicker selected={activeEditDate} onDayClick={editDate}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="todo-component-edit-controls-container">
                        <div className="todo-component-button" onClick={saveEdit}>Save</div>
                        <div className="todo-component-button" onClick={cancelEdit}>Cancel</div>
                    </div>
                </>
            }
        </div>
    )
}


export default TodoComponent