import search from "../assets/search.png";
import add from "../assets/add.png";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import teams_api from "../api/protected/teams"
import TeamsGridContainer from "../containers/TeamsGridContainer";
import NewTeam from "../components/teams/NewTeam";
import {useNavigate} from "react-router-dom";
import Slider from "../components/Slider";


const Teams = () => {
    const navigate = useNavigate()
    const [showHidden, setShowHidden] = useState(false);

    const [teams, setTeams] = useState([]);
    const [userTeams, setUserTeams] = useState([]);
    const [demoTeams, setDemoTeams] = useState([]);
    const [showNewTeam, setShowNewTeam] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')

    const [params] = useSearchParams();
    const [searchName, setSearchName] = useState("")
    const [companyId, setCompanyId] = useState(0)

    useEffect(() => {
        getTeams()
    }, [])

    useEffect(() => {
        if (params.get('name') !== null) {
            let _name = params.get('name')
            setSearchName(_name)
            setShowNewTeam(true)
        }
        if (params.get('company') !== null) {
            let _id = params.get('company')
            setCompanyId(_id)
        }
    }, []);

    useEffect(() => {
        setTeams([...demoTeams, ...userTeams])
    }, [demoTeams, userTeams]);

    const handleEditTeam = (team) => {
        if (!team) return;
        if(team.demo) {
            navigate(`/teams/${team.id}?demo=true`)
        } else {
            navigate(`/teams/${team.id}`)
        }
    }

    const hideNewTeamForm = () => {
        getTeams()
        setSearchName("")
        setShowNewTeam(false)
    }

    const getTeams = () => {
        let response = teams_api.getTeams();
        response.then(response => {
            setUserTeams(response.data.teams);
        })
        response = teams_api.getDemoTeams();
        response.then(response => {
            setDemoTeams(response.data.teams)
        })
    }

    const updateToggleHidden = () => {
        setShowHidden(!showHidden);
    }

    return (
        <div className="teams-page-background-container">
            <div className="teams-page-container">
                {showNewTeam ?
                    <NewTeam updateTeamsGrid={hideNewTeamForm.bind(this)} searchName={searchName} companyId={companyId}/>
                    :
                    <>
                        <div className="teams-page-header">
                            <div className="teams-page-title">Teams</div>
                            <div className="teams-page-search-container">
                                <input type="text" placeholder="Search" value={searchTerm}
                                       onChange={(ev) => setSearchTerm(ev.target.value)}
                                       onKeyUp={(ev) => {
                                           if (ev.key === 'Enter') navigate("/search?term=" + searchTerm)
                                       }}
                                />
                                <img src={search} alt="search icon" className="link"/>
                            </div>
                        </div>
                        <div className="page-actions-container">
                            <div className="page-toggle-hidden">
                                <Slider update={updateToggleHidden.bind(this)} state={showHidden}/>
                            </div>
                            <div className="toggle-hidden-text">Show hidden</div>
                            <div className="teams-page-add-container">
                                <img src={add} alt="add icon" className="link"
                                     onClick={() => setShowNewTeam(true)}/>
                            </div>
                        </div>
                        <TeamsGridContainer teams={teams} showHidden={showHidden} handleClick={handleEditTeam.bind(this)}/>
                    </>
                }
            </div>
        </div>
    )
}

export default Teams
