import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import stripe_api from "../api/protected/memberships";
import GenericDialog from "../components/GenericDialog";
import {tr} from "date-fns/locale";
import AccreditationPopup from "../components/AccreditationPopup";

const StripeSuccess = () => {

    const navigate = useNavigate();
    const [parameters] = useSearchParams();

    const [error, setError] = useState("")
    const [processing, setProcessing] = useState(true)
    const [showAccreditationPopup, setShowAccreditationPopup] = useState(false)

    const okButton = [{"text":"OK", "id": "1"}];

    useEffect(() => {
        let checkout_id = parameters.get("session_id")
        if (!checkout_id) {
            setProcessing(false)
            setError("We were unable to locate your transaction. Please contact {contact_email}")
        }
        CheckCheckoutSessionOutcome(checkout_id);
    }, []);

    const CheckCheckoutSessionOutcome = (checkout_session_id) => {
        if (!checkout_session_id) return;
        let response = stripe_api.CheckCheckoutOutcome(checkout_session_id)
        response.then(
            response => {
                if (response.data?.status === "success") {
                    setProcessing(false)
                    setShowAccreditationPopup(true)
                }
                else if (response.data?.status === "retry") {
                    setTimeout(() => CheckCheckoutSessionOutcome(checkout_session_id), 5000)
                }
                else {
                    setProcessing(false)
                    setError("There was an issue processing your transaction. \n" +
                        "Please contact {contact_email} with the message: \n" + response.data?.code)
                }
            }
        ).catch((err) => {
            setError("We were unable to locate your transaction. " +
                "Please contact {contact_email} with the information " + err.response.status)
        })
    }

    const CloseErrorPopup = () => {
        setError("")
        navigate("/dashboard")
    }

    return (
        <>
            <div className="stripe-success-page-container">
                {processing ?
                    <div className="stripe-success-processing-container">
                        <div className="stripe-success-loading-wheel">
                            <div className="loading-wheel-section" /> <div className="loading-wheel-section" />
                            <div className="loading-wheel-section" /> <div className="loading-wheel-section" />
                            <div className="loading-wheel-section" /> <div className="loading-wheel-section" />
                            <div className="loading-wheel-section" /> <div className="loading-wheel-section" />
                            <div className="loading-wheel-section" /> <div className="loading-wheel-section" />
                            <div className="loading-wheel-section" /> <div className="loading-wheel-section" />
                        </div>
                        <div className="stripe-success-processing-message">
                            Please do not leave this page whilst we process the transaction
                        </div>
                    </div>
                    : null }

            </div>
            {error && error ?
                <GenericDialog title={"Error"} text={error} buttons={okButton} result={CloseErrorPopup.bind(this)} />
                : null }
            {showAccreditationPopup && showAccreditationPopup ?
                <AccreditationPopup />
            : null}
        </>
    )
}

export default StripeSuccess