import info from "../assets/info-icon.png"
import {useEffect, useState} from "react";

const PercentageWheel = (props) => {

    const [customClass, setCustomClass] = useState("")

    useEffect(() => {
        if(props.customClass) setCustomClass(props.customClass)
    }, []);

    const CompletionBar = () => {
        let _completion = Math.min(props.completion, 100)
        let radius = 98
        let halfWidth = 115
        let circumference = radius * 2 * Math.PI
        let offset = circumference - _completion / 100 * circumference;

        let linecap_rotation = 360 * (_completion / 100)

        return (
            <svg className="progress-ring-bar">
                <circle className="progress-ring__circle" strokeWidth="14" stroke={`#${props.colour}`} fill="transparent"
                        r={radius} cx={halfWidth} cy={halfWidth} strokeDasharray={`${circumference} ${circumference}`}
                        strokeDashoffset={offset} strokeLinecap="round"
                />
                {_completion > 0 ?
                    <circle className="progress-ring__linecap" strokeWidth="5px" stroke={`#${props.colour}`} fill="white"
                            r={12.5} cx={halfWidth} cy={halfWidth-radius} transform={`rotate(${linecap_rotation})`}
                    />
                    : null
                }
            </svg>
        )
    }


    return (
        <div className={`percentage-wheel-container ${customClass}`}>
            <div className="percentage-wheel-info-button" onClick={props.handleInfoClick}>
                <img src={info} alt="Info"/>
            </div>
            <CompletionBar/>
            <div className="percentage-wheel-outer-ring" style={{boxShadow: `0 0 15px #${props.colour}`}}>
                <div className="percentage-wheel-outer-disc">
                    <div className="percentage-wheel-inner-ring">
                        <div className="percentage-wheel-inner-disc">
                            <div className="percentage-wheel-content">
                                <div className="percentage-wheel-value">{`${props.completion}%`}</div>
                                <div className="percentage-wheel-text">{props.text}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PercentageWheel