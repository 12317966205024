import types from "../../assets/constants/types";
import green_plus from "../../assets/green_plus.png";
import {useNavigate} from "react-router-dom";

const TeamSurveyCard = (props) => {
    const survey = props.survey;
    const editor = props.editor;
    const teamId = props.teamId
    const companyId = props.companyId
    const navigate = useNavigate();

    const pointColors = [
        {backgroundColor: "#FAF5FF", border: "1px solid #773DBD"},
        {backgroundColor: "#F4FBFF", border: "1px solid #0082CA"},
        {backgroundColor: "#FFFAF5", border: "1px solid #F08019"},
        {backgroundColor: "#FFF4FB", border: "1px solid #F478C4"},
    ]

    const handleAddSurvey = (point) => {
        switch (point){
            case 1:
                localStorage.setItem("new_survey","1");
                break;
            case 2:
                localStorage.setItem("new_survey","2");
                break;
            case 3:
                localStorage.setItem("new_survey","3");
                break;
            case 4:
                localStorage.setItem("new_survey","4");
                break;
        }
        localStorage.setItem("company", companyId);
        localStorage.setItem("team", teamId);
        navigate(`/surveys?company=${companyId}&team=${teamId}&type=${point}`);
    }

    return (
        <div className="survey-card-container">
            <div className="survey-card-header">{types.point_types[parseInt(survey.point-1)].name}</div>
            <div className="survey-card-rows" style={pointColors[survey.point-1]}>
                <div className="survey-card-row">
                    <div className="status col">Created</div>
                    <div className="value col">{survey.created}</div>
                </div>
                <div className="survey-card-row">
                    <div className="status col">Sent</div>
                    <div className="value col">{survey.sent}</div>
                </div>
                <div className="survey-card-row">
                    <div className="status col">Completed</div>
                    <div className="value col">{survey.completed}</div>
                </div>
            </div>
            {editor ?
                <div className="survey-card-add-container link" onClick={() => handleAddSurvey(survey.point)}>
                    <img src={green_plus} alt={"Add"} />
                </div>
                :null}
        </div>
    )
}

export default TeamSurveyCard