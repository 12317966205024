import BackgroundRings from "../components/BackgroundRings";
import arrow from "../assets/right-arrow.svg"
import unlock_image from "../assets/perill-unlock-image.png"
import one_step_image from "../assets/one-step-image.png"
import {useEffect} from "react";
import {Link} from "react-router-dom";


const PerillModel = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, []);

    return (
        <div className="learn-more-page-container">
            <BackgroundRings top={-370} right={-143}/>
            <div className="learn-more-page-title">What is the PERILL model?</div>
            <div className="learn-more-page-subtext">
                Professor David Clutterbuck has spent over 45 years exploring and creating best practice in HR and
                organisational management and, in recent years, the factors that make high performing teams. In 2015,
                Facebook (now META) commissioned David to conduct research across the organisation to try to identify
                this elusive x factor. Those conclusions went on to become the PERILL model.
            </div>
            <div className="perill-model-flow-container">
                <div className="perill-model-flow-row">
                    <div className="perill-model-flow-text-box starter">
                        <div className="perill-model-starter-box-title">Six Key Factors</div>
                        <div className="perill-model-starter-box-text">
                            The PERILL model identifies six key factors that interact to drive or hinder team
                            performance and provides a framework for exposing and understanding those factors within
                            the complex, ever adapting environment they work within.
                        </div>
                    </div>
                    <div className="perill-model-chain-arrow">
                        <img src={arrow} alt=""/>
                        <img src={arrow} alt=""/>
                        <img src={arrow} alt=""/>
                        <img src={arrow} alt=""/>
                    </div>
                    <div className="perill-model-letter-circle blue">P</div>
                    <div className="perill-model-dashed-line"/>
                    <div className="perill-model-flow-text-box blue">
                        <div className="perill-model-box-title">Purpose and Motivation</div>
                        <div className="perill-model-box-text">
                            We seek to explore at the individual, team and organisational level:
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                Clarity of strategic direction and reason for being.
                            </div>
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                The levels of motivation and energy derived from their purpose.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="perill-model-flow-row">
                    <div className="perill-model-flow-text-box red">
                        <div className="perill-model-box-title">External Systems and Processes</div>
                        <div className="perill-model-box-text">
                            We seek to understand:
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                The customer and stakeholder relationships, values and processes with the team.
                            </div>
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                The environmental context and the process for understanding markets, threats and
                                opportunities.
                            </div>
                        </div>
                    </div>
                    <div className="perill-model-dashed-line" style={{width: 54}}/>
                    <div className="perill-model-letter-circle red">E</div>
                    <div className="perill-model-dashed-connecting-line right-border">
                        <div className="perill-model-chain-arrow">
                            <img src={arrow} alt=""/>
                            <img src={arrow} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="perill-model-flow-row">
                    <div className="perill-model-dashed-connecting-line left-border">
                        <div className="perill-model-chain-arrow">
                            <img src={arrow} alt=""/>
                            <img src={arrow} alt=""/>
                        </div>
                    </div>
                    <div className="perill-model-letter-circle green">R</div>
                    <div className="perill-model-dashed-line"/>
                    <div className="perill-model-flow-text-box green">
                        <div className="perill-model-box-title">Relationships</div>
                        <div className="perill-model-box-text">
                            We seek to establish:
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                The levels of trust, respect, psychological safety, collaboration and inclusion within
                                the team.
                            </div>
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                Is the team appropriately skilled and staffed?
                            </div>
                        </div>
                    </div>
                </div>
                <div className="perill-model-flow-row">
                    <div className="perill-model-flow-text-box purple">
                        <div className="perill-model-box-title">Internal Systems and Processes</div>
                        <div className="perill-model-box-text">
                            We seek to clarify:
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                The functional work of the team (communication, accountability, autonomy and decision
                                making).
                            </div>
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                The agility of the team (responsiveness, innovation, reallocation of workloads etc).
                            </div>
                        </div>
                    </div>
                    <div className="perill-model-dashed-line" style={{width: 54}}/>
                    <div className="perill-model-letter-circle purple">I</div>
                    <div className="perill-model-dashed-connecting-line right-border">
                        <div className="perill-model-chain-arrow">
                            <img src={arrow} alt=""/>
                            <img src={arrow} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="perill-model-flow-row">
                    <div className="perill-model-dashed-connecting-line left-border">
                        <div className="perill-model-chain-arrow">
                            <img src={arrow} alt=""/>
                            <img src={arrow} alt=""/>
                        </div>
                    </div>
                    <div className="perill-model-letter-circle pink">L</div>
                    <div className="perill-model-dashed-line"/>
                    <div className="perill-model-flow-text-box pink">
                        <div className="perill-model-box-title">Learning</div>
                        <div className="perill-model-box-text">
                            We seek awareness of:
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                How the team enhances its skills, networking, reflection and learning.
                            </div>
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                How does the team manage resources, capacity and performance?
                            </div>
                        </div>
                    </div>
                </div>
                <div className="perill-model-flow-row">
                    <div className="perill-model-flow-text-box orange">
                        <div className="perill-model-box-title">Leadership</div>
                        <div className="perill-model-box-text">
                            We seek to uncover:
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                How the Leadership qualities and behaviours have a moderating influence on team
                                function.
                            </div>
                        </div>
                        <div className="perill-model-box-bullet-container">
                            <div className="perill-model-box-bullet"/>
                            <div className="perill-model-box-bullet-text">
                                What impact the Leadership has on the other 5 influencing  factors (in a positive or
                                negative way).
                            </div>
                        </div>
                    </div>
                    <div className="perill-model-dashed-line" style={{width: 54}}/>
                    <div className="perill-model-letter-circle orange">L</div>
                    <div className="perill-model-dashed-connecting-line right-border">
                        <div className="perill-model-chain-arrow">
                            <img src={arrow} alt=""/>
                            <img src={arrow} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="perill-model-unlock-container">
                <div className="home-page-background-circle" style={{top: -20, left: -215}}/>
                <div className="perill-model-unlock-text-container">
                    <div className="perill-model-unlock-text-title">Unlock the door to your teams future.</div>
                    <div className="perill-model-unlock-text">
                        <span className="bold">The dynamics of a team are dependent on both its internal dynamics and
                            how it is nested within the systems around it*.</span> PERILL provides a uniquely simple
                        ‘Complex Adaptive Systems’ lens
                        that lets you examine both the team within teams and systems within systems without complexity.
                        <div className="bold">(*Conclusions of the Facebook commission - 2015-2017)</div>
                    </div>
                    <div className="perill-model-unlock-text">
                         <span className="bold">The performance problem a team identifies is usually just a symptom -
                        the real issue often lies elsewhere.</span> The PERILL model simplifies the evaluation of the
                        complex, adaptive systems and allows you to draw out and address the root cause of issues
                        rather than just treat the symptoms.
                    </div>
                </div>
                <div className="perill-model-unlock-image-container">
                    <img src={unlock_image} alt="PERILL Model"/>
                </div>
            </div>
            <div className="perill-model-one-step-container">
                <div className="home-page-background-circle" style={{top: -60, left: 880}}/>
                <div className="perill-model-one-step-image-container">
                    <img src={one_step_image} alt="PERILL Model"/>
                </div>
                <div className="perill-model-one-step-text-container">
                    <div className="perill-model-one-step-text-title">Go one step further.</div>
                    <div className="perill-model-one-step-text">
                        The <b className="bold">PERILL Diagnostics for Teams</b> platform is designed to ease the process of managing the
                        diagnostic and discovery phase with teams through:
                    </div>
                    <div className="perill-model-box-bullet-container">
                        <div className="perill-model-box-bullet"/>
                        <div className="perill-model-box-bullet-text extended">
                            Digital, virtual management of the administrative process to send and manage diagnostic
                            responses.
                        </div>
                    </div>
                    <div className="perill-model-box-bullet-container">
                        <div className="perill-model-box-bullet"/>
                        <div className="perill-model-box-bullet-text extended">
                            Processing the results through the PERILL algorithms to help analyse the responses.
                        </div>
                    </div>
                    <div className="perill-model-box-bullet-container">
                        <div className="perill-model-box-bullet"/>
                        <div className="perill-model-box-bullet-text extended">
                            Breaking down the findings by theme within the PERILL framework to help the team focus in
                            on areas of high function and dysfunction.
                        </div>
                    </div>
                    <div className="perill-model-box-bullet-container">
                        <div className="perill-model-box-bullet"/>
                        <div className="perill-model-box-bullet-text extended">
                            Summarising the findings in an accessible report, with exercises designed to support the
                            team in improving their collective performance.
                        </div>
                    </div>
                    <Link to={"/perill-how-it-works"} className={"undecorated"}>
                        <div className="home-page-button filled-button">FIND OUT MORE</div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default PerillModel