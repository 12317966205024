import {useEffect, useState} from "react";


const ROIBarChart = (props) => {

    const [data, setData] = useState([])
    const [benchmark, setBenchmark] = useState([0, 0, 0, 0, 0, 0])
    const [benchmarkEnabled, setBenchmarkEnabled] = useState(false)

    useEffect(() => {
        setData(props.data)
    }, [props.data]);

    useEffect(() => {
        setBenchmark(props.benchmark)
    }, [props.benchmark]);

    const getPillarColour = (pillar_id) => {
        switch (pillar_id) {
            case 1: return "pillar-blue"
            case 2: return "pillar-red"
            case 3: return "pillar-green"
            case 4: return "pillar-purple"
            case 5: return "pillar-pink"
            case 6: return "pillar-orange"
        }
    }

    const getLiteralPillarColour = (pillar_id) => {
        switch (pillar_id) {
            case 1: return "#0082CA"
            case 2: return "#C11B20"
            case 3: return "#93D500"
            case 4: return "#773DBD"
            case 5: return "#F478C4"
            case 6: return "#F08019"
        }
    }

    const getLiteralPillarBorder = (pillar_id) => {
        switch (pillar_id) {
            case 1: return "#065683"
            case 2: return "#4B0F11"
            case 3: return "#227B17"
            case 4: return "#351B53"
            case 5: return "#8E5076"
            case 6: return "#794C22"
        }
    }

    const getBenchmarkPillarColour = (pillar_id) => {
        switch (pillar_id) {
            case 1: return "#9DDCFF"
            case 2: return "#FFA1A2"
            case 3: return "#E4FCAE"
            case 4: return "#D9BAFE"
            case 5: return "#FFD9F0"
            case 6: return "#FFDBB9"
        }
    }

    const getBenchmarkPillarBorder = (pillar_id) => {
        switch (pillar_id) {
            case 1: return "rgba(0, 130, 202, 0.49)"
            case 2: return "rgba(193, 27, 32, 0.24)"
            case 3: return "rgba(147, 213, 0, 0.21)"
            case 4: return "rgba(119, 61, 189, 0.18)"
            case 5: return "rgba(244, 120, 196, 0.27)"
            case 6: return "rgba(240, 128, 25, 0.38)"
        }
    }

    const getPillarName = (pillar_id) => {
        switch (pillar_id) {
            case 1: return "Purpose & Motivation"
            case 2: return "External Systems & Processes"
            case 3: return "Relationships"
            case 4: return "Internal Systems & Processes"
            case 5: return "Learning"
            case 6: return "Leadership"
        }
    }

    const normal_round = (value) => {
        if (!value) return;
        if (value - Math.floor(value) < 0.5) return Math.floor(value);
        return Math.ceil(value)
    }

    const getBarPositions = (pillar, idx) => {
        let sum = 0
        let total = 0
        for (const x of pillar.data) {
            sum += x;
            total += 1
        }
        let improvement
        if (total > 0) {
            improvement = normal_round(sum / total)
        } else {
            improvement = 0
        }

        let barHeight;
        let left;
        let right;
        let top;
        let bottom;

        if (improvement > 80) {
            barHeight = "400%"
        } else if (improvement < -20) {
            barHeight = "100%"
        } else {
            barHeight = `${Math.abs((5/6 * 500) * (improvement / 100))}px`
        }

        if (benchmarkEnabled) {
            left = "calc(50% - 42px)"
            right = "calc(50% - 2px)"
        } else {
            left = "calc(50% - 20px)"
            right = "calc(50% + 20px)"
        }

        if (improvement >= 0) {
            bottom = 0
            top = "unset"
        } else {
            top = "100%"
            bottom = "unset"
        }

        return {
            "height": barHeight,
            "left": left,
            "right": right,
            "top": top,
            "bottom": bottom,
            "colour": getLiteralPillarColour(idx + 1),
            "text": `${Math.round(improvement)}%`,
            "border_colour": getLiteralPillarBorder(idx + 1)
        }
    }

    const getBenchmarkPosition = (idx) => {
        let improvement = benchmark[idx]
        let barHeight;
        let left;
        let right;
        let bottom;
        let top;

        if (improvement > 80) {
            barHeight = "400%"
        } else if (improvement < -20) {
            barHeight = "100%"
        } else {
            barHeight = `${Math.abs((4/5 * 500) * (improvement / 100))}px`
        }

        left = "calc(50% + 2px)"
        right = "calc(50% + 42px)"

        if (improvement >= 0) {
            bottom = 0
            top = "unset"
        } else {
            top = "100%"
            bottom = "unset"
        }

        return {
            "height": barHeight,
            "left": left,
            "right": right,
            "top": top,
            "bottom": bottom,
            "colour": getBenchmarkPillarColour(idx + 1),
            "border_colour": getBenchmarkPillarBorder(idx + 1),
            "text": `${improvement}%`
        }
    }

    return (
        <div className="roi-graphical-container">
            <div className="roi-barchart-title-container">
                <div className="summary-tabular-title">Overall Percentage Change</div>
                <div className="roi-barchart-benchmark-switch">
                    <div className={`chart-controls-switch ${benchmarkEnabled ? "active" : "inactive"}`}
                         onClick={() => setBenchmarkEnabled(!benchmarkEnabled)}>
                        <div className="chart-controls-switch-centre"/>
                    </div>
                    <div className="roi-barchart-benchmark-switch-text">Add Benchmarking Data</div>
                </div>
            </div>
            <div className="roi-bar-chart-container">
                <div className="roi-barchart-table-left-spacer">
                    <div className="roi-graphical-spacer-segment no-border">80%</div>
                    <div className="roi-graphical-spacer-segment no-border">60%</div>
                    <div className="roi-graphical-spacer-segment no-border">40%</div>
                    <div className="roi-graphical-spacer-segment no-border">20%</div>
                    <div className="roi-graphical-spacer-segment no-border">0%</div>
                    <div className="roi-graphical-spacer-segment no-border">-20%</div>
                </div>
                {data && data.map((pillar, idx) => {
                    let bar = getBarPositions(pillar, idx)
                    let benchmark = getBenchmarkPosition(idx)
                    return (
                        <>
                            <div className="roi-bar-chart-table-column-container" key={idx}>
                                <div className={`roi-barchart-table-column-top ${idx === 0 ? "left-corner" : idx === 5 ? "right-corner" : ""}`}/>
                                <div className="roi-barchart-table-column-dark"/>
                                <div className="roi-barchart-table-column-mid"/>
                                <div className="roi-barchart-table-column-light">
                                    <div className="roi-barchart-bar" style={{
                                        height: bar.height, left: bar.left, right: bar.right, top: bar.top,
                                        bottom: bar.bottom, backgroundColor: bar.colour,
                                        border: `solid 1px ${bar.border_colour}`
                                    }}>
                                        {bar.text}
                                    </div>
                                    {benchmarkEnabled ?
                                        <div className="roi-barchart-bar benchmark" style={{
                                            height: benchmark.height, left: benchmark.left, right: benchmark.right,
                                            top: benchmark.top, bottom: benchmark.bottom,
                                            border: `solid 1px ${benchmark.border_colour}`,
                                            backgroundColor: benchmark.colour
                                        }}>
                                            {benchmark.text}
                                        </div> : null
                                    }

                                </div>
                                <div className="roi-barchart-table-column-bottom"/>
                                <div className="roi-barchart-table-column-colourarea">
                                    <div className="summary-graphical-table-column-text-container">
                                        <div className={`summary-graphical-table-column-pillar-colour ${getPillarColour(idx + 1)}`}/>
                                        <div className="roi-graphical-table-column-pillar-text">
                                            {getPillarName(idx + 1)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {idx !== 5 ?
                                <div className="summary-graphical-table-centre-spacer" key={`${idx} ${idx}`}>
                                    <div className="roi-graphical-spacer-segment no-border"/>
                                    <div className="roi-graphical-spacer-segment no-border"/>
                                    <div className="roi-graphical-spacer-segment no-border"/>
                                    <div className="roi-graphical-spacer-segment no-border"/>
                                    <div className="roi-graphical-spacer-segment no-border"/>
                                    <div className="roi-graphical-spacer-segment no-border"/>
                                </div> : null
                            }
                        </>
                    )
                })}
            </div>
        </div>
    )
}


export default ROIBarChart