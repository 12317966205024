import {useEffect, useState} from "react";

const RoiTextRow = (props) => {
    const number = props.number;
    const idx = props.idx;
    const question = props.question;
    const handleScoreChange = props.handleScoreChange;
    const data = props.data;
    const end = props.end;

    const [textLength, setTextLength] = useState("0")

    useEffect(() => {
        if (!data[idx]) {
            setTextLength("0")
            return;
        }
        let _length = data[idx].length;
        setTextLength(`${_length}`)
    }, [data]);

    return(
        <div className="roi-row-container">
            <div className="roi-row-data-container">
                <div className="roi-row-int">
                    {number}
                </div>
                <div className="roi-row-question">
                    {question}
                </div>
                <div className="roi-survey-textarea-container">
                                <textarea type="text" value={data[idx]} onChange={(ev)=>
                                    handleScoreChange(ev.target.value, idx)} id={"scrollbar1"}/>
                </div>
            </div>
            <div className="roi-row-empty-container">
                <div className="roi-row-int"/>
                <div className={!end ? "roi-underline-container" : "roi-underline-container end"}>
                    <div className="roi-text-counter">
                        {"(" + textLength + "/256)"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoiTextRow