import {useEffect, useState} from "react";
import profile_api from "../../api/protected/profile";
import Slider from "../Slider";
import {Link, useNavigate} from "react-router-dom";
import kapi from "../../api/kratos";

const SettingsSecurity = (props) => {
    const navigate = useNavigate()
    const updateProfile = props.updateProfile;
    const [mfa, setMfa] = useState(false);

    const [settingsFlow, setSettingsFlow] = useState(null);
    const [totp, setTotp] = useState("")

    const PageLoad = () => {
        const params = new URLSearchParams(window.location.search);
        const flowId = params.get('flow');

        if (!flowId) {
            init_settings();
        } else {
            let response = kapi.get_settings(flowId);
            response.then(response => {
                setSettingsFlow(response.data);
            }).catch();
        }
    }

    useEffect(() => {
        if (!props.profile) return;
        PageLoad()
        setMfa(props.profile.mfa_enabled);
    }, [props.profile]);

    const init_settings = () => {
        let response = kapi.init_settings();
        response.then(response => {
            setSettingsFlow(response.data)
        }).catch(err => console.log(err))
    }

    const updateMfa = (setting) => {
        setMfa(setting);
        let body = {"mfa_enabled": setting}
        let response = profile_api.updateMfa(body);
        response.then(() => updateProfile()).catch(err => {console.log(err)});
    }

    const handleChangePasswordClick = (ev) => {
        if(!ev) return;
        navigate('/reset-password', {replace: true})
    }

    return (
        <div className="settings-form-container">
            <div className="settings-form-title security">Security</div>
            <div>
                <div className="settings-page-subheading security">Two Factor Authentication</div>
                {settingsFlow && !(settingsFlow.ui.nodes.find(n => n.attributes.name === 'totp_unlink')) ?
                    <>
                        <div className="settings-2fa-container">
                            <div className="settings-2fa-title regular">Enable 2FA for your account</div>
                            <Slider update={updateMfa.bind(this)} state={mfa}/>
                        </div>

                    </> : null
                }
                {mfa ?
                    settingsFlow && settingsFlow.ui.nodes.find(n => n.attributes.name === 'totp_unlink') ?
                        <form action={settingsFlow && settingsFlow.ui.action} method="POST">
                            <input name="csrf_token" type="hidden" value={settingsFlow && settingsFlow.ui.nodes.find(n => n.attributes.name === 'csrf_token')?.attributes.value}/>
                            <div className="settings-mfa-form-guide">Unlink 2FA from your account</div>
                            <button className="settings-button small-button" name="totp_unlink" type="submit" value="true">Unlink 2FA</button>
                        </form>
                        :
                        <form action={settingsFlow && settingsFlow.ui.action} method="POST">
                            <input name="csrf_token" type="hidden" value={settingsFlow && settingsFlow.ui.nodes.find(n => n.attributes.name === 'csrf_token')?.attributes.value}/>
                            <div className="settings-mfa-form-guide">
                                To enable 2FA, scan the QR code with Google Authenticator, then enter your OTP below. If
                                you are unable to scan the QR code, you can use the setup key instead.
                            </div>
                            <img src={settingsFlow && settingsFlow.ui.nodes.find(n => n.attributes.id === 'totp_qr')?.attributes.src} className="settings-mfa-form-image"/>
                            <div className="bold">{settingsFlow && settingsFlow.ui.nodes.find(n => n.attributes.id === 'totp_secret_key')?.attributes.text.text}</div>
                            <div className="messages">
                                {settingsFlow && settingsFlow.ui.nodes.find(n => n.attributes.name === 'totp_code').messages.map((message, idx) => {
                                    return <div key={idx}>{message.text}</div>
                                })}
                            </div>
                            <div className="field-input-entry">
                                <input className="settings-mfa-totp-input settings-input" name="totp_code" value={totp}
                                       maxLength={6} onChange={ev => setTotp(ev.target.value.trim())}/>
                            </div>
                            <button className="settings-button small-button" name="method" type="submit" value="totp">Submit OTP</button>
                        </form>
                    : null
                }
                <div className="settings-security-border"/>
                <div className="settings-page-subheading">Change your password</div>
                <div className="field-input">
                    <div className="manage-subscription-button bold" onClick={handleChangePasswordClick}>
                        CHANGE PASSWORD
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsSecurity