import {useEffect, useState} from "react";
import GenericDialog from "../components/GenericDialog";
import role_types from "../assets/constants/roles";
import mailbox from "../assets/mailbox.svg";
import plane from "../assets/paper-plane.svg";
import envelope from "../assets/envelope-open.svg";
import link from "../assets/link.svg";


const SurveyParticipantsGridContainer = (props) => {
    const [people, setPeople] = useState();
    const [selectedId, setSelectedId] = useState();
    const [showInfoDialog, setShowInfoDialog] = useState(false);
    const [editor, setEditor] = useState(false);
    const buttons = [{"text":"OK", "id": "1"}, {"text":"Cancel", "id": "2"}];
    const updateSelected = props.updateSelected;
    const selectedPeople = props.selectedPeople;
    const newlySelected = props.newlySelected
    const updateNewlySelected = props.updateNewlySelected;
    const isNew = props.isNew;

    useEffect(() => {
        if(!props.editor) return;
        setEditor(props.editor)
    }, [props.editor])

    const togglePerson = (ev) => {
        if (!ev.target.id) return;

        // If the person is to be removed show the yes/no dialog otherwise add them
        if (isSelected(parseInt(ev.target.id))){
            setSelectedId(ev.target.id);
            setShowInfoDialog(true);
        } else {
            let selected = [...selectedPeople];
            let newly = [...newlySelected];
            let index = selected.findIndex(x => x.person_id === parseInt(ev.target.id))
            if (index < 0){
                let person = {}
                person.person_id = parseInt(ev.target.id);
                person.name = ev.target.name
                person.email = ev.target.value
                selected.push(person);
                newly.push(person);
            }
            updateSelected(selected);
            updateNewlySelected(newly);
        }
    }

    const removeSelected = () => {
        if (!selectedId) return;
        let selected = [...selectedPeople];
        let index = selected.findIndex(x => x.person_id === parseInt(selectedId))
        if (index > -1){
            // if it's in the array, remove it
            selected.splice(index,1);
        }
        updateSelected(selected);
        // check if in newlySelected array
        if (!newlySelected) return;
        let newly = [...newlySelected];
        let ind = newly.findIndex(x => x.person_id === parseInt(selectedId))
        if (index > -1){
            // if it's in the array, remove it
            newly.splice(ind,1);
        }
        updateNewlySelected(newly);
    }

    const handleInfoDialogResult = (res) => {
        if (res === "1"){
            removeSelected();
        }
        setShowInfoDialog(false);
    }

    const isSelected = (id) => {
        let result = false;
        for (const person of selectedPeople) {
            if (person.person_id === id) {
                result = true;
                break;
            }
        }
        return result;
    }

    useEffect(() => {
        // Populate people with data from those already associated with s survey
        if (!props.people || !selectedPeople || isNew) return;
        let p = [...props.people];
        for (const person of selectedPeople) {
            let index = p.findIndex(x => x.id === person.person_id)
            if(index === -1 ) continue;
            p[index].sent = person.sent ? person.sent : 0;
            p[index].delivered = person.delivered ? person.delivered : 0;
            p[index].opened = person.opened ? person.opened : 0;
            p[index].clicked = person.clicked ? person.clicked : 0;
            p[index].percentage_complete = person.percentage_complete ? person.percentage_complete : 0;
        }
        setPeople(p);
    }, [props.people, selectedPeople]);

    useEffect(() => {
        if(!isNew || !props.people) return;

        let selected_list = []
        for(const person of props.people) {
            let selected = {"person_id": person.id, "name": person.name, "email": person.email}
            selected_list.push(selected)
        }
        updateSelected(selected_list)
        updateNewlySelected(selected_list)
        setPeople(props.people)
    }, [props.people]);


    return (
        <>
            <div className="survey-participant-grid-container">
                <div className="survey-participant-grid-headers">
                    <div className="survey-participant-grid-include col">Inc</div>
                    <div className="survey-participant-grid-name col">Name</div>
                    <div className="survey-participant-grid-role col">Role</div>
                    <div className="survey-participant-grid-email col">Email</div>
                    <div className="survey-participant-grid-email-status col" title="The number of times an email
                    has been sent. Please note the value may be slightly delayed.">
                        {!isNew ? <img src={mailbox} alt="Mailbox icon"/> : null}
                    </div>
                    <div className="survey-participant-grid-email-status col" title="The number of times an email
                    has been delivered. Please note the value may be slightly delayed.">
                        {!isNew ? <img src={plane} alt="Mailbox icon"/> : null }
                    </div>
                    <div className="survey-participant-grid-email-status col" title="The number of times an email
                    has been opened. Please note the value may be slightly delayed.">
                        {!isNew ? <img src={envelope} alt="Mailbox icon"/> : null}
                    </div>
                    <div className="survey-participant-grid-email-status col" title="The number of times an email
                    has been clicked. Please note the value may be slightly delayed.">
                        {!isNew ? <img src={link} alt="Mailbox icon"/> : null}
                    </div>
                    <div className="survey-participant-grid-complete col">
                        {!isNew ? "Percent Complete"  : ""}
                    </div>
                </div>
                {people && people.map((person, idx) => {
                    return (
                        <div className="survey-participant-grid-row" key={idx}>
                            <div className="survey-participant-grid-include col" >
                                <input type="checkbox" checked={isSelected(person.id)} value={person.email}
                                       onChange={togglePerson} id={person.id} name={person.name} disabled={!isNew && !editor}/>
                            </div>
                            <div className="survey-participant-grid-name col">{person.name}</div>
                            <div className="survey-participant-grid-role col">{role_types.roles.find(x => x.id === person.role).name}</div>
                            <div className="survey-participant-grid-email col">{person.email}</div>
                            <div className="survey-participant-grid-email-status col">
                                {!isNew ? person.sent : ""}
                            </div>
                            <div className="survey-participant-grid-email-status col">
                                {!isNew ? person.delivered : ""}
                            </div>
                            <div className="survey-participant-grid-email-status col">
                                {!isNew ? person.opened : ""}
                            </div>
                            <div className="survey-participant-grid-email-status col">
                                {!isNew ? person.clicked : ""}
                            </div>
                            <div className="survey-participant-grid-complete col">
                                <div className="perc-complete-container">
                                    <div className="perc-complete-filler" style={{width:person.percentage_complete + "%"}}></div>
                                    <div className="perc-complete-text">
                                        {!isNew && person.percentage_complete >= 0 ? person.percentage_complete + "%" : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {showInfoDialog ?
                <GenericDialog title="Edit Survey Participants" text={"Removing this person from the participants " +
                    "list will remove them from the survey and delete any progress they have submitted thus " +
                    "far. The data will be removed when you save the survey."} buttons={buttons}
                               result={handleInfoDialogResult.bind(this)} /> : null }
        </>
    )
}

export default SurveyParticipantsGridContainer