import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")

const getReport = (id) => http.get("/pdf/" + id)

const postReport = (body) => http.post("/pdf", body);

export default {
    getReport,
    postReport
}