import {useEffect, useState} from "react";
import tick from "../assets/plan-feature-tick.png"
import cross from "../assets/red_cross.png"


const CoCoachInviteGrid = (props) => {

    const [invites, setInvites] = useState([])
    const handle = props.handle

    useEffect(() => {
        setInvites(props.invites)
    }, [props]);

    return (
        invites && invites.length ?
            <div className="settings-form-container co-coaches">
                <div className="settings-form-title">Invites</div>
                <div className="settings-invites-grid">
                    <div className="settings-co-coaches-row header">
                        <div className="settings-co-coaches-column email">Email</div>
                        <div className="settings-co-coaches-column expiry-date">Expiry Date</div>
                        <div className="settings-co-coaches-column controls"/>
                    </div>
                    {invites && invites.map((invite, idx) => {
                        let date = new Date(invite.expiry_date * 1000).toLocaleDateString()
                        return (
                            <div className="settings-co-coaches-row" key={idx}>
                                <div className="settings-co-coaches-column email">{invite.email}</div>
                                <div className="settings-co-coaches-column expiry-date recolour medium">{date}</div>
                                <div className="settings-co-coaches-column controls" id={invite.id}>
                                    <div id={invite.id} className="settings-co-coaches-column-button"
                                         onClick={ev => handle(ev.target.id, true)}>
                                        <img src={tick} alt="Accept" id={invite.id}/>
                                    </div>
                                    <div id={invite.id} className="settings-co-coaches-column-button"
                                         onClick={ev => handle(ev.target.id, false)}>
                                        <img src={cross} alt="Reject" id={invite.id}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            : null
    )
}


export default CoCoachInviteGrid