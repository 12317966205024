import strengths from "../assets/strengths.svg"
import weaknesses from "../assets/weaknesses.svg"
import variance from "../assets/variance.svg"
import s_variance from "../assets/stakeholder_variance.svg"
import graphs from "../assets/graphs_button.svg"
import graphs_filled from "../assets/graphs_button_filled.svg"
import {useEffect, useState} from "react";


const SummaryScoresCard = (props) => {

    const [hoveringGraphs, setHoveringGraphs] = useState(false)

    const getCardIcon = () => {
        switch (props.title) {
            case "Strengths":
                return strengths
            case "Weaknesses":
                return weaknesses
            case "Team Variance":
                return variance
            case "Stakeholder Variance":
                return s_variance
        }
    }

    const getIconClassname = () => {
        switch (props.title) {
            case "Strengths":
                return "summary-score-card-icon strengths"
            case "Weaknesses":
                return "summary-score-card-icon weaknesses"
            case "Team Variance":
                return "summary-score-card-icon variance"
            case "Stakeholder Variance":
                return "summary-score-card-icon stakeholder-variance"
        }
    }

    const getThemeColour = (theme_id) => {
        switch (theme_id) {
            case 1:
                return "blue"
            case 2:
                return "red"
            case 3:
                return "green"
            case 4:
                return "purple"
            case 5:
                return "pink"
            case 6:
                return "orange"
        }
    }

    const handleGraphClick = (ev) => {
        if(!ev) return;
        switch (props.title) {
            case "Strengths":
                props.showTop5s(0)
                break;
            case "Weaknesses":
                props.showTop5s(1)
                break;
            case "Team Variance":
                props.showTop5s(2)
                break
            case "Stakeholder Variance":
                props.showTop5s(3)
                break;
        }
    }


    return (
        <div className="summary-score-card-container">
            <div className="summary-score-card">
                <div className="summary-score-card-header">
                    <div className="summary-score-card-title-container">
                        <img className={getIconClassname()} src={getCardIcon()} alt=""/>
                        <div className="summary-score-card-title">{props.title}</div>
                    </div>
                    <div className="summary-score-card-graph-button-container">
                        <div className="summary-score-card-graph-text">Graphs</div>
                        <img src={hoveringGraphs ? graphs_filled : graphs} alt="View Graphs"
                             className="graphs-button link" onMouseOver={() => setHoveringGraphs(true)}
                             onMouseLeave={() => setHoveringGraphs(false)} onClick={handleGraphClick}/>
                    </div>
                </div>
                <div className="summary-score-card-row">
                    <div className="summary-score-card-row-text">{props.card && props.card.themes[0].name}</div>
                    <div className={`summary-score-card-row-circle ${getThemeColour(props.card && props.card.themes[0].pillar_id)}`}/>
                </div>
                <div className="summary-score-card-row">
                    <div className="summary-score-card-row-text">{props.card && props.card.themes[1].name}</div>
                    <div className={`summary-score-card-row-circle ${getThemeColour(props.card && props.card.themes[1].pillar_id)}`}/>
                </div>
                <div className="summary-score-card-row">
                    <div className="summary-score-card-row-text">{props.card && props.card.themes[2].name}</div>
                    <div className={`summary-score-card-row-circle ${getThemeColour(props.card && props.card.themes[2].pillar_id)}`}/>
                </div>
                <div className="summary-score-card-row">
                    <div className="summary-score-card-row-text">{props.card && props.card.themes[3].name}</div>
                    <div className={`summary-score-card-row-circle ${getThemeColour(props.card && props.card.themes[3].pillar_id)}`}/>
                </div>
                <div className="summary-score-card-row no-border">
                    <div className="summary-score-card-row-text">{props.card && props.card.themes[4].name}</div>
                    <div className={`summary-score-card-row-circle ${getThemeColour(props.card && props.card.themes[4].pillar_id)}`}/>
                </div>
            </div>
            <div className="summary-scores">
                <div className="summary-scores-header">
                    {props.card && props.title === "Strengths" || props.card && props.title === "Weaknesses" ? "Mean Score" : "Variance Score"}
                </div>
                <div className="summary-score-row">
                    {props.card && !(props.title === "Strengths" || props.title === "Weaknesses") ?
                        props.card && props.title === "Stakeholder Variance" ?
                            props.card?.themes[0].stakeholder_variance
                            : props.card?.themes[0].team_variance
                        : props.card?.themes[0].avg_score
                    }
                </div>
                <div className="summary-score-row">
                    {props.card && !(props.title === "Strengths" || props.title === "Weaknesses") ?
                        props.card && props.title === "Stakeholder Variance" ?
                            props.card?.themes[1].stakeholder_variance
                            : props.card?.themes[1].team_variance
                        : props.card?.themes[1].avg_score
                    }
                </div>
                <div className="summary-score-row">
                    {props.card && !(props.title === "Strengths" || props.title === "Weaknesses") ?
                        props.card && props.title === "Stakeholder Variance" ?
                            props.card?.themes[2].stakeholder_variance
                            : props.card?.themes[2].team_variance
                        : props.card?.themes[2].avg_score
                    }
                </div>
                <div className="summary-score-row">
                    {props.card && !(props.title === "Strengths" || props.title === "Weaknesses") ?
                        props.card && props.title === "Stakeholder Variance" ?
                            props.card?.themes[3].stakeholder_variance
                            : props.card?.themes[3].team_variance
                        : props.card?.themes[3].avg_score
                    }
                </div>
                <div className="summary-score-row no-border">
                    {props.card && !(props.title === "Strengths" || props.title === "Weaknesses") ?
                        props.card && props.title === "Stakeholder Variance" ?
                            props.card?.themes[4].stakeholder_variance
                            : props.card?.themes[4].team_variance
                        : props.card?.themes[4].avg_score
                    }
                </div>
            </div>
        </div>
    )
}

export default SummaryScoresCard