import React from "react";
import logo from "../assets/ccmi_logo.webp";
import {Link} from "react-router-dom";
import ColourBar from "../components/ColourBar";

const FooterContainer = () => {

    return (
        <div className="footer">
            <ColourBar height={6}/>
            <div className="footer-container">
                <div className="footer-nav-container">
                    <div className="footer-logo">
                        <img src={logo} alt="Logo"/>
                    </div>
                    <div className="footer-links">
                        <Link to="/privacy" >Privacy Policy</Link>
                        <Link to="/faq">FAQ's</Link>
                        <Link to="/settings?preferences=true">Newsletter</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterContainer;