import BackgroundRings from "../components/BackgroundRings";
import {useEffect, useState} from "react";
import monitor from "../assets/PerillHIWmonitor.png"
import diagnostics from "../assets/perill-HIW-diagnostics.png";
import trophy from "../assets/perill-trophy.png"
import idea from "../assets/perill-community-idea.png"
import plant from "../assets/perill-plant.png"


const PerillHowItWorks = () => {

    useEffect(() => window.scrollTo({top: 0, left: 0, behavior: 'instant'}), []);

    return (
        <>
            <div className="perill-resources-page-container">
                <div className="background-rings-container">
                    <BackgroundRings top={-370} right={-143} globe={true}/>
                </div>
                <div className="perill-HIW-page-title">How PERILL Diagnostics for Teams works:</div>
                <div className="perill-HIW-page-subtext">
                    "To achieve real, long lasting change that future proofs the team and its performance, then we
                    don't address the individual problems, but we address the system as a whole".
                </div>
                <div className="perill-HIW-page-subtext-author">
                    David Clutterbuck
                </div>
                <div className="perill-HIW-page-monitor-container">
                    <img className="perill-HIW-page-monitor" src={monitor} alt="video monitor"/>

                </div>
                <div className="perill-HIW-divider"/>
                <div className="perill-HIW-page-title blue">How PERILL Diagnostics supports the coaching cycle</div>
                <div className="perill-HIW-page-subtext diagnostics">
                    <b className="bold">PERILL</b> Diagnostics for Teams is designed to optimise the coaching cycle - offering a practical,
                    straightforward approach to managing the process of diagnostic gathering and analysis, whilst also
                    supporting and promoting a constructive coaching culture within the team.
                </div>
                <div className="perill-HIW-diagnostic-container">
                    <img className="perill-HIW-diagnositc-img" src={diagnostics} alt="diagnostics cycle"/>
                </div>
            </div>
            <div className="perill-HIW-footer-container">
                <img className="perill-HIW-footer-idea" src={idea} alt="idea"/>
                <div className="perill-HIW-footer-plant-container">
                    <img className="perill-HIW-footer-plant" src={plant} alt="plant"/>
                </div>
                <div className="perill-HIW-footer-plant-container">
                    <img className="perill-HIW-footer-plant flipped" src={plant} alt="plant"/>
                </div>
                <img className="perill-HIW-footer-trophy" src={trophy} alt="trophy"/>
            </div>
        </>
    )
}

export default PerillHowItWorks