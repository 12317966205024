import {useEffect, useState} from "react";
import back_arrow from "../assets/backarrow.svg";
import SummaryGraphical from "../components/SummaryGraphical";
import SummaryTabular from "../components/SummaryTabular";
import SummaryProgramme from "../components/SummaryProgramme";
import CoachCommentaryContainer from "../components/CoachCommentaryContainer";


const SummaryPillars = (props) => {
    const surveyData = props.surveyData;

    const cardType = props.cardType;
    const [cardData, setCardData] = useState();

    useEffect(() => {
        if (!surveyData || cardType === undefined) return;
        setCardData(surveyData.pillars[cardType]);
    }, [surveyData, cardType]);

    const handleBackClick = () => {
        props.back(cardType);
    }

    const getTitle = () => {
        switch (cardType) {
            case 0: return "Purpose & Motivation";
            case 1: return "External Systems & Processes";
            case 2: return "Relationships";
            case 3: return "Internal Systems & Processes";
            case 4: return "Learning";
            case 5: return "Leadership";
            default: return "Summary";
        }
    }

    return (
        <>
            <div className="link" onClick={handleBackClick}>
                <img src={back_arrow} alt={"Back Arrow"}/>
            </div>
            <div className="summary-title">{getTitle()}</div>
            <div className="summary-top-5-container">
                <div className="summary-pillar-controls-container">
                    <div className={`summary-pillar-control-button link pillar-blue ${cardType === 0 ? "active" : ""}`}
                         onClick={() => props.switchMenu(0)}
                    />
                    <div className={`summary-pillar-control-button link pillar-red ${cardType === 1 ? "active" : ""}`}
                         onClick={() => props.switchMenu(1)}
                    />
                    <div className={`summary-pillar-control-button link pillar-green ${cardType === 2 ? "active" : ""}`}
                         onClick={() => props.switchMenu(2)}
                    />
                    <div className={`summary-pillar-control-button link pillar-purple ${cardType === 3 ? "active" : ""}`}
                         onClick={() => props.switchMenu(3)}
                    />
                    <div className={`summary-pillar-control-button link pillar-pink ${cardType === 4 ? "active" : ""}`}
                         onClick={() => props.switchMenu(4)}
                    />
                    <div className={`summary-pillar-control-button link pillar-orange ${cardType === 5 ? "active" : ""}`}
                         onClick={() => props.switchMenu(5)}
                    />
                </div>
                <div className="summary-top-5-body-container">
                    <SummaryGraphical cardData={cardData}/>
                    <CoachCommentaryContainer context_id={props.surveyID} section_id="pillar-graphical"/>
                    <SummaryTabular cardData={cardData} surveyData={surveyData} showQuestions={true}
                                    cardType={cardType} seeQuestions={props.seeQuestions.bind(this)}/>
                    <CoachCommentaryContainer context_id={props.surveyID} section_id="pillar-tabular"/>
                    <SummaryProgramme programmes={surveyData.pillars[cardType].coaching_programme}
                                      cardType={cardType}/>
                    <CoachCommentaryContainer context_id={props.surveyID} section_id="pillar-programme"/>
                </div>
            </div>
        </>
    )
}

export default SummaryPillars