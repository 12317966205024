import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")


const getCompanies = () => http.get("/companies");
const getCompany = (id) => http.get("/companies/" + id);
const newCompany = (body) => http.post("/companies", body);
const updateCompany = (id, body) => http.put("/companies/" + id, body);
const deleteCompany = (id) => http.delete("/companies/" + id);
const putCompanyVisibility = (company_id, body) => http.put(`/company_hidden/${company_id}`, body)
const updateCompanyLogo = (body) => http.post("/company_logo", body);
const getDemoCompanies = () => http.get("/demo_companies");
const getDemoCompany = (id) => http.get(`/demo_companies/${id}`)

export default {
    getCompanies,
    getCompany,
    newCompany,
    updateCompany,
    deleteCompany,
    putCompanyVisibility,
    updateCompanyLogo,
    getDemoCompany,
    getDemoCompanies
}