import {useEffect, useState} from "react";

const ThemeRowContainer = (props) => {
    const theme = props.theme;
    const containerClassname = props.classname
    const calculateMeanScore = props.calculateMeanScore
    const getFunctionText = props.getFunctionText
    const tmEnabled = props.tmEnabled
    const tlEnabled = props.tlEnabled
    const stEnabled = props.stEnabled
    const t_idx= props.t_idx
    const surveyData = props.surveyData
    const cardType = props.cardType
    const getContainerClassname = props.getContainerClassname
    const teamMembers = props.teamMembers
    const teamLeaders = props.teamLeaders
    const stakeholders= props.stakeholders

    const [showDescription, setShowDescription] = useState(false)

    const handleHover = (bool) => {
        setShowDescription(bool)
    }

    return(
        <>
            <div className="summary-questions-theme-row" key={t_idx}>
                <div className={`summary-questions-theme-name header ${containerClassname}`}>
                    {theme.name}
                    <div className="summary-questions-theme-icon-container link" onMouseOver={() => handleHover(true)} onMouseOut={() => handleHover(false)}>
                        <div>
                            i
                        </div>
                    </div>
                    {showDescription ?
                        <div className="summary-theme-empty-description-container">
                            <div className={`summary-theme-description-container ${containerClassname}`}>
                                {theme.description}
                            </div>
                        </div>
                        :null}
                </div>
                <div className="summary-questions-theme-values">
                    {tmEnabled && theme.team_member_scores.map((score, idx) => {
                        return (
                            <div className="summary-questions-theme-score bold" key={idx}>
                                {Math.round(score.score)}
                            </div>
                        )
                    })}
                    {tlEnabled && theme.team_leader_scores.map((score, idx) => {
                        return (
                            <div className="summary-questions-theme-score bold" key={idx}>
                                {Math.round(score.score)}
                            </div>
                        )
                    })}
                    {stEnabled && theme.stakeholder_scores.map((score, idx) => {
                        return (
                            <div className="summary-questions-theme-score bold" key={idx}>
                                {Math.round(score.score)}
                            </div>
                        )
                    })}
                    <div className="summary-questions-theme-score bold">
                        {calculateMeanScore(theme.team_member_scores, theme.team_leader_scores, theme.stakeholder_scores)}
                    </div>
                    <div className="summary-questions-theme-score function bold">
                        {getFunctionText(theme.team_member_scores, theme.team_leader_scores, theme.stakeholder_scores)}
                    </div>
                </div>
                <div className="summary-questions-heading-end-spacer"/>
            </div>
            {surveyData && surveyData.pillars[cardType].all_question_scores.map((question, idx) => {
                if(question.theme_name === theme.name) {
                    return (
                        <div className="summary-questions-theme-row question" key={idx}>
                            <div className={`summary-questions-theme-name ${getContainerClassname()}`}>
                                {question.question}
                            </div>
                            <div className="summary-questions-theme-values">
                                {tmEnabled && question.scores.team_member_scores.map((score, idx) => {
                                    return (
                                        <div className="summary-questions-theme-score bold" key={idx}>
                                            {Math.round(score.score)}
                                        </div>
                                    )
                                })}
                                {tlEnabled && question.scores.team_leader_scores.map((score, idx) => {
                                    return (
                                        <div className="summary-questions-theme-score bold" key={idx}>
                                            {Math.round(score.score)}
                                        </div>
                                    )
                                })}
                                {stEnabled && question.scores.stakeholder_scores.map((score, idx) => {
                                    return (
                                        <div className="summary-questions-theme-score bold" key={idx}>
                                            {Math.round(score.score)}
                                        </div>
                                    )
                                })}
                                <div className="summary-questions-theme-score bold">
                                    {calculateMeanScore(question.scores.team_member_scores, question.scores.team_leader_scores, question.scores.stakeholder_scores)}
                                </div>
                                <div className="summary-questions-theme-score function bold">
                                    {getFunctionText(question.scores.team_member_scores, question.scores.team_leader_scores, question.scores.stakeholder_scores)}
                                </div>
                            </div>
                            <div className="summary-questions-heading-end-spacer"/>
                        </div>
                    )
                }
            })}
            {t_idx !== surveyData.pillars[cardType].themes.length - 1 ?
                <>
                    <div className="summary-questions-theme-spacer-row">
                        <div className="summary-questions-theme-spacer-row-wide-spacer"/>
                        <div className="summary-questions-theme-spacer-row-colour-container">
                            {tmEnabled && teamMembers.map((tm, idx) => {
                                return (
                                    <div className="summary-questions-participant-heading" key={idx}>
                                        <div className="summary-questions-participant-color tm-green"/>
                                    </div>
                                )
                            })}
                            {tlEnabled && teamLeaders.map((tl, idx) => {
                                return (
                                    <div className="summary-questions-participant-heading" key={idx}>
                                        <div className="summary-questions-participant-color tl-pink"/>
                                    </div>
                                )
                            })}
                            {stEnabled && stakeholders.map((st, idx) => {
                                return (
                                    <div className="summary-questions-participant-heading" key={idx}>
                                        <div className="summary-questions-participant-color st-blue"/>
                                    </div>
                                )
                            })}
                            <div className="summary-questions-participant-heading">
                                <div className="summary-questions-participant-color fill-grey"/>
                            </div>
                            <div className="summary-questions-participant-heading function">
                                <div className="summary-questions-participant-color fill-grey"/>
                            </div>
                        </div>
                        <div className="summary-questions-theme-spacer-row-thin-spacer"/>
                    </div>
                </> : null
            }

        </>

    )
}

export default ThemeRowContainer