import axiosInstance from "../axios/axios_instance"
const http = axiosInstance("/protected",  null, "/dashboard")


const getNotificationCount = () => http.get('/notification_indicators')
const getNotifications = () => http.get('/notifications')
const deleteNotification = (id) => http.delete(`/notifications/${id}`)


export default {
    getNotificationCount,
    getNotifications,
    deleteNotification
}