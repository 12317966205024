import {useEffect, useState} from "react";


const ROIFilterTeamRow = (props) => {

    const [team, setTeam] = useState(null)
    const [colour, setColour] = useState('#0082CA')
    const [selected, setSelected] = useState(false)

    useEffect(() => {
        setTeam(props.team)
    }, [props.team]);

    useEffect(() => {
        setColour(props.colour)
    }, [props.colour])

    useEffect(() => {
        if(!team) return;
        if(props.selected.map(x => x.team_id).includes(team.team_id)) {
            setSelected(true)
        } else {
            setSelected(false)
        }
    }, [props.selected, team]);

    const updateSelected = (ev) => {
        if(!ev) return;
        props.update([team])
    }

    return (
        <div className="roi-filter-team-row">
            <div className={`roi-filter-selected-box ${selected ? "filled" : ""}`} onClick={updateSelected}>
                {selected ? "✓" : ""}
            </div>
            <div className="filter-colour-circle" style={{backgroundColor: colour}}/>
            <div className="filter-company-name regular">{team && team.team_name}</div>
        </div>
    )
}

export default ROIFilterTeamRow