import search from "../assets/search.png";
import ReportsGridRow from "../components/ReportsGridRow"
import reports_api from "../api/protected/reports"
import pdf_api from "../api/protected/pdf"
import {useEffect, useState} from "react";

const Reports = () => {

    const [reports, setReports] = useState([])
    const [roiReports, setRoiReports] = useState([])

    const GetReports = () => {
        let response = reports_api.getReports()
        response.then(response => {
            setReports(response.data)
        })
    }

    const GetROIReports = () => {
        let response = reports_api.getROIReports()
        response.then(response => {
            setRoiReports(response.data)
        })
    }

    const DownloadReport = (report) => {
        if (!report) return;
        let response = pdf_api.getReport(report.id)
        response.then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = report.name + ".pdf";
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
        }).catch(err => {
            console.log(err)
            alert("Failed to download report.")
        })
    }

    const DownloadROIReport = (report) => {
        if(!report) return;
        let response = reports_api.downloadROIReport(report.path);
        response.then(response => {
            const FILE_DATA = response.data
            const anchorElement = document.createElement('a');
            anchorElement.href = FILE_DATA;
            anchorElement.download = "report.pdf";
            anchorElement.click();
        })
    }


    useEffect(() => {
        GetReports()
        GetROIReports()
    }, []);

    return (
        <div className="reports-page-background-container">
            <div className="reports-page-container">
                <div className="reports-page-header">
                    <div className="reports-page-title">Reports</div>
                    <div className="reports-page-search-container">
                        <input type="text" placeholder="Report Search"/>
                        <img src={search} alt="search icon" className="link"/>
                    </div>
                </div>
                <div className="reports-grid-container">
                    <div className="reports-grid-headers">
                        <div className="reports-grid-company-logo"/>
                        <div className="reports-grid-name">Name</div>
                        <div className="reports-grid-survey-name">Survey Name</div>
                        <div className="reports-grid-report-type">Report Type</div>
                        <div className="reports-grid-created-date">Created Date</div>
                    </div>
                    {reports && reports.map((report, idx) => {
                        return <ReportsGridRow report={report} idx={idx} DownloadReport={DownloadReport.bind(this)}/>
                    })}
                    {roiReports && roiReports.map((report, idx) => {
                        return <ReportsGridRow report={report} idx={idx} download={DownloadROIReport.bind(this)}/>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Reports
