import logo from "../../assets/ccmi-graphic-logo-color.svg";
import ROISurvey from "../ROISurvey";

const QuestionnaireComplete = (props) => {
    return (
        <div className="questionnaire-complete-container">
            {props.surveyPoint === 3 && props.coachSubscription !== 0 ?
                <ROISurvey uuid={props.uuid} accessKey={props.accessKey}/>
                :
                <div className="questionnaire-complete-header">
                    <div className="questionnaire-complete-logo">
                        <img src={logo} alt="ccmi logo"/>
                    </div>
                    <h2>You're Finished</h2>
                    <h3>Thank you. You have now completed your diagnostics questionnaire.</h3>
                </div>
            }
        </div>
    )
}

export default QuestionnaireComplete