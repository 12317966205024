import {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import coach_api from "../api/protected/coaching"
import CoCoachTeamCard from "../components/CoCoachTeamCard";
import VerifySharedPopup from "../components/VerifySharedPopup";
import CoachRadioButton from "../components/CoachRadioButton";
import back_arrow from "../assets/backarrow.svg";

const EditCoCoach = () => {

    const params = useParams()
    const [view, setView] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [coachLinkID, setCoachLinkID] = useState(0)
    const [coach, setCoach] = useState(null)
    const [teams, setTeams] = useState([])

    const navigate = useNavigate();
    const [verify, setVerify] = useState(false)

    const [selectAll, setSelectAll] = useState(false)
    const [readOnlyAll, setReadOnlyAll] = useState(true)
    const [editorAll, setEditorAll] = useState(false)

    const [originalTeams, setOriginalTeams] = useState([])
    const [newLinks, setNewLinks] = useState([])
    const [deleteLinks, setDeleteLinks] = useState([])

    useEffect(() => {
        if(searchParams.get('view')) {
            setView(true)
        } else {
            setView(false)
        }
        if(!params.coCoachId) {
            BackToSettings()
        } else {
            setCoachLinkID(params.coCoachId)
        }
    }, [])

    useEffect(() => {
        if(!coachLinkID) return;
        getCoachDetails(view)
    }, [coachLinkID, view])

    useEffect(() => {
        if(!coach) return;
        getSharedResources()
    }, [coach])

    const getSharedResources = () => {
        let response = coach_api.getSharedResources(coachLinkID, view);
        response.then(response => {
            setTeams(response.data)
            setOriginalTeams(JSON.parse(JSON.stringify(response.data))) //workaround fix
            validateAllSelected(response.data)
        }).catch(err => console.log(err))
    }

    const getCoachDetails = (view=false) => {
        let response = coach_api.getCoachDetails(coachLinkID, view);
        response.then(response => {
            setCoach(response.data);
        }).catch(err => console.log(err))
    }

    const updateTeams = () => {
        let response = coach_api.updateSharedTeams(coachLinkID, newLinks, deleteLinks);
        response.then(() => {}).catch(err => console.log(err))
    }

    const handleSave = (result) => {
        if (!result) return;
        if (result.button === "1") {
            updateTeams()
            navigate('/settings?coaching=true')
        } else {
            setVerify(false)
        }
    }

    const handleSaveClick = () => {
        let changes = evaluateChanges()
        if (changes) {
            setVerify(true)
        } else {
            navigate('/settings?coaching=true')
        }
    }

    const BackToSettings = (ev) => {
        if(!ev) return;
        navigate('/settings?coaching=true')
    }

    const handleSelectAllClick = (ev) => {
        if(!ev || view) return;
        let _teams = [...teams]
        for (const team of _teams) {
            team.shared = !selectAll
        }

        setTeams(_teams)
        setSelectAll(!selectAll)
    }

    const handleReadOnlyAllClick = (ev) => {
        if(!ev || view) return;
        let _teams = [...teams]
        for (const team of _teams) {
            team.editor = readOnlyAll
        }

        validateAllSelected(_teams)
        setTeams(_teams)
    }

    const handleEditorAllClick = (ev) => {
        if(!ev || view) return;
        let _teams = [...teams]
        for (const team of _teams) {
            team.editor = !editorAll
        }

        validateAllSelected(_teams)
        setTeams(_teams)
    }

    const handleTeamUpdate = (id, shared, editor) => {
        if(view) return;
        let _teams = [...teams]
        let idx = _teams.map(team => team.id).indexOf(id)
        _teams[idx].shared = shared
        _teams[idx].editor = editor

        validateAllSelected(_teams)
        setTeams(_teams)
    }

    const validateAllSelected = (_teams) => {
        let editor_all = true;
        let readonly_all = true;
        let all_shared = true;
        for (const team of _teams) {
            if (!team.editor) {
                editor_all = false;
                break;
            }
        }
        for (const team of _teams) {
            if (team.editor) {
                readonly_all = false;
                break;
            }
        }
        for (const team of _teams) {
            if(!team.shared) {
                all_shared = false;
            }
        }
        setEditorAll(editor_all)
        setReadOnlyAll(readonly_all)
        setSelectAll(all_shared)
    }

    const evaluateChanges = () => {
        let new_links = []
        let delete_links = []

        for (const team of teams) {
            let original = originalTeams[originalTeams.map(x => x.id).indexOf(team.id)]
            if (team.shared && (team.shared === original.shared)) {
                if (team.editor !== original.editor) {
                    new_links.push({
                        ...team,
                        "change": "editor"
                    })
                }
            } else if (team.shared && (team.shared !== original.shared)) {
                new_links.push({
                    ...team,
                    "change": "shared"
                })
            } else if (original.shared && (team.shared !== original.shared)) {
                delete_links.push({
                    ...team,
                    "change": "deleted"
                })
            }
        }
        setNewLinks(new_links)
        setDeleteLinks(delete_links)
        console.log(new_links)
        return new_links.length + delete_links.length
    }

    return (
        <div className="co-coach-page-background-container">
            <div className="co-coach-page-container">
                <div className="back-link link margin-adjust" onClick={BackToSettings}>
                    <img src={back_arrow} alt="Back Arrow"/>
                </div>
                {coach && coach ?
                    <>
                        <div className="co-coach-page-header">
                            <div className="co-coach-page-title">{view ? "View Co-Coach" : "Manage Co-Coach"}</div>
                        </div>
                        <div className="co-coach-page-section">
                            <div className="co-coach-details-row bold no-detail">Co-Coach Details</div>
                            <div className="co-coach-panel-name-container title medium">
                                <div className="co-coach-details-row name">Co-Coach Name</div>
                                <div className="co-coach-details-row email">Co-Coach Email</div>
                            </div>
                            <div className="co-coach-panel-name-container">
                                <div className="co-coach-details-row display name">{`${coach.first_name} ${coach.last_name}`}</div>
                                <div className="co-coach-details-row display email">{coach.email}</div>
                            </div>
                        </div>
                        <div className="co-coach-page-section fit-content">
                            <div className="co-coach-details-row bold no-detail">
                                {view ? "Shared with me" : "Co-Coach Sharing Options"}
                            </div>
                            <div className="coach-edit-row-container flex-end">
                                <div className="co-coach-page-title-row control-name medium">
                                    <div className="coach-edit-team-row-header-item unset-width"
                                         style={{visibility: view ? "hidden" : "unset", marginRight: "73px"}}>
                                        Shared Account
                                    </div>
                                    <div className="coach-edit-team-row-header-item unset-width" style={{marginRight: "75px"}}>
                                        Role
                                    </div>
                                </div>
                                <div className="co-coach-page-title-row medium type">
                                    <div className="coach-edit-team-row-header-item unset-width" style={{marginRight: "23px"}}>
                                        Editor
                                    </div>
                                    <div className="coach-edit-team-row-header-item unset-width" style={{marginRight: "28px"}}>
                                        Read Only
                                    </div>
                                </div>
                                <div className="co-coach-page-title-row space-between">
                                    <div className="title-container medium">
                                        <div className="coach-edit-team-row-header-item unset-width" style={{marginRight: "220px", width: "107px"}}>
                                            Company Name
                                        </div>
                                        <div className="coach-edit-team-row-header-item unset-width" style={{width: "80px"}}>
                                            Team Name
                                        </div>
                                    </div>
                                    <div className="coach-edit-team-row background">
                                        <div className="coach-edit-team-row-item medium text-field"
                                             style={{visibility: view ? "hidden" : "unset"}}>
                                        Apply to all
                                        </div>
                                        <div className="coach-edit-team-row-item centred long-margin"
                                             style={{visibility: view ? "hidden" : "unset"}}>
                                            <CoachRadioButton selected={selectAll}
                                                              handle={handleSelectAllClick.bind(this)}
                                            />
                                        </div>
                                        <div className="coach-edit-team-row-item centred mid-margin">
                                            <CoachRadioButton selected={editorAll}
                                                              handle={handleEditorAllClick.bind(this)}
                                                              text=""
                                                              view={view}
                                                              disabled={view}
                                                              active={selectAll}
                                            />
                                        </div>
                                        <div className="coach-edit-team-row-item centred">
                                            <CoachRadioButton selected={readOnlyAll}
                                                              handle={handleReadOnlyAllClick.bind(this)}
                                                              text=""
                                                              view={view}
                                                              disabled={view}
                                                              active={selectAll}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {teams && teams.map((team, idx) => {
                                    return <CoCoachTeamCard team={team} update={handleTeamUpdate.bind(this)} key={idx}
                                                            disabled={view}/>
                                })}
                            </div>
                        </div>
                    </> : null
                }
                <div className="co-coach-page-save-container">
                    <div className="co-coach-page-save-button bold"
                         onClick={handleSaveClick}>{view ? "BACK TO SETTINGS" : "SAVE"}</div>
                </div>
                {verify ?
                    <VerifySharedPopup created={newLinks} deleted={deleteLinks} result={handleSave.bind(this)}/>
                    : null
                }
            </div>
        </div>
    )
}

export default EditCoCoach