import logo from "../../assets/ccmi-graphic-logo-color.svg";
import q_api from "../../api/public/questionnaire";
import {useState} from "react";

const QuestionnaireAccess = (props) => {
    const uuid = props.uuid;
    const updateKey = props.updateKey;
    const updateQuestions = props.updateQuestions;
    const updatePillarName = props.updatePillarName;
    const updateSurveyType = props.updateSurveyType;
    const updateSurveyPoint = props.updateSurveyPoint;
    const updateCoachSubscription = props.updateCoachSubscription;
    const [accessKey, setAccessKey] = useState();

    const formSubmit = (ev) => {
        ev.preventDefault();
        let response = q_api.getQuestions(uuid, accessKey, 1);
        response.then(response => {
            updateQuestions(response.data.responses);
            updateKey(accessKey);
            updatePillarName(response.data.pillar.name);
            updateSurveyType(response.data.type);
            updateSurveyPoint(response.data.point);
            updateCoachSubscription(response.data.membership)
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            <div className="page-mask"/>
            <div className="questionnaire-access-container">
                <div className="questionnaire-access-dialog">
                    <div className="questionnaire-access-dialog-header">
                        <div className="questionnaire-access-dialog-logo">
                            <img src={logo} alt="ccmi logo"/>
                        </div>
                        <h2>Welcome</h2>
                        <h3>Enter your access key to start your survey.</h3>
                    </div>
                    <form name="questionnaire-form" method="POST">
                        <div className="field-input">
                            <div className="field-input-entry">
                                <input name="access.code"
                                       type="text"
                                       value={accessKey}
                                       placeholder="Access Key"
                                       onChange={ev => setAccessKey(ev.target.value)}
                                       required
                                />
                            </div>
                        </div>
                        <div className="field-input">
                            <button className="submit-questionnaire-button link"
                                    name="method"
                                    type="submit"
                                    value="password"
                                    disabled={!accessKey}
                                    onClick={formSubmit}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default QuestionnaireAccess