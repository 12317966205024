const registerStyles = {
    control: (baseStyles, state) => ({
        border: "solid 1px #9DD6F7",
        background: "#FAFDFF",
        outline: state.isFocused ? "solid 2px black" : "none",
        outlineOffset: "-1px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "5px 15px 5px 15px",
        height: "56px",
        fontSize: "18px",
        borderRadius: "15px",
        color: "black",
        fontFamily: "ccmiInterMedium"
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    dropdownIndicator: (baseStyles) => ({
        fill: "black"
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: "none",
    }),
    input: (baseStyles) => ({
        ...baseStyles,
        color: "black",
        fontFamily: "ccmiInterMedium"
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "black",
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        color: "black",
        background: state.isFocused ? "#9DD6F7" : "#FAFDFF"
    })
}

const profileStyles = {
    control: (baseStyles, state) => ({
        border: "solid 1px #9DD6F7",
        background: "#FAFDFF",
        outline: state.isFocused ? "solid 2px black" : "none",
        outlineOffset: "-1px",
        display: "flex",
        alignItems: "center",
        padding: "5px 15px 5px 15px",
        height: "56px",
        fontSize: "14px",
        borderRadius: "15px",
        color: "black",
        fontFamily: "ccmiInterBold",
        width: "530px"
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    dropdownIndicator: (baseStyles) => ({
        fill: "black",
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: "none",
    }),
    input: (baseStyles) => ({
        ...baseStyles,
        color: "black",
        fontFamily: "ccmiInterMedium"
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "black",
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        color: "black",
        background: state.isFocused ? "#9DD6F7" : "#FAFDFF",
        width: "530px"
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        width: "530px",
        overflowX: "hidden"
    })
}

const profileStylesThin = {
    control: (baseStyles, state) => ({
        border: "solid 1px #9DD6F7",
        background: "#FAFDFF",
        outline: state.isFocused ? "solid 2px black" : "none",
        outlineOffset: "-1px",
        display: "flex",
        alignItems: "center",
        padding: "5px 15px 5px 15px",
        height: "56px",
        fontSize: "14px",
        borderRadius: "15px",
        color: "black",
        fontFamily: "ccmiInterBold",
        width: "300px"
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    dropdownIndicator: (baseStyles) => ({
        fill: "black",
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: "none",
    }),
    input: (baseStyles) => ({
        ...baseStyles,
        color: "black",
        fontFamily: "ccmiInterMedium"
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "black",
        overflowX: "hidden"
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        color: "black",
        background: state.isFocused ? "#9DD6F7" : "#FAFDFF",
        width: "300px",
        overflowX: "hidden"
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        width: "300px",
        overflowX: "none"
    })
}

const accreditationPopupSelect = {
    control: (baseStyles, state) => ({
        border: "solid 1px #9DD6F7",
        background: "#FAFDFF",
        outline: "none",
        outlineOffset: "-1px",
        display: "flex",
        alignItems: "center",
        padding: "5px 15px 5px 15px",
        height: "56px",
        fontSize: "14px",
        borderRadius: "15px",
        color: "black",
        fontFamily: "ccmiInterBold",
        width: "536px",
        cursor: "pointer"
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    dropdownIndicator: (baseStyles) => ({
        fill: "#0082CA",
        filter: "invert(35%) sepia(68%) saturate(5720%) hue-rotate(184deg) brightness(99%) contrast(101%)",
        marginTop: "-3px"
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: "none",
    }),
    input: (baseStyles) => ({
        ...baseStyles,
        color: "black",
        fontFamily: "ccmiInterMedium"
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "black",
        overflowX: "hidden",
        fontSize: "14px",
        fontFamily: "ccmiInterMedium"
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        color: "black",
        background: state.isFocused ? "#9DD6F7" : "#FAFDFF",
        width: "536px",
        overflowX: "hidden",
        cursor: "pointer",
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        width: "536px",
        overflowX: "none"
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        fontSize: "12px",
        fontFamily: "ccmiInterBold",
        color: "#0082CA",
        textAlign: "end",
        marginRight: "10px"
    })
}


export default {
    registerStyles,
    profileStyles,
    profileStylesThin,
    accreditationPopupSelect
}