import {useEffect, useState} from "react";

const SummaryGraphicPoint = (props) => {
    const points = props.points;
    const attributableData = props.attributableData;
    const idx = props.idx;
    const [showPopup, setShowPopup] = useState(false)
    const [name, setName] = useState("Team")

    const handlePopup = (boolean) => {
        setShowPopup(boolean)
    }

    useEffect(() => {
        if (points.name) setName(points.name)
        if (points.team_name) setName(points.team_name)
    },[points])

    return(
        <>
            <div className={`summary-graphical-point ${points.class}`}
                 style={{top: `calc(${points.top}% - 14px)`}} key={idx} onMouseOver={() => handlePopup(true)} onMouseOut={() => handlePopup(false)}>
                <div className="summary-graphical-point-centre" style={{backgroundColor: points.colour}} onMouseOver={() => handlePopup(true)}/>
                {showPopup?
                    <div className="summary-graphical-point-hover-container">
                        <div className="summary-graphical-point-hover-triangle">
                            <div className="summary-graphical-point-hover-triangle-inner"/>
                        </div>
                        <div className="summary-graphical-point-hover-main">
                            <div className="summary-graphical-average-hover-details">
                                <div className="hover-details-key">Score</div>
                                <div className="hover-details-score">
                                    {points.score}
                                </div>
                            </div>
                            <div className={`summary-graphical-point-hover-circle`} style={{backgroundColor: points.colour}}/>
                            <div className="summary-graphical-point-hover-text">
                                {attributableData ? name : points.hidden_name}
                            </div>
                        </div>
                    </div>
                    :null}
            </div>
        </>
    )
}

export default SummaryGraphicPoint