import {useState} from "react";

const DeleteDialog = (props) => {
    const title = props.title;
    const type = props.type;
    const name = props.name;
    const description = props.description;
    const buttons = props.buttons;
    const result = props.result;

    const [inputName, setInputName] = useState("");

    const handleButtonClick = (ev) => {
        if (!ev) return;
        if (ev.currentTarget.id === "2") {
            result(ev.currentTarget.id);
        } else {
            if (inputName !== name) return;
            result(ev.currentTarget.id);
        }
    }

    return (
        <>
            <div className="page-mask"/>
            <div className="delete-dialog-container">
                <div className="delete-dialog-header-container">
                    <div className="delete-dialog-header">{title}</div>
                    <div className="delete-dialog-name">{name}</div>
                    <div className="delete-dialog-description">{description}</div>
                    <div className="delete-input-container">
                        <input className="delete-company-name-input"
                               name="company.name"
                               type="text"
                               value={inputName}
                               onChange={(ev) =>
                                   setInputName(ev.target.value) }
                               required
                        />
                    </div>
                    <div className="delete-dialog-intructions">
                        To enable delete, please enter the name of the current {type}.
                    </div>
                    <div className="delete-dialog-footer-container">
                        <div className={inputName === name ? "dialog-button link": "dialog-button disabled"}
                             onClick={handleButtonClick} id={buttons[0].id}>
                            <div className="dialog-button-text">{buttons[0].text}</div>
                        </div>
                        <div className="dialog-button link"
                             onClick={handleButtonClick} id={buttons[1].id}>
                            <div className="dialog-button-text">{buttons[1].text}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteDialog