import {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import back_arrow from "../../assets/backarrow.svg"
import SummaryScoresCard from "../SummaryScoresCard";
import SummaryChart from "../SummaryChart";
import search from "../../api/protected/search";


const SurveySummary = (props) => {
    const surveyData = props.surveyData;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()

    const [teamMembersEnabled, setTeamMembersEnabled] = useState(true)
    const [teamLeadersEnabled, setTeamLeadersEnabled] = useState(true)
    const [stakeholdersEnabled, setStakeholdersEnabled] = useState(true)

    const dateFormat = (ts) => {
        let d = new Date(ts * 1000)
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    }

    const handleBackClick = (ev) => {
        if(!ev) return;
        if(searchParams.get('demo')) {
            navigate(`/surveys/${surveyData.survey_id}?demo=true`)
        } else {
            navigate(`/surveys/${surveyData.survey_id}`)
        }
    }

    const getCard = (card_type) => {
        if(!surveyData) return;
        let i = 0;
        switch (card_type) {
            case "Strengths":
                i = surveyData.summary_aggregates.map(x => x.group_name).indexOf("Team Strengths")
                return surveyData.summary_aggregates[i]
            case "Weaknesses":
                i = surveyData.summary_aggregates.map(x => x.group_name).indexOf("Team Weaknesses")
                return surveyData.summary_aggregates[i]
            case "Team Variance":
                i = surveyData.summary_aggregates.map(x => x.group_name).indexOf("Variance with Team")
                return surveyData.summary_aggregates[i]
            case "Stakeholder Variance":
                i = surveyData.summary_aggregates.map(x => x.group_name).indexOf("Variance with Stakeholder")
                return surveyData.summary_aggregates[i]
        }
    }

    const CompletionBar = (props) => {
        let completion = props.completion

        let radius = 132
        let halfWidth = 140
        let circumference = radius * 2 * Math.PI
        let dasharray_circ = circumference * 0.905
        let offset = circumference - completion / 100 * dasharray_circ || 0;

        let linecap_rotation = ((360 * 0.905) * (completion / 100) - 163)

        return (
            <svg className="summary-progress-ring">
                <circle className="summary-ring-circle" strokeWidth="14" stroke={"#773dbd"} fill="transparent"
                        r={radius} cx={halfWidth} cy={halfWidth} strokeDasharray={`${circumference} ${circumference}`}
                        strokeDashoffset={offset} strokeLinecap="round"
                />
                {completion > 0 ?
                    <circle className="progress-ring__linecap" strokeWidth="3px" stroke={"#773dbd"} fill="white"
                            r={10} cx={halfWidth} cy={halfWidth-radius} transform={`rotate(${linecap_rotation})`}
                    />
                    : null
                }
            </svg>
        )
    }

    return (
        <>
            <div className="summary-back-button link" onClick={handleBackClick}>
                <img src={back_arrow} alt={"Back Arrow"}/>
            </div>
            <div className="summary-title">Diagnostic Summary</div>
            <div className="survey-summary-details-container">
                <div className="survey-summary-details-subcontainer left">
                    <div className="survey-summary-details-heading-text">Company:</div>
                    <div className="survey-summary-details-value-text">{surveyData && surveyData.company_name}</div>
                    <div className="survey-summary-details-heading-text">Team:</div>
                    <div className="survey-summary-details-value-text">{surveyData && surveyData.team_name}</div>
                    <div className="survey-summary-details-heading-text">Survey Type</div>
                    <div className="survey-summary-details-light-switch-container">
                        <div className="survey-summary-details-light-switch">
                            <div className={`light-switch ${surveyData && surveyData.survey_type !== 1 ? "active" : "inactive"}`}/>
                            <div className="light-switch-text">Lite</div>
                        </div>
                        <div className="survey-summary-details-light-switch">
                            <div className={`light-switch ${surveyData && surveyData.survey_type === 1 ? "active" : "inactive"}`}/>
                            <div className="light-switch-text">Full</div>
                        </div>
                    </div>
                    <div className="survey-summary-details-heading-text">Survey Point</div>
                    <div className="survey-summary-details-light-switch-container">
                        <div className="survey-summary-details-light-switch">
                            <div className={`light-switch ${surveyData && surveyData.survey_point === 1 ? "active" : "inactive"}`}/>
                            <div className="light-switch-text">Initial</div>
                        </div>
                        <div className="survey-summary-details-light-switch">
                            <div className={`light-switch ${surveyData && surveyData.survey_point === 2 ? "active" : "inactive"}`}/>
                            <div className="light-switch-text">Midpoint</div>
                        </div>
                        <div className="survey-summary-details-light-switch">
                            <div className={`light-switch ${surveyData && surveyData.survey_point === 3 ? "active" : "inactive"}`}/>
                            <div className="light-switch-text">Endpoint</div>
                        </div>
                        <div className="survey-summary-details-light-switch">
                            <div className={`light-switch ${surveyData && surveyData.survey_point === 4 ? "active" : "inactive"}`}/>
                            <div className="light-switch-text">Post Engagement</div>
                        </div>
                    </div>
                </div>
                <div className="survey-summary-details-container-divider"/>
                <div className="survey-summary-details-subcontainer right">
                    <div className="completion-circle">
                        <div className="survey-summary-details-completion-circle-container">
                            <div className="survey-summary-details-completion-circle">
                                <div className="survey-summary-details-completion-circle-inner-ring">
                                    <div className="survey-summary-details-completion-circle-inner">
                                        <div className="survey-summary-completion-circle-text-container">
                                            <div className="survey-summary-details-completion-circle-percentage-text">
                                                {`${surveyData && surveyData.survey_percent_complete || 0}%`}
                                            </div>
                                            <div
                                                className="survey-summary-details-completion-circle-percentage-subtext">
                                                COMPLETE
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CompletionBar completion={surveyData && surveyData.survey_percent_complete}/>
                    </div>
                    <div className="survey-summary-details-completion-text-container">
                        <div className="survey-summary-details-heading-text">Survey Name:</div>
                        <div className="survey-summary-details-value-text">{surveyData && surveyData.survey_name}</div>
                        <div className="survey-summary-details-heading-text">Number of participants</div>
                        <div
                            className="survey-summary-details-value-text">{surveyData && surveyData.participant_count}</div>
                        <div className="survey-summary-details-heading-text">Created</div>
                        <div className="survey-summary-details-value-text">
                            {searchParams.get('demo') ? "N/A" : dateFormat(surveyData && surveyData.survey_created_date)}
                        </div>
                        <div className="survey-summary-details-heading-text">Expires</div>
                        <div className="survey-summary-details-value-text">
                            {searchParams.get('demo') ? "N/A" : dateFormat(surveyData && surveyData.survey_expiry_date)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="survey-summary-chart-container">
                <div className="summary-chart-details">
                    <div className="summary-chart-details-title">Overview of Diagnostic Scores</div>
                    <div className="summary-chart-details-controls-container">
                        <div className="summary-chart-details-controls-row">
                            <div className="chart-controls-circle green"></div>
                            <div className="chart-controls-text">Team Members</div>
                            <div className={`chart-controls-switch ${teamMembersEnabled ? "active" : "inactive"}`}
                                 onClick={() => setTeamMembersEnabled(!teamMembersEnabled)}>
                                <div className="chart-controls-switch-centre"/>
                            </div>
                        </div>
                        <div className="summary-chart-details-controls-row">
                            <div className="chart-controls-circle pink"></div>
                            <div className="chart-controls-text">Team Leader(s)</div>
                            <div className={`chart-controls-switch ${teamLeadersEnabled ? "active" : "inactive"}`}
                                 onClick={() => setTeamLeadersEnabled(!teamLeadersEnabled)}>
                                <div className="chart-controls-switch-centre"/>
                            </div>
                        </div>
                        <div className="summary-chart-details-controls-row">
                            <div className="chart-controls-circle blue"></div>
                            <div className="chart-controls-text">Stakeholder(s)</div>
                            <div className={`chart-controls-switch ${stakeholdersEnabled ? "active" : "inactive"}`}
                                 onClick={() => setStakeholdersEnabled(!stakeholdersEnabled)}>
                                <div className="chart-controls-switch-centre"/>
                            </div>
                        </div>
                    </div>
                    <div className="summary-chart-details-subtext">
                        This polar graph shows the mean scores for your participant groups, with scores ranging from
                        1-50. Each section of the graph represents a theme within the PERILL pillars. The score for
                        each group in each theme is represented by a coloured dot, and the further out the dot from the
                        centre, the higher the score.
                        <br/><br/>
                        Toggle on or off the participant groups or click on each dot to see the score for that group.
                    </div>
                </div>
                <div className="summary-chart-container">
                    <div className="summary-interactive-chart">
                        <SummaryChart surveyData={surveyData}
                                      tmEnabled={teamMembersEnabled}
                                      tlEnabled={teamLeadersEnabled}
                                      stEnabled={stakeholdersEnabled}
                                      showPillars={props.showPillars.bind(this)}
                        />
                    </div>
                </div>
            </div>
            <div className="survey-summary-collective-scores">
                <div className="summary-chart-details-title">Collective Scores</div>
                <div className="summary-collective-scores-card-container">
                    <div className="summary-collective-scores-card-row">
                        <SummaryScoresCard title="Strengths" card={getCard("Strengths")}
                                           showTop5s={t => props.showTop5s(t)}/>
                        <SummaryScoresCard title="Weaknesses" card={getCard("Weaknesses")}
                                           showTop5s={t => props.showTop5s(t)}/>
                    </div>
                    <div className="summary-collective-scores-card-row">
                        <SummaryScoresCard title="Team Variance" card={getCard("Team Variance")}
                                           showTop5s={t => props.showTop5s(t)}/>
                        <SummaryScoresCard title="Stakeholder Variance" card={getCard("Stakeholder Variance")}
                                           showTop5s={t => props.showTop5s(t)}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SurveySummary