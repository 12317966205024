import reports_api from "../api/protected/reports"
import {useEffect, useState} from "react";


const ROIReportSelector = (props) => {

    const [teams, setTeams] = useState([])
    const [selectedTeams, setSelectedTeams] = useState([])

    const getROITeams = () => {
        let response = reports_api.getValidROITeams()
        response.then(response => {
            setTeams(response.data.teams)
        })
    }

    const handleButtonClick = (team) => {
        if(!team) return;
        if(selectedTeams.map(x => x.team_id).includes(team.team_id)) {
            let temp = [...selectedTeams]
            temp = temp.filter(x => x.team_id !== team.team_id)
            setSelectedTeams(temp)
        } else {
            let temp = [...selectedTeams]
            temp.push(team)
            setSelectedTeams(temp)
        }
    }


    const handleGenerateReport = (ev) => {
        if(!ev) return
        let response = reports_api.generateROIReport(selectedTeams, props.reportID);
        response.then(response => {
            console.log(response)
            const base64_link = response.data.data;
            const anchorElement = document.createElement('a');
            anchorElement.href = base64_link;
            anchorElement.download = "roi_report.pdf";
            anchorElement.click();
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getROITeams()
    }, []);

    return (
        <div className="page-mask" id="mask" onClick={ev => props.closePopup(ev)}>
            <div className="report-config-container">
                <div className="report-config-title-row">
                    <div className="report-config-company-name">Company Name</div>
                    <div className="report-config-team-name">Team Name</div>
                    <div className="report-config-selected">Selected</div>
                </div>
                {teams && teams.map((team, idx) => {
                    return (
                        <div className="report-config-row" key={idx}>
                            <div className="report-config-company-name">{team.company_name}</div>
                            <div className="report-config-team-name">{team.team_name}</div>
                            <div className="report-config-selected">
                                <div className="slider" onClick={() => handleButtonClick(team)}>
                                    <div className={selectedTeams.map(x => x.team_id).includes(team.team_id) ? "slider-container on" : "slider-container off"}>
                                        <div className={selectedTeams.map(x => x.team_id).includes(team.team_id) ? "slider-button on" : "slider-button off"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className="report-config-buttons">
                    <div className="report-config-button" id="mask" onClick={ev => props.closePopup(ev)}>Cancel</div>
                    <div className="report-config-button fill" onClick={handleGenerateReport}>Generate Report</div>
                </div>
            </div>
        </div>
    )
}


export default ROIReportSelector