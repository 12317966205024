import {useState} from "react";

const Slider = (props) => {
    const state = props.state;
    const text = props.text;
    const update = props.update;
    const type = props.type;
    const elem = props.elem;
    const [showHover, setShowHover] = useState(false)

    const handleHoverOn = () => {
        if (type === undefined) return;
        setShowHover(true)
    }

    const handleHoverOut = () => {
        if (type === undefined) return;
        setShowHover(false)
    }

    function handleSliderClick() {
        update(!state);
    }

    return (
        <>
            <div className="slider">
                <div className={state ? "slider-container on" : "slider-container off"} tabIndex="0"
                     onMouseOver={handleHoverOn} onMouseLeave={handleHoverOut} onClick={handleSliderClick}>
                    <div className={state ? "slider-button on" : "slider-button off"}
                         onMouseOver={handleHoverOn} onMouseLeave={handleHoverOut}/>
                </div>
                <div className="slider-text">{elem ? elem : text}</div>
            </div>
            {showHover && type ?
                <div className="slider-tooltip-container">
                    <div className="slider-tooltip-text">
                        Enable to hide {type} from {type}'s page.
                    </div>
                </div>
                : null
            }
        </>
    )
}

export default Slider