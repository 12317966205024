import LegalClause from "../components/LegalClause";
import {useEffect} from "react";


const FAQ = () => {

    useEffect(() => window.scrollTo({top: 0, left: 0, behavior: 'instant'}), []);

    return(
        <>
            <div className="faq-page-container">
                <div className="faq-page-title">FAQ/ Self Help Guide</div>
                {/*Clauses are acting as placeholders until content has been requested.*/}
                <div className="privacy-policy-container home-contents">
                    <LegalClause text="1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.">
                        <LegalClause text="1.1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."/>
                        <LegalClause text="1.1.2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."/>
                        <LegalClause text="1.1.3. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.">
                            <LegalClause text="1.1.3.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."/>
                            <LegalClause text="1.1.3.2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."/>
                        </LegalClause>
                    </LegalClause>
                </div>
            </div>
        </>
    )
}

export default FAQ