import {useEffect, useState} from "react";


const SummaryChart = (props) => {

    const surveyData = props.surveyData
    const [tmData, setTmData] = useState([])
    const [tlData, setTlData] = useState([])
    const [stData, setStData] = useState([])

    const [activeHoverSection, setActiveHoverSection] = useState("")
    const [activeHoverScores, setActiveHoverScores] = useState({"tm": 0, "tl": 0, "st": 0})
    const [hoverX, setHoverX] = useState(0)
    const [hoverY, setHoverY] = useState(0)

    const [mouseOverPopup, setMouseOverPopup] = useState(false)
    const [activeHoverEvent, setActiveHoverEvent] = useState(null)
    const [activeHoverIdx, setActiveHoverIdx] = useState(0)

    const themes = [
        "Alignment", "Clarity", "Enthusiasm & Contribution", "Enjoyment",
        "Support & Collaboration", "Environmental Awareness", "Stakeholder & Customer Alignment",
        "Talent & Resource Acquisition", "Stakeholder & Customer Communication",
        "Trust & Integrity in Relationships", "Skills & Expertise", "Respect & Trust", "Camaraderie & Cohesion",
        "Safe Communication", "Values Diversity & Inclusion", "Appropriate Resourcing", "Team Agility",
        "Accountability", "System Efficacy", "Team Protection", "Development Planning", "Learning Relevance",
        "Embedded Learning", "Developmental Orientation", "Reflective Learning", "Vision & Context",
        "Collaborative & Empowering Leadership", "Interpersonal Leadership", "Leadership Courage",
        "Inspirational Leadership"
    ]

    useEffect(() => {
        if(!surveyData) return
        formatData()
    }, [surveyData])

    const getAverageScore = (scores) => {
        if(!scores.length) return;
        let total = 0
        for(const score of scores) {
            total += score.score
        }

        return total / scores.length
    }

    const convertToPercentage = (score) => {
        if(!score && score !== 0) return;
        return (score / 50 * 100)
    }

    const reverseFromPercentage = (score) => {
        return (score / 100) * 50
    }

    const correctScoreOrder = (scores) => {
        if(!scores.length) return []

        //Fixes array filled with NaN when certain participant types not involved with survey
        if(scores.includes(NaN)) return []

        let scores_left = scores.slice(0, 10)
        let scores_right = scores.slice(10, scores.length)
        return [...scores_right, ...scores_left]
    }

    const formatData = () => {
        let tm_scores = []
        let tl_scores = []
        let st_scores = []
        let theme_question_count = 0
        let tm_theme_total = 0
        let tl_theme_total = 0
        let st_theme_total = 0

        for(const theme of themes) {
            for(const pillar of surveyData.pillars) {
                for(const question of pillar.all_question_scores) {
                    if(theme !== question.theme_name) continue;

                    let tm_avg = convertToPercentage(getAverageScore(question.scores.team_member_scores))
                    let tl_avg = convertToPercentage(getAverageScore(question.scores.team_leader_scores))
                    let st_avg = convertToPercentage(getAverageScore(question.scores.stakeholder_scores))

                    tm_theme_total += tm_avg
                    tl_theme_total += tl_avg
                    st_theme_total += st_avg

                    theme_question_count += 1
                }
            }
            tm_scores.push(tm_theme_total / theme_question_count)
            tl_scores.push(tl_theme_total / theme_question_count)
            st_scores.push(st_theme_total / theme_question_count)

            tm_theme_total = 0
            tl_theme_total = 0
            st_theme_total = 0
            theme_question_count = 0
        }

        setTmData(correctScoreOrder(tm_scores))
        setTlData(correctScoreOrder(tl_scores))
        setStData(correctScoreOrder(st_scores))
    }

    const getCircumferenceX = (angle, radius) => {
        return radius * Math.cos(angle)
    }

    const getCircumferenceY = (angle, radius) => {
        return radius * Math.sin(angle)
    }

    const calculateScoreCoordinates = (points) => {
        if(!points.length) return;
        let coords = ""
        let cx = 350
        let cy = 350
        let r = 320
        let angle = (2 * Math.PI) / points.length

        let i = 0
        for (const point of points) {
            let px = (getCircumferenceX(angle * i, r) * (point / 100)) + cx
            let py = (getCircumferenceY(angle * i, r) * (point / 100)) + cy
            coords = coords + `${px},${py} `
            i += 1
        }

        return coords.slice(0, -1)
    }

    const calculatePointMarkers = (points) => {
        if(!points.length) return;
        let coords = []
        let cx = 350
        let cy = 350
        let r = 320
        let angle = (2 * Math.PI) / points.length

        let i = 0
        for (const point of points) {
            let px = (getCircumferenceX(angle * i, r) * (point / 100)) + cx
            let py = (getCircumferenceY(angle * i, r) * (point / 100)) + cy
            coords.push([px, py])
            i += 1
        }

        return coords
    }

    const calculateLineCoords = () => {
        let coords = []
        let cx = 350
        let cy = 350
        let r = 320
        let lines = 30
        let angle = (2 * Math.PI) / lines

        for (let i = 0; i<lines; i++) {
            let px = (getCircumferenceX(angle * i, r)) + cx
            let py = (getCircumferenceY(angle * i, r)) + cy
            coords.push([px, py])
        }

        return coords
    }

    const calculatePointCoords = (idx) => {
        let cx = 350
        let cy = 350
        let r = 200
        let angle = (2 * Math.PI) / 30

        let px = (getCircumferenceX((angle * idx) + (angle / 2), r)) + cx
        let py = (getCircumferenceY((angle * idx) + (angle / 2), r)) + cy
        return [px, py]
    }

    const generateInnerHoverSections = () => {
        let path = ""
        let paths = []
        let cx = 350
        let cy = 350
        let r = 320
        let sections = 30
        let angle = (2 * Math.PI) / sections

        for (let i = 0; i<sections; i++) {
            let j = i + 1
            if(i === 29) j = 0;

            let x1 = (getCircumferenceX(angle * i, r)) + cx
            let y1 = (getCircumferenceY(angle * i, r)) + cy
            let x2 = (getCircumferenceX(angle * j, r)) + cx
            let y2 = (getCircumferenceY(angle * j, r)) + cy

            path = `M ${cx},${cy} L ${x1},${y1} A ${cx},${cy} 12 0,1 ${x2},${y2} L ${cx},${cy}`
            paths.push(path)
        }

        return paths
    }

    const generateOuterHoverSections = () => {
        let path = ""
        let paths = []
        let cx = 350
        let cy = 350
        let or = 350
        let ir = 320
        let sections = 6
        let angle = (2 * Math.PI) / sections

        for (let i = 0; i<sections; i++) {
            let j = i + 1
            if(i === 29) j = 0;

            let ox1 = (getCircumferenceX(angle * i, or)) + cx
            let oy1 = (getCircumferenceY(angle * i, or)) + cy
            let ox2 = (getCircumferenceX(angle * j, or)) + cx
            let oy2 = (getCircumferenceY(angle * j, or)) + cy

            let ix1 = (getCircumferenceX(angle * i, ir)) + cx
            let iy1 = (getCircumferenceY(angle * i, ir)) + cy
            let ix2 = (getCircumferenceX(angle * j, ir)) + cx
            let iy2 = (getCircumferenceY(angle * j, ir)) + cy

            path = `M ${ox1},${oy1} A ${cx},${cy} 60 0,1 ${ox2},${oy2} M ${ox1},${oy1} L ${ix1},${iy1} A ${cx},${cy} 60 0,1 ${ix2},${iy2} L ${ox2},${oy2}`
            paths.push(path)
        }

        return paths
    }

    const getPointFill = (idx) => {
        if(idx < 5) return "#93D500";
        if(idx < 10) return "#773DBD";
        if(idx < 15) return "#F478C4";
        if(idx < 20) return "#F08019";
        if(idx < 25) return "#0082CA";
        if(idx < 30) return "#C11B20";
    }

    const getSectionName = (idx) => {
        let sections = [
            "Skills & Expertise", "Respect & Trust", "Camaraderie & Cohesion", "Safe Communication",
            "Values Diversity & Inclusion", "Appropriate Resourcing", "Team Agility", "Accountability",
            "System Efficacy", "Team Protection", "Development Planning", "Learning Relevance", "Embedded Learning",
            "Developmental Orientation", "Reflective Learning", "Vision & Context",
            "Collaborative & Empowering Leadership", "Interpersonal Leadership", "Leadership Courage",
            "Inspirational Leadership", "Alignment", "Clarity", "Enthusiasm & Contribution", "Enjoyment",
            "Support & Collaboration", "Environmental Awareness", "Stakeholder & Customer Alignment",
            "Talent & Resource Acquisition", "Stakeholder & Customer Communication",
            "Trust & Integrity in Relationships"
        ]
        return sections[idx]
    }

    const handleSectionMouseOver = (ev, idx) => {
        if(!ev) return
        setActiveHoverEvent(ev)
        setActiveHoverIdx(idx)
        let idx2;
        if(idx === 29) {
            idx2 = 0
        } else {
            idx2 = idx + 1
        }
        let elem = document.getElementById(ev.target.id)
        let line1 = document.getElementById(`line-${idx}`)
        let line2 = document.getElementById(`line-${idx2}`)
        elem.style.filter = "url(#segment-drop-shadow)"
        line1.style.display = "none"
        line2.style.display = "none"

        let tm_score = reverseFromPercentage(tmData[idx])
        let tl_score = reverseFromPercentage(tlData[idx])
        let st_score = reverseFromPercentage(stData[idx])
        let active_scores = {
            "tm": tm_score,
            "tl": tl_score,
            "st": st_score
        }

        let point_coords = calculatePointCoords(idx)
        let yOffset = 0
        if (props.tmEnabled && tm_score && tm_score !== 0) yOffset += 31
        if (props.tlEnabled && tl_score && tl_score !== 0) yOffset += 31
        if (props.stEnabled && st_score && st_score !== 0) yOffset += 31

        setHoverX(point_coords[0] - 17)
        setHoverY(point_coords[1] - yOffset)
        setActiveHoverScores(active_scores)
        setActiveHoverSection(getSectionName(idx))
    }

    const handleSectionMouseLeave = (ev, idx) => {
        if(!ev || mouseOverPopup) return
        setActiveHoverEvent(null)
        setActiveHoverIdx(0)
        let idx2;
        if(idx === 29) {
            idx2 = 0
        } else {
            idx2 = idx + 1
        }
        let elem = document.getElementById(ev.target.id)
        let line1 = document.getElementById(`line-${idx}`)
        let line2 = document.getElementById(`line-${idx2}`)
        elem.style.filter = "unset"
        line1.style.display = "unset"
        line2.style.display = "unset"

        setActiveHoverSection("")
        let active_scores = {
            "tm": 0,
            "tl": 0,
            "st": 0
        }
        setActiveHoverScores(active_scores)
        setHoverX(0)
        setHoverY(0)
    }

    const handleOuterSectionClick = (ev, idx) => {
        if (!ev) return;
        let true_idx = 0
        switch (idx) {
            case 0: true_idx = 2; break;
            case 1: true_idx = 3; break;
            case 2: true_idx = 4; break;
            case 3: true_idx = 5; break;
            case 4: true_idx = 0; break;
            case 5: true_idx = 1; break;
        }
        props.showPillars(true_idx)
    }

    const handleInnerSectionClick = (ev, idx) => {
        if(!ev) return;
        let true_idx = 0

        if(idx < 5) true_idx = 2;
        if(idx < 10 && idx >= 5) true_idx = 3;
        if(idx < 15 && idx >= 10) true_idx = 4;
        if(idx < 20 && idx >= 15) true_idx = 5;
        if(idx < 25 && idx >= 20) true_idx = 0;
        if(idx < 30 && idx >= 25) true_idx = 1;

        props.showPillars(true_idx)
    }

    return (
        <div className="summary-radial-chart-container">
            <svg height="700" width="700" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <path id="high-score-1" d="M 350,350 m 0,-305 a 305,305 90 0,1 305,305"/>
                    <path id="high-score-2" d="M 350,350 m -311,0 a 311,311 90 0,0 311,311"/>
                    <path id="high-score-3" d="M 350,350 m 0,305 a 305,305 90 0,1 -305,-305"/>
                    <path id="high-score-4" d="M 350,350 m -305,0 a 305,305 90 0,1 305,-305"/>

                    <path id="low-score-1" d="M 350,350 m 0,-75 a 75,75 90 0,1 75,75"/>
                    <path id="low-score-2" d="M 350,350 m -81.5,0 a 81.5,81.5 90 0,0 81.5,81"/>
                    <path id="low-score-3" d="M 350,350 m 0,75 a 75,75 90 0,1 -75,-75"/>
                    <path id="low-score-4" d="M 350,350 m -75,0 a 75,75 90 0,1 75,-75"/>

                    <path id="segment-text" d="M 350,350 m 330,0 a 330,330 60 0,1 -165,285.788"/>
                    <path id="segment-text-reversed" d="M 350,350 m -340,0 a 340,340 60 0,0 170,294.449"/>

                    <filter id="inner-circle-box-shadow" width="150" height="150">
                        <feOffset in="SourceAlpha" dx="0" dy="0"/>
                        <feGaussianBlur stdDeviation="5"/>
                        <feBlend in="SourceGraphic" in2="blurOut"/>
                    </filter>
                    <radialGradient id="pale-filter-inset-shadow" cx="50%" cy="50%" r="100%" fx="50%" fy="50%">
                        <stop offset="45%" stopColor="rgba(255, 255, 255, 0.5"/>
                        <stop offset="56%" stopColor="rgba(0, 0, 0, 1)"/>
                    </radialGradient>
                    <filter id="segment-drop-shadow">
                        <feColorMatrix type="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 100 0"
                                       result="boostedInput"/>
                        <feDropShadow dx="0" dy="0" stdDeviation="3" floodColor="rgba(0, 0, 0, 0.8)"/>
                        <feComposite operator="out" in2="boostedInput"/>
                    </filter>
                    <filter id="trans-shadow">

                    </filter>
                </defs>

                {/*Styling*/}
                <circle cx="350" cy="350" r="320" fill="url(#pale-filter-inset-shadow)"/>
                {calculateLineCoords().map((line, idx) => {
                    return <line x1="350" y1="350" x2={line[0]} y2={line[1]} id={`line-${idx}`}
                                 style={{stroke: "rgba(255, 255, 255, 0.5)", strokeWidth: "1"}} key={idx}/>
                })}

                {/*Team Member Graph*/}
                {props.tmEnabled && tmData.length ?
                    <>
                        <polygon points={calculateScoreCoordinates(tmData)} transform="rotate(6, 350, 350)"
                                 style={{fill: "rgba(255, 255, 255, 0.5)", stroke: "#27FF00", strokeWidth: "3"}}/>
                        {calculatePointMarkers(tmData).map((point, idx) => {
                            return <circle cx={point[0]} cy={point[1]} r="4" stroke="#27FF00" fill={getPointFill(idx)}
                                           transform="rotate(6, 350, 350)" style={{strokeWidth: "1"}} key={idx}/>
                        })}
                    </> : null
                }

                {/*Team Leader Graph*/}
                {props.tlEnabled && tlData.length ?
                    <>
                        <polygon points={calculateScoreCoordinates(tlData)} transform="rotate(6, 350, 350)"
                                 style={{fill: "rgba(255, 255, 255, 0.5)", stroke: "#FF00D8", strokeWidth: "3"}}/>
                        {calculatePointMarkers(tlData).map((point, idx) => {
                            return <circle cx={point[0]} cy={point[1]} r="4" stroke="#FF00D8" fill={getPointFill(idx)}
                                           transform="rotate(6, 350, 350)" style={{strokeWidth: "1"}} key={idx}/>
                        })}
                    </> : null
                }
                {/*Stakeholder Graph*/}
                {props.stEnabled && stData.length ?
                    <>
                        <polygon points={calculateScoreCoordinates(stData)} transform="rotate(6, 350, 350)"
                                 style={{fill: "rgba(255, 255, 255, 0.5)", stroke: "#000AFF", strokeWidth: "3"}}/>
                        {calculatePointMarkers(stData).map((point, idx) => {
                            return <circle cx={point[0]} cy={point[1]} r="4" stroke="#000AFF" fill={getPointFill(idx)}
                                           transform="rotate(6, 350, 350)" style={{strokeWidth: "1"}} key={idx}/>
                        })}
                    </> : null
                }

                {/*Outer score line*/}
                <text style={{fill: "#4D4D4D", fontSize: 8, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(45, 350, 350)">
                    <textPath href="#high-score-1" lengthAdjust="spacing" textLength={(2 * Math.PI * 305) / 4}>
                        ------------------------------- High Score -------------------------------
                    </textPath>
                </text>
                <text style={{fill: "#4D4D4D", fontSize: 8, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(-46, 350, 350)">
                    <textPath href="#high-score-2" lengthAdjust="spacing" textLength={(2 * Math.PI * 305) / 4}>
                        ------------------------------- High Score -------------------------------
                    </textPath>
                </text>
                <text style={{fill: "#4D4D4D", fontSize: 8, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(45, 350, 350)">
                    <textPath href="#high-score-3" lengthAdjust="spacing" textLength={(2 * Math.PI * 305) / 4}>
                        ------------------------------- High Score -------------------------------
                    </textPath>
                </text>
                <text style={{fill: "#4D4D4D", fontSize: 8, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(45, 350, 350)">
                    <textPath href="#high-score-4" lengthAdjust="spacing" textLength={(2 * Math.PI * 305) / 4}>
                        ------------------------------- High Score -------------------------------
                    </textPath>
                </text>

                {/*Inner score line*/}
                <text style={{fill: "#4D4D4D", fontSize: 8, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(45, 350, 350)">
                    <textPath href="#low-score-1" lengthAdjust="spacing" textLength={(2 * Math.PI * 75) / 4}>
                        ---- Low Score ----
                    </textPath>
                </text>
                <text style={{fill: "#4D4D4D", fontSize: 8, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(-45, 350, 350)">
                    <textPath href="#low-score-2" lengthAdjust="spacing" textLength={((2 * Math.PI * 75) / 4) + 1}
                              textAnchor="middle" startOffset="50%">
                        ---- Low Score ----
                    </textPath>
                </text>
                <text style={{fill: "#4D4D4D", fontSize: 8, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(45, 350, 350)">
                    <textPath href="#low-score-3" lengthAdjust="spacing" textLength={(2 * Math.PI * 75) / 4}
                              textAnchor="middle" startOffset="50%">
                        ---- Low Score ----
                    </textPath>
                </text>
                <text style={{fill: "#4D4D4D", fontSize: 8, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(45, 350, 350)">
                    <textPath href="#low-score-4" lengthAdjust="spacing" textLength={(2 * Math.PI * 75) / 4}
                              textAnchor="middle" startOffset="50%">
                        ---- Low Score ----
                    </textPath>
                </text>

                {/*Large section text*/}
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(-120, 350, 350)">
                    <textPath href="#segment-text-reversed" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 330) / 6) * 0.29} method="stretch">
                        Relationships
                    </textPath>
                </text>
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(-60, 350, 350)">
                    <textPath href="#segment-text-reversed" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 330) / 6) * 0.62} method="stretch">
                        Internal Systems & Processes
                    </textPath>
                </text>
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}>
                    <textPath href="#segment-text-reversed" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 330) / 6) * 0.19} method="stretch">
                        Learning
                    </textPath>
                </text>
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(180, 350, 350)">
                    <textPath href="#segment-text" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 330) / 6) * 0.23} method="stretch">
                        Leadership
                    </textPath>
                </text>
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(240, 350, 350)">
                    <textPath href="#segment-text" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 330) / 6) * 0.47} method="stretch">
                        Purpose & Motivation
                    </textPath>
                </text>
                <text style={{fill: "white", fontSize: 15, userSelect: "none", msUserSelect: "none"}}
                      transform="rotate(300, 350, 350)">
                    <textPath href="#segment-text" lengthAdjust="spacing" textAnchor="middle" startOffset="50%"
                              textLength={((2 * Math.PI * 330) / 6) * 0.62} method="stretch">
                        External Systems & Processes
                    </textPath>
                </text>

                {/*Small section text*/}
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(7, 350, 350)">
                    <tspan>Skills & Expertise</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(19, 350, 350)">
                    <tspan>Respect & Trust</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(31, 350, 350)">
                    <tspan x="510" dy="-0.5em">Camaraderie</tspan>
                    <tspan x="510" dy="1em">& Cohesion</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(43, 350, 350)">
                    <tspan>Safe Communication</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(55, 350, 350)">
                    <tspan x="510" dy="-0.5em">Values Diversity</tspan>
                    <tspan x="510" dy="1em">& Inclusion</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(67, 350, 350)">
                    <tspan x="510" dy="-0.5em">Appropriate</tspan>
                    <tspan x="510" dy="1em">Resourcing</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(79, 350, 350)">
                    <tspan>Team Agility</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(-91, 350, 350)" textAnchor="end">
                    <tspan>Accountability</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(-79, 350, 350)" textAnchor="end">
                    <tspan>System Efficacy</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(-67, 350, 350)" textAnchor="end">
                    <tspan>Team Protection</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(-55, 350, 350)" textAnchor="end">
                    <tspan>Development Planning</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(-43, 350, 350)" textAnchor="end">
                    <tspan>Learning Relevance</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(-31, 350, 350)" textAnchor="end">
                    <tspan>Embedded Learning</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(-19, 350, 350)" textAnchor="end">
                    <tspan x="190" dy="-0.5em">Developmental</tspan>
                    <tspan x="190" dy="1em">Orientation</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(-7, 350, 350)" textAnchor="end">
                    <tspan>Reflective Learning</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(5, 350, 350)" textAnchor="end">
                    <tspan>Vision & Context</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(17, 350, 350)" textAnchor="end">
                    <tspan x="190" dy="-0.5em">Collaborative &</tspan>
                    <tspan x="190" dy="1em">Empowering Leadership</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(29, 350, 350)" textAnchor="end">
                    <tspan x="190" dy="-0.5em">Interpersonal</tspan>
                    <tspan x="190" dy="1em">Leadership</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(41, 350, 350)" textAnchor="end">
                    <tspan>Leadership Courage</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(53, 350, 350)" textAnchor="end">
                    <tspan>Inspirational Leadership</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(65, 350, 350)" textAnchor="end">
                    <tspan>Alignment</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="190" y="350"
                      transform="rotate(77, 350, 350)" textAnchor="end">
                    <tspan>Clarity</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(271, 350, 350)">
                    <tspan x="510" dy="-0.5em">Enthusiasm</tspan>
                    <tspan x="510" dy="1em">& Contribution</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(283, 350, 350)">
                    <tspan>Enjoyment</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(295, 350, 350)">
                    <tspan>Support & Collaboration</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(307, 350, 350)">
                    <tspan x="510" dy="-0.5em">Environmental</tspan>
                    <tspan x="510" dy="1em">Awareness</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(319, 350, 350)">
                    <tspan x="510" dy="-0.5em">Stakeholder & Customer</tspan>
                    <tspan x="510" dy="1em">Alignment</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(331, 350, 350)">
                    <tspan x="510" dy="-0.5em">Talent & Resource</tspan>
                    <tspan x="510" dy="1em">Acquisition</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(343, 350, 350)">
                    <tspan x="510" dy="-0.5em">Stakeholder & Customer</tspan>
                    <tspan x="510" dy="1em">Communication</tspan>
                </text>
                <text style={{fill: "black", fontSize: 11, userSelect: "none", msUserSelect: "none"}} x="510" y="350"
                      transform="rotate(355, 350, 350)">
                    <tspan x="510" dy="-0.5em">Trust & Integrity in</tspan>
                    <tspan x="510" dy="1em">Relationships</tspan>
                </text>

                {/*Interactive elements*/}
                {generateOuterHoverSections().map((section, idx) => {
                    return <path d={section} style={{fill: "transparent", stroke: "none", cursor: "pointer"}}
                                 onClick={ev => handleOuterSectionClick(ev, idx)} key={idx}
                    />
                })}
                {generateInnerHoverSections().map((section, idx) => {
                    return <path d={section} id={`hover-${idx}`} key={idx}
                                 style={{fill: "black", stroke: "none", cursor: "pointer", fillOpacity: "0.01"}}
                                 onMouseOver={ev => handleSectionMouseOver(ev, idx)}
                                 onMouseLeave={ev => handleSectionMouseLeave(ev, idx)}
                                 onClick={ev => handleInnerSectionClick(ev, idx)}/>
                })}

                {/*Inner white circle*/}
                <circle cx="350" cy="350" r="40" fill="white" filter="url(#inner-circle-box-shadow)"/>
            </svg>
            {activeHoverSection && (props.tmEnabled || props.tlEnabled || props.stEnabled) ?
                <div className="summary-chart-section-hover-container" id="hover-popup-container"
                     style={{left: hoverX, top: hoverY}}
                     onMouseOver={() => handleSectionMouseOver(activeHoverEvent, activeHoverIdx)}
                     onMouseLeave={() => handleSectionMouseLeave(activeHoverEvent, activeHoverIdx)}>
                    <div className="summary-chart-section-hover-box">
                        {props.tmEnabled && activeHoverScores.tm && activeHoverScores.tm !== 0 ?
                            <div className="summary-chart-section-hover-box-section">
                                <div className="summary-chart-section-hover-score-container">
                                    <div className="summary-chart-section-hover-score-text">Score</div>
                                    <div
                                        className="summary-chart-section-hover-score-value">{Math.ceil(activeHoverScores.tm)}</div>
                                </div>
                                <div className="summary-chart-section-hover-theme-container">
                                    <div className="summary-chart-section-hover-theme-colour green"/>
                                    <div className="summary-chart-section-hover-theme-name">{activeHoverSection}</div>
                                </div>
                            </div> : null
                        }
                        {props.tlEnabled && activeHoverScores.tl && activeHoverScores.tl !== 0 ?
                            <div className="summary-chart-section-hover-box-section">
                                <div className="summary-chart-section-hover-score-container">
                                    <div className="summary-chart-section-hover-score-text">Score</div>
                                    <div className="summary-chart-section-hover-score-value">{Math.ceil(activeHoverScores.tl)}</div>
                                </div>
                                <div className="summary-chart-section-hover-theme-container">
                                    <div className="summary-chart-section-hover-theme-colour pink"/>
                                    <div className="summary-chart-section-hover-theme-name">{activeHoverSection}</div>
                                </div>
                            </div> : null
                        }
                        {props.stEnabled && activeHoverScores.st && activeHoverScores.st !== 0 ?
                            <div className="summary-chart-section-hover-box-section">
                                <div className="summary-chart-section-hover-score-container">
                                    <div className="summary-chart-section-hover-score-text">Score</div>
                                    <div className="summary-chart-section-hover-score-value">{Math.ceil(activeHoverScores.st)}</div>
                                </div>
                                <div className="summary-chart-section-hover-theme-container">
                                    <div className="summary-chart-section-hover-theme-colour blue"/>
                                    <div className="summary-chart-section-hover-theme-name">{activeHoverSection}</div>
                                </div>
                            </div> : null
                        }
                    </div>
                    <div className="summary-chart-section-hover-triangle">
                        <div className="summary-chart-section-hover-triangle-inner"/>
                    </div>
                </div> : null
            }
        </div>
    )
}

export default SummaryChart