import burger from "../assets/burger-icon.svg"
import {useEffect} from "react";

const CompanyCard = (props) => {
    const company = props.company;

    // TODO: Uncomment to show preview of logo in circle
    // company.logo = "https://nerdl.io/assets/img/n-logo.png"

    return (
        <>
            <div className={company.hidden ? "company-card hidden link" : "company-card link"}
                 onClick={() => props.handleClick(company)} style={{border:  '1px solid #' + company.colour}}>
                <div className="company-card-title">
                    <div className="company-name">
                        {company && company.name}
                    </div>
                </div>
                <div className="company-card-description-container">
                    <div className="company-short-description">
                        {company && company.description}
                    </div>
                    <div className="company-logo">
                        <div className="company-card-logo-container" style={{backgroundColor: company.colour ? `#${company.colour}` : '#0082CA'}}>
                            {company && company.logo ?
                                <img src={company && company.logo} alt={company && company.name + " Logo"}
                                     className="company-card-logo" />
                                : null }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CompanyCard
