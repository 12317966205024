import {useCallback, useEffect, useState} from "react";
import kapi from "../api/kratos";

const ForgotPassword = () => {

    const [email, setEmail] = useState();
    const [flowResponse, setFlowResponse] = useState();

    const InitRecovery = () => {
        const response = kapi.init_recovery();
        response.then(response => {
            setFlowResponse(response.data);
        }).catch()
    }

    const PageLoad = useCallback(() => {
        const params = new URLSearchParams(window.location.search);
        const flowId = params.get('flow');

        if (!flowId) {
            InitRecovery();
        } else {
            const response = kapi.get_recovery(flowId);
            response.then(response => {
                setFlowResponse(response.data);
            }).catch(err => {
                console.log("Unable to retrieve recovery flow: " + err);
            });
        }
    }, [])

    useEffect(() => {
        PageLoad()
    }, [PageLoad])

    useEffect(() => {
        localStorage.setItem('forgotten-password', JSON.stringify(false));
    }, []);

    return (
        <div className="forgot-page-container">
            <div className="forgot-container">
                <div className="forgot-form-container">
                    <form name="forgot-form" action={flowResponse && flowResponse.ui.action} method="POST">
                        {flowResponse && flowResponse.state === "sent_email" ?
                            <>
                                <div className="forgot-heading-container">
                                    <div className="forgot-heading">Check your email</div>
                                    <div className="forgot-subheading">
                                        We have sent a recovery link to the email address associated with
                                        your account.
                                    </div>
                                </div>
                                <input name="csrf_token" type="hidden"
                                       value={flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'csrf_token')?.attributes.value}/>
                                <input name="email" type="hidden" value={flowResponse.ui.nodes.find(n => n.attributes.name === 'email')?.attributes.value}/>
                                <div className="row">
                                    <div className="forgot-page-resend-container">
                                        <div className="forgot-page-resend-text">Didn’t receive the email?</div>
                                        <button className="forgot-button" type="submit" name="method" value="link">
                                            Resend email
                                        </button>
                                    </div>
                                </div>
                                <div className="field-input">
                                    <div className="label">
                                        <a className="link" href="/login">&lt; Go back to sign-in</a>
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className="forgot-heading-container">
                                    <div className="forgot-heading">Forgotten your password?</div>
                                    <div className="forgot-subheading">
                                        Please enter the email address associated with your account.
                                    </div>
                                </div>
                                <input name="csrf_token" type="hidden"
                                       value={flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'csrf_token')?.attributes.value}/>
                                <div className="field-input">
                                    <div className="label">Account email address</div>
                                    <div className="field-input-entry">
                                        <input type="email" placeholder="Enter your email" name="email"
                                               value={email}
                                               onChange={(ev) => setEmail(ev.target.value)}
                                               required/>
                                    </div>
                                </div>
                                <div className="field-input">
                                    <button className="forgot-button" name="method" type="submit" value="link"
                                            disabled={!email}>
                                        Send reset email
                                    </button>
                                </div>
                                <div className="field-input">
                                    <div className="label">
                                        <a className="link" href="/login">Go back to sign-in</a>
                                    </div>
                                </div>
                            </>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword