import axiosInstance from "../axios/axios_instance";
const http = axiosInstance("/protected",  null, "/dashboard");

const getProfile = () => http.get("/profile");
const getProfilePicture = () => http.get("/profile_picture");
const getCoachingOrganisationLogo = () => http.get("/coaching_organisation_logo");
const updateProfile = (body) => http.put("/profile", body);
const updateMfa = (body) => http.put("/profile/mfa", body);
const updateNewsletter = (body) => http.put("/profile/newsletter", body);
const updateProfilePicture = (body) => http.post("/profile_picture", body);
const updateCoachingOrganisationLogo = (body) => http.post("/coaching_organisation_logo", body);
const getSubscription = () => http.get("/get_subscription");
const getAccreditation = () => http.get("/accreditation")
const submitAccreditation = (body) => http.post("/accreditation", body)
const updateAutofill = (body) => http.put("/profile/autofill", body)

export default {
    getProfile,
    updateProfile,
    updateMfa,
    updateNewsletter,
    getProfilePicture,
    updateProfilePicture,
    getCoachingOrganisationLogo,
    updateCoachingOrganisationLogo,
    getSubscription,
    getAccreditation,
    submitAccreditation,
    updateAutofill
}