import back from "../../assets/dashboard_back.svg";
import del from "../../assets/delete-bin.svg";
import {useEffect, useState} from "react";


const SelectionPopup = (props) => {

    const [contextSelected, setContextSelected] = useState(0);

    const [companies, setCompanies] = useState([])
    const [teams, setTeams] = useState([])
    const [surveys, setSurveys] = useState([])

    const [selectionLevel, setSelectionLevel] = useState(0);
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [demoCompanySelected, setDemoCompanySelected] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(0);
    const [demoTeamSelected, setDemoTeamSelected] = useState(false);

    useEffect(() => {
        setContextSelected(props.selected)
        setSelectionLevel(props.selection_level)
        setCompanies(props.companies)
        setTeams(props.teams)
        setSurveys(props.surveys)
    }, [props]);

    const handleSelection = (ev) => {
        let idx;
        switch (ev.target.id) {
            case "company-selector":
                idx = companies.map(x => x.id).indexOf(parseInt(ev.target.value))
                if (selectionLevel === 0) props.updateSelected(companies[idx]);
                else {
                    setDemoCompanySelected(companies[idx].demo)
                    setSelectedCompany(parseInt(ev.target.value));
                }
                break;
            case "team-selector":
                idx = teams.map(x => x.id).indexOf(parseInt(ev.target.value))
                if (selectionLevel === 1) props.updateSelected(teams[idx]);
                else {
                    setDemoTeamSelected(teams[idx].demo)
                    setSelectedTeam(parseInt(ev.target.value));
                }
                break;
            case "survey-selector":
                idx = surveys.map(x => x.id).indexOf(parseInt(ev.target.value))
                props.updateSelected(surveys[idx]);
                break;
            default: break;
        }
    }

    return (
        <div className="page-mask">
            <div className="dashboard-selection-popup-container">
                <div className="dashboard-selection-popup-title">{props.title}</div>
                {selectionLevel >= 0 ?
                    <>
                        <div className="dashboard-selection-popup-description">Please select a company.</div>
                        <select className="dashboard-selection-popup-selector" id="company-selector"
                                onChange={handleSelection} value={selectionLevel === 0 ? contextSelected : selectedCompany}>
                            <option value={0} disabled hidden={true}>--</option>
                            {companies.map((company, idx) => {
                                return <option value={company.id} key={idx}>{company.name}</option>
                            })}
                        </select>
                    </> : null
                }
                {selectionLevel >= 1 && selectedCompany ?
                    <>
                        <div className="dashboard-selection-popup-description">Please select a team.</div>
                        <select className="dashboard-selection-popup-selector" id="team-selector"
                                onChange={handleSelection} value={selectionLevel === 1 ? contextSelected : selectedTeam}>
                            <option value={0} disabled hidden={true}>--</option>
                            {teams.map((team, idx) => {
                                if((team.company_id === selectedCompany) && (team.demo === demoCompanySelected)) {
                                    return <option value={team.id} key={idx}>{team.name}</option>
                                }
                            })}
                        </select>
                    </> : null
                }
                {selectionLevel === 2 && selectedTeam ?
                    <>
                        <div className="dashboard-selection-popup-description">Please select a survey</div>
                        <select className="dashboard-selection-popup-selector" id="survey-selector"
                                onChange={handleSelection} value={selectionLevel === 2 ? contextSelected : 0}>
                            <option value={0} disabled hidden={true}>--</option>
                            {surveys.map((survey, idx) => {
                                if((survey.team_id === selectedTeam) && (survey.demo === demoTeamSelected)) {
                                    return <option value={survey.id} key={idx}>{survey.name}</option>
                                }
                            })}
                        </select>
                    </> : null
                }
                <div className="dashboard-component-button-container">
                    {contextSelected ?
                        <div className="dashboard-component-button" onClick={props.closePopup}>
                            <img src={back} alt="Back"/>
                        </div>
                        : null
                    }
                    <div className="dashboard-component-button" onClick={() => props.deleteComponent(props.id)}>
                    <img src={del} alt="Delete Component"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectionPopup