import CompanyForm from "./CompanyForm";
import back_arrow from "../../assets/back_arrow.png"

const NewCompany = (props) => {

    const updateCompaniesGrid = props.updateCompaniesGrid

    return (
        <>
            <div className="back-link link" onClick={() => updateCompaniesGrid()}>
                <img src={back_arrow} alt={"Back Arrow"}/>
            </div>
            <div className="companies-page-header">
                <div className="companies-page-title">New Company</div>
            </div>
            <CompanyForm updateData={updateCompaniesGrid.bind(this)} buttonText={"Save"}/>
        </>
    )
}
export default NewCompany;