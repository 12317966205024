
const containsSpecial = (string) => {
    if (!string) return false
    return (/[@#$%^&*()_+={};':"|,.<>?!]/g.test(string))
}

const containsCapital = (string) => {
    if (!string) return false
    return (/[A-Z]/.test(string))
}

const containsDigit = (string) => {
    if (!string) return false
    return /\d/g.test(string)
}

const toTitleCase = (string) => {
    return string.split(' ').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ')
}

export default {
    containsSpecial,
    containsCapital,
    containsDigit,
    toTitleCase
}