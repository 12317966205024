

const types = [
    {
        "name": "CMI Team Coach - Foundation/Practitioner/Senior Practitioner",
        "value": "CMI Team Coach"
    },
    {
        "name": "GTCI Team Coach - Foundation/Practitioner/Senior Practitioner  (either PERILL or STC streams)",
        "value": "GTCI Team Coach"
    },
    {
        "name": "AOEC / Renewal Associates Systemic Team Coaching Certificate or Diploma",
        "value": "AOEC"
    },
    {
        "name": "Henley Business School:Professional Certificate in Team and Systemic Coaching",
        "value": "Henley Business School"
    },
    {
        "name": "ORSC",
        "value": "ORSC"
    },
    {
        "name": "Ruth Wageman’s 6 Conditions",
        "value": "Ruth Wageman"
    },
    {
        "name": "Team Coach International",
        "value": "Team Coach International"
    },
    {
        "name": "Smurfit Business School (Dublin)",
        "value": "Smurfit"
    },
    {
        "name": "High Performance Team Coaching System (Carr) ",
        "value": "Carr"
    },
    {
        "name": "Another EMCC & ICF accredited systemic team coaching programme at the same level.",
        "value": "other"
    },
]


export default {
    types
}