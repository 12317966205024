import React, {useEffect, useState} from 'react';
import {DndContext, DragOverlay} from '@dnd-kit/core';
import dashboard_api from "../api/protected/dashboard";
import Draggable from "../components/dashboard/Draggable";
import Droppable from "../components/dashboard/Droppable";
import Placeholder from "../components/dashboard/Placeholder";
import DashboardComponentSelector from "../components/dashboard/DashboardComponentSelector";
import SurveyOverview from "../components/dashboard/SurveyOverview";
import CoachROI from "../components/dashboard/CoachROI";
import SurveyCompletion from "../components/dashboard/SurveyCompletion";
import SurveyBarChart from "../components/dashboard/SurveyBarChart";
import SurveyLineChart from "../components/dashboard/SurveyLineChart";
import TodoComponent from "../components/dashboard/TodoComponent";
import Slider from "../components/Slider";
import auth from "../api/auth";
import stripe_api from "../api/protected/memberships"
import GenericDialog from "../components/GenericDialog";
import {useNavigate} from "react-router-dom";
import TextUtils from "../utils/TextUtils";


const Dashboard = () => {
    const slots = 10
    const navigate = useNavigate()
    const [draggingCardID, setDraggingCardID] = useState(null)
    const [draggingCard, setDraggingCard] = useState(null)
    const [allowMovement, setAllowMovement] = useState(false)
    const [dropAbleSlots, setDropAbleSlots] = useState(Array(slots).fill(null))
    const [cards, setCards] = useState(Array(slots).fill(null))

    const [showComponentMenu, setShowComponentMenu] = useState(false)
    const [activeComponentID, setActiveComponentID] = useState(null)

    const [allSurveys, setAllSurveys] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [allTeams, setAllTeams] = useState([])

    const [newAdded, setNewAdded] = useState(false)

    const buttons = [
        {"id": 1, "text": "Confirm and Proceed to Payment"},
        {"id": 2, "text": "Return to plan options"}
    ];
    const popUpText = () => {
       return (
           <div className="accreditation-popup-text">
               <div className="accreditation-popup-text-paragraph">
                   You are just about to pay for the {enquiryTier && TextUtils.toTitleCase(enquiryTier)} plan.
               </div>
               <div className="accreditation-popup-text-paragraph">
                   In order to be able to access the full functionality, you must be an accredited team coach, and
                   you will need to upload details of your team coach training and/or accreditation. This is to ensure
                   that only trained users who understand team coaching and the necessity for a psychologically safe
                   environment for debriefing diagnostics are provided access.
               </div>
               <div className="accreditation-popup-text-paragraph">
                   We accept suitably qualified Team Coaches from the systemic programmes listed below:
                   <ul>
                       <li>CMI Team Coach - Foundation/Practitioner/Senior Practitioner</li>
                       <li>GTCI Team Coach - Foundation/Practitioner/Senior Practitioner (either PERILL or STC
                           streams)</li>
                       <li>AOEC / Renewal Associates Systemic Team Coaching Certificate or Diploma</li>
                       <li>Henley Business School:Professional Certificate in Team and Systemic Coaching</li>
                       <li>ORSC</li>
                       <li>Ruth Wageman’s 6 Conditions</li>
                       <li>Team Coach International</li>
                       <li>Smurfit Business School (Dublin)</li>
                       <li>High Performance Team Coaching System (Carr)</li>
                       <li>Another EMCC & ICF accredited systemic team coaching programme at the same level</li>
                   </ul>
               </div>
               <div className="accreditation-popup-text-paragraph">
                   You will now be directed to the payment screen and you will be charged for your annual
                   subscription to the {enquiryTier && TextUtils.toTitleCase(enquiryTier)} plan. Following successful payment,
                   you will be asked to upload details of your accreditation. Approval of accreditation will be
                   within 7 working days. You will not be able to access the full features of
                   the {enquiryTier && TextUtils.toTitleCase(enquiryTier)} plan until you have uploaded these documents
                   and they have been verified, even if your payment has been taken.
               </div>
           </div>
       )
    }

    const [showAccreditationPopUp, setShowAccreditationPopUp] = useState(false);
    const [enquiryTier, setEnquiryTier] = useState("")

    const handleAccreditationDialogResult = (res) => {
        if (!res || !enquiryTier) return;
        if (res === "1") {
            switch (enquiryTier) {
                case 'silver':
                    stripe_api.CreateCheckoutSession('silver', "/dashboard")
                    break;
                case 'gold':
                    stripe_api.CreateCheckoutSession("gold", "/dashboard");
                    break;
            }
        }
        else if (res === "2") navigate("/pricing-plans")
        setShowAccreditationPopUp(false)
    }

    // Get Data
    useEffect(() => {
        getComponents()
        getCompanies()
        getSurveys()
        getTeams()
    }, [])

    const getCompanies = () => {
        let response = dashboard_api.getCompanySelections();
        response.then(response => {
            setAllCompanies(response.data)
        }).catch()
    }
    const getTeams = () => {
        let response = dashboard_api.getTeamSelections();
        response.then(response => {
            setAllTeams(response.data)
        }).catch()
    }
    const getSurveys = () => {
        let response = dashboard_api.getSurveySelections();
        response.then(response => {
            setAllSurveys(response.data)
        }).catch()
    }

    useEffect(() => {
        auth.hasSession().then((session) => {
            if (!session) window.location.assign("/logout");
            // Membership Enquiry after login/register
            let tier = stripe_api.ExecuteEnquiry();
            if (!tier) return;
            setEnquiryTier(tier)
            setShowAccreditationPopUp(true)
        });
    }, [])

    const getComponents = () => {
        let response = dashboard_api.getComponents();
        response.then(response => {
            let c = [...cards]
            let ds = [...dropAbleSlots]
            let component;
            for(const comp of response.data) {
                console.log(comp)
                switch (comp.type) {
                    case "overview": {
                        component = <SurveyOverview id={comp.position} context={comp.context_id} surveys={allSurveys}
                                                    teams={allTeams} companies={allCompanies} demo={comp.demo}
                                                    manage={removeComponent.bind(this)}/>;
                        break;
                    }
                    case "feedback": {
                        component = <CoachROI id={comp.position} context={comp.context_id} companies={allCompanies}
                                              demo={comp.demo} manage={removeComponent.bind(this)}/>;
                        break;
                    }
                    case "participant_completion": {
                        component = <SurveyCompletion id={comp.position} context={comp.context_id} surveys={allSurveys}
                                                      teams={allTeams} companies={allCompanies} demo={comp.demo}
                                                      manage={removeComponent.bind(this)}/>;
                        break;
                    }
                    case "team_completion": {
                        component = <SurveyBarChart id={comp.position} context={comp.context_id} companies={allCompanies}
                                                    demo={comp.demo} manage={removeComponent.bind(this)}/>
                        break;
                    }
                    case "survey_score": {
                        component = <SurveyLineChart id={comp.position} context={comp.context_id} teams={allTeams}
                                                     companies={allCompanies} demo={comp.demo}
                                                     manage={removeComponent.bind(this)}/>;
                        break;
                    }
                    case "todo": {
                        component = <TodoComponent id={comp.position} manage={removeComponent.bind(this)}/>;
                        break;
                    }
                    default: return;
                }
                let card = {
                    "component": component,
                    "id": comp.position,
                    "context_id": comp.context_id,
                    "type": comp.type,
                    "demo": comp.demo
                }
                c[comp.position] = card
                ds[comp.position] = true
            }
            setCards(c)
            setDropAbleSlots(ds)
        }).catch()
    }
    const addComponent = (id, type, newItem=false, context_id=null, isDemo=false) => {
        // Saves component to database if not loading from getComponents
        if(newItem) {
            if (type !== "todo" && !context_id) {
                setNewAdded(true)
                setAllowMovement(false)
            }
            let response = dashboard_api.createComponent(id, type, context_id, isDemo)
            response.then(() => {}).catch()
        }
        // make component
        let component;
        switch (type) {
            case "overview": {
                component = <SurveyOverview id={id} context={context_id} surveys={allSurveys}
                                            teams={allTeams} companies={allCompanies}
                                            manage={removeComponent.bind(this)}/>;
                break;
            }
            case "feedback": {
                component = <CoachROI id={id} context={context_id} companies={allCompanies}
                                             manage={removeComponent.bind(this)}/>;
                break;
            }
            case "participant_completion": {
                component = <SurveyCompletion id={id} context={context_id} surveys={allSurveys}
                                              teams={allTeams} companies={allCompanies}
                                              manage={removeComponent.bind(this)}/>;
                break;
            }
            case "team_completion": {
                component = <SurveyBarChart id={id} context={context_id} companies={allCompanies}
                                            manage={removeComponent.bind(this)}/>
                break;
            }
            case "survey_score": {
                component = <SurveyLineChart id={id} context={context_id} teams={allTeams}
                                             companies={allCompanies}
                                             manage={removeComponent.bind(this)}/>;
                break;
            }
            case "todo": {
                component = <TodoComponent id={id} manage={removeComponent.bind(this)}/>;
                break;
            }
            default: return;
        }
        return {
            "component": component,
            "id": id,
            "context_id": context_id,
            "type": type
        }
    }
    const storeComponent = (idx, cards_value) => {
        // Add card
        let c = [...cards]
        c[idx] = cards_value
        setCards(c)

        // Update drop-able slots
        let ds = [...dropAbleSlots]
        ds[idx] = true
        setDropAbleSlots(ds)
    }
    const removeComponent = (location) => {
        // Deletes component from database
        let response = dashboard_api.deleteComponent(location);
        response.then(() => {
            // Remove from cards
            let c = [...cards]
            c[location] = null
            setCards(c)
            // Update drop-able slots
            let ds = [...dropAbleSlots]
            ds[location] = null
            setDropAbleSlots(ds)
        })
    }
    const moveComponent = (new_position, old_position) => {
        let old_cards_info = cards[old_position]
        // Create copies of lists
        let ds = [...dropAbleSlots]
        let c = [...cards]
        let response = dashboard_api.deleteComponent(old_position);
        response.then(
            () => {
                c[old_position] = null
                c[new_position] = addComponent(new_position, old_cards_info.type, true, old_cards_info.context_id)
                // update the list of droppable slots
                ds[new_position] = true
                ds[old_position] = null
                // Update
                setDropAbleSlots(ds)
                setCards(c)
            }
        )
    }
    const SwapComponents = (new_position, old_position) => {
        if ((!new_position && new_position !== 0) || (!old_position && old_position !== 0)) return;
        let new_cards_info = cards[old_position]
        let current_cards_info = cards[new_position]
        // Create copies of lists
        let ds = [...dropAbleSlots]
        let c = [...cards]
        // Delete both old components from database and then once confirmation of deletes add the new ones
        let response = dashboard_api.deleteComponent(new_position);
        response.then(
            () => {
                let second_response = dashboard_api.deleteComponent(old_position);
                second_response.then(() => {
                    // Create New Cards and put into Cards List
                    let  new_card = addComponent(new_position, new_cards_info.type, true, new_cards_info.context_id)
                    let  current_card = addComponent(old_position, current_cards_info.type, true, current_cards_info.context_id)
                    c[new_position] = new_card
                    c[old_position] = current_card

                    // Update
                    setDropAbleSlots(ds)
                    setCards(c)
                })
            }
        )
    }
    const handleDragStart = (event) => {
        if (!cards[(event.active.id).split("-")[1]].component) return
        setDraggingCard(cards[(event.active.id).split("-")[1]].component)
        setDraggingCardID((event.active.id).split("-")[1])
    }
    const handleDragEnd = ({over}) => {
        // return if not over a valid drop-able slot
        if (!over) {
            ResetDragging()
            return;
        }
        let new_position = over.id && (over.id).split("-")[1]
        let old_position = draggingCardID
        // Do nothing if in same position OR already something in position
        if (new_position === old_position) {}
        else if (dropAbleSlots[new_position]) {
            SwapComponents(new_position, old_position)
        }
        else {
            moveComponent(new_position, old_position)
        }
        // reset dragging card
        ResetDragging()
    }
    const ResetDragging = () => {
        setDraggingCard(null)
        setDraggingCardID(null)
    }

    // New Card Menu
    const openMenu = (componentID) => {
        setActiveComponentID(componentID)
        setShowComponentMenu(true)
    }
    const closeMenu = (componentSelection) => {
        if (componentSelection && (activeComponentID || activeComponentID === 0)) {
            let card = addComponent(activeComponentID, componentSelection, true)
            storeComponent(activeComponentID, card)
        }
        setActiveComponentID(null)
        setShowComponentMenu(false)
    }

    return (
        <div className="dashboard-page-background-container">
            <div className="dashboard-page-container">
                <div className="dashboard-navigation-container">
                    {newAdded && newAdded
                        ? <button className={"save-dashboard-changes-button"} onClick={() => window.location.reload()}>Save</button>
                        : <Slider state={allowMovement} update={setAllowMovement.bind(this)} elem={"Unlock"}/>
                    }
                </div>
                <DashboardComponentSelector show={showComponentMenu}
                                            close={closeMenu.bind(this)} />
                <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                    <div className="row">
                        {allTeams && allSurveys && allCompanies && cards && cards.map((item, idx) => {
                            return (
                                <div className="dropable-container col-sm-6" key={idx}>
                                    <Droppable id={"drop-" + idx} disabled={false}>
                                        {!dropAbleSlots[idx]
                                            ? <Placeholder openMenu={openMenu.bind(this)} activeComponentID={idx}/>
                                            : allowMovement && allowMovement ?
                                                <Draggable id={"drag-"+idx} opacity={draggingCardID && idx === parseInt(draggingCardID)}>
                                                    {
                                                        item.type === "overview" ?
                                                            <SurveyOverview id={item.id} context={item.context_id} surveys={allSurveys} teams={allTeams} companies={allCompanies}
                                                                            manage={removeComponent} demo={item.demo} />
                                                            : item.type === "feedback" ?
                                                                <CoachROI id={item.id} context={item.context_id} companies={allCompanies}
                                                                          manage={removeComponent} demo={item.demo}/>
                                                                : item.type === "participant_completion" ?
                                                                    <SurveyCompletion id={item.id} context={item.context_id} surveys={allSurveys}
                                                                                      teams={allTeams} companies={allCompanies}
                                                                                      manage={removeComponent} demo={item.demo}/>
                                                                    : item.type === "team_completion" ?
                                                                        <SurveyBarChart id={item.id} context={item.context_id} companies={allCompanies}
                                                                                        manage={removeComponent} demo={item.demo}/>
                                                                        : item.type === "survey_score" ?
                                                                            <SurveyLineChart id={item.id} context={item.context_id} teams={allTeams}
                                                                                             companies={allCompanies} manage={removeComponent} demo={item.demo}/>
                                                                            : item.type === "todo" ?
                                                                                <TodoComponent id={item.id} manage={removeComponent}
                                                                                               cards={cards} drop_able_slots={dropAbleSlots}/>
                                                                                : null
                                                    }
                                                </Draggable>
                                                :
                                                    item.type === "overview" ?
                                                        <SurveyOverview id={item.id} context={item.context_id} surveys={allSurveys}
                                                                        teams={allTeams} companies={allCompanies}
                                                                        manage={removeComponent} demo={item.demo}/>
                                                        : item.type === "feedback" ?
                                                            <CoachROI id={item.id} context={item.context_id} companies={allCompanies}
                                                                      manage={removeComponent} demo={item.demo}/>
                                                            : item.type === "participant_completion" ?
                                                                <SurveyCompletion id={item.id} context={item.context_id} surveys={allSurveys}
                                                                                  teams={allTeams} companies={allCompanies} demo={item.demo}
                                                                                  manage={removeComponent}/>
                                                                : item.type === "team_completion" ?
                                                                    <SurveyBarChart id={item.id} context={item.context_id} companies={allCompanies}
                                                                                    manage={removeComponent} demo={item.demo}/>
                                                                    : item.type === "survey_score" ?
                                                                        <SurveyLineChart id={item.id} context={item.context_id} teams={allTeams}
                                                                                         companies={allCompanies}
                                                                                         manage={removeComponent} demo={item.demo}/>
                                                                        : item.type === "todo" ?
                                                                            <TodoComponent id={item.id} manage={removeComponent}
                                                                                           cards={cards} drop_able_slots={dropAbleSlots}/>
                                                                            : null

                                        }
                                    </Droppable>
                                </div>
                            )
                        })}
                    </div>
                    <DragOverlay>
                        {draggingCard && draggingCard ? draggingCard : null}
                    </DragOverlay>
                </DndContext>
            </div>
            {showAccreditationPopUp && showAccreditationPopUp ?
                <GenericDialog text={popUpText()} title={"Membership Accreditation"} buttons={buttons}
                               result={handleAccreditationDialogResult.bind(this)} wide={true} />
                : null}
        </div>
    )
}

export default Dashboard
