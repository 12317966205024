import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import coaches_api from "../../api/protected/coaching"
import CoCoachInviteGrid from "../CoCoachInviteGrid";
import CoCoachLinkGrid from "../CoCoachLinkGrid";
import CoCoachCoachesGrid from "../CoCoachCoachesGrid";
import CoCoachInviteForm from "../CoCoachInviteForm";



const SettingsCoCoaches = () => {

    const [coachEmail, setCoachEmail] = useState("")
    const [coachName, setCoachName] = useState("")
    const navigate = useNavigate();

    const [coaches, setCoaches] = useState([])
    const [invites, setInvites] = useState([])
    const [activeLinks, setActiveLinks] = useState([])

    const [showEditorWarning, setShowEditorWarning] = useState(false)

    useEffect(() => {
        getInvites()
        getCoaches()
    }, []);

    const getCoaches = () => {
        let response = coaches_api.getUserInvitations()
        response.then(response => {
            setCoaches(response.data)
        }).catch(err => console.log(err))
    }

    const getInvites = () => {
        let response = coaches_api.getCoachInvitations()
        response.then(response => {
            setInvites(response.data.invitations)
            setActiveLinks(response.data.active)
        }).catch(err => console.log(err))
    }

    const revokeInvite = (id) => {
        console.log(id)
        if (!id) return;
        let response = coaches_api.revokeInvitation(id)
        response.then(() => getCoaches()).catch(err => console.log(err))
    }

    const postCoCoach = (ev) => {
        if(!ev) return;
        let response = coaches_api.createInvitation(coachEmail)
        response.then(response => {
            navigate(`/co-coach/${response.data.coach_link_id}`)
        }).catch(err => console.log(err))
    }

    const handleRowClick = (id, status) => {
        if (!id || !status) return;
        navigate(`/co-coach/${id}`)
    }

    const handleInvite = (id, submit) => {
        if (!id) return;
        if (!submit) {
            rejectInvite(id)
        } else {
            acceptInvite(id)
        }
    }

    const acceptInvite = (id) => {
        if (!id) return;
        let response = coaches_api.acceptCoachInvitation(id)
        response.then(() => getInvites()).catch(err => console.log(err))
    }

    const rejectInvite = (id) => {
        if (!id) return;
        let response = coaches_api.rejectCoachInvitation(id)
        response.then(() => getInvites()).catch(err => console.log(err))
    }

    return (
        <>
            <CoCoachInviteGrid invites={invites} handle={handleInvite.bind(this)}/>
            <CoCoachCoachesGrid coaches={coaches} handle={handleRowClick.bind(this)} revoke={revokeInvite.bind(this)}/>
            <CoCoachInviteForm coachEmail={coachEmail} showEditorWarning={showEditorWarning}
                               postCoCoach={postCoCoach.bind(this)}
                               setShowEditorWarning={setShowEditorWarning.bind(this)}
                               setCoachEmail={setCoachEmail.bind(this)}
                               coachName={coachName} setCoachName={setCoachName.bind(this)}
            />
            <CoCoachLinkGrid activeLinks={activeLinks} handle={handleInvite.bind(this)}/>
        </>
    )
}

export default SettingsCoCoaches