import BackgroundRings from "../components/BackgroundRings";
import {useEffect, useState} from "react";
import contact_api from "../api/public/contact"
import {Link} from "react-router-dom";


const GetInTouch = () => {

    const [faqMenu1Open, setFaqMenu1Open] = useState(false)
    const [faqMenu2Open, setFaqMenu2Open] = useState(false)
    const [faqMenu3Open, setFaqMenu3Open] = useState(false)
    const [faqMenu4Open, setFaqMenu4Open] = useState(false)
    const [faqMenu5Open, setFaqMenu5Open] = useState(false)

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [checkbox, setCheckbox] = useState(false)

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant',
        })
    }, []);

    const toggleMenu = (ev) => {
        if (!ev) return;
        switch (ev.target.id) {
            case "faq-row-1": setFaqMenu1Open(!faqMenu1Open); break;
            case "faq-row-2": setFaqMenu2Open(!faqMenu2Open); break;
            case "faq-row-3": setFaqMenu3Open(!faqMenu3Open); break;
            case "faq-row-4": setFaqMenu4Open(!faqMenu4Open); break;
            case "faq-row-5": setFaqMenu5Open(!faqMenu5Open); break;
            default: break;
        }
    }

    const sendMessage = (ev) => {
        if (!ev || !name || !email || !phoneNumber || !message || !checkbox) return;
        contact_api.sendContactEmail(name, email, phoneNumber, subject, message)
    }

    const updateEmail = (ev) => {
        if (!ev) return;
        setEmail(ev.target.value)
    }
    const updateName = (ev) => {
        if (!ev) return;
        setName(ev.target.value)
    }
    const updatePhoneNumber = (ev) => {
        if (!ev) return;
        setPhoneNumber(ev.target.value)
    }
    const updateSubject = (ev) => {
        if (!ev) return;
        setSubject(ev.target.value)
    }
    const updateMessage = (ev) => {
        if (!ev) return;
        setMessage(ev.target.value)
    }
    const toggleCheckbox = (ev) => {
        if (!ev) return;
        setCheckbox(!checkbox)
    }

    return (
        <div className="learn-more-page-container get-in-touch">
            <BackgroundRings top={-370} right={-143}/>
            <div className="learn-more-page-title">Get in touch</div>
            <div className="learn-more-page-subtext">
                We are always happy to answer any questions that you might have regarding PERILL or the online
                Diagnostics for Teams platform and the related plans. Send us a message via the below form, and we’ll
                aim to get back to you within 48 hours.
            </div>
            <div className="get-in-touch-secondary-title">Firstly let’s see of our FAQ can help</div>
            <div className="learn-more-page-subtext extended-width">
                Sed a eros sodales diam sagittis faucibus. Cras id erat nisl. Fusce faucibus nulla sed finibus egestas.
                Vestibulum purus magna, auctor consectetur sem nec, consectetur porta.
            </div>
            <div className="get-in-touch-dropdown-container">
                <div className="get-in-touch-dropdown-row">
                    <div className="get-in-touch-dropdown-row-header no-select" id="faq-row-1" onClick={toggleMenu}>
                        <div className="get-in-touch-dropdown-row-title" id="faq-row-1">
                            What ante quis tincidunt porta, neque metus dapibus velit, porta aliquam nisi est quis
                            turpis?
                        </div>
                        <div className="get-in-touch-dropdown-row-button" id="faq-row-1">
                            {faqMenu1Open ? "-" : "+"}
                        </div>
                    </div>
                    <div className={faqMenu1Open ? "get-in-touch-dropdown-row-body" : "get-in-touch-dropdown-row-body hidden"}>
                        <div className="get-in-touch-dropdown-row-body-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna
                        </div>
                    </div>
                </div>
                <div className="get-in-touch-dropdown-row">
                    <div className="get-in-touch-dropdown-row-header no-select" id="faq-row-2" onClick={toggleMenu}>
                        <div className="get-in-touch-dropdown-row-title" id="faq-row-2">
                            What ante quis tincidunt porta, neque metus dapibus velit, porta aliquam nisi est quis
                            turpis?
                        </div>
                        <div className="get-in-touch-dropdown-row-button" id="faq-row-2">
                            {faqMenu2Open ? "-" : "+"}
                        </div>
                    </div>
                    <div className={faqMenu2Open ? "get-in-touch-dropdown-row-body" : "get-in-touch-dropdown-row-body hidden"}>
                        <div className="get-in-touch-dropdown-row-body-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna
                        </div>
                    </div>
                </div>
                <div className="get-in-touch-dropdown-row">
                    <div className="get-in-touch-dropdown-row-header no-select" id="faq-row-3" onClick={toggleMenu}>
                        <div className="get-in-touch-dropdown-row-title" id="faq-row-3">
                            What ante quis tincidunt porta, neque metus dapibus velit, porta aliquam nisi est quis
                            turpis?
                        </div>
                        <div className="get-in-touch-dropdown-row-button" id="faq-row-3">
                            {faqMenu3Open ? "-" : "+"}
                        </div>
                    </div>
                    <div className={faqMenu3Open ? "get-in-touch-dropdown-row-body" : "get-in-touch-dropdown-row-body hidden"}>
                        <div className="get-in-touch-dropdown-row-body-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna
                        </div>
                    </div>
                </div>
                <div className="get-in-touch-dropdown-row">
                    <div className="get-in-touch-dropdown-row-header no-select" id="faq-row-4" onClick={toggleMenu}>
                        <div className="get-in-touch-dropdown-row-title" id="faq-row-4">
                            What ante quis tincidunt porta, neque metus dapibus velit, porta aliquam nisi est quis
                            turpis?
                        </div>
                        <div className="get-in-touch-dropdown-row-button" id="faq-row-4">
                            {faqMenu4Open ? "-" : "+"}
                        </div>
                    </div>
                    <div className={faqMenu4Open ? "get-in-touch-dropdown-row-body" : "get-in-touch-dropdown-row-body hidden"}>
                        <div className="get-in-touch-dropdown-row-body-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna
                        </div>
                    </div>
                </div>
                <div className="get-in-touch-dropdown-row">
                    <div className="get-in-touch-dropdown-row-header no-select" id="faq-row-5" onClick={toggleMenu}>
                        <div className="get-in-touch-dropdown-row-title" id="faq-row-5">
                            What ante quis tincidunt porta, neque metus dapibus velit, porta aliquam nisi est quis
                            turpis?
                        </div>
                        <div className="get-in-touch-dropdown-row-button" id="faq-row-5">
                            {faqMenu5Open ? "-" : "+"}
                        </div>
                    </div>
                    <div className={faqMenu5Open ? "get-in-touch-dropdown-row-body" : "get-in-touch-dropdown-row-body hidden"}>
                        <div className="get-in-touch-dropdown-row-body-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna
                        </div>
                    </div>
                </div>
            </div>
            <div className="get-in-touch-more-help-container">
                <div className="get-in-touch-more-help-title">Still need more help? Contact us</div>
                <div className="get-in-touch-more-help-subtext">
                    If you have questions about the PERILL platform or do not know which plan to choose, leave your
                    request: we will be happy to answer all your questions.
                </div>
                <div className="get-in-touch-required-field">*Required field</div>
                <div className="get-in-touch-input-row">
                    <input type="text" placeholder="*Email" value={email} onChange={updateEmail}
                           className="get-in-touch-input"/>
                    <input type="text" placeholder="*Name" value={name} onChange={updateName}
                           className="get-in-touch-input"/>
                </div>
                <div className="get-in-touch-input-row">
                    <input type="text" placeholder="*Phone number" value={phoneNumber} onChange={updatePhoneNumber}
                           className="get-in-touch-input"/>
                    <input type="text" placeholder="Subject" value={subject} onChange={updateSubject}
                           className="get-in-touch-input"/>
                </div>
                <textarea placeholder="Your message here" value={message} onChange={updateMessage}
                          className="get-in-touch-input large-input"/>
                <div className="get-in-touch-radio-button-container">
                    <div className="get-in-touch-checkbox" onClick={toggleCheckbox}>
                        {checkbox ? <div className="get-in-touch-checkbox-centre"/> : null}
                    </div>
                    <div className="get-in-touch-radio-button-text">I agree the terms of data processing.</div>
                    <Link to='/privacy' className="get-in-touch-radio-button-link undecorated">Terms & Conditions</Link>
                </div>
                <div className="home-page-button filled-button" onClick={sendMessage}>SEND MESSAGE</div>
            </div>
        </div>
    )
}

export default GetInTouch