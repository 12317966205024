import {useEffect, useState} from "react";
import country_assets from "../../assets/json/countries.json";
import profile_api from "../../api/protected/profile";
import ImageUtils from "../../utils/ImageUtils";
import GenericDialog from "../GenericDialog";
import styles from "../../utils/ReactSelectStyles";
import Select from "react-select";
import SettingsDropdown from "./SettingsDropdown";

const SettingsAccountDetails = (props) => {
    const [title, setTitle] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [postcode, setPostcode] = useState();
    const [orgName, setOrgName] = useState();
    const [orgCountry, setOrgCountry] = useState();
    const [orgCountrySelected, setOrgCountrySelected] = useState();
    const [orgRole, setOrgRole] = useState();
    const [orgRoleSelected, setOrgRoleSelected] = useState();
    const organisationRoles = props.orgRoles

    const [organisationLogo, setOrganisationLogo] = useState();
    const [organisationLogoBase64, setOrganisationLogoBase64] = useState("")
    const [organisationLogoFileType, setOrganisationLogoFileType] = useState("")

    const [profileImage, setProfileImage] = useState();
    const [profileImageBase64, setProfileImageBase64] = useState("")
    const [profileImageFileType, setProfileImageFileType] = useState("")
    const [error, setError] = useState('');
    const [showSuccess, setShowSuccess] = useState(false)
    const buttons = [{"text":"OK", "id": "1"}];

    const reloadData = props.reloadData

    const countries_json = country_assets.countries;
    const countries = Object.entries(countries_json).map(([key, value]) => {
        return {
            name: value,
            value: key
        };
    });

    useEffect(() => {
        if (!props.profile) return;
        setTitle(props.profile.title);
        setFirstName(props.profile.first_name);
        setLastName(props.profile.last_name);
        setEmail(props.profile.email);
        setPostcode(props.profile.postcode);
        setOrgName(props.profile.coaching_organisation);
        setOrgCountry(props.profile.coaching_country);
        setOrgRole(props.profile.role);

        //Set defaults for react select
        let country = countries.find((country) => country.value === props.profile.coaching_country)
        setOrgCountrySelected(country)
        let org_role = organisationRoles.find((role) => role.id === props.profile.role)
        setOrgRoleSelected(org_role)
    }, [props.profile]);

    useEffect(() => {
        if (!props.profilePicture) return;
        setProfileImage(props.profilePicture);
    }, [props.profilePicture]);

    useEffect(() => {
        if (!props.coachingOrganisationLogo) return;
        setOrganisationLogo(props.coachingOrganisationLogo);
    }, [props.coachingOrganisationLogo]);

    const handleFormSubmit = (ev) => {
        ev.preventDefault();
        let profile = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "title": title,
            "postcode": postcode,
            "coaching_organisation": orgName,
            "coaching_country": orgCountry,
            "role_id": orgRole
        }
        let response = profile_api.updateProfile(profile);
        response.then(response => {
            if (response.status) {
                handleProfileImageUpdate()
            } else {
                setError("Failed to update your profile.")
            }
        }).catch(err => {console.log(err)})
    }

    const updateProfileImageFileType = (string) => {
        if (string === "" || string === undefined) return;
        setProfileImageFileType(string)
    }

    const handleProfileImageUpdate = () => {
        if (!profileImageBase64 || !profileImageFileType) {
            handleCoachingLogoUpdate();
            return;
        }
        let body = {
            "file_format": profileImageFileType,
            "image_string": profileImageBase64.split(',')[1]
        }
        let response = profile_api.updateProfilePicture(body);
        response.then((response) => {
            handleCoachingLogoUpdate()
        }
        ).catch((err) => {
            setError("Failed to update the profile image. Please ensure the file is a recognized format " +
                "and is not too large");
        })
    }

    const handleProfileImageClick = () => {
        let element = document.getElementById("profile-picture-uploader")
        if (!element) return;
        element.click()
    }

    const updateCoachingOrganisationLogoFileType = (string) => {
        if (string === "" || string === undefined) return;
        setOrganisationLogoFileType(string)
    }

    const handleCoachingLogoUpdate = () => {
        if (!organisationLogoBase64 || !organisationLogoFileType) {
            setShowSuccess(true)
            return;
        }
        let body = {
            "file_format": organisationLogoFileType,
            "image_string": organisationLogoBase64.split(',')[1]
        }
        let response = profile_api.updateCoachingOrganisationLogo(body);
        response.then(() => {
            setShowSuccess(true)
        }
        ).catch(() => {
            setError("Failed to update the coaching organisation logo. Please ensure the file is a recognized " +
                "format and is not too large");
        })
    }

    const handleCoachingOrganisationClick = () => {
        let element = document.getElementById("organisation-logo-uploader")
        if (!element) return;
        element.click()
    }

    const handleErrorDialogResult = (res) => {
        if(!res) return;
        reloadData()
        setError("")
        setShowSuccess(false)
        window.location.reload();
    }

    const updateTitle = (selection) => {
        if(!selection) return;
        setTitle(selection)
    }

    return (
        <>
            <div className="settings-form-container">
                <div className="settings-form-title">Account Details</div>
                <div className="duel-field-input-container">
                    <div className="field-input">
                        <div className="label">Title</div>
                        <div className="field-input-entry">
                            <SettingsDropdown options={["Mr", "Mrs", "Miss", "Rather not say"]} classnames="top-margin"
                                              selected={title} update={updateTitle.bind(this)}/>
                        </div>
                    </div>
                    <div className="field-input profile-image">
                        <div className="label">Profile Image</div>
                        <div className="field-input-entry flex">
                            <div className="settings-image-container link" onClick={() => handleProfileImageClick()}>
                                {profileImageBase64 && profileImageBase64 ?
                                    <img src={profileImageBase64} className="settings-image" alt="Profile Picture"/>
                                    : profileImage && profileImage ?
                                        <img src={profileImage} className="settings-image" alt="Profile Picture"/>
                                        : null
                                }
                            </div>
                            <div className="settings-image-edit-button" onClick={() => handleProfileImageClick()}>Edit</div>
                            <input accept="image/*" type='file' id="profile-picture-uploader"
                                   onChange={async (e) => {
                                       let base64_image = await ImageUtils.convertBase64(e.target.files[0]);
                                       setProfileImageBase64(base64_image);
                                       updateProfileImageFileType(e.target.files[0].name.split(".")[1]);
                                   }}
                                   className="profile-picture-uploader-hidden"
                            />
                        </div>
                    </div>
                </div>
                <div className="field-input">
                <div className="label">First Name</div>
                    <div className="field-input-entry">
                        <input className="settings-input" type="text" value={firstName}
                               onChange={ev => setFirstName(ev.target.value)} required
                        />
                    </div>
                </div>
                <div className="field-input">
                    <div className="label">Last Name</div>
                    <div className="field-input-entry">
                        <input className="settings-input" type="text" value={lastName}
                               onChange={ev => setLastName(ev.target.value)} required
                        />
                    </div>
                </div>
                <div className="field-input">
                    <div className="label">Email</div>
                    <div className="field-input-entry">
                        <input className="settings-input" type="email" value={email}
                               onChange={ev => setEmail(ev.target.value)} required
                        />
                    </div>
                </div>
                <div className="field-input">
                    <div className="label">Postcode</div>
                    <div className="field-input-entry">
                        <input className="settings-input-small" type="text" value={postcode}
                               onChange={ev => setPostcode(ev.target.value)} required
                        />
                    </div>
                </div>
            </div>
            <div className="settings-form-container">
                <div className="settings-form-title">Organisation</div>
                <div className="field-input">
                    <div className="label">Name</div>
                    <div className="field-input-entry">
                        <input className="settings-input max-width" type="text" value={orgName}
                               onChange={ev => setOrgName(ev.target.value)} required
                        />
                    </div>
                </div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <div className="label">Organisation Role</div>
                        <Select name="traits.organisation_role" options={organisationRoles} value={orgRoleSelected}
                                getOptionLabel={option => option.name} getOptionValue={option => option.id}
                                placeholder="" styles={styles.profileStyles} required={true}
                                onChange={ev => {
                                    setOrgRole(ev.id);
                                    setOrgRoleSelected(ev);
                                }}
                        />
                    </div>
                </div>
                <div className="duel-field-input-container max-width">
                    <div className="field-input">
                        <div className="label">Organisation Logo</div>
                        <div className="field-input-entry flex">
                            <div className="settings-image-container link"
                                 onClick={() => handleCoachingOrganisationClick()}>
                                {organisationLogoBase64 && organisationLogoBase64 ?
                                    <img src={organisationLogoBase64} className="settings-image"
                                         alt="Profile Picture"/>
                                    : organisationLogo && organisationLogo ?
                                        <img src={organisationLogo} className="settings-image" alt="Profile Picture"/>
                                        : null
                                }
                            </div>
                            <div className="settings-image-edit-button" onClick={() => handleCoachingOrganisationClick()}>Edit</div>
                            <input accept="image/*" type='file' id="organisation-logo-uploader"
                                   onChange={async (e) => {
                                       let base64_image = await ImageUtils.convertBase64(e.target.files[0]);
                                       setOrganisationLogoBase64(base64_image);
                                       updateCoachingOrganisationLogoFileType(e.target.files[0].name.split(".")[1]);
                                   }}
                                   className="organisation-logo-uploader-hidden"
                            />
                        </div>
                    </div>
                    <div className="field-input">
                        <div className="label">Country you are based in</div>
                        <div className="field-input-entry top-margin">
                            <Select name="traits.country" options={countries} value={orgCountrySelected}
                                    getOptionLabel={option => option.name} getOptionValue={option => option.value}
                                    placeholder="" styles={styles.profileStylesThin} required={true}
                                    onChange={ev => {
                                        setOrgCountry(ev.value);
                                        setOrgCountrySelected(ev);
                                    }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field-input">
                <div className="field-input-entry">
                    <button className="settings-button" onClick={handleFormSubmit}>Save</button>
                </div>
            </div>
            {error && error ?
                <GenericDialog title="Profile Update Error" text={error} buttons={buttons}
                               result={handleErrorDialogResult.bind(this)}/>
                : null
            }
            {showSuccess && showSuccess ?
                <GenericDialog title="Profile Update" text="Your Profile has successfully been updated"
                               result={handleErrorDialogResult.bind(this)} buttons={buttons}/>
                : null
            }
        </>
    )
}

export default SettingsAccountDetails