

const LegalClauseHeader = (props) => {
    return (
        <div className="legal-clause-container header">
            <div className="legal-clause-text-row">{props.text}</div>
            <div className="legal-clause-child-row">{props.children}</div>
        </div>
    )
}

export default LegalClauseHeader