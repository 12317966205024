import axiosInstance from "./axios/axios_instance"
import kratosAxiosInstance from "./axios/kratos_axios_instance";
const http_kratos = kratosAxiosInstance("/auth")

const base_redirect_url = process.env.REACT_APP_WEB_URL;

const check_session = () => http_kratos.get("sessions/whoami");
const init_registration = () => http_kratos.get("self-service/registration/browser");
const init_from_registration = (from) => http_kratos.get("self-service/registration/browser?return_to=" + base_redirect_url + from);
const get_registration = (flowId) => http_kratos.get("self-service/registration/flows?id=" + flowId);
const init_login = () => http_kratos.get("self-service/login/browser");
// const init_from_login = (from) => http_kratos.get("self-service/login/browser?return_to=" + base_redirect_url + from);
const get_login = (flowId) => http_kratos.get("self-service/login/flows?id=" + flowId);
const init_recovery = () => http_kratos.get("self-service/recovery/browser");
const get_recovery = (flowId) => http_kratos.get("self-service/recovery/flows?id=" + flowId);
const resend_recovery = (url) => http_kratos.post(url);
const init_settings = () => http_kratos.get("self-service/settings/browser");
const get_settings = (flowId) => http_kratos.get("self-service/settings/flows?id=" + flowId);
const init_verification = () => http_kratos.get("self-service/verification/browser");
const get_verification = (flowId) => http_kratos.get("self-service/verification/flows?id=" + flowId);
const get_error = (Id) => http_kratos.get("self-service/errors?id=" + Id);
const logout_urls = () => http_kratos.get("self-service/logout/browser");

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    check_session,
    init_registration,
    init_from_registration,
    get_registration,
    init_login,
    get_login,
    init_recovery,
    get_recovery,
    resend_recovery,
    init_settings,
    get_settings,
    init_verification,
    get_verification,
    get_error,
    logout_urls,
    // init_from_login,
}