import axiosInstance from "../axios/axios_instance";
const http = axiosInstance("/public",  null, null);

const sendContactEmail = (name, email, phone, subject, text) => http.post('/contact_us', {
    'name': name,
    'email': email,
    'phone': phone,
    'subject': subject,
    'text': text
})


export default {
    sendContactEmail
}