import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import teams_api from "../api/protected/teams";
import companies_api from "../api/protected/companies";
import CompaniesTeamsGridContainer from "../containers/CompaniesTeamsGridContainer";
import CompanyForm from "../components/companies/CompanyForm";
import Slider from "../components/Slider";
import DeleteDialog from "../components/DeleteDialog";
import deleteIcon from "../assets/delete-icon.svg";
import back_arrow from "../assets/back_arrow.png";


const EditCompany = () => {
    const navigate = useNavigate()
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams()

    const [companyId, setCompanyId] = useState("")
    const [isDemo, setIsDemo] = useState(false)

    const [company, setCompany] = useState(null)
    const [companyName, setCompanyName] = useState("");
    const [companyDescription, setCompanyDescription] = useState("");
    const [companySector, setCompanySector] = useState(0)
    const [companyLogo, setCompanyLogo] = useState()
    const [editor, setEditor] = useState(false)

    const [teams, setTeams] = useState();

    const [newTeam, setNewTeam] = useState("")

    const [showDelete, setShowDelete] = useState();
    const buttons = [{"text":"Delete", "id": "1"}, {"text":"Cancel", "id": "2"}];

    const [showDeleteHover, setShowDeleteHover] = useState(false)
    const [showHidden, setShowHidden] = useState()

    useEffect(() => {
        if (!params.companyId) return;
        if(searchParams.get('demo')) {
            setIsDemo(true);
        }
        setCompanyId(params.companyId)
    }, [params.companyId]);

    useEffect(() => {
        if (!companyId) return;
        getCompany()
        getTeams();
    }, [companyId]);

    const getCompany = () => {
        if (!companyId) return;

        if(isDemo) {
            let response = companies_api.getDemoCompany(companyId);
            response.then(response => {
                if (response.data.demo_companies.length === 0) BackToCompanies()
                let data = response.data.demo_companies[0];
                setCompany(data)
                setCompanyLogo(data.logo)
                setCompanyName(data.name)
                setCompanyDescription(data.description)
                setEditor(false)
            })
        } else {
            let response = companies_api.getCompany(companyId);
            response.then(response => {
                if (response.data.companies.length === 0) BackToCompanies()
                let data = response.data.companies[0];
                setCompany(data);
                setCompanyLogo(data.logo)
                setCompanyName(data.name);
                setCompanyDescription(data.description);
                setShowHidden(data.hidden);
                setCompanySector(data.sector);
                setEditor(data.editor)
            })
        }


    }

    const deleteCompany = () => {
        if (!companyId || company.demo) return;
        let response = companies_api.deleteCompany(companyId);
        response.then(() => {
            navigate("/companies")
        })
    }

    const getTeams = () => {
        if (!companyId) return;
        if(isDemo) {
            let response = teams_api.getDemoTeamsByCompanyID(companyId)
            response.then(response => {
                setTeams(response.data.teams)
            })
        } else {
            let response = teams_api.getTeamsByCompanyID(companyId);
            response.then(response => {
                setTeams(response.data.teams);
            })
        }
    }

    const formSubmit = (ev) => {
        if (!ev || !newTeam) return;
        navigate("/teams?name=" + newTeam + "&company=" + companyId)
    }

    const handleTeamClick = (team) => {
        if(isDemo) {
            navigate(`/teams/${team.id}?demo=true`)
        } else {
            navigate(`/teams/${team.id}`)
        }
    }

    const BackToCompanies = () => {
        navigate("/companies")
    }

    const updateToggleHidden = () => {
        setShowHidden(!showHidden);
    }

    const handleDeleteClick = () => {
        setShowDelete(!showDelete)
    }

    const handleDeleteResult = (result) => {
        if (result === "1") deleteCompany();
        setShowDelete(false)
    }

    const handleDeleteHoverOn = () => {
        setShowDeleteHover(true)
    }

    const handleDeleteHoverOut = () => {
        setShowDeleteHover(false)
    }

    useEffect(() => {
        const body = {"hidden": showHidden}
        let response = companies_api.putCompanyVisibility(companyId, body);
        response.catch(err => {console.log(err)})
    }, [showHidden]);


    return (
        <div className="companies-page-background-container">
            <div className="companies-page-container">
                <div className="back-link link" onClick={() => BackToCompanies()}>
                    <img src={back_arrow} alt={"Back Arrow"}/>
                </div>
                {company && company ?
                    <>
                        <div className="companies-page-header">
                            <div className="company-header-logo-container"
                                 style={{backgroundColor: company.colour ? `#${company.colour}` : '#0082CA'}}>
                                {company && company.logo ?
                                    <img src={company && company.logo} alt={company && company.name + " Logo"}
                                         className="company-card-logo"/>
                                    : null}
                            </div>
                            <div className="companies-page-title">{companyName ? companyName : "Edit Company"}</div>
                            <div className="col">
                                <div className="header-icon-container">
                                    {editor ?
                                        <div className="header-slider-container">
                                            <div className="header-hide-text">Hide</div>
                                            <Slider update={updateToggleHidden.bind(this)} state={showHidden} type="Company"/>
                                        </div> : null
                                    }
                                    <div className="header-delete-container" onMouseOver={handleDeleteHoverOn}
                                         onClick={handleDeleteClick} onMouseLeave={handleDeleteHoverOut}>
                                        <img className="header-delete-icon link" src={deleteIcon} alt="Delete Company"/>
                                        {showDeleteHover ?
                                            <div className="delete-tooltip-container">
                                                <div className="delete-tooltip-text">
                                                    Delete Company
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CompanyForm buttonText={"Update"} companyName={companyName && companyName}
                                     companyDescription={companyDescription && companyDescription}
                                     companyLogo={companyLogo && companyLogo}
                                     companySector={companySector && companySector} companyID={company && company.id}
                                     updateData={getCompany.bind(this)} editor={editor}
                                     companyDemo={company && company.demo}
                        />
                        <div className="company-form-container">
                            <div className="company-form-title">Teams</div>
                            <CompaniesTeamsGridContainer teams={teams} handleClick={handleTeamClick}/>
                            {editor ?
                                <div className="company-add-team-container">
                                    <div className="field-input">
                                        <div className="label">Team Name</div>
                                        <div className="field-input-entry">
                                            <input className="company-name-input" name="company.name" type="text"
                                                   value={newTeam} required
                                                   onChange={ev => setNewTeam(ev.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="field-input left-spacer">
                                        <button className="update-company-button team-member link" name="method"
                                                type="submit" value="team_name" disabled={!newTeam} onClick={formSubmit}>
                                            +
                                        </button>
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    </> : null
                }
                {showDelete ?
                    <DeleteDialog title={"Delete Company"} type={"Company"} name={companyName && companyName}
                                  description={companyDescription && companyDescription} buttons={buttons}
                                  result={handleDeleteResult.bind(this)}/>
                    : null
                }
            </div>
        </div>
    )
}

export default EditCompany