import axiosInstance from "../axios/axios_instance";
const http = axiosInstance("/public",  null, null);

const getQuestions = (uuid, key, pillar) => http.get(`/questionnaire/${uuid}/${key}/${pillar}`)

const updateResponses = (uuid, key, pillar, body) => http.post(`/questionnaire/${uuid}/${key}/${pillar}`, body)
const postROI = (uuid, access_key, response) => http.post('/roi_response', {
    "uuid": uuid,
    "key": access_key,
    "response": response
})

export default {
    getQuestions,
    updateResponses,
    postROI
}