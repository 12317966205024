import {useEffect, useState} from "react";


const LegalClause = (props) => {
    const [className, setClassName] = useState("legal-clause-container")

    return (
        <div className="legal-clause-container">
            <div className="legal-clause-text-row">{props.text}</div>
            <div className="legal-clause-child-row">{props.children}</div>
        </div>
    )
}

export default LegalClause