import axiosInstance from "../axios/axios_instance";
const http = axiosInstance("/public",  null, null);

const putUnsubscribe = (body) => http.put(`/unsubscribe`, body)

const subscribe = (body) => http.post(`/subscribe`, body)

export default {
    putUnsubscribe,
    subscribe
}