import {useEffect, useState} from "react";
import dashboard_api from "../../api/protected/dashboard";
import NoData from "./NoData";
import SelectionPopup from "./SelectionPopup";
import PercentageWheel from "../PercentageWheel";
import edit from "../../assets/edit-pencil.svg";
import del from "../../assets/delete-bin.svg";
import participant_icon from "../../assets/participant-icon.svg"
import date_icon from "../../assets/date-icon.svg"


const SurveyOverview = (props) => {

    const [showEditMenu, setShowEditMenu] = useState(true);
    const [selectedTeam, setSelectedTeam] = useState(0)

    const [allSurveys, setAllSurveys] = useState([])
    const [allTeams, setAllTeams] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [selectedSurvey, setSelectedSurvey] = useState(0)
    const [isDemo, setIsDemo] = useState(false)

    const [companyName, setCompanyName] = useState("")
    const [colour, setColour] = useState("")
    const [participants, setParticipants] = useState(0)
    const [issueDate, setIssueDate] = useState(0)
    const [expiryDate, setExpiryDate] = useState(0)
    const [completion, setCompletion] = useState(0)


    useEffect(() => {
        if(!props) return;
        setIsDemo(props.demo)
        setAllSurveys(props.surveys)
        setAllTeams(props.teams)
        setAllCompanies(props.companies)
        if(!props.context) return;
        setSelectedSurvey(props.context)
        setShowEditMenu(false);
    }, [props]);

    useEffect(() => {
        if(!selectedSurvey) return;
        getSurveyOverview(selectedSurvey)
    }, [selectedSurvey]);

    const getSurveyOverview = (id) => {
        let response;
        if(isDemo || props.demo) {
            response = dashboard_api.getDemoSurveyOverview(id);
        } else {
            response = dashboard_api.getSurveyOverview(id);
        }
        response.then(response => {
            setCompanyName(response.data.company_name);
            setSelectedTeam(response.data.team_name);
            setColour(response.data.company_colour);
            setParticipants(response.data.survey_participants);
            setIssueDate(response.data.issue_date);
            setExpiryDate(response.data.expiry_date);
            setCompletion(response.data.completion_percentage);
            setShowEditMenu(false)
        }).catch(err => console.log(err))
    }

    const formatDate = (ts) => {
        let date = new Date(ts * 1000)
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }

    const handleInfoClick = () => {
        //Do something
        return;
    }

    const updateSelectedSurvey = (survey) => {
        if(!survey) return;
        setIsDemo(survey.demo)
        let response = dashboard_api.updateComponent(props.id, survey.id, survey.demo);
        response.then(() => {
            setSelectedSurvey(survey.id)
        })
    }

    const openEditMenu = (ev) => {
        if(!ev) return;
        setShowEditMenu(true)
    }
    const closeEditMenu = (ev) => {
        if(!ev) return;
        setShowEditMenu(false);
    }

    return (
        <>
            <div className="dashboard-component">
                {participants ?
                    <>
                        <div className="company-overview-component-container">
                            <div className="company-overview-info-column">
                                <div className="company-overview-title">{companyName}</div>
                                <div className="company-overview-info-container">
                                    <img src={participant_icon} alt="Participants"/>
                                    <div className="company-overview-info-key-value">
                                        <div className="company-overview-info-key">Number of participants</div>
                                        <div className="company-overview-info-value">{participants}</div>
                                    </div>
                                </div>
                                <div className="company-overview-info-container">
                                    <img src={date_icon} alt="Issue Date"/>
                                    <div className="company-overview-info-key-value">
                                        <div className="company-overview-info-key">Issue survey date</div>
                                        <div className="company-overview-info-value">{formatDate(issueDate)}</div>
                                    </div>
                                </div>
                                <div className="company-overview-info-container">
                                    <img src={date_icon} alt="Expiry Date"/>
                                    <div className="company-overview-info-key-value">
                                        <div className="company-overview-info-key">Issue expiry date</div>
                                        <div className="company-overview-info-value">{formatDate(expiryDate)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="company-overview-chart-column">
                                <div className="company-overview-chart-title">{`${selectedTeam} - Survey ${selectedSurvey}`}</div>
                                <PercentageWheel handleInfoClick={handleInfoClick.bind(this)}
                                                 colour={colour}
                                                 completion={completion}
                                                 text="COMPLETE"
                                                 customClass="low-margin"
                                />
                            </div>
                        </div>
                        <div className="dashboard-component-button-container company-overview">
                            <div className="dashboard-component-button" onClick={openEditMenu}>
                                <img src={edit} alt="Edit Component"/>
                            </div>
                            <div className="dashboard-component-button" onClick={() => props.manage(props.id)}>
                                <img src={del} alt="Delete Component"/>
                            </div>
                        </div>
                    </>
                    :
                    <NoData id={props.id} edit={openEditMenu.bind(this)} delete={props.manage} context="survey"/>
                }
            </div>
            {showEditMenu ?
                <SelectionPopup id={props.id}
                                title="Diagnostic Summary"
                                selection_level={2}
                                companies={allCompanies}
                                teams={allTeams}
                                surveys={allSurveys}
                                selected={selectedSurvey}
                                updateSelected={updateSelectedSurvey.bind(this)}
                                closePopup={closeEditMenu.bind(this)}
                                deleteComponent={props.manage}
                />
                : null
            }
        </>
    )
}

export default SurveyOverview