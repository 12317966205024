import {useEffect, useState} from "react";
import ROITabularRow from "./ROITabularRow";


const ROITabular = (props) => {

    const [data, setData] = useState([])

    useEffect(() => {
        setData(props.data)
    }, [props.data]);

    const getAllCompaniesMean = (idx) => {
        if(!data || !data.length) return;
        let total = 0
        let count = 0
        for(const company of data) {
            for(const team of company.team_data) {
                total += team.scores[idx]
                count += 1
            }
        }
        if (!count) return 0;

        return total / count
    }

    const normal_round = (value) => {
        if (!value) return;
        if (value - Math.floor(value) < 0.5) return Math.floor(value);
        return Math.ceil(value)
    }

    const formatScore = (score) => {
        let s = normal_round(score)
        if (s > 0) {
            return `+${s}%`
        } else {
            return `${s}%`
        }
    }


    return (
        <div className="roi-tabular-container">
            <div className="summary-tabular-header-container">
                <div className="summary-tabular-title-container">
                    <div className="summary-tabular-title">Tabular</div>
                </div>
            </div>
            <div className="roi-tabular-top-row">
                <div className="roi-tabular-spacer-row"/>
                <div className="summary-tabular-section-column">Purpose & Motivation</div>
                <div className="summary-tabular-section-column">External Systems & Processes</div>
                <div className="summary-tabular-section-column">Relationships</div>
                <div className="summary-tabular-section-column">Internal Systems & Processes</div>
                <div className="summary-tabular-section-column">Learning</div>
                <div className="summary-tabular-section-column">Leadership</div>
            </div>
            <div className="summary-tabular-colour-row">
            <div className="roi-tabular-spacer-row"/>
                <div className="summary-tabular-section-column">
                    <div className="summary-tabular-pillar-colour blue"/>
                </div>
                <div className="summary-tabular-section-column">
                    <div className="summary-tabular-pillar-colour red"/>
                </div>
                <div className="summary-tabular-section-column">
                    <div className="summary-tabular-pillar-colour green"/>
                </div>
                <div className="summary-tabular-section-column">
                    <div className="summary-tabular-pillar-colour purple"/>
                </div>
                <div className="summary-tabular-section-column">
                    <div className="summary-tabular-pillar-colour pink"/>
                </div>
                <div className="summary-tabular-section-column">
                    <div className="summary-tabular-pillar-colour orange"/>
                </div>
            </div>
            {data && data.map((company, idx) => {
                return <ROITabularRow data={company} key={idx}/>
            })}
            <div className="summary-tabular-spacer-border"/>
            <div className="summary-tabular-team-row mean">
                <div className="roi-tabular-row-name">Overall Mean</div>
                <div className={`summary-tabular-section-column ${getAllCompaniesMean(0) > 0 ? "green" : "red"}`}>
                    {formatScore(getAllCompaniesMean(0))}
                </div>
                <div className={`summary-tabular-section-column ${getAllCompaniesMean(1) > 0 ? "green" : "red"}`}>
                    {formatScore(getAllCompaniesMean(1))}
                </div>
                <div className={`summary-tabular-section-column ${getAllCompaniesMean(2) > 0 ? "green" : "red"}`}>
                    {formatScore(getAllCompaniesMean(2))}
                </div>
                <div className={`summary-tabular-section-column ${getAllCompaniesMean(3) > 0 ? "green" : "red"}`}>
                    {formatScore(getAllCompaniesMean(3))}
                </div>
                <div className={`summary-tabular-section-column ${getAllCompaniesMean(4) > 0 ? "green" : "red"}`}>
                    {formatScore(getAllCompaniesMean(4))}
                </div>
                <div className={`summary-tabular-section-column ${getAllCompaniesMean(5) > 0 ? "green" : "red"}`}>
                    {formatScore(getAllCompaniesMean(5))}
                </div>
            </div>
        </div>
    )
}


export default ROITabular